import { Styles } from '../../types';

const styles: Styles = {
  Fab: () => ({
    margin: 0,
    top: 'auto',
    right: 30,
    bottom: 30,
    left: 'auto',
    position: 'fixed',
  }),
}

export default styles