import arrowUrl from '../../../assets/images/misc/arrow_down_right.jpeg';
import { SxProps } from '@mui/material';

export const groupInfoContainerSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: ['column', 'row'],
};

export const bigInfoContainerSx = (direction: 'left' | 'down' | 'down_reverse' | 'none' = 'none'): SxProps => ({
  display: ['flex', 'block'],
  minWidth: ['auto', '400px'],
  textAlign: 'center',
  p: [0, 10],
  mb: [5, 0],
  position: 'relative',
  ':after': {
    content: '""',
    background: direction === 'none' ? '' : `url(${arrowUrl})`,
    backgroundSize: 'contain',
    display: ['none', 'block'],
    width: '100px',
    height: '100px',
    position: 'absolute',
    margin: 'auto',
    left: '50%',
    transform: (direction === 'left' && 'scaleX(-1)') || (direction === 'down' && 'rotate(90deg) translateY(-50px)') || (direction === 'down_reverse' && 'rotate(90deg) scaleY(-1) translateY(-150px)') || 'none',
  }
});

export const smallInfoContainerSx: SxProps = {
  px: [4, 8], py: [4, 8], my: [4, 0],
};