import { Box, IconButton, MobileStepper, Typography } from '@mui/material';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import styles from './Hero.styles';
import { useComputedResource } from '../../hooks/useComputedResource';
import SwipeableViews  from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export type HeroFileExtensions = 'webp' | 'jpeg' | 'png';

interface HeroProps {
  imageName: string | string[];
  directory?: string;
  extension?: HeroFileExtensions;
  hasFilter?: boolean;
  size?: 'big' | 'small';
}

export function Hero({
  imageName,
  children,
  directory = 'heroes',
  extension = 'webp',
  hasFilter = true,
  size = 'big',
}: PropsWithChildren<HeroProps>) {
  const images: string[] = useComputedResource(imageName, directory, extension);
  const [currentImage, setCurrentImage] = useState(0)

  const handleChangeIndex = useCallback((index: number) => {
    setCurrentImage(index);
  }, []);

  return (
    <Box sx={styles.HeroContainer(size)}>
      {images.length > 1 ? (
        <>
          <AutoPlaySwipeableViews
            autoPlay
            index={currentImage}
            interval={4000}
            onChangeIndex={handleChangeIndex}
          >
            {images.map((imageUrl: string) => (
              <Box key={imageUrl} sx={styles.HeroOverlay(imageUrl, hasFilter, size)} />
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            backButton={<IconButton/>}
            nextButton={<IconButton/>}
            steps={images.length}
            activeStep={currentImage}
            sx={{ position: 'relative' }}
          />
        </>
      ) : (
        <Box
          className='animate__animated animate__fadeIn'
          sx={styles.HeroOverlay(images[0], hasFilter, size)}
        />
      )}
      {children && (
        <Typography
          className='animate__animated animate__fadeIn'
          sx={{
            ...styles.Title(),
            width: {
              xs: '90%',
              md: 'auto',
            },
            fontSize: {
              xs: '2.7rem',
              md: '3.5rem',
            }
          }}
        >
          {children}
        </Typography>
      )}
    </Box>
  )
}