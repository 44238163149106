import { Box, Card, CardContent, CardMedia, Grid, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useComputedResource } from '../../../../hooks/useComputedResource';

interface GridItemProps {
  title: string;
  href?: string;
  onClick?: () => void;
  description?: string;
  imageName: string;
  baseRoute: string;
  asIs?: boolean;
}

export function GridItem({
  description,
  href = '',
  title,
  imageName,
  baseRoute,
  asIs = false,
  onClick,
}: GridItemProps) {
  const [imageUrl]: string[] = useComputedResource(imageName, baseRoute, undefined, undefined, asIs);

  const handleOnClickLink = useCallback((event: any) => {
    if (onClick) {
      event.preventDefault()
      event.stopPropagation()
      onClick()
    }
  }, [onClick])

  return (
    <Grid key={title} item xs={16} md={6}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Link to={href} onClick={handleOnClickLink}>
          <Card
            elevation={5}
            sx={{
              maxWidth: 380,
              minHeight: 305,
              ':hover': {
                boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)'
              }
            }}
          >
            <CardMedia
              component="img"
              alt={title}
              height="140"
              image={imageUrl}
            />
            <CardContent>
              <Tooltip title={title}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: 'primary.main',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    fontWeight: 'bold',
                    fontSize: 25,
                  }}
                >
                  {title}
                </Typography>
              </Tooltip>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '4',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {description}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
    </Grid>
  )
}