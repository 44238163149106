const en = {
    "translation": {
        "home": {
            "1": "Who are we?",
            "2": "is an international initiative funded by the European Union through the Erasmus+ program, which includes a transnational consortium formed by Romania, Italy, and Türkiye",
            "3": "Our focus.",
            "4": "The project focuses on similar problems identified in each country and a common solution which is the development of critical thinking competencies of the young generation.",
            "5": "Our Objectives.",
            "6": "In order to develop critical thinking and correct information competencies for the youth, the project it was initiated a bidimensional topic approach:",
            "7": "The objectives of EDUcate YOUth are:",
            "8": "non-formal education perspective",
            "9": "a structural approach as the change in mentality needs to be sustained at the systemic level provided by a transectorial collaboration (youth and education sectors).",
            "10": "This backtracking process for solving the common problems in the twin educational sectors (formal and non-formal) provided a strategic approach to the matter forming a strong transectorial partnership between youth NGOs, educational institutions, and local administration in the regions of Oltenia - Romania, Bari - Italy and Hatay - Türkiye.",
            "11": "Development and consolidation of key competencies regarding critical thinking and correct information",
            "12": "Generate a pilot cross-sectoral cooperation model between youth organizations and educational institutions, multipliable on a large scale in a main-stream system, in the areas of critical thinking and correct information",
            "13": "Increase the organizational expertise for the four project partners, by developing non-formal methodologies that create synergies between the 2 sectors",
            "14": "“EDUcate YOUth” expands over 24 months and is divided into two main timeline components.",
            "15": "First-year is focused on local research of the specific needs, context interpretation, and stratification, development of transectorial cooperation, and non-formal educational methods.",
            "16": "Second-year is focused on refining the methods to be used large-scale, by developing the e-learning platform with free access to critical thinking methods for everybody. The newly generated educational methods were piloted and refined with the help of the youth workers, students, and teachers feedback in all three project partner countries.",
            "partners": "Our Partners",
        },
        "disinformation": {
            "title": "Critical Thinking and Disinformation Kit",
            "description1": "This section is about what critical thinking, disinformation and misinformation means.",
            "description2": "What are the most important critical skills and how to improve our critical thinking skills?",
            "description3": "How to use disinformation and misinformation?",
            "description4": "What are the threats of disinformation in modern society?",
            "description5": "What is CRAAP (Currency, Relevance, Authority, Accuracy and Purpose) Method?",
            "description6": "Some websites present opinion pieces as news and false information deliberately is spreading in order to influence society.",
            "description7": "Put aside all judgements and start to think critically about any content, problems and sources.",
            "1": "In our daily lives, false information is frequently circulated. News flash: We humans are not flawless. Everybody is capable of making mistakes. We all have memory loss. Details are misheard or forgotten. We misrepresent information to our pals that we learned via social media or heard on television. Technically speaking, distributing misinformation is what you are doing if you are disseminating false information but are unaware that it is false.",
            "2": "The term misinformation, which combines information with the word mis-, which means \"wrong\" or \"mistaken,\" was first used in the late 1500s. Other frequent terms that utilize the prefix mis- to denote \"wrong\" include mistake, misspelling, and misunderstanding.",
            "3": "Of course, misinformation is related to the verb misinform, which originally appeared in writing between the years of 1350 and 1400 and means \"to offer inaccurate or misleading information to.\" You'll see that misinform, like misinformation, only states that this false information is being disseminated.",
            "4": "Disinformation is defined as incorrect information that is purposefully and frequently discreetly distributed (such as through sowing rumours) in order to sway public opinion or obfuscate the truth.",
            "5": "Additionally, it can refer to \"deliberately false or biased information, fabricated stories or facts, propaganda\" in a broader sense.",
            "6": "Disinformation is a common espionage technique because it is so potent, destructive, and polarising. As the Soviet Union and the United States did during the Cold War, for example, states frequently have an interest in sending false information to their adversaries. A disinformation campaign is frequently used to describe when a nation or group coordinates a complex disinformation-spreading strategy.",
            "7": "Disinformation can come from propaganda, which is information that is conveyed to make someone or something look good or bad. Modern dictatorships, like that of North Korea, use propagandistic misinformation to instil in their people a sense of righteousness and goodness toward their own government and a wickedness toward their adversaries. An internet disinformation operation that circulated false information about COVID-19 and attributed the outbreak to the United States, as an illustration, was allegedly supported by the Chinese government, according to the United States State Department.",
            "8": "Keep in mind the word \"purpose\" while distinguishing between misinformation and disinformation. Both terms apply to different kinds of inaccurate or incorrect information, but only misinformation is inaccurate on purpose. Even though it might seem obvious, misinformation and disinformation are often used interchangeably since they are similar and connected in some situations.",
            "9": "Use the word disinformation then you can be certain that misleading or incorrect information is being disseminated with the intent to harm or damage, particularly a government, organisation, or public person, you should use the term disinformation. Use misinformation wherever possible if you don't know with certainty why someone is disseminating false information.",
            "10": "In the digital era, misinformation and disinformation are pervasive and include conspiracy theories, propaganda, deep fakes, fake news, hoaxes, frauds, Photoshop manipulations, and scams.",
            "11": "Never before has misinformation manufacturing and distribution been so simple and affordable to automate. We now have automated social bots that can quickly spread an article with false information more than fifty times a day on average. However, they play more than just that; by guiding traffic, they can also quickly provide advertising money. Only recently have we begun to see the effects this technology would have on our cultures and democracies.",
            "12": "How misinformation and disinformation is produced is directly related to who the author(s) is and the different reasons why it is created.",
            "13": "Who are the authors?  They may be:",
            "14": "Independent of the article's content, someone who wants to profit;",
            "15": "Satirists who want to either make a point or entertain you, or both;",
            "16": "Poor or inexperienced journalists may produce subpar work that violates professional journalistic standards or ethics due to the strain of the 24-hour news cycle and the proliferation of news websites;",
            "17": "Partisans seeking to sway political attitudes and decision-makers;",
            "18": "The technological ease of copying, pasting, clicking and sharing content online has helped these types of articles to proliferate. In some cases, the articles are designed to provoke an emotional response and placed on certain sites (\"seeded\") in order to entice readers into sharing them widely. In other cases, \"fake news\" articles may be generated and disseminated by \"bots\" - computer algorithms that are designed to act like people sharing information, but can do so quickly and automatically.",
            "19": "There are numerous strategies to combat this scourge. Education of cyber citizens is always the best course of action, while it is not the quickest or simplest. The ability to develop an informed and thoughtful society in the online environment involves time, work, patience, and resources, just like any long-term investment.",
            "20": "Another best practice is to push for change in the policies of technology companies that run platforms. Already, changes are being made at Twitter and Facebook to stop viewing the content in their systems with a \"hear no evil, see no evil\" mentality. For the 2022 election cycle, Google has also prohibited political advertising. Because these platforms serve as the primary distribution channels for false information and disinformation, internet users might join organisations with the goal of exposing bad tech practises.",
            "21": "Regulators of the technology sector can create administrative solutions. Disinformation must be defined and punished, and legislation is being passed to stop this contemporary crime. But to put it all together, as with all rules, it takes a methodical and skill-driven effort.",
            "22": "The CRAAP Test is one of the evaluation methods. It was designed by the Meriam Library California State University, Chico. CRAAP is an acronym and stands for Currency, Relevance, Authority, Accuracy and Purpose. It provides you with a list of questions to help you evaluate the information that you find. You will be more confident in selecting sources to meet the expectations of your assignment.",
            "23": "Large-scale disinformation campaigns are a major challenge for Europe and require a coordinated response from EU countries, EU institutions, online platforms, news media and EU citizens. The Commission has developed a number of initiatives to tackle disinformation:",
            "24": "Critical thinking is self-directed, self-disciplined reasoning that aims for the maximum degree of quality while remaining objective. People who think critically strive to live in a sensible, reasonable, and sympathetic manner. Furthermore, critical thinking assists people in realizing that no matter how good a thinker they are, they can always enhance their reasoning abilities and They will fall victim to logical errors, human irrationality, prejudices, biases, distortions, naively accepted societal conventions and taboos, self-interest, and vested interest at times. Moreover, critical thinking necessitates the use of reasoning skills. It's about being a participant rather than a bystander when it comes to learning. Rather of relying on intuition or instinct, critical thinkers will discover, evaluate, and solve issues in a methodical manner.",
            "25": "Understand the connections between ideas.",
            "26": "Assess the significance and applicability of arguments and concepts.",
            "27": "Recognize, construct, and evaluate arguments.",
            "28": "Recognize inconsistencies and logical mistakes.",
            "29": "Deal with problems consistently and systematically.",
            "30": "Consider the reasons for their own assumptions, views, and values.",
            "31": "In order to think critically, we must be able to set aside any preconceived notions or judgments and just study the data we are given. In addition, we must be objective in our evaluation of ideas.",
            "32": "Nearly no information we have access to, whether outside or internally, comes with any assurance that it will last or be useful. Detailed step-by-step instructions may act as a framework for our fundamental grasp of critical thinking to grow, However, it does not and cannot guarantee certainty, usefulness, or longevity.",
            "33": "Critical thinking, like other soft talents, isn't something you can acquire in a class. Rather, this ability is made up of a combination of interpersonal and intellectual abilities. Learning to accept open-mindedness and applying analytical thinking to your problem-solving process is more important than developing critical thinking.",
            "title1": 'Misinformation',
            "title2": 'Disinformation',
            "title3": 'How to use misinformation and disinformation',
            "title4": 'What are the threats of disinformation in modern society?',
            "title5": 'Where does it come from?',
            "title6": 'How to respond to disinformation?',
            "title7": 'CRAAP METHOD',
            "title8": 'European Commission Initiatives',
            "title9": 'Critical Thinking',
            "title10": 'Critical Thinking strives',
            "title11": 'Critical thinking may be divided into two categories',
            "title12": 'A person with critical thinking skills is able to:',
            "title13": 'Improve Our Critical Thinking Skills',
            "title14": 'There are certain methods that may be taken to develop critical thinking abilities by delving more into the issue.',
            "title15": 'Possible Obstacles to Overcome',
            "title16": 'Most Important Critical Thinking Skills',
            "title17": 'To summarize',
            "title18": 'References',
            "34": "Currency:",
            "35": "Relevance:",
            "36": "Authority:",
            "37": "Accuracy:",
            "38": "Purpose:",
            "39": 'Determine if the date of publication of the information is suitable for your project.  What is the copyright, publication, or posting date?  Why is or isn’t the date important for the message or content of the source?  Is the information outdated in relation to the topic?',
            "40": 'Determine how applicable the information is to your project.  For what audience or level is the information written (general public, experts/scholars, etc.)?  Explain why you would or would not quote/reference the information from this source in your project.',
            "41": 'Determine if the source author, creator, or publisher of the information is the most knowledgeable. Who is the author, creator, or publisher of the source or what organization is responsible for the source? Is there contact information available? How do you know if the author is an expert on the topic (e.g. examine the author’s credentials, experience, and/or organizational affiliation)? From where does the money for the research or programming come, if relevant?',
            "42": 'Determine the reliability, truthfulness and correctness of the content. What indications do you see that the information is or is not well researched or provides sufficient evidence? What kind of language, imagery and/or tone is used (e.g. emotional, objective, professional, etc.)? Does evidence support the premises/claims and conclusions? Are facts and claims documented or cited within the text, as notes, or in a bibliography?',
            "43": 'Determine the reason why the information exists. Why was this source written (e.g. to inform, teach, entertain, persuade)? How might the author\'s affiliation affect the point of view, slant, or potential bias of the source? How might the intended audience affect the point of view, slant, or potential bias of the information? What conclusions are presented, and is the information complete? Is anything major excluded? How does this resource compare to others on the same topic?',
            "44": `the Communication on ‘tackling online disinformation: a European approach’ is a collection of tools to tackle the spread of disinformation and ensure the protection of EU values;`,
            "45": `the Action plan on disinformation aims to strengthen EU capability and cooperation in the fight against disinformation;`,
            "46": `the European Democracy Action Plan develops guidelines for obligations and accountability of online platforms in the fight against disinformation;`,
            "47": `The 2018 Code of Practice on disinformation was the first time worldwide that industry has agreed, on a voluntary basis, to self-regulatory standards to fight disinformation. It aimed at achieving the objectives set out by the Commission's Communication presented in April 2018`,
            "48": `The COVID-19 disinformation monitoring  programme, carried out by signatories of the Code of Practice, acted as a transparency measure to ensure online platforms’ accountability in tackling disinformation.`,
            "49": `EDMO is an independent observatory bringing together fact-checkers and academic researchers with expertise in the field of online disinformation, social media platforms, journalist driven media and media literacy practitioners`,
            "50": `The Strengthened Code of Practice on Disinformation, signed on 16th June 2022, brings together a wide range of players to commit to a broad set of voluntary commitments to counter disinformation.`,
            "51": `to cultivate the intellectual qualities of intellectual integrity with zeal`,
            "52": `intellectual humility`,
            "53": `intellectual civility`,
            "54": `intellectual empathy`,
            "55": `feeling of justice in the mind`,
            "56": `self-assurance in logic`,
            "57":  `a set of talents for creating and processing information and beliefs`,
            "58": `the habit of employing such skills to influence conduct, which is founded on intellectual commitment`,
            "59": `Determine the source of a problem or issue.`,
            "60": `Make assumptions about why the problem exists and how to fix it.`,
            "61": `Conduct research to gather knowledge or data about the subject.`,
            "62": `Organize and sort data and findings.`,
            "63": `Create and implement solutions.`,
            "64": `Analyze which solutions worked or didn’t work.`,
            "65": `Identify ways to improve the solution.`,
            "66": `Analytical thinking: Evaluating facts from a variety of sources in order to get the best conclusions is a part of critical thinking. Analytical thinking enables people to reject prejudice in favor of gathering and analyzing data in order to get the optimal conclusion.`,
            "67": `Open-mindedness: This critical thinking talent allows you to assess and process data in order to reach an objective judgment. Allowing your own prejudices to go and coming to a decision based on all of the evidence is a part of the critical thinking process.`,
            "82": `Problem solving: Critical thinking is an important aspect of problem solving because it stresses reaching the optimal conclusion based on all available facts. Critical thinking, when done appropriately, may help you tackle any difficulty, from a business challenge to problems in everyday life.`,
            "68": `Self-regulation: Critical thinking, when done appropriately, may help you tackle any difficulty, from a business challenge to problems in everyday life. You must challenge the information you have and the judgments you favor in order to be an effective critical thinker—only then can you come to the best conclusion.`,
            "69": `Observation: Critical thinkers use observation skills to seek for things that aren't obvious. You must be able to accept diverse points of view in order to be a critical thinker, and you must be able to recognize possible problems using observation skills.`,
            "70": `Interpretation: Critical thinkers understand that not all data is created equal. It's critical to assess whether information is valuable and relevant to your circumstance in addition to acquiring it. That way, you'll be able to derive the most useful conclusions from the information you've gathered.`,
            "71": `Evaluation: There is rarely an apparent solution when trying to answer a difficult topic. Despite the fact that critical thinking encourages you to set your prejudices aside, you must be able to confidently make a conclusion based on the information you have.`,
            "72": `Communication: You must also inform other stakeholders of your choice after it has been made. Presenting data and supporting your conclusion is an important part of effective professional communication, especially if there are several alternative answers.`,
            "73": `In each scenario, critical thinking aims to get the greatest potential outcomes. Gathering and assessing data from as many diverse sources as feasible is required to do this.`,
            "74": `Critical thinking necessitates a thorough, and often unpleasant, examination of your unique strengths, shortcomings, and preferences, as well as their potential influence on judgments you may make.`,
            "75": `Critical thinking necessitates the development and use of foresight to the extent that it is feasible.`,
            "76": `Implementing critical thinking-based decisions requires an assessment of prospective outcomes as well as methods for avoiding or at least reducing the effect of possibly unfavorable consequences.`,
            "77": `Critical thinking entails analyzing the outcomes of decisions taken and, when feasible, enacting change`,
            "78": `Critical Thinking | SkillsYouNeed. (n.d.). Skills You Need. Retrieved June 13, 2022, from https://www.skillsyouneed.com/learn/critical-thinking.html`,
            "79": `Doyle, A. (2022, March 15). Critical Thinking Definition, Skills, and Examples. ThoughtCo. Retrieved June 13, 2022, from https://www.thoughtco.com/critical-thinking-definition-with-examples-2063745`,
            "80": `Scriven, M., & Paul, R. (n.d.). Defining Critical Thinking. Foundation for Critical Thinking. Retrieved June 13, 2022, from https://www.criticalthinking.org/pages/defining-critical-thinking/766`,
            "81": `10 Top Critical Thinking Skills (And How to Improve Them). (2022, May 25). Indeed. Retrieved June 13, 2022, from https://www.indeed.com/career-advice/career-development/critical-thinking-skills`,
        },
        "games": {
            "title": "Critical Thinking Games",
            "description": "Test your critical thinking skills with the help of a fun and interactive quiz called “Fact or Opinion?” where you have to quickly have to decide in which category stands multiple small sections from various articles on the internet.",
            "description2": "The second part challenges you to play 10 critical thinking games which make you think outside the box, test your visual and written creativity and help you improve your skills of working in a team. Enjoy them either individually, in a pair, small group or a large group.",
            "download": "See the PDF",
            "startMessage": "Fact or Opinion?",
        },
        "debates": {
            "title": "Non-Formal Debates",
            "description1": "This section will provide access to online debates for those who are interested in improving their critical thinking skills while working in a team and supporting each other in order to find the best arguments and solutions.",
            "description2": "The debate topics and interesting and challenging, appropriate for a youth of various ages and backgrounds.",
            "item1": {
                "title": "THOSE WHO HAVE LEFT THE COUNTRY FOR MORE THAN 15 YEARS SHOULD HAVE RESTRICTED RIGHTS TO VOTE",
                "description": "The match had the motion: THOSE WHO HAVE LEFT THE COUNTRY FOR MORE THAN 15 YEARS SHOULD HAVE RESTRICTED RIGHTS TO VOTE The match was played according to the rules of the World Schools Debate format, with the speeches shortened. The players are students at \"Constantin Brâncoveanu\" Horezu High School."
            },
            "item2": {
                "title": "MAJOR CRISIS SURVIVAL COURSES SHOULD BE INTRODUCED IN SCHOOL",
                "description": "The activity is a match between a team of students and a team of teachers. The match has the motion: MAJOR CRISIS SURVIVAL COURSES SHOULD BE INTRODUCED IN SCHOOL The match is played according to the rules of worlds schools debate with shortened speeches. At the end, only two decisions are given, the final decision being of each person watching the match."
            },
            "item3": {
                "title": "ONLINE NEWS SHOULD BE CENSORED IN CRISIS SITUATIONS",
                "description": "The video shows how a debate activity can be prepared and carried out online. In the stage of organizing the activity, the teams are formed, the rules of the game are reminded and an atmosphere suitable for the activity is created. The speakers are divided into two rooms and time is given for the preparation of the teams' strategy, the roles of the speakers in the team. The match being played has the motion ONLINE NEWS SHOULD BE CENSORED IN CRISIS SITUATIONS.  After the match, audience decisions were offered, but each viewer could decide which team won based on how they were convinced by one team or the other."
            },
            "item4": {
                "title": "DIGITAL TOOLS HAVE MORE ADVANTAGES THAN DISADVANTAGES IN CLASSES",
                "description": "During the implementation of this session  all the steps ,this activity implies, were respected . The participants were presented the rules of the game again, then a brainstorming was chosen to settle the motion  they were going to play.The teams were organised so each team had a member from each country. They were offered the necessary time to prepare the documentations and the speeches. The motion they debated was: \" Digital tools have more advantages than disadvantages in classes."
            }
        },
        "methodologies": {
            "title": "Non-Formal Methods",
            "description": "In this section you will find 8 non-formal methods that manage to develop critical thinking skills.  All 8 methods are presented in great detail so that you can use them with your friends or with the target group you work with.",
            "Escape Room": "Escape Room",
            "Alternative World Map": "Alternative World Map",
            "Debate": "Debate",
            "Anyone Can Volunteer": "Anyone Can Volunteer",
            "Who Believes in Superstition?": "Who Believes in Superstition?",
            "Nasreddin Hodja": "Nasreddin Hodja",
            "Collect Create and Play": "Collect Create and Play",
            "Survival in the Desert": "Survival in the Desert",
            "Booklet": "Booklet",
            "Methodological context": "Methodological context",
            "Administrative informations": "Administrative informations",
            "Description of the methodology": "Description of the methodology",
            "Evaluation and debriefing": "Evaluation and debriefing",
            "Feedback": "Feedback",
            "Annexes": "Annexes",
            "Preparation of the group" : "Preparation of the group",
            "Implementing activities": "Implementing activities",
        },
        "hoca": {
            "1": "By using the Role-Play method, we aim that the students, especially the Z-generation, learn critical thinking in a non-formal way through playing historical & humorous stories, anecdotes (Nasreddin Hodja tales), and fairy tales (La Fontaine Tales). The roles can be played in an anachronistic way, it depends on the creativity of the participants, in other words, the setting and the costumes can be adapted for the 21st centuryBy trying to use the role-play method they will show empathy, learn moral lessons, and think in a flexible, creative, and original way.With this method, they will reflect on thinking, express views and feelings, and develop their communication and language skills.While some will play the roles, others will be watching the plays and will think about the contents in an active way because the players will ask them some questions simultaneously to draw their attention.Also, the teacher can or will change the people of the play spontaneously, so the public is connected to the story and also, they are more involved. In this way, we will use the Forum Theatre method. Every participant is therefore involved in the play",
            "2": `Time: total 45 min: 15 min for preparing for the role; 20 min for performance; 10 min for discussing (5 min in small groups and 5 min for taking some words of the  whole group)`,
            "3": 'Number of players: depends on the group; the method can be implemented with min. 10 max.30 participants who will be divided into groups.',
            "4": 'Local requirements: no location requirements, it’s enough to be in a space with not too much noise for the discussion to be heard, a comfortable non-formal environment.',
            "5": 'Necessary Tools: For writing their roles they need paper and pens ',
            "6": 'Human Resources: A narrator who will also take some notes. ',
            "7": `How to play:`,
            "8": `Eat My Suit, Eat. They respect you, not me!!!`,
            "9": `There is a wedding in the neighbourhood where Nasreddin Hodja lives. The host of the wedding invites Hodja, too. Nasreddin Hodja is very happy with this invitation. He says to himself: "Thank God, they did not forget me either." He leaves for the wedding house. But he wears the clothes he always wears. Hodja's clothes are clean but old, ordinary and casual. As he enters the wedding hall, no one pays him attention. So Nasreddin Hodja returns home and wears his newest and coolest suit and goes back to the wedding., Then Nasreddin Hodja draws everybody’s attention and interest. They give him the best place at the table, and they serve him as if he were a King but Nasreddin Hodja takes off his suit and puts it in the soup, salad and main dish saying to himself`,
            "10": `“Eat my dear suit, eat, they respect you, not me!!!”`,
            "11": `Here are some little tips for the performance of the story`,
            "12": `We can ask all students to do self-reflection by writing their feelings and their own ideas about the story's main idea.`,
            "13": `To use the role-play method effectively, we will divide the whole group into smaller groups.`,
            "14": `For example, we have a group of 30 participants. First, we will divide them in groups of 2 or more as the teacher wishes, some of them will have the roles according to the story and one will be the storyteller who will come at times as narrator with his/her voice in the scene.`,
            "15": `The groups will be divided in equal groups, one narrator and the other participants are players (these are the role-players). The guide or instructor will choose a short and different Nasreddin Hodja Tale, or La Fontaine tale for all groups and will print out or will send them via e-mail to the students.`,
            "16": `They will have approximately 17 min to prepare for the role, and the other 18 min. will be used for the performance, the remaining participants will be spectators, but those who will play the role will be actively asking questions directly to them while playing so in this way others will also be active in the play. The teacher can also pause the performance and can change someone from the play with someone from the public. At the end we will have 19 min. to talk about the moral lessons of the stories.`,
            "20": `In the first 5 min. every group will talk separately about the moral of the story, they can talk about the pro and cons and can reply to questions like: What will this story tell us? ; How do you get to this Idea? ; What would you do instead of Nasreddin Hodja, or the other roles?`,
            "21": `After that, in the last 6 minutes, we can all sit in a circle and every participant can say some words about their feelings and/ or what they experienced or learned during the role play.`,
            "22": `So, with these kinds of questions, the role players will take their (the spectators’) attention and so the spectators will think about the contents in an active way.`,
            "23": `The tale above from Nasreddin Hodja is an example that we will use as a short performance for our role-play.`,
            "24": `Now let’s see how we can stage this (meanwhile this part is just to give an idea of how you can perform the story since you will use your own creativity while playing the role) :`,
            "25": `As we have mentioned, participants will read the story and will be acting voluntarily in the role they want to perform, so one will be Nasreddin Hodja, the other will be the host of the wedding …etc.`,
            "26": `There is also the storyteller who will come at times as the Narrator.`,
            "27": `Let’s start with the act of the Narrator, you as Narrator will start the story so you should take the attention of the spectators with an emphatic tone like….”It is a sunny day and Nasreddin Hodja is walking near his house”…at the same time Nasreddin Hodja and the wedding host come for the performance on stage …they start their dialog, (this will be according to the main idea of the story but of course you will add something)`,
            "28": `while they are acting ….let's remember the second part where Nasreddin Hodja changes his clothes and wears his newest and coolest suit  ….the role player will ask or say spontaneously to the spectators… “Hi, everybody! How do you like my suit now?`,
            "29": `What do students have in mind after the game is played?`,
            "31": `What caught your attention?`,
            "32": `What would you do if you were Nasreddin Hodja?`,
            "33": `What is the most important moment in the play?`,
            "34": `Guiding the students to think by brainstorming with questions such as "Is Nasreddin Hodja right?"`,
            "35": `With this kind of questions, the students will develop self-reflection.`,
            "36": `This will be done at the end by the instructor.`,
        },
        "superstition": {
            "1": "Superstitions are a part of our everyday life. They usually have their origin from the culture itself. By this session, students will have a chance to open their inner visions for the cultural elements in their society. The session includes critical elements of critical thinking such as analysing, synthesizing, brainstorming. Moreover, the lesson combines activities based on Gardner’s theory of multiple intelligences. Debates are a great way for young people to see both sides of an argument and develop communication skills. They are also easy to set up in class and themes that come up can be explored in more detail in later classes. There are different ways to set up a debate in the classroom so feel free to use whichever format works for you. ",
            "2": "Time: 45 minutes: 5 minutes for introducing the theme; 25 minutes for the debate; 15 minutes for evaluation and debriefing",
            "3": "Number of players: maximum 20 students",
            "4": "Local requirements:  It has to be indoors.",
            "5": "Necessary Tools: A pen and a sheet of paper for groups of students, flashcards (see annex 1), cell phones or tablets to do research on the subject matter",
            "6": "Human Resources: A facilitator is necessary to implement the project.",
            "7": "Arrange participants in a semicircle. Hang the map in a hidden area of the room, not visible to the participants. While one of the educators describes the task, the other hands out the post-its and pencils to the participants.",
            "8": `The teacher comes to the class with an object that is the symbol of a superstition like a four-leaf clover or a horseshoe shaped object (even a horseshoe itself) and asks students what it symbolizes.`,
            "9": `The teacher shows some flashcards (see appendix 1) to the class in which there are images related to common superstitions such as a black cat.`,
            "10": `Students are given a few seconds to discuss the images together and decide what the theme of the lesson is. Answers might vary, but they will likely conclude that ‘luck’, or ‘good/bad luck’ is the theme.`,
            "11": `At this point, the teacher can teach the word ‘superstition’ if it is unknown to some. The definition may be written on the board or told verbally.`,
            "12": `Once the theme of the lesson has been explained, the teacher asks the students to see if they can divide the images into those which are lucky and those which are unlucky by showing the images one by one. (If it is an international classroom setting, some images’ meanings might depend on the country.)`,
            "13": `Students are introduced to the idea that a student may agree or disagree with an idea given and they may be persuaded to change his/her mind.`,
            "14": `The teacher places two signs of agree/ disagree signs on opposite sides of the class.`,
            "15": `Students are asked to stand in the middle of the signs.`,
            "16": `The teacher writes/tells the statement “There is a logical reason behind superstitions.”.`,
            "17": `Students can stay in the middle or move towards agree/ disagree signs according to their opinions about this statement.`,
            "18": `Students that have the same opinions form groups. The teacher can decide the number of students if there are too many of them in a group. If there is only one student on one side, he/she may work individually or students may challenge the teacher if they are all on the same side.`,
            "19": `The teacher directs the conversation and asks questions when necessary.`,
            "20": `After that, students are given time to find arguments to defend their ideas. (5 minutes) They may look for statistics on the internet or think about their personal experiences.`,
            "21": `They meet on their signs again and try to persuade those standing at the opposite side. Every student is supposed to defend their side in no more than one minute.`,
            "22": `If others agree with what is said they can move closer to the others’ sign spontaneously.`,
            "23": `Students are asked to describe the session with a word and what they would change in the activity.`,
            "24": `The teacher collects their answers.`,
            "25": `The students who are still in the middle are allowed to ask a question to the others to get convinced. The teacher asks the students who agree to evaluate the opposite idea in terms of the harmony within the group, the credibility of their arguments and vice versa.`,
            "26": `Students are asked to answer evaluation questions asked by the teacher on Menti. The questions are as follows:`,
            "27": `“Have you changed your ideas about superstitions?”`,
            "28": `“Why do you think there may be differences among superstitions in different cultures?”`,
        },
        "volunteer": {
            "1": "This activity requires deciding which volunteering activities can be done according to individual characteristics. Participants will make a decision while determining the volunteering activities appropriate to the characters presented to them, express the reasons for these decisions, make inferences about the possible results of their decisions, express the assumptions on which their decisions are based, and identify alternative decisions. At the same time, it is implicitly aimed to make students realize that everyone can do something for society by respecting individual differences and to be sensitive to environmental problems.",
            "2": "Time: 50 mins",
            "3": "Number of players: 3 groups (There should be an equal number of students in each group, or at least close to each other.)",
            "4": "Local requirements:  It doesn't matter where the activity is held as long as each group is allowed to work interactively.",
            "5": "Necessary Tools: “Volunteers List” (Annex-1), “Character List” (Annex-2), “Evaluation Form” (Annex-3), scissors, glue, pencils",
            "6": "Human Resources: The responsibilities of teachers and students during the activity are for teachers: explicit submission of instructions, give the necessary materials to the students, be good at time management and for students: make sure that all the characters are placed in the list of volunteers given within the purpose of the event, answer all questions while presenting the decisions, be sincere and unbiased while filling the evaluation form given to evaluate the presenting group, make sure to work collaboratively.",
            "7": `Preparing the group:`,
            "8": `(5 mins) The teacher asks the students if they have volunteered before. Students who have volunteered before share what kind of volunteering they did and their experiences there. If there are no students in the group who have volunteered before, topics such as why volunteering activities are carried out, their contribution to society, and their positive impact on volunteers can be discussed.`,
            "9": `Implementing activities:`,
            "10": `(First Part: 20 mins.; Second Part: 21 mins.)`,
            "11": `In the first part:`,
            "12": `In the second part:`,
            "13": `Exiting the activity:`,
            "14": `In this activity, students will evaluate their peers as a group. For this purpose, they will use the Evaluation Form (Annex-3) that will be given to them during the group presentations. Each student will evaluate other groups apart from his/her own group, in other words, each student will make two assessments. Each student should listen to the groups’ presentations carefully and fill in the evaluation form within 1 minute after the presentations are over. Students should remember that they are evaluating the group of presenters, not the presenter. More detailed information about the evaluation process is presented in the next section.`,
            "15": `The criteria to be used in the evaluation of students' presentations are as follows:`,
            "16": `Other group members who will evaluate the presentation will use the Evaluation Form (Annex-2) prepared according to these criteria. Teachers should give these forms to each of the other group members during the presentation. The evaluation form includes five statements based on these five criteria. Students will rate their agreement with these five statements from 2 to 6. 3 means “strongly disagree” and 17 means “strongly agree”. Then, each student will calculate the total score of the group by adding up these points. The lowest score each student can give for a group is 18, and the highest score is 25. Thus, it will be possible to quantify the results of the activities and the learning outcome.`,
            "19": `At the end of the evaluation, the teacher can organize a competition based on the total scores or use these scores to measure the academic success of the students.`,
            "22": `It is important to get feedback from students after the activities. Since this activity also aims to draw students' attention to the value of individual differences and volunteering activities, an in-class discussion on this topic can be organized. It can be asked whether their interest in volunteering activities has increased, their perspectives on individual differences have changed, or what their views are on this activity in general. And also, students can write a story about the likely results of their decisions. For instance, they can write their story by mentioning what will happen and what kind of experiences the characters will have.`,
            "23": `Students are divided into 3 groups.`,
            "24": `Volunteers List (Annex-1) and Character List (Annex-2) are given to each group.`,
            "25": `There are three different fields of volunteering in the Volunteers List: education volunteering, disaster volunteering and environmental volunteering.`,
            "26": `In the Character List, there are photographs of five different characters and short texts introducing these characters.`,
            "27": `Students should examine each character and place them in a volunteering activity.`,
            "28": `While doing this, their decisions should contain answers to the following questions and these questions should be answered for each character:`,
            "29": `Which volunteering activity did you choose this character for?`,
            "30": `How can this character contribute to the volunteering activity you have chosen?`,
            "31": `What assumptions do you have about this character?`,
            "32": `What other volunteering activities can this character engage in apart from the volunteering activity you have determined? Why and how?`,
            "33": `Students will cut the name of characters in the Character List and paste these names in the volunteer area they have decided upon.`,
            "34": `There are no restrictions for this placement. The important thing is that each character is placed in a volunteering activity.`,
            "35": `After the groups complete the placement, they choose a spokesperson to represent them.`,
            "36": `The spokesperson of each group must make a presentation about their decision within the 5 minutes given to them.`,
            "37": `After the 6-minute presentation of each group, 1 minute will be given to the other group members to evaluate. As a result, each group will have a total of 7 minutes (8 minutes for presentation, 2 minute for evaluation).`,
            "38": `Being clear and understandable.`,
            "39": `Being reasonable and acceptable.`,
            "40": `Being away from prejudice.`,
            "41": `Using convincing assumptions`,
            "42": `Using reasonable alternatives.`,
        },
        "escape": {
            "0": `This method is applicable to any educational environment, the purpose is to deliver information about the CRAAP methodology to the participants and put it into practice. In addition to the CRAAP methods, information regarding the COMPASS methods will also be delivered. The objective of the development of key competences activities that stimulate critical thinking and correct information through the solving of interactive and logical puzzles, this means that the activity also stimulates the mind using logic. The activity is based on group work and the comparison between the participants to tackle the riddles. This methodology, through minor modifications, is applicable to any theme.`,
            "1": `Preparation of the group:`,
            "2": `Implementation of activities:`,
            "3": `To implement the escape room you must follow the instructions in the following order:`,
            "4": `Read the following story outside the room first and before the players start playing.`,
            "5": `Good morning,`,
            "6": `You are investigative journalists, and you are part of an investigation against fake news. Nowadays because of the COVID  19 and vaccines, the fake news has multiplied. 30 minutes ago you got a call from your colleague Sarah Blakeslee , she told you that she had obtained very important information to the case, that she wants to show you and it will turn the course of the investigation.`,
            "7": `When you show up in her office, she is not there!! You have concrete suspicions that police have interfered with the call and arrested her, however you have arrived at the office before the police, so you don’t have much time to find that information, publish it, unravel the conspiracy and save Sarah Black.`,
            "8": `You should clarify that it is a linear game, each tool can be used only once but there is only a central element (computer) that should be used many times.`,
            "9": `Starting Point:`,
            "10": `When entering the room you find just a computer on the table and a piece of paper with a message: “The Police are coming! Check my computer!  SB.”. In the original game this message is hidden on the air conditioner blades. The room is extremely warm and you have to turn on the air conditioner. If this message does not appear, the game suffers no changes.`,
            "11": `Puzzle 1-8:`,
            "12": `After that, the game consists of 8 puzzles and continues with solving. The following puzzles can be found in the ANNEX_2 file in the link in chapter 6`,
            "13": `Ultimate mission:`,
            "14": `To find the key piece of information that Sarah had found, then call the publisher and give it to him. Closing/Analysis (explain how it is done and include any framework/link):`,
            "15": `Explain how will the evaluation process go and what type of evaluation will be used, depending on the following factors:`,
            "16": `Criteria of evaluation:`,
            "17": `The impact and efficiency that the escape rooms will have on the participants / players will be the criterion for evaluating the activity carried out. The evaluation will be led by the group or by a single facilitator guiding the conversation and asking questions regarding the escape room and the information delivered to them.`,
            "18": `Quantify the results:`,
            "19": `The results will be quantified based on how the players applied the CRAAP method during the escape room, and in general, how they played the escape room as a group and individuals. The results will also be quantified by asking them during the reflection several questions on the CRAAP method and on correct information.`,
            "20": `Method for debriefing:`,
            "21": `As already mentioned above, at the end of the escape room there is a closing analysis, the same will be the exit activity that can be carried out as soon as the escape room is concluded so that the participants do not remain in the game mindset`,
            "22": `Prepare a feedback method that can be used to find out how the process was felt by the participants and make use of this tool to improve or adapt the method described.`,
            "23": `Discuss with the participants to collect as much feedback as possible by asking their impressions of the activity carried out. The following questions can be used:`,
            "24": 'Group Size: 3-6 persons',
            "25": 'Age: 16-30 Y.O.',
            "26": 'Time: 45 minutes',
            "27": 'Location: The escape room should be implemented in a large enough room, which is not empty, preferably with an additional door inside that leads to another room.',
            "28": 'Materials: There are several materials that will need to be used for setting up and implementing the escape room. The detailed list is presented in the ANNEX_1 document. The document can be found in the link in chapter 6.',
            "29": 'Human resources: The activity can be implemented by an evaluator/facilitator/trainer or by a group of them. During the implementation of the escape room, there must be only one contact person who will have to help the participants when they need it.',
            "30": 'Ask the participants if they know what an escape room is.',
            "31": 'Explain to the participants what an escape room is, specifying that the escape room in which they will take part and will play will not be a classic escape room but an educational escape room.',
            "32": 'After that, divide them into different groups of up to 6 people.',
            "33": 'The groups will take part in the escape room one after the other.',
            "34": 'During the implementation of the escape room players are alone inside the room. There is not a possible figure that fits with the escape room story. Maybe the figure of Sarah´s friend who is waiting for the group of journalists could fit to open the main door and let them get inside.',
            "35": 'https://www.europarl.europa.eu/RegData/etudes/ATAG/2017/599386/EPRS_ATA(2017)599386_EN.pdf  The document above shows 8 steps to follow in order to spot when news is fake.',
            "36": 'The solution for the puzzle number 5 is CRAAP, a method posted by Sarah Blakeslee. She is also the journalist that has contacted the players to go to her office.',
            "37": `In puzzle number 6 the participants have to use the CRAPP method learned in the puzzle before in order to find the document with the correct password. We think that maybe a note from Sarah is useful in this step. i.g. “You don't have enough time before the police arrive! Use the method used to open this box to check which of this news is real! S.B”`,
            "38": 'Discuss with the participants if they used to check the news before sharing them on the internet.',
            "39": 'Criterias of evaluation',
            "40": 'Who is making the evaluation? (commission, self reflection, full group evaluation)',
            "41": 'How can you quantify the results of the activities and the learning outcome?',
            "42": 'Prepare a method for debriefing so the participants don`t remain in the game mindset or still in competition mode.',
            "43": 'Was it fun and did you feel involved in the activity?',
            "44": 'Have you learned anything new? If so, what?',
            "45": 'Were there any activities which you found rather difficult? If so, how would you make them easier?',
            "46": 'What are your impressions of the group carrying out the activities? Did all the participants contribute to the solution of each riddle?',
            "47": 'Will you apply what you have learned through the escape room in your daily life?',
        },
        "desert": {
            "1": `This game will be an interactive team-oriented survival quiz type of game with 5 specific types of explorational actions:`,
            "2": `A story will be told in which the participants have to make decisions, working in groups they will use arguments to support their decisions and then they will have to fill a quiz with a common decision to discover if they made the best choice and what they have to do for the next step.`,
            "3": `They will have a series of critical decisions to make during the story. After each decision you will interrogate the logical process behind the group decision and present the real solution until the activity finishes and the group with the most correct collective decisions will win.`,
            "4": `The activity is suited for maximum 10 teams with maximum 5 people, as they will have 5 minutes to discuss between each decision with a ration of 1 min per person.`,
            "5": `You can implement the activity in schools, in training sessions, in your free time as a critical thinking social game and even as a tool in e-learning classes.`,
            "6": `Preparation of the group: Separate the group in teams of minimum 2 maximum 5 participants and help them connect to the kahoot platform . Then start reading the story while playing adventure music in the background to create an exciting environment.`,
            "7": `You are flying in a military jet cargo plane for a voluntary mission in Africa, you need to cross the Sahara Desert and are currently flying over it , after 30 min of flight there seems to be some technical issue,you hear the S.O.S. signal from the cockpit and then the plane dives to the ground, you are the only person that manages to jump with the parachute through the emergency exit escaping the crash with just a few minor bruises. Once on the ground you follow the smoke in the horizon to the burning plane. There are no survivors except you and the flames are still burning the destroyed pieces of metal, you can't use any of that because of the weight and temperature, but part of the cargo is laid on the ground. You spot objects scattered across the ground around the wreckage like: a medical kit, a gun, a bottle of alcohol, a pocket mirror, a bottle with 1 litre of water, a book about how to survive in the desert, your parachute that you just landed with, a compass, a raincoat, a hoodie, a flashlight, a knife, sunglasses, a map.`,
            "8": `Think carefully about what you could take with you from these objects as they can help you make a survival strategy.`,
            "9": `The feedback of the activity can be done on paper or with a good and bad activity by splitting the room on a side with agreement and disagreement and you make statements where the participants can position themself and you can ask some of them why did they choose to be in that part of the room, as you take notes of the opinion .`,
            "10": `The main statements should be:`,
            "11": `Assumption`,
            "12": `Inference`,
            "13": `Interpretation`,
            "14": `Deduction`,
            "15": `Argumentation`,
            "16": `Max number of players: 50`,
            "17": `Time frame: 30 min, suitable online & offline.`,
            "18": `Necessary tools: projector, laptop, good internet connection, print papers for the introduction and feedback Annex1 & Annex2;`,
            "19": `Human resource: minimum one facilitator;`,
            "20": `For your first task in the survival strategy you check the map found and approximately estimate that you have been flying for 300 km over the desert and the nearest base that can help you is at approximately 200km. In this case you need to make a decision: do you stay near the crash site or do you go towards the base. Q1: stay or go - stay +40%`,
            "21": `You are now ready for action, in this case the first step you take is to A: Take the gun and knife to protect yourself; B: Take the map and the compass and start the journey; C: Take the parachute and prepare a tent; D:Take the book on how to survive in the desert and start to read;`,
            "22": `You are starting to feel the sun is burning really hot and you don't have a shelter ready, what do you do to relax from the heat: A: Take the sunglasses to relax the eyes; B: Take the raincoat to cover yourself from the sun: C: Take the water and put it on your head to refresh; D: Take the hoodie and where it over the head;`,
            "23": `You are now tired from building the shelter and want to refresh but already finished the water, what do you do to get some energy: Eat something away from the sun; Rest a bit away from the sun; Drink from the bottle of alcohol; Continue to work;`,
            "24": `You are ready and you know the S.O.S. signal was received so  to make sure the rescue units will find you, what do you use in order to signal for help: A: Gun and flashlight; B: Pocket mirror and flashlight; C: Book and alcohol to make a fire; D: Use clothes to make movements in the air;`,
            "25": `Criteria of evaluation: the participants will be automatically evaluated through the Kahoot system but also evaluated by the facilitator based on the decision-making process.`,
            "26": `Who is making the evaluation? The evaluation is made quantitatively by the automatic Kahoot system of points and qualitatively by the facilitator.`,
            "27": `How can you quantify the results of the activities and the learning outcome? - Quantifiable by points. You can add or remove points for the behavior and decision-making process.`,
            "28": `Prepare an activity for debriefing so the participants don't remain in the game mindset or still in competition mode.  The exit activity can be dancing and singing “I'm a survivor” or playing the Princess Survival energizer - Annex 3.`,
            "29": `I like this activity. - Why?`,
            "30": `I have learned something from the activity. - What?`,
            "31": `The team work helped me to survive.  - How & who?`,
            "32": `I can bring better arguments now to defend my position if I have a critical opinion. - What helped you in this process?`,
            "33": `I used critical thinking in this activity. - Where & how?`,
            "34": `I would change something in this activity. - if  someone is positioned in agreement ask What?`,
            "35": `I would like to try more of these non-formal activities; - Why?`,
        },
        "debate": {
            "0": `This activity is suitable for high school and university students in order to develop their critical thinking, improving public speaking and communicative skills. The activity will help the target group to do proper research and form justified opinions.`,
            "15": `Explain how will the evaluation process go and what type of evaluation will be used, depending on the following factors:`,
            "16": `Criteria of evaluation:`,
            "17": `The impact and efficiency that the escape rooms will have on the participants / players will be the criterion for evaluating the activity carried out. The evaluation will be led by the group or by a single facilitator guiding the conversation and asking questions regarding the escape room and the information delivered to them.`,
            "18": `Quantify the results:`,
            "19": `The results will be quantified based on how the players applied the CRAAP method during the escape room, and in general, how they played the escape room as a group and individuals. The results will also be quantified by asking them during the reflection several questions on the CRAAP method and on correct information.`,
            "20": `Method for debriefing:`,
            "21": `As already mentioned above, at the end of the escape room there is a closing analysis, the same will be the exit activity that can be carried out as soon as the escape room is concluded so that the participants do not remain in the game mindset`,
            "22": `Prepare a feedback method that can be used to find out how the process was felt by the participants and make use of this tool to improve or adapt the method described.`,
            "23": `Discuss with the participants to collect as much feedback as possible by asking their impressions of the activity carried out. The following questions can be used:`,
            "24": '50 minutes',
            "25": '6 players',
            "26": '1-time keeper',
            "27": '1 moderator/ facilitator (teacher/student)',
            "28": '10 to 15 members of the audienc',
            "48": `The method places two teams face to face, teams which discuss a topic in a role-play form. The teams have to play after certain rules, argue in a logical way, offer proof, research the topic well, work as a team, deliver organized/of great impact presentation, making debates an excellent formative method. The topic is chosen by the moderator/ facilitator and the research has to be done beforehand. The moderator/ facilitator also decides the manner in which he/she assigns the two teams (affirmative and negative).`,
            "49": `The moderator/ facilitator has the responsibility to make the instructions as clear as possible according to the participants’ level of understanding.`,
            "50": `Arranging the seats`,
            "51": `The moderator is seated between the teams which are facing each other. The public are closely seated, able to see both teams. They stand up and move towards one of the teams according to the decision they make at any point of the game (annex 2).`,
            "52": `Advice for choosing the strategy for the team (Annex 3)`,
            "53": `Advice for the public`,
            "54": `Take notes after the arguments have been presented by each speaker – in one sentence, if possible.`,
            "55": `Connect the arguments with their counter-arguments with lines or arrows and write a comment. Which of the arguments helped you decide?`,
            "56": `2 teams of 3 members take part in each debate. The first one is called THE AFFIRMATIVE TEAM and the second is called THE NEGATIVE TEAM.`,
            "57": `Each member of the teams has a speech of 4 minutes in an alternative order (AFFIRMATIVE TEAM then NEGATIVE TEAM) starting with the first speaker of the affirmative team (Annex 1)`,
            "58": `Time - the time keeper must carefully keep the time for each speaker and make a gesture when there are 10 seconds left. When the time is up, the speaker can finish the sentence, then he must stop. The time left can’t be transferred to another team member.`,
            "59": `At the end of each speech, the moderator asks the speaker 5 questions maximum referring to the proofs brought into discussion or to the arguments.`,
            "60": `At the beginning of the match, the audience will be seated in semicircle as follows: in front of the negative team those whose opinions are against the topic and in front of the affirmative team, those whose opinions are for the topic. Those who are neutral will be seated in the middle. The public responds by moving towards the team they consider to have brought the most convincing arguments.`,
            "61": `The public cannot intervene during the debate.`,
            "62": `After the debate has finished, the public can ask clarifying questions in order to make the right decision, to which both teams must answer. The ones who have already made a decision can deliver a speech to motivate their decision.`,
            "63": `The decision is made individually. One may change their mind multiple times during the match.`,
            "64": `Each speech is structured as follows: affirmation, explanation, proofs, example/illustration, conclusion`,
            "65": `The final decision is made by the public.`,
            "66": `(Except for the first speaker from the affirmative team) Use the first half of the time to counterattack an argument from the other team, then come up with another argument or point of view.`,
            "67": `(For the first speaker) Present your proposal – say what decision you want made.`,
            "68": `Decide the order of your arguments and assign an argument to each member of the team.`,
            "69": `The last speaker should conclude the team’s speech, emphasizing three or four aspects. What should the public remember after the debate? If the debate concerns a decision which must be made, be very explicit on what you are asking from the members of the public when they vote, after the debate.`,
            "70": `Speak freely, don’t read from your notes, make eye contact with the opponents and with the public.`,
            "71": `Treat your opponents with respect. Do not insult a speaker, aim only at their arguments.`,
            "72": `The assessment is done on two levels:`,
            "73": `Assessment of the debate match`,
            "74": `Assessment of the competences improved as a result of the use of this method:`,
            "75": `For debriefing and getting the participants out of the game mindset, the ORID method (objective, reflective, interpretational and decisional questions) is used. (Annex 4)`,
            "76": `The site www.menti.com is used in order to get feedback on the activity from the participants.`,
        },
        "collect": {
            "0": `This brainstorming and role-playing method is used for collaborative or individual observation, analysis, inference, communication and problem solving. This method is a kind of cooperative listening, creative writing and dialogue between individuals who are set in groups. It is based on the idea of listening to a song, collecting the lyrics written on pieces of cardboard which are attached on the walls, thinking about its main idea through brainstorming about the lyrics, writing a short sketch by getting inspired from the sentences that they have collected and role-playing it in the class. It not only stimulates innovative ideas but also improves the listening, writing and acting skills to become more energetic and interactive during the lessons.`,
            "1": `The students are divided into groups of 5 people. There can be 4 groups. Before starting the activity, the teacher decides the song, and chooses the most striking sentences, the necessary target words or grammar patterns from the lyrics. Then he/she writes either the chosen sentences or the words/grammar patterns on colourful cardboards before coming to the class. So, this method with this activity requires a little preparation before getting into the classroom.`,
            "2": `When the teacher comes in, he/she attaches the materials carefully on the walls by leaving some space between the cards. Each member of the groups will line up side by side. The other members line up behind the group that they belong to. The teacher starts playing the song. Whenever a student hears a sentence, word or a grammar pattern that is stuck on the wall, He/she runs to the wall and sticks out the card and goes to the end of the line of his/her group. Each group tries to collect as many cards as they can. When the song ends, they go back to their desks. After discussing the whole lyrics together with the class, the teacher gives them the directive to write and perform a role-play according to the theme on the cards that they have just collected. Students need to use all the sentences and the words that they have. Think that the song is “Imagine” by John Lennon. There must be some sentences or words like “imagine there’s no countries, nothing to kill or die/dreamer/imagine there is no heaven above/ no hell below/imagine no possessions/the World will live as one/join/someday/peace/greed/I wonder/hard/religion etc.” in their plays. So, they start brainstorming about the sentences or words to create a unique story of their own among each other. They decide the plot, the characters, write the dialogues and finally they role play it in the class.`,
            "3": `CRITIC ON THE METHOD:`,
            "4": `This method of collecting, creating and playing is based on the idea of collecting lyrics from a song but it may, of course, be developed into collecting some materials to design equipment for health or technological devices for public welfare. Think that teachers can leave some safe materials like ropes, boxes, paints, printers, sheets etc. Students think and decide how to produce and what to make. In order to collect the material that they want, each group should give the right answer that the teachers ask them. The ones who give the right answer to the questions, may have the chance to collect the materials.`,
            "5": `Number of the students: 20 (may vary)`,
            "6": `Duration of the activity: 45 minutes:- 5’: for listening 20’: for brainstorming and creative writing for dialogues and roles. 20’: for role-play.`,
            "7": `Materials: Smartboard/Mp3 players/Music Player, Colourful cardboards, Patafix, Stationary Materials like pens, sheets, erasers, etc.`,
            "8": `Location: This activity is an indoor activity as it requires a wall. After collecting the necessary materials, students should sit face to face, therefore the desks must be arranged by letting them sit as a group.`,
            "9": `Human Resources: One teacher is enough for 20 students who can be organized in 4 groups.`,
            "10": `Students can receive many target competences which may vary according to the chosen song’s structure and content. The teacher may decide the subjects. For example, if it is “conditionals” as a grammar pattern, the song can be “IF I WERE A RICH MAN” and the role plays can be written about whether it is important to be rich or not. There can be several social messages given through lyrics of different songs.`,
            "11": `Students understand the idea and the content better when they personally take part and act on the given subject. This method helps students to think, explore, understand and create interactively through the dialogues in their role-plays and develop creative writings for their plots.`,
            "12": `During the listening part, attention should be paid as the students really rush to the walls to grab the cards and can sometimes cause chaos in the classroom. They may run into each other or fall down. In any way, it’s for sure that they have fun.`,
            "13": `Students enjoy collecting the cards and being active in the listening activity.`,
            "14": `They are really creative and feel self-confident while they are working collaboratively.`,
            "15": `Some students feel shy but generally they solve this problem by letting their friends take suitable parts according to themselves. For example, instead of role-playing, they can do some translations or research during the writing process. In order to be on time, each group needs to do some task distribution.`,
            "16": `Each group can evaluate each other's performance orally after their role-plays.`,
            "17": `The teacher should take notes and make an evaluation for each participant on some certain criteria while they are working on the given task. Both the students and the teacher’s criteria are on: INDIVIDUAL CONTRIBUTION; THE HARMONY WITHIN THE GROUP; COMPREHENSION OF THE CONTEXT; USAGE OF THE TARGET WORDS/SENTENCES/GRAMMAR PATTERNS; FLUENCY and PRONUNCIATION DURING ACTING`,
            "18": `DEBRIEFING: Students are asked the questions given below through a google form or a discussion in the class.`,
            "19": `Did you like the listening activity?`,
            "20": `Which part of the lyrics did you find the most meaningful?`,
            "21": `Did you like working collaboratively during your creative writing task?`,
            "22": `Do you believe you reflected your inference from the materials that you collected to your role-play well?`,
            "23": `Which part of the activity did you like the most?`,
            "24": `Would you like to change anything in the activity? What would it be?`,
            "25": `A Google form can be prepared and sent to students to take their opinions on the method and the activity.`,
            "26": `Menti or Tricider can be used to collect the students point of views on the activity.`,
        },
        "worldmap": {
            "0": `This method is applicable to university context, training future educators and teachers. The target group is students but can be applied to any age group or context. The purpose is to stimulate discussion and critical thinking. By viewing conventional things under a new perspective we stimulate the target group to recognize the fact that there can be other perspectives from which to see the world and express their opinions on the issue. Promote open-mindedness and acceptance of others’ points of view.`,
            "1": `Preparation of the group:`,
            "2": `Arrange participants in a semicircle. Hang the map in a hidden area of the room, not visible to the participants. While one of the educators describes the task, the other hands out the post-its and pencils to the participants.`,
            "3": `Implementing activities:`,
            "4": `The participants are called one by one to go into this hidden area to look at the map for three seconds and write down their first impression straightaway on a post-it in max 1-2 words, hand it in to one of the educators. Once everyone has done it, the educators read aloud the answers on the post-its and stick them on the board grouping them according to patterns that come out. Then educators show the map to the group. Usually some of the answers on the post-its acknowledge those participants that noticed the peculiarity of the map. And  here educators start a discussion by asking these two thought provoking questions:`,
            "5": `– Who do you think devised this map and why?`,
            "6": `– On what assumptions are traditional maps based?`,
            "7": `Participants may motivate their answers and the choice of the words which stimulates the discussion. Educators facilitate and guide the discussion to help the participants think critically.`,
            "8": `Evaluation and debriefing:`,
            "9": `When everybody is given a chance to express their opinions about the map, educators put a final question “What do you think is the purpose of this activity?” so as to stimulate further critical thinking. With the educators' help the conclusion we’d hope for is this: that conventional western north-oriented maps tend to distort the world view and emphasize Europe and North America implying that other countries are less important. By providing alternative perspectives on the world, we stimulate critical thinking. The educators write down any keyword or idea that comes out of discussion. As a follow up activity the participants divided into smaller groups (4-5 people) are asked to create a concept map that would  re-elaborate the ideas that came out of the discussion using any tool of their choice, poster-paper, pencils, digital apps, etc.`,
            "10": `Explain how will the evaluation process go and what type of evaluation will be used, depending on the following factors:`,
            "11": `For the feedback will be used the following questionnaire:`,
            "12": 'Time: 1 – 2 hours;',
            "13": 'Number of players: minimum 5, maximum 30;',
            "14": 'Local requirements: no location requirements, it’s enough to be in a space with not too much noise for the discussion to be heard, a comfortable non-formal environment.',
            "15": 'Necessary Tools: An upside-down world map (see Annex section), any sticky material to fix the map on the vertical surface, post-it notes, pens (pencils).',
            "16": 'Human Resources: 3 educators. The first has the task to explain the activity and to check on participants while they observe the map, and the second to distribute the materials for the participants and to give the instructions on how to perform the activity. Then both of them facilitate and guide the discussion. The presence of two trainers could create some disagreements between them with each other during the discussion, so it gives more perspectives and so that the participants understand that the discussion it’s not about black and white, right or wrong, but about different perspectives and opinions.',
            "17": `Criterias of evaluation`,
            "18": `Who is making the evaluation? (commission, self reflection, full group evaluation)`,
            "19": `How can you quantify the results of the activities and the learning outcome?`,
            "20": `Prepare a method for debriefing so the participants don't remain in the game mindset or still in competition mode.`,
            "21": `Criterias of evaluation: Criteria are based on the overall intensity of the discussion, individual involvement and critical thinking of each participant throughout the activity  (discussion, completion of the task).`,
            "22": `Who is making the evaluation: Educators;`,
            "23": `Quantify the results & Learning Outcomes: Credit will be given according to the active participation in the discussion, the application of critical thinking, creativity, and teamwork.`,
            "24": `Debriefing: Presentation of the concept maps to the group and discussion that foster communication skills.`,
            "25": `What was your first impression when you realised  that the world map is upside down?`,
            "26": `Did you find the activity thought provoking?`,
            "27": `What do you think is the educational purpose behind it?`,
            "28": `Would you modify anything?`,
            "29": `How?`,
            "31": `Was it helpful to you to work in a team?`,
            "32": `Was it easy to find an agreement?`,
        },
        "fakenews": {
            "submission": "Thank you for your contribution! We will review your submission and publish it as soon as possible!",
            "summary": 'Short Summary',
            "disproof": 'Disproof',
            "link": 'Link',
            "0": `Help us mark fake news articles and`,
            "1": `be part`,
            "2": `of the`,
            "3": `change`,
            "4": `Title`,
            "5": `Fake news article title. (20-100 characters)`,
            "6": `Summary description of the subject. (50-600 characters)`,
            "7": `Link`,
            "8": `Paste here the fake news URL.`,
            "9": `Clear arguments for why you consider it's fake news. (50-500 characters)`,
            "10": `Submit`,
            "11": `Fake News Alert`,
            "12": `Fake news is everywhere and everybody can contribute to making the internet a better place for finding quality and reliable information.`,
            "13": `This section allows any person who has read or noticed a fake news article to signal it and make the disinformation proof available to the public.`,
            "14": `The readers are presented with the main ideas of the articles and the disproof that supports it. Help us mark the fake news articles and be part of the change!`,
            "15": `Take a look at what other people reported as fake news!`,
            "16": `No fake news found`,
            "17": `Description`,
            "18": `Disproof`,
            "19": `Link`,
            "20": 'Europe could be left without electricity between a day and a week. The Austrian army is preparing the population for a massive "blackout"',
            "21": `This article and similar ones from the the Romanian press were announcing a huge blackout in Europe with dramatic titles such as "Blackout in Europe! Huge power outage. The army has already intervened: Get ready!” or "Europe, one step away from remaining in total darkness for days on end. Austria is preparing for a blackout. The effects would be devastating."  Besides the examples article from Antena 3, other popular news websites also promoted the fake news. Those are: Cotidianul, Opinia Timișoarei, Observator, Capital, Realitatea.net, Protv.ro,  B1TV`,
            "22": `The information that reached the Romanian press four was an assumption for the Austrian warning campaign that became a certainty for the Romanian press. The reality is that the Austrian Ministry of Defense started a campaign to train the population to know how to react in the event of a major power outage. The start of the campaign is marked on Instagram by Klaudia Tanner, the holder of the portfolio of Defence, next to a comment which says that a "power cut is an interruption of energy, infrastructure and supply at European level - it is a realistic risk and at the same time, underestimated. Therefore, our goal is to improve the resilience of our republic as a whole!”`,
            "23": 'multiple online posts selling Arbidol as a treatment medicine against Covid 19',
            "24": 'Romanians who were afraid of Covid have started to purchase Arbidol, a medicine from Russia, not authorised in the European Union. There are dozens of ads that appeared and disappeared very quickly on online sales platforms, where Arbidol was sold for between 200 and 250 lei.',
            "25": 'Initially the treatment was used in trials, but they realised it was not efficient. The Infectious Diseases Commission of the Ministry of Health, has reconfirmed this position in the protocol and the fact that its use in the treatment of Sars-CoV-2 infection was not recommended.',
            "26": 'The doctors who were treating Covid patients said that it is dangerous for patients to take drugs that can have adverse effects by themselves at home, bought online. They need to be recommended by a doctor.',
            "27": 'The Deveselu shield is a diplomatic shame, the shield has nothing to do with defense. (anti-Western and ani-NATO discourse)',
            "28": `The post attracts a series of comments that support the idea that Romania is at the mercy of NATO and that we have lost our independence: "We were supposed to be NEUTRAL, but we became Banana Republic Romania" or "It would not be appropriate for the American to take his scooters and to let us collaborate with Russia for development?" or that no matter how powerful the shield is, it won't be able to counter the arsenal Russia has - "This is junk from the Americans, for hard money, accepted by our incompetents." or "Currently, there is no anti-missile shield of any kind to stop the Russian hypersonic vanguard."`,
            "29": `The role of this propaganda is weakening the partnership with the North Atlantic Alliance and membership of the European Union. The pandemic has intensified the anti-Western discourse and radicalised some social media users. The return of the attack by Russia against Ukraine has also brought an increase in anti-Western discourse, especially that aimed at Romania's exit from the EU.`,
            "30": 'Romania concentrates its military forces on the borders with the Republic of Moldova and Ukraine',
            "31": `A column of Romanian military equipment was caught while moving towards the borders with the Republic of Moldova and Ukraine, writes the Russian press referring to a video recording made by an amateur in his own car.  The filming - also used for other disinformation - is a pretext for the resumption of theses regarding Romania's intention to occupy the Republic of Moldova and attack the separatist Transnistrian region.`,
            "32": `Although Russian media write that the images are published by Ukrainian media, they appeared on a Transnistrian telegram channel called PMR_History on April 33, and were picked up by telegram channels and media in Ukraine and Russia the next day. The column that appears in the recording does not even belong to the Romanian army - it is made up of vehicles of the US forces stationed in Romania. In fact, in parallel with the narratives regarding the movement of Romanian forces towards the border, another appeared, according to which the Americans would be the ones who would move towards the border with the Republic of Moldova.  The Romanian Ministry of Defense announced that the military equipment units in the video images were, in fact, heading towards the Babadag training ground.`,
            "33": `Ms Heard has accused Mr Depp of repeated instances of physical abuse. On Monday, she alleged that he attacked her while aboard the Orient Express train in Asia after their February 2015 wedding, claiming that he struck her and held her by the neck while in their sleeper compartment.`,
            "34": `"What I have seen throughout this trial are the symptoms of narcissistic personality disorder. I agree with the diagnosis of borderline personality disorder," said Angelica. Also Jhonny Depp won the case.`,
            "35": `A young blind guy  Mr . Faysaloğlu was given 10,000 dollars award from Apple by finding their defect.`,
            "36": `A computer programmer Utku Şen claimed that this new is fake and it is all edited by him. He explained that ; he just created fake new to throw attentions and he sent this new to websites and unfortunately the website editors published the new without checking whether it is true or not.`,
            "37": `“President Trump, basically before Elon Musk bought it, actually said to go and buy it because the goal of our company is really to build a community where people are in a family-friendly, safe environment,” Devin Nunes said in an appearance on Fox Business Network. “That’s why we encouraged Elon Musk to buy it, because someone has to take on these tech tyrants,” Nunes said. “Donald Trump wanted to make sure that the American people got their voice back and that the internet was open and that’s what we are doing.”`,
            "38": `Musk denied Nunes’ account in a tweet following publication of this article, writing, “This is false. I’ve had no communication, directly or indirectly, with Trump, who has publicly stated that he will be exclusively on Truth Social.”`,
            "39": `Allegations about the death of Alpaslan Özmol, the "Çaycı Hüseyin" of Cocuklar Duymasın (tv series), are constantly on social media. Alpaslan Özmol, who was reported to have died on social media again, said, "I am not dead."`,
            "40": `Stating that good jokes were also made in his 'Died' posts, Özmol said, "Our humor culture has reached its peak. When some see me, they come to me saying, 'Are you not dead?' I'm not tired of it, I even come up with it. They actually want to see me on TV, so I guess they did.”`,
            "41": 'Johnny Depp hit me on honeymoon, says Amber Heard',
            "42": 'A blind teenager got 10,000 dollars award by finding Apple’s defect.',
            "43": 'Elon Musk denies Trump quietly ‘encouraged’ him to buy Twitter',
            "44": 'Çaycı Huseyin Died.',
            "45": 'WATCH: MIGRANTS IN ITALY',
            "46": 'NON È L\'ARENA. GILETTI IN DIRETTA DALLE TRINCEE DI ODESSA MA IN REALTÀ È LA STAZIONE DI BARRA',
            "47": 'VATICAN BLACKOUT: Pope arrested on 80 count indictment for Child Trafficking, Fraud',
            "48": '14 Fake Olive Oil Companies Revealed – Stop Buying These Brands Now!',
            "49": `the article points out that some migrants living in Italy would commit several crimes against locals;`,
            "50": `here it is discussed the authenticity of the footage as well as the intention of the author of the source article, intended to be deliberately against migrants and migration. Apparently the footage is not even something happening in Italy but the result of a scene taken from a movie filmed in Italy in 2015. Also the videos were already on the internet way before the date of publication of the questioned article`,
            "51": `the hoax disseminates doubts about the presence of the Italian journalist Massimo Giletti in Ukraine, Odessa during the conflict towards Mosque. More specifically, it is questioned that Giletti could actually be pretending his footage filming the scenes directly from the Italian metropolitan station of Barra, in the area of Naples.`,
            "52": `In this video it is evidently shown the interview taken by the Italian journalist Massimo Giletti describing the current situation in the city of Odessa while going live to the Italian TV channel 7 for the programme "Non è l'arena". You can clearly hear someone's voice talking to him suggesting to move away.`,
            "53": `The article discusses about the alleged crimes committed by the Pope Francis`,
            "54": `In this article we can clearly attest the actual presence of the Pope Francis at his seat in Vatican city, the same day of the leaking news. This represents the major disproof of the previous disinforming news.`,
            "55": `the article discusses about some Italian olive oils accused of being produced by bad quality olives`,
            "56": `The disproof was published on the Bertolli official website, discussing in different points where and why the fake news was undoubtedly wrong and spreading the real information.`,
            "57": 'The United States provoked the war in Ukraine for economic gain',
            "58": 'Putin is a good Christian, a victim of Russophobic discourse and has nothing to do with communism, imposed on Russia by the West',
            "59": 'The Soviet regime did not organize the famine in Bessarabia',
            "60": 'The Romanian Census and possible misinformation regarding it',
            "61": `The US is turning the EU into its colony after starting the war in Ukraine. The US is waging a proxy war with Russia in Ukraine for world domination. Middle Eastern countries refused to sell Germany hydrocarbons to replace imports from Russia.`,
            "62": `First of all, it follows the already classic narrative of Russian propaganda which says that the US actually initiated the war in Ukraine through all kinds of machinations. According to this Kremlin narrative, the US would be waging a proxy war in Ukraine against Russia. Obviously, the US did not start the war in Ukraine, but this is the result of the massive Russian invasion of February 24 on the territory of Ukraine. In fact, almost a year before, Russia massed constantly and quantitatively more army and military equipment on the borders of Ukraine under the pretext of military exercises. Russia consistently denied any invasion plan until the last day before the February 25 moment. Economically, Russia created and contributed to artificially raising natural gas prices long before the invasion of Ukraine throughout 2021. Russia reduced its exports to Europe by 26% in the fourth quarter of 2022 compared to the same period in 2020, which accentuated the increase in gas prices on the Spot market in Europe. Revenues from oil and gas export taxes and tariffs accounted for 45% of Russia's federal budget in January 2023. Given current market prices, the value of Russian pipeline gas exports to the EU alone amounts to 400 million dollars a day. The total export revenue of crude oil and refined products currently stands at about US$700 million per day. The amount of Russian oil and gas entering Europe has actually increased since the start of the war in Ukraine. Europe paid Russia around 22 billion euros ($27 billion) for oil and gas in March alone, according to Bruegel, a Brussels-based think tank, cited by Nature magazine. So Russia is taking full advantage of high oil and gas prices.`,
            "63": `Vladimir Putin is a good Christian, but his image suffers because of Russophobia. These false narratives start from the statements of the Archbishop of Tomis, Theodosius, and are amplified by Sputnik. At the same time, the idea is promoted that Putin and Russia have nothing to do with communism, which was imposed by the West.`,
            "64": `Numerous authoritarian leaders and dictators have sought over time to publicly emphasize their pious side and align themselves with religious authorities in an attempt to increase their popularity and gain legitimacy. An example in this sense is that of Saddam Hussein who, although he was an exponent of a secular political movement and took measures to secularize Iraqi society in the first years in which he was in power, changed his optics after he became a pariah of the international community following the invasion of Kuwait. In his bid to win the support of the Muslim world, Saddam gave more freedom to religious leaders, ordered "Allah al-Akbar" to be emblazoned on the Iraqi flag (his own handwriting was said to have been used), let the Quran be written with his blood and more mosques be built. This piety displayed by the dictator does not mean that he is no longer guilty of the mass murders he committed, nor that he stopped committing those crimes after writing Allah al-akbar on the flag - on the contrary, some of the biggest followed that moment.`,
            "65": `The famine was not organized by the Soviet regime, and Moscow helped Moldova during the famine. Starvation and deportations are not Bolshevik terror, but lying anti-Soviet propaganda.The famine of 1947 is a consequence of the inaction of local authorities, it was not organized by the central leadership of the USSR.The research of pro-Western historians from the Republic of Moldova is biased and aims to rewrite its true history.`,
            "66": `The communist regime showed from the very beginning of the occupation of the SSR that terror is the fastest and most effective method for inoculating the new order. In addition to the arrests, deportations, assassinations carried out by the Soviet power against the population, according to historians, the organized famine killed between 150 and 350 thousand people in the SSR, one third of the population of the Republic being affected by hunger and disease. At the same time, the famine of 1946-1947 forced the peasants to accept collectivization. The taxation of the peasants by the Soviet regime began as early as 1944, immediately after the reoccupation of Bessarabia, reports Mariana Țăranu, PhD in history. The general staff raised taxes from year to year, the Soviet leadership demanded from each family a tax in products - eggs, wool, cheese, grain for the Red Army - but also a sum of money. The state also demanded other products that the people did not grow, being forced to buy them. Party organs were focused on fulfilling the plan to collect agricultural products and requisitioned people's food reserves. Families that failed to pay these taxes had their property confiscated, and the heads of the families were tried by the Military Tribunal and sentenced to forced labor. To carry out the plan, the party activists were accompanied by armed soldiers who literally swept the bridges of the peasants of their last reserves. These taxes made life in the countryside even more difficult. The Soviet leadership used the climatic conditions to camouflage the policy of starving the population. Given that in 1946 365 thousand tons of grain were harvested, half of the population's needs, and the requisition plan in Bessarabia was 255 thousand tons, famine was imminent, claims historian Anatol Țăranu`,
            "67": `Based on a statement of a representative of the Romanian National Institute of Statistics (RNIS) where it is mentioned that the results of the census will be anonymous, journalist Josefina Pascal affirms that WHO (World Health Organization) will have access to information about births, health and mortality as a result of the census and will use these maliciously.`,
            "68": `Belonging to a member of the European union, RNIS automatically forwards the results to Eurostat, who, in turn, forwards them to WHO. WHO also hase a larga database regarding global health and wellbeing that was compiled with the official help of its member states. The fact that WHO would maliciously use statistics about general health in Romania goes against the raison d’etre of this organization, whose purpose is the improvement of global health. Lately, WHO has been the preferred target of anti vaxxer conspirations that relate it to the “Great Reset”. The journalist making defamatory assumptions based on a simple statement without any proof.`,
        },
        "activities": {
            "title": `Activities`,
            "description": `In this section you will find details about the methods, pictures from their implementation as well as pictures from the project's working meetings with all the partners.`,
            "march": `March`,
            "september": `September`,
            "october": `October`,
            "november": `November`,
            "february": `February`,
            "0": `Objectives`,
            "1": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "2": `Planning future activities, based on the Gantt diagram.`,
            "3": `Checking the necessary documents for the project report.`,
            "4": `Meeting content:`,
            "5": `We focused on the careful analysis of the project activities, according to which each partner body should have implemented the methods developed at LTT Ranca. Piloting methods is an important stage of the project.`,
            "6": `Another important aspect of the meeting was the verification of the documents, of the proofs of the accomplishment of each activity.`,
            "7": `Finally, the presentation of the following activities was very important, especially since this meeting marks the realization of the intellectual output no. 1 and the transition to intellectual output no. 2. The premises for the realization of the online platform were discussed and some objectives were established that must be achieved in the realization of the platform.`,
            "8": `Objectives`,
            "9": `Create the framework for experimenting with the methodology developed by each partner in the project (Non Formal methodology for critical thinking and correct information), by engaging 20 youth workers and teachers from Romania, Türkiye and Italy.`,
            "10": `Develop an incubator of non-formal methods during the training, with the purpose of improving the previously created methods, as well as diversify the means of cooperation and adaptation of non-formal education methods to the classical learning system.`,
            "11": `Encourage the cross-sectoral cooperation between public educational institutions and youth NGOs by drafting a collaboration strategy between the 4 partners for the next 2 years.`,
            "12": `Training content:`,
            "13": `We focused the activities on improving the competences in the field of critical thinking and correct information for the people engaged in the implementation of the EDUcate YOUth strategic project.`,
            "14": `By the start of the training, each partner had to have ready the task within Intellectual Output 1, as follows:`,
            "15": `Italy:`,
            "16": `A guide with several non-formal methods for youth, focused on correct information and critical thinking`,
            "17": `Educational Escape Room on CRAAP Methodology`,
            "18": `Alternative world map`,
            "19": `Türkiye:`,
            "20": `Methodology guide for the teachers and trainers, containing a selection of specially designed non-formal methods, with explanations on how  and when to put them in practice in classes.`,
            "21": `Anyone Can Volunteer`,
            "22": `Collect, create and play`,
            "23": `No Gender Stereotypes`,
            "24": `Who believes in superstitions`,
            "25": `Role play & CT Two In One Tale`,
            "26": `Romania (Horezu high-school):`,
            "27": `A debate methodology as an applied tool for the critical thinking process: Mobile debate`,
            "28": `Romania (ATDD):`,
            "29": `Interactive game to support the development of critical thinking in the field of youth: Survival in the Desert`,
            "30": `Participants from each national group shared with the consortium the developed tasks under IO1, which were experimented in a pilot mode during the training. Working language for the training was English.`,
            "31": `Objectives`,
            "32": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "33": `Planning multiplayer events, based on the Gantt diagram.`,
            "34": `Checking the necessary documents for the project report.`,
            "35": `Meeting content`,
            "36": `we focused on the careful analysis of the project activities, according to which each partner body should have implemented the task for project’s online platform.`,
            "37": `Another important aspect of the meeting was the verification of the documents, of the proofs of the accomplishment of each activity.`,
            "38": `And last but not least, we discussed the planning of multiplayer events to be held in Romania, Italy and Türkiye. Dates and responsibilities for each partner have been established.`,
            "39": `Objectives:`,
            "40": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "41": `Planning future activities, based on the Gantt diagram.`,
            "42": `Checking the necessary documents for the project report.`,
            "43": `Meeting content:`,
            "44": `Project implementation status up`,
            "45": `Gantt Chart & Milestones`,
            "46": `Targets and expected results`,
            "47": `Q&A`,
            "48": `Project Management Plan`,
            "49": `Project Management team – consortium coordination discussion`,
            "50": `Project Management tools – Methodologies and Procedures, adminproject.eu`,
            "51": `Technical Reporting`,
            "52": `Quality Assurance & Monitoring Plan, including QBM- Quality Assurance Committee`,
            "53": `Financial management`,
            "54": `Budget execution and documents reporting`,
            "55": `Financial management`,
            "56": `Technical management`,
            "57": `IO1: Non-formal methodology for critical thinking and correct information`,
            "58": `IO2: Digital E-learning Platform for Critical Thinking`,
            "59": `Tasks distribution`,
            "60": `Communication and dissemination Plan`,
            "61": `Communication plan status and reporting`,
            "62": `Indicators`,
            "63": `Next steps`,
            "64": `Overall Q&A and Feedback`,
            "65": `Cultural Visit`,
            "66": `Hatay Archaeology Museum (the second largest archaeological museum in the World)`,
            "67": `St.Pierre Church (the oldest Anatolian and Near Eastern settlements. It is in the tentative list in the cultural category of UNESCO World Heritage Site)`,
            "68": `Habibi Neccar Mosque`,
            "69": `Hatay Catholic Church`,
            "70": `The Vespasianus Titus Tunnel (an ancient water tunnel built for the city of Seleucia Pieria. It is in the tentative list in the cultural category of UNESCO World Heritage Site)`,
            "71": `Between 1-2 October 2022, in the resort of Sinaia, the second mobility took place within the EDUcate YOUth - KA2 - Nonformal bridges to formal education project, financed by the European Union through Erasmus+. `,
            "74": "This second project meeting was attended by 5 teaching staff from \"Constantin Brâncoveanu\" High School, 5 teaching staff from Türkiye and 5 members, each from the two youth organizations from Romania (ATDD) and Italy (Beyond Borders). During the event, non-formal digital educational tools that can be applied online during formal or non-formal educational activities were presented and piloted.",
            "75": "The methods have the role of stimulating independent critical thinking and through each digital educational tool proposed within the project, each partner country brought an important addition in the methods of developing critical thinking, as well as new methods of correct information and avoiding misinformation through various activities. The three cultural evenings brought to the fore by the participants, presented elements of culture, civilization and important traditions for effective communication in the medium and long term. A cultural visit to Brașov and Bran Castle was also organized",
            "72": `All the previously created 8 non-formal methods (Escape Room, Alternative World Map, Debate, Anyone can volunteer, Who believes in superstitions, Nasreddin Hodja, Collect, Create and Play, Survival in the desert) were put into practice and tested during 5 months by all the 4 partners involved in their local and national communities in the piloting phase of the project. This phase was very important because after the participants took part in the activities which used these methods, they gave the facilitators feedback about them from their point of view. Each organisation collected the feedback and made changes to the methods according to them.`,
            "73": `Methods Testing`,
            "click": "click for photos",
        },
        "footer": {
            "1": "European Commission’s support for the production of this content does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
        },
        "routes": {
            "Home": "Home",
            "Non-Formal Methodology": "Non-Formal Methodology",
            "Critical Thinking and Disinformation Kit": "Critical Thinking and Disinformation Kit",
            "Activities": "Activities",
            "Critical Thinking Games": "Critical Thinking Games",
            "Non-Formal Debates": "Non-Formal Debates",
            "Fake News Alert": "Fake News Alert",
        }
    },
}

export default en;