import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { useGetCollection } from '../../../../hooks/useGetCollection';
import React, { memo } from 'react';
import { FakeNewsDoc } from '../../../../types';
import { FakeNewsCard } from './FakeNewsCard';
import { where, orderBy, limit } from 'firebase/firestore';
import { useRenderOnVisible } from '../../../../hooks/useRenderOnVisible';
import { Hero } from '../../../Hero/Hero';
import { useTranslation } from 'react-i18next';

export const FakeNewsFeed = memo(function FakeNewsFeedComponent() {
  const { t } = useTranslation()
  const { visibility, VisibilityComponent } = useRenderOnVisible();

  const { data, loading } = useGetCollection<FakeNewsDoc>(
    'news',
    visibility,
    where('visibility', '==', true),
    orderBy('createdAt'),
    limit(15)
  );

  return (
    <Box>
      <Hero imageName='home4' extension='jpeg'/>
      {VisibilityComponent}
      {visibility && (
        <>
          <Typography
            className='fontFamilyMeans animate__animated animate__fadeInUp'
            variant='h3'
            component='h3'
            sx={{ p: 4, textAlign: 'center', flex: '7' }}
          >
            {t('fakenews.15')}
          </Typography>
          <Paper className='animate__animated animate__fadeIn' sx={{ p: [0, 4], minHeight: 30 }}>
            <Stack spacing={[0, 2]}>
              {loading ? (
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}  />
                </Box>
              ) : (
                data.length > 0 ? (
                  data.map((fakeNews: FakeNewsDoc, index: number) => (
                    <FakeNewsCard
                      key={fakeNews.id}
                      isExpanded={!index}
                      {...fakeNews}
                    />
                  ))
                ) : (
                  <Box sx={{ textAlign: 'center' }}>{t('fakenews.16')}</Box>
                )
              )}
            </Stack>
          </Paper>
        </>
      )}
    </Box>
  )
})