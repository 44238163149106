import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { InlineBold } from '../../Typography/Inline';
import { AnimateOnVisibility } from '../../../utils/Animations/AnimateOnVisibility';
import React from 'react';
import { EduList } from '../../List/List';
import { Hero } from '../../Hero/Hero';
import { PartnerLogos } from '../../PartnerLogos/PartnerLogos';
import { bigInfoContainerSx, groupInfoContainerSx, smallInfoContainerSx } from './Home.styles';

import { useTranslation } from 'react-i18next';

export function Home() {
  const { t } = useTranslation()

  return (
    <>
      <Hero imageName={['home.webp', 'home2.jpeg', 'home3.jpeg', 'home4.jpeg']}>
        <span className="colorEduRed">EDU</span><span className="colorEduOrange">cate</span> <span className="colorEduRed">YOU</span><span className="colorEduOrange">th</span> <br/> <div style={{ paddingTop: 20 }}>Nonformal bridges to formal education</div>
      </Hero>
      <Container sx={{ mt: 25 }}>
        <Typography component='div'>
          <Stack spacing={[5, 10]}>
            <Box sx={groupInfoContainerSx}>
              <AnimateOnVisibility minTopValue={0} animation={['fadeIn', 'delay-1s']}>
                <Box sx={bigInfoContainerSx()}>
                  <Typography variant='h3' className='fontFamilyMeans'>{t('home.1')}</Typography>
                </Box>
              </AnimateOnVisibility>
              <AnimateOnVisibility animation={['fadeIn', 'slideInRight']}>
                <Paper elevation={5}>
                  <Box className='backgroundJasmine' sx={smallInfoContainerSx}>
                    <InlineBold fontSize={18}>EDUcate YOUth - Nonformal bridges to formal education" </InlineBold>{t("home.2")} .
                  </Box>
                </Paper>
              </AnimateOnVisibility>
            </Box>

            <Box sx={groupInfoContainerSx}>
              <Box sx={{ display: ['flex', 'none'] }}>
                <AnimateOnVisibility animation={['fadeIn']}>
                  <Box sx={bigInfoContainerSx('left')}>
                    <Typography variant='h4' className='fontFamilyMeans'>{t("home.3")}</Typography>
                  </Box>
                </AnimateOnVisibility>
              </Box>
              <AnimateOnVisibility animation={['fadeIn', 'slideInLeft']}>
                <Paper elevation={5}>
                  <Box className='backgroundEduOrange' sx={smallInfoContainerSx}>
                    <Box>
                      {t("home.4")}
                      <br/><br/>{t("home.6")}
                    </Box>
                    <Box>
                      <EduList
                        sx={{ listStyleType: 'decimal' }}
                        items={[
                          t("home.8"),
                          t("home.9"),
                        ]}
                      />
                      {t("home.10")}
                    </Box>
                  </Box>
                </Paper>
              </AnimateOnVisibility>
              <Box sx={{ display: ['none', 'flex'] }}>
                <AnimateOnVisibility animation={['fadeIn']}>
                  <Box sx={bigInfoContainerSx()}>
                    <Typography variant='h3' className='fontFamilyMeans'>{t("home.3")}</Typography>
                  </Box>
                </AnimateOnVisibility>
              </Box>
            </Box>

            <Box sx={groupInfoContainerSx}>
              <AnimateOnVisibility animation={['fadeIn']}>
                <Box sx={bigInfoContainerSx()}>
                  <Typography variant='h3' className='fontFamilyMeans'>{t("home.5")}</Typography>
                </Box>
              </AnimateOnVisibility>
              <AnimateOnVisibility animation={['fadeIn', 'slideInRight']}>
                <Paper elevation={5}>
                  <Box className='backgroundJasmine' sx={smallInfoContainerSx}>
                    {t("home.6")}
                    <EduList
                      items={[
                        t('home.11'),
                        t('home.12'),
                        t('home.13'),
                      ]}
                    />
                    {t("home.14")}
                    <br/>
                    {t("home.15")}
                    <br/>
                    {t("home.16")}
                  </Box>
                </Paper>
              </AnimateOnVisibility>
            </Box>
          </Stack>
        </Typography>
        <Box sx={{ mt: 15 }}>
          <PartnerLogos/>
        </Box>
      </Container>
    </>
  );
}