import React, { FC, memo } from 'react';
import { Bold } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Collect: FC = memo(function CollectComponent() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='collect'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: 'Collect Create and Play',
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          <>
            {t('collect.0')}
          </>
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('collect.5'),
              t('collect.6'),
              t('collect.7'),
              t('collect.8'),
              t('collect.9'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <Bold>{t('methodologies.Preparation of the group')}:</Bold>
            {t('collect.1')}
            <Bold>{t('methodologies.Implementing activities')}:</Bold>
            {t('collect.2')}
            <Bold>
              {t('collect.3')}
            </Bold>
            <EduList
              items={[
                t('collect.10'),
                t('collect.11'),
                t('collect.12'),
              ]}
            />
            {t('collect.4')}
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <EduList
              items={[
                t('collect.13'),
                t('collect.14'),
                t('collect.15'),
                t('collect.16'),
                t('collect.17'),
                t('collect.18'),
                t('collect.19'),
                t('collect.20'),
                t('collect.21'),
                t('collect.22'),
                t('collect.23'),
                t('collect.24'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            <EduList
              items={[
                t('collect.25'),
                t('collect.26'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
