import React, { FC, memo } from 'react';
import { Bold, InlineBold } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { useTranslation } from 'react-i18next';

export const DebateMethodology: FC = memo(function DebateMethodologyComponent() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='debate'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: 'Debate',
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          <>
            {t('debate.0')}
          </>
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('debate.24'),
              t('debate.25'),
              t('debate.26'),
              t('debate.27'),
              t('debate.28'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            {t('debate.48')}

            <InlineBold>
              {t('debate.49')}
            </InlineBold>

            <EduList
              items={[
                t('debate.56'),
                t('debate.57'),
                t('debate.58'),
                t('debate.59'),
                t('debate.60'),
                t('debate.61'),
                t('debate.62'),
                t('debate.63'),
                t('debate.64'),
                t('debate.65'),
              ]}
            />

            <InlineBold>
              {t('debate.50')}
            </InlineBold>
            <br/>
            <br/>
            {t('debate.51')}

            <br/>
            <br/>
            <InlineBold>
              {t('debate.52')}
            </InlineBold>
            <br/>
            <EduList
              items={[
                t('debate.66'),
                t('debate.67'),
                t('debate.68'),
                t('debate.69'),
                t('debate.70'),
                t('debate.71'),
              ]}
            />

            <InlineBold>
              {t('debate.53')}
            </InlineBold>
            <br/>
            <br/>
            {t('debate.54')}
            {t('debate.55')}
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <Bold>
              {t('debate.72')}
            </Bold>
            <EduList
              items={[
                `Assessment of the debate match (who won the match?)`,
                `Assessment of the skills improved as a result of the use of this method.`,
              ]}
            />
            <Bold>
              {t('debate.73')}
            </Bold>
            <EduList
              items={[
                `The decision is made by the public.`,
                `The decision is made taking into account:`,
                `The quality of the arguments (logical structure, connection between proofs and topic, relevance of the arguments to the topic, the quality of the sources of information, relevance of the used proofs)`,
                `Oratorical style;`,
                `Number of conflict areas won by the team.`,
              ]}
            />
            <Bold>
              {t('debate.74')}
            </Bold>
            <EduList
              items={[
                `This type of assessment can be done after the repeated use of this method.`,
                `The aim is to use the competences acquired during other activities also (volunteering activities, taking part in projects, behaviour and getting involved in classes such as History, Literature, Philosophy etc.)`,
                `The progress is recorded during a debate competition.`,
                `The way the teenager promotes this method is also taken into consideration.`,
                `The way they research/get informed in order to carry out projects or write essays or articles etc, is also taken into consideration.`,
              ]}
            />
            {t('debate.75')}
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            {t('debate.76')}
          </>
        )
      }]}
    />
  )
})
