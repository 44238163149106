import { Styles } from '../../types';

const styles: Styles = {
  BtnContainer: () => ({
    display: 'flex',
    my: 2,
    justifyContent: 'center',
  }),
  Btn: (isActive: boolean, sx: any) => ({
    ':hover': {
      backgroundColor: 'transparent',
      transform: 'scale(1.05)',
    },
    ':hover:after': {
      opacity: 1,
      transform: 'scaleX(2)',
    },
    '&::after': {
      content: '""',
      display: 'block',
      opacity: isActive ? 1 : 0,
      position: 'absolute',
      height: '1px',
      width: '35%',
      bottom: '0',
      background: isActive ? 'rgba(247, 188, 74, .5)' : 'rgba(0,0,0, .5)',
      transition: 'all 0.1s linear',
      transform: isActive ? 'scaleX(2)' : undefined,
    },
    py: 1,
    px: 1.5,
    transition: 'all 0.1s linear',
    color: isActive ? '#f7bc4a' : 'black',
    transform: isActive ? 'scale(1.05)' : undefined,
    ...sx,
  }),
  BtnTypography: (isActive) => ({
    textTransform: 'none',
    fontWeight: isActive ? 'bold' : 'normal',
    fontSize: 17,
  })
}

export default styles