import { useScrollIndicator } from 'react-use-scroll-indicator';
import { FC, memo } from 'react';
import { LinearProgress, SxProps } from '@mui/material';

interface ScrollIndicatorProps {
  sx?: SxProps
}

export const ScrollIndicator: FC<ScrollIndicatorProps> = memo(function ScrollIndicatorComponent({
  sx,
}) {
  const [{ value }] = useScrollIndicator();

  return (
    <LinearProgress variant="determinate" value={value} sx={{ height: 3, mt: '4px', ...sx }} />
  );
})