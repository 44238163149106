import { Hero } from '../../Hero/Hero';
import { Description } from '../../Description/Description';
import { Container, Divider } from '@mui/material';
import { Paragraph } from '../../Typography/Paragraph';
import { EduList } from '../../List/List';
import { InlineBold } from '../../Typography/Inline';
import { Article } from '../../Article/Article';
import { sharedProps } from '../../Article/Article.constants';
import { useTranslation } from 'react-i18next';

export function DisinformationKit() {
  const { t } = useTranslation()

  return (
    <>
      <Hero imageName='critical_thinking'>{t("disinformation.title")}</Hero>
      <Description>
          {t("disinformation.description1")}
        <br/>
          {t("disinformation.description2")}
        <br/>
          {t("disinformation.description3")}
        <br/>
          {t("disinformation.description4")}
        <br/>
          {t("disinformation.description5")}
        <br/>
          {t("disinformation.description6")}
        <br/>
          {t("disinformation.description7")}
      </Description>
      <Container sx={{ p: [0, 2] }}>
        <Article
          extension='webp'
          sharedProps={sharedProps}
          subsections={[{
            title: t('disinformation.title1'),
            children: (
              <>
                <Paragraph>{t("disinformation.1")}</Paragraph>
                <Paragraph>{t("disinformation.2")}</Paragraph>
                <Paragraph>{t("disinformation.3")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title2'),
            children: (
              <>
                <Paragraph>{t("disinformation.4")}</Paragraph>
                <Paragraph>{t("disinformation.5")}</Paragraph>
                <Paragraph>{t("disinformation.6")}</Paragraph>
                <Paragraph>{t("disinformation.7")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title3'),
            children: (
              <>
                <Paragraph>{t("disinformation.8")}</Paragraph>
                <Paragraph>{t("disinformation.9")}</Paragraph>
                <Paragraph>{t("disinformation.10")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title4'),
            children: (
              <>
                <Paragraph>{t("disinformation.11")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title5'),
            children: (
              <>
                <Paragraph>{t("disinformation.12")}</Paragraph>
                <Paragraph>{t("disinformation.13")}</Paragraph>
                <EduList
                  items={[
                    t("disinformation.14"),
                    t("disinformation.15"),
                    t("disinformation.16"),
                    t("disinformation.17"),
                  ]}
                />
                <Paragraph>{t("disinformation.18")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title6'),
            children: (
              <>
                <Paragraph>{t("disinformation.19")}</Paragraph>
                <Paragraph>{t("disinformation.20")}</Paragraph>
                <Paragraph>{t("disinformation.21")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title7'),
            children: (
              <>
                <Paragraph>{t("disinformation.22")}</Paragraph>

                <Paragraph>
                  <InlineBold>{t('disinformation.34')}</InlineBold>{t('disinformation.39')}
                </Paragraph>

                <Paragraph>
                  <InlineBold>{t('disinformation.35')}</InlineBold>{t('disinformation.40')}
                </Paragraph>
                <Paragraph>
                  <InlineBold>{t('disinformation.36')}</InlineBold>{t('disinformation.41')}
                </Paragraph>
                <Paragraph>
                  <InlineBold>{t('disinformation.37')}</InlineBold>{t('disinformation.42')}
                </Paragraph>
                <Paragraph>
                  <InlineBold>{t('disinformation.38')}</InlineBold>{t('disinformation.43')}
                </Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title8'),
            children: (
              <>
                <Paragraph>{t("disinformation.23")}</Paragraph>
                <EduList
                  items={[
                    t('disinformation.44'),
                    t('disinformation.45'),
                    t('disinformation.46'),
                    t('disinformation.47'),
                    t('disinformation.48'),
                    t('disinformation.49'),
                    t('disinformation.50'),
                  ]}
                />
              </>
            )
          }]}
        />


        <Divider sx={{ my: 4 }}></Divider>

        <Article
          extension='webp'
          sharedProps={sharedProps}
          subsections={[{
            title: t('disinformation.title9'),
            children: (
              <>
                <Paragraph>{t("disinformation.24")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title10'),
            children: (
              <>
                <EduList
                  items={[
                    t('disinformation.51'),
                    t('disinformation.52'),
                    t('disinformation.53'),
                    t('disinformation.54'),
                    t('disinformation.55'),
                    t('disinformation.56'),
                  ]}
                />
              </>
            )
          }, {
            title: t('disinformation.title11'),
            children: (
              <>
                <EduList
                  items={[
                    t('disinformation.57'),
                    t('disinformation.58'),
                  ]}
                />
              </>
            )
          }, {
            title: t('disinformation.title12'),
            children: (
              <>
                <Paragraph>{t("disinformation.25")}</Paragraph>
                <Paragraph>{t("disinformation.26")}</Paragraph>
                <Paragraph>{t("disinformation.27")}</Paragraph>
                <Paragraph>{t("disinformation.28")}</Paragraph>
                <Paragraph>{t("disinformation.29")}</Paragraph>
                <Paragraph>{t("disinformation.30")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title13'),
            children: (
              <>
                <Paragraph>{t("disinformation.31")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title14'),
            children: (
              <>
                <EduList items={[
                    t('disinformation.59'),
                    t('disinformation.60'),
                    t('disinformation.61'),
                    t('disinformation.62'),
                    t('disinformation.63'),
                    t('disinformation.64'),
                    t('disinformation.65'),
                ]}/>
              </>
            )
          }, {
            title: t('disinformation.title15'),
            children: (
              <>
                <Paragraph>{t("disinformation.32")}</Paragraph>
              </>
            )
          }, {
            title: t('disinformation.title16'),
            children: (
              <>
                <Paragraph>{t("disinformation.33")}</Paragraph>
                <EduList items={[
                    t('disinformation.66'),
                    t('disinformation.67'),
                    t('disinformation.82'),
                    t('disinformation.68'),
                    t('disinformation.69'),
                    t('disinformation.70'),
                    t('disinformation.71'),
                    t('disinformation.72'),
                ]}/>
              </>
            )
          }, {
            title: t('disinformation.title17'),
            children: (
              <>
                <EduList items={[
                    t('disinformation.73'),
                    t('disinformation.74'),
                    t('disinformation.75'),
                    t('disinformation.76'),
                    t('disinformation.77'),
                  ]}/>
              </>
            )
          }, {
            title: t('disinformation.title18'),
            children: (
              <>
                <EduList items={[
                    t('disinformation.78'),
                    t('disinformation.79'),
                    t('disinformation.80'),
                    t('disinformation.81'),
                ]}/>
              </>
            )
          }]}
        />
      </Container>
    </>
  )
}