import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './components/App/App';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { init } from './services/firebase';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import './i18n';

init()

const muiTheme = createTheme({
  typography: {
    fontFamily: [
      'graphik',
      'sans-serif',
      'Roboto',
    ].join(','),
    fontSize: 16,
  },
  palette: {
    primary: {
      main: '#f5be63',
      light: 'rgba(245, 190, 99, .8)'
    },
    secondary: {
      main: '#f6f6f4'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#fafafa',
        }
      }
    }
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
  >
    <BrowserRouter>
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </GoogleReCaptchaProvider>
);
