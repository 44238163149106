const tr = {
                "translation": {
                    "home": {
                        "1": "Biz kimiz?",
                        "2": "Romanya, İtalya ve Türkiye'nin oluşturduğu ulusötesi bir konsorsiyumu içeren Erasmus+ programı aracılığıyla Avrupa Birliği tarafından finanse edilen uluslararası bir işbirliğidir.",
                        "3": "Odak noktamız.",
                        "4": "Proje, her ülkede tespit edilen benzer sorunlara ve genç neslin eleştirel düşünme yetkinliklerinin geliştirilmesi olan ortak bir çözüme odaklanıyor.",
                        "5": "Hedeflerimiz.",
                        "6": "Gençler için eleştirel düşünme ve doğru bilgi yetkinliklerini geliştirmek amacıyla, proje iki boyutlu bir konu yaklaşımı başlattı:",
                        "7": "Gençleri Eğitmenin amaçları şunlardır:",
                        "8": "yaygın eğitim perspektifi",
                        "9": "zihniyet değişikliği olarak yapısal bir yaklaşımın, sektörler arası bir işbirliğinin (gençlik ve eğitim sektörleri) sağladığı sistemik düzeyde sürdürülmesi gerekiyor.",
                        "10": "Ortak eğitim sektörlerindeki (örgün ve yaygın) ortak sorunların çözümüne yönelik bu izleme süreci, Oltenia - Romanya, Bari - İtalya ve Hatay, Türkiye bölgelerindeki gençlik STK'ları, eğitim kurumları ve yerel yönetim arasında güçlü bir sektörlerarası ortaklık oluşturan konuya stratejik bir yaklaşım sağladı.",
                        "11": "Eleştirel düşünme ve doğru bilgiye ilişkin temel yetkinliklerin geliştirilmesi ve pekiştirilmesi",
                        "12": "Gençlik örgütleri ve eğitim kurumları arasında, eleştirel düşünme ve doğru bilgi alanlarında, ana akım bir sistemde büyük ölçekte çarpılabilen, sektörler arası pilot bir işbirliği modeli oluşturmak",
                        "13": "2 sektör arasında sinerji yaratan resmi olmayan metodolojiler geliştirerek dört proje ortağı için organizasyonel uzmanlığı artırın",
                        "14": `"Gençliği Eğitin" 24 ay boyunca genişler ve iki ana zaman çizelgesi bileşenine ayrılır.`,
                        "15": "Birinci yıl, özel ihtiyaçların yerel araştırmasına, bağlam yorumuna ve tabakalaşmaya, sektörler arası işbirliğinin geliştirilmesine ve yaygın eğitim yöntemlerine odaklanmıştır.",
                        "16": "İkinci yıl, herkes için eleştirel düşünme yöntemlerine ücretsiz erişime sahip e-öğrenme platformunu geliştirerek, büyük ölçekli kullanılacak yöntemleri iyileştirmeye odaklanmıştır. Yeni oluşturulan eğitim yöntemleri, her üç proje ortağı ülkedeki gençlik çalışanları, öğrenciler ve öğretmenlerin geri bildirimleriyle pilotlaştırıldı ve geliştirildi.",
                        "partners": "Bizim Ortaklarımız",
                    },
"disinformation": {
                        "title": "Eleştirel Düşünme ve Dezenformasyon Seti",
                        "description1": "Bu bölüm eleştirel düşünme, dezenformasyon ve yanlış bilgilendirmenin ne anlama geldiğiyle ilgilidir.",
                        "description2": "En önemli kritik beceriler nelerdir ve eleştirel düşünme becerilerimizi nasıl geliştirebiliriz?",
                        "description3": "Dezenformasyon ve yanlış bilgilendirme nasıl kullanılır?",
                        "description4": "Modern toplumda dezenformasyon tehditleri nelerdir?",
                        "description5": "CRAAP (Para Birimi, Alaka Düzeyi, Yetki, Doğruluk ve Amaç) Yöntemi Nedir?",
                        "description6": "Bazı web siteleri, toplumu etkilemek için kasıtlı olarak yayılan haberler ve yanlış bilgiler olarak fikir parçaları sunar.",
                        "description7": "Tüm yargıları bir kenara bırakın ve herhangi bir içerik, sorun ve kaynak hakkında eleştirel düşünmeye başlayın.",
                        "1": "Günlük hayatımızda sıklıkla yanlış bilgiler dolaşır. Flaş haber: Biz insanlar kusursuz değiliz. Herkes hata yapabilir. Hepimizin hafıza kaybı var. Ayrıntılar yanlış duyulur veya unutulur. Sosyal medya aracılığıyla öğrendiğimiz veya televizyonda duyduğumuz bilgileri arkadaşlarımıza yanlış tanıtıyoruz. Teknik olarak konuşursak, yanlış bilgi yayıyorsanız, ancak yanlış olduğunun farkında değilseniz, yanlış bilgi dağıtmak yaptığınız şeydir.",
                        "2": "Bilgiyi \"yanlış\" veya \"yanlış\" anlamına gelen yanlış- kelimesiyle birleştiren yanlışbilgi terimi ilk olarak 1500'lerin sonlarında kullanılmıştır. \"yanlış\" ı belirtmek için yanlış önekini kullanan diğer sık kullanılan terimler arasında hata, yanlış yazım yer alır ve yanlış anlaşılma.",
                        "3": `Elbette yanlış bilgilendirme, başlangıçta 1350 ile 1400 yılları arasında yazılı olarak ortaya çıkan ve yanlış veya yanıltıcı bilgi sunmak anlamına gelen yanlış bilgilendirme fiiliyle ilgilidir." Yanlış bilginin, yanlış bilgi gibi, yalnızca bu yanlış bilginin yayılmakta olduğunu belirttiğini göreceksiniz.`,
                        "4": `Dezenformasyon, kamuoyunu etkilemek veya gerçeği gizlemek için kasıtlı ve sıklıkla gizlice dağıtılan (söylenti ekmek gibi) yanlış bilgiler olarak tanımlanır.`,
                        "5": "Ek olarak, daha geniş anlamda \"kasıtlı olarak yanlış veya önyargılı bilgilere, uydurulmuş hikayelere veya gerçeklere, propagandaya\" atıfta bulunabilir.",
                        "6": "Dezenformasyon yaygın bir casusluk tekniğidir çünkü çok güçlü, yıkıcı ve kutuplaştırıcıdır. Örneğin, Sovyetler Birliği ve ABD'nin Soğuk Savaş sırasında yaptığı gibi, devletler sık sık düşmanlarına yanlış bilgi göndermekle ilgilenirler. Bir ulusun veya grubun karmaşık bir dezenformasyon yayma stratejisini ne zaman koordine ettiğini tanımlamak için sıklıkla bir dezenformasyon kampanyası kullanılır.",
                        "7": "Dezenformasyon, birinin veya bir şeyin iyi veya kötü görünmesi için aktarılan bilgi olan propagandadan gelebilir. Kuzey Kore'inki gibi modern diktatörlükler, halklarına kendi hükümetlerine karşı bir doğruluk ve iyilik duygusu ve düşmanlarına karşı bir kötülük aşılamak için propaganda amaçlı yanlış bilgileri kullanır. Amerika Birleşik Devletleri Dışişleri Bakanlığı'na göre, COVID-19 hakkında yanlış bilgiler yayan ve salgını örnek olarak Amerika Birleşik Devletleri'ne bağlayan bir internet dezenformasyon operasyonunun Çin hükümeti tarafından desteklendiği iddia edildi.",
                        "8": "Yanlış bilgilendirme ile dezenformasyon arasında ayrım yaparken \"amaç\" kelimesini aklınızda bulundurun. Her iki terim de farklı türde yanlış veya yanlış bilgiler için geçerlidir, ancak yalnızca yanlış bilgiler bilerek yanlıştır. Açık görünse de, yanlış bilgilendirme ve dezenformasyon, bazı durumlarda benzer ve bağlantılı oldukları için genellikle birbirinin yerine kullanılır.",
                        "9": "Dezenformasyon kelimesini kullanın o zaman yanıltıcı veya yanlış bilgilerin, özellikle bir hükümete, kuruluşa veya halka açık bir kişiye zarar vermek veya zarar vermek amacıyla yayıldığından emin olabilirsiniz, dezenformasyon terimini kullanmalısınız. Birinin neden yanlış bilgi yaydığını kesin olarak bilmiyorsanız, mümkün olan her yerde yanlış bilgi kullanın.",
                        "10": "Dijital çağda yanlış bilgilendirme ve dezenformasyon yaygındır ve komplo teorileri, propaganda, derin sahtecilikler, sahte haberler, aldatmacalar, sahtekarlıklar, Photoshop manipülasyonları ve dolandırıcılıkları içerir.",
                        "11": "Yanlış bilgi üretimi ve dağıtımının otomatikleştirilmesi daha önce hiç bu kadar basit ve uygun fiyatlı olmamıştı. Artık günde ortalama elli defadan fazla yanlış bilgi içeren bir makaleyi hızla yayabilen otomatik sosyal botlarımız var. Ancak bundan daha fazlasını oynarlar; Trafiği yönlendirerek reklam parasını da hızlı bir şekilde sağlayabilirler. Ancak son zamanlarda bu teknolojinin kültürlerimiz ve demokrasilerimiz üzerindeki etkilerini görmeye başladık.",
                        "12": "Yanlış bilgilendirme ve dezenformasyonun nasıl üretildiği, yazar (lar) ın kim olduğu ve yaratılmasının farklı nedenleri ile doğrudan ilgilidir.",
                        "13": "Yazarlar kimlerdir?  Onlar olabilir: ",
                        "14": "Makalenin içeriğinden bağımsız olarak, kar etmek isteyen biri;",
                        "15": "Bir noktaya değinmek ya da sizi eğlendirmek isteyen hicivciler ya da her ikisi;",
                        "16": "Yoksul veya tecrübesiz gazeteciler, 24 saatlik haber döngüsünün zorlanması ve haber sitelerinin yaygınlaşması nedeniyle profesyonel gazetecilik standartlarını veya etiğini ihlal eden yetersiz işler üretebilir;",
                        "17": "Siyasi tutumları ve karar vericileri etkilemek isteyen partizanlar;",
                        "18": `İçeriği çevrimiçi kopyalamanın, yapıştırmanın, tıklamanın ve paylaşmanın teknolojik kolaylığı, bu tür makalelerin çoğalmasına yardımcı oldu. Bazı durumlarda makaleler, okuyucuları geniş çapta paylaşmaya ikna etmek için duygusal bir tepkiye neden olacak şekilde tasarlanır ve belirli sitelere (\"tohumlanmış \") yerleştirilir. Diğer durumlarda, \ "sahte haberler\" makaleleri, bilgi paylaşan kişiler gibi davranmak üzere tasarlanmış, ancak bunu hızlı ve otomatik olarak yapabilen bilgisayar algoritmaları olan \ "botlar\" tarafından oluşturulabilir ve yayılabilir.`,
                        "19": "Bu belayla mücadele etmek için çok sayıda strateji var. Siber vatandaşların eğitimi her zaman en iyi eylem şeklidir, ancak en hızlı veya en basit olanı değildir. Çevrimiçi ortamda bilinçli ve düşünceli bir toplum geliştirme yeteneği, tıpkı herhangi bir uzun vadeli yatırım gibi zaman, iş, sabır ve kaynakları içerir.",
                        "20": `Bir diğer en iyi uygulama, platformları yöneten teknoloji şirketlerinin politikalarındaki değişimi teşvik etmektir. Twitter ve Facebook, "kötülük duyma, kötülük görme" zihniyetiyle sistemlerindeki içeriği görüntülemeyi durdurmak için şimdiden değişiklikler yapıyor. 2022 seçim döngüsü için Google, siyasi reklamları da yasakladı. Bu platformlar yanlış bilgi ve dezenformasyon için birincil dağıtım kanalları olarak hizmet ettiğinden, internet kullanıcıları kötü teknoloji uygulamalarını ortaya çıkarmak amacıyla kuruluşlara katılabilir.`,
                        "21": "Teknoloji sektörünün düzenleyicileri idari çözümler üretebilir. Dezenformasyon tanımlanmalı ve cezalandırılmalı ve bu çağdaş suçu durdurmak için yasalar çıkarılıyor. Fakat hepsini bir araya getirmek için, tüm kurallarda olduğu gibi, metodik ve beceri odaklı bir çaba gerekir.",
                        "22": "CRAAP Testi değerlendirme yöntemlerinden biridir. Tarafından tasarlandı Meriam Kütüphanesi Kaliforniya Eyalet Üniversitesi, Chico. CRAAP bir kısaltmadır ve Para Birimi, Alaka Düzeyi, Yetki, Doğruluk ve Amaç anlamına gelir. Bulduğunuz bilgileri değerlendirmenize yardımcı olacak bir soru listesi sunar. Görevinizin beklentilerini karşılayacak kaynakları seçme konusunda kendinize daha fazla güveneceksiniz.",
                        "23": "Büyük çaplı dezenformasyon kampanyaları Avrupa için büyük bir zorluktur ve AB ülkelerinden, AB kurumlarından, çevrimiçi platformlardan, haber medyasından ve AB vatandaşlarından koordineli bir yanıt alınmasını gerektirir. Komisyon, dezenformasyonla mücadele için bir dizi girişim geliştirdi: ",
                        "24": "Eleştirel düşünme, nesnel kalırken maksimum kalite derecesini hedefleyen, kendi kendini yöneten, disiplinli bir akıl yürütmedir. Eleştirel düşünen insanlar mantıklı, makul ve sempatik bir şekilde yaşamaya çalışırlar. Ayrıca eleştirel düşünme, insanlara ne kadar iyi bir düşünür olurlarsa olsunlar muhakeme yeteneklerini her zaman geliştirebileceklerini ve mantıksal hatalara, insani mantıksızlığa, önyargılara, önyargılara, çarpıtmalara, safça kabul edilmiş toplumsal geleneklere ve tabulara, kişisel çıkarlara ve zaman zaman kazanılmış çıkarlara kurban gideceklerini anlamalarında yardımcı olur. Ayrıca eleştirel düşünme, akıl yürütme becerilerinin kullanılmasını gerektirir. Konu öğrenmeye geldiğinde seyirci olmaktan ziyade katılımcı olmakla ilgilidir. Eleştirel düşünürler sezgiye veya içgüdüye güvenmek yerine sorunları metodik bir şekilde keşfedecek, değerlendirecek ve çözecektir.",
                        "25": "Fikirler arasındaki bağlantıları anlayın.",
                        "26": "Argümanların ve kavramların önemini ve uygulanabilirliğini değerlendirin.",
                        "27": "Argümanları tanıyın, oluşturun ve değerlendirin.",
                        "28": "Tutarsızlıkları ve mantıksal hataları tanıyın.",
                        "29": "Sorunları tutarlı ve sistematik bir şekilde ele alın.",
"30": "Kendi varsayımlarının, görüşlerinin ve değerlerinin nedenlerini düşünün.",
                        "31": "Eleştirel düşünebilmek için her türlü önyargılı kavramı veya yargıyı bir kenara bırakabilmeli ve sadece bize verilen verileri inceleyebilmeliyiz. Ayrıca fikir değerlendirmemizde objektif olmalıyız.",
                        "32": "Erişebildiğimiz neredeyse hiçbir bilgi, ister dışarıda ister içeride olsun, bunun süreceğine veya faydalı olacağına dair herhangi bir güvenceyle birlikte gelmez. Ayrıntılı adım adım talimatlar, eleştirel düşünme konusundaki temel kavrayışımızın büyümesi için bir çerçeve görevi görebilir, Ancak kesinliği, kullanışlılığı veya uzun ömürlülüğü garanti etmez ve garanti edemez.",
                        "33": "Eleştirel düşünme, diğer yumuşak yetenekler gibi, bir sınıfta edinebileceğiniz bir şey değildir. Aksine, bu yetenek kişilerarası ve entelektüel yeteneklerin bir kombinasyonundan oluşur. Açık fikirliliği kabul etmeyi öğrenmek ve problem çözme sürecinize analitik düşünmeyi uygulamak eleştirel düşünmeyi geliştirmekten daha önemlidir.",
                        "title1": 'Yanlış Bilgilendirme',
                        "title2": 'Dezenformasyon',
                        "title3": 'Yanlış bilgilendirme ve dezenformasyon nasıl kullanılır',
                        "title4": 'Modern toplumda dezenformasyon tehditleri nelerdir?',
                        "title5": 'Nereden geliyor?',
                        "title6": 'Dezenformasyona nasıl yanıt verilir?',
                        "title7": 'CRAAP YÖNTEMİ',
                        "title8": 'Avrupa Komisyonu Girişimleri',
                        "title9": 'Eleştirel Düşünme',
                        "title10": "Eleştirel Düşünme çabalar",
                        "title11": "Eleştirel düşünme iki kategoriye ayrılabilir",
                        "title12": 'Eleştirel düşünme becerisine sahip bir kişi şunları yapabilir:',
                        "title13": "Eleştirel Düşünme Becerilerimizi Geliştirin",
                        "title14": `Konuya daha derinlemesine bakarak eleştirel düşünme yeteneklerini geliştirmek için alınabilecek belirli yöntemler vardır.`,
                        "title15": 'Üstesinden Gelinmesi Gereken Olası Engeller',
                        "title16": 'En Önemli Eleştirel Düşünme Becerileri',
                        "title17": 'Özetlemek gerekirse',
                        "title18": 'Referanslar',
                        "34": "Para Birimi:",
                        "35": "Alaka düzeyi:",
                        "36": "Yetki:",
                        "37": "Doğruluk:",
                        "38": "Amaç:",
                        "39": 'Bilgilerin yayınlanma tarihinin projeniz için uygun olup olmadığını belirleyin.  Telif hakkı, yayınlanma veya yayınlanma tarihi nedir?  Kaynağın mesajı veya içeriği için tarih neden önemlidir veya önemli değildir?  Konuyla ilgili bilgiler güncel değil mi?',
                        "40": 'Bilgilerin projenize ne kadar uygulanabilir olduğunu belirleyin.  Yazılan bilgiler hangi izleyici kitlesi veya düzeyi için (genel halk, uzmanlar / akademisyenler vb.)?  Projenizdeki bu kaynaktan gelen bilgileri neden alıntıladığınızı / referans vermediğinizi açıklayın.',
                        "41": 'Bilginin kaynak yazarının, yaratıcısının veya yayıncısının en bilgili olup olmadığını belirleyin. Kaynağın yazarı, yaratıcısı veya yayıncısı kimdir veya kaynaktan hangi kuruluş sorumludur? İletişim bilgileri mevcut mu? Yazarın konuyla ilgili uzman olup olmadığını nasıl anlarsınız (örneğin, yazarın kimlik bilgilerini, deneyimini ve / veya kuruluş ilişkisini inceleyin)? Araştırma veya programlama için para nereden geliyor, eğer ilgiliyse?',
                        "42": 'İçeriğin güvenilirliğini, doğruluğunu ve doğruluğunu belirleyin. Bilginin iyi araştırıldığına veya yeterince kanıt sağlamadığına dair hangi belirtileri görüyorsunuz? Ne tür bir dil, imge ve / veya ton kullanılır (örneğin duygusal, nesnel, profesyonel vb.)? Kanıtlar önermeleri / iddiaları ve sonuçları destekliyor mu? Gerçekler ve iddialar metin içinde, not olarak mı yoksa kaynakçada mı belgelenir veya alıntılanır?',
                        "43": 'Bilginin var olma nedenini belirleyin. Bu kaynak neden yazılmıştır (örneğin bilgilendirmek, öğretmek, eğlendirmek, ikna etmek için)? Yazarın bağlılığı, kaynağın bakış açısını, eğimini veya potansiyel önyargısını nasıl etkileyebilir? Hedeflenen kitle, bilginin bakış açısını, eğimini veya potansiyel önyargısını nasıl etkileyebilir? Hangi sonuçlar sunuluyor ve bilgiler eksiksiz mi? Önemli bir şey hariç tutuldu mu? Bu kaynak aynı konudaki diğer kaynaklarla nasıl karşılaştırılır?',
                        "44": `çevrimiçi dezenformasyonla mücadele: bir Avrupa yaklaşımı’ konulu iletişim, dezenformasyonun yayılmasıyla mücadele etmek ve AB değerlerinin korunmasını sağlamak için bir araçlar topluluğudur;`,
                        "45": `dezenformasyona ilişkin Eylem planı, AB'nin dezenformasyona karşı mücadelede kabiliyetini ve işbirliğini güçlendirmeyi amaçlamaktadır`,
                        "46": `Avrupa Demokrasi Eylem Planı, dezenformasyonla mücadelede çevrimiçi platformların yükümlülükleri ve hesap verebilirliği için kılavuzlar geliştiriyor;`,
                        "47": `2018 Dezenformasyon Uygulama Kuralları, endüstrinin dezenformasyonla mücadele için kendi kendini düzenleyen standartları gönüllü olarak kabul ettiği dünya çapında ilk kez oldu. Komisyonun Nisan 2018'de sunduğu İletişim ile belirlenen hedeflere ulaşmayı amaçladı`,
                        "48": `Uygulama Kurallarını imzalayanlar tarafından yürütülen COVID-19 dezenformasyon izleme programı, çevrimiçi platformların dezenformasyonla mücadelede hesap verebilirliğini sağlamak için şeffaflık önlemi olarak hareket etti.`,
                        "49": `EDMO, çevrimiçi dezenformasyon, sosyal medya platformları, gazeteci odaklı medya ve medya okuryazarlığı uygulayıcıları alanında uzmanlığa sahip gerçek denetçileri ve akademik araştırmacıları bir araya getiren bağımsız bir gözlemevidir`,
                        "50": `16 Haziran 2022'de imzalanan Güçlendirilmiş Dezenformasyon Uygulama Kuralları, dezenformasyona karşı geniş bir dizi gönüllü taahhütte bulunmak için çok çeşitli oyuncuları bir araya getiriyor.`,
                        "51": `entelektüel bütünlüğün ntelektüel niteliklerini gayretle geliştirmek`,
                        "52": `entelektüel alçakgönüllülük`,
                        "53": `entelektüel nezaket`,
                        "54": `entelektüel empati`,
                        "55": `akılda adalet duygusu`,
                        "56": `mantıkta özgüven`,
                        "57": `bilgi ve inançları yaratmak ve işlemek için bir dizi yetenek`,
                        "58": `entelektüel bağlılığa dayanan davranışı etkilemek için bu tür becerileri kullanma alışkanlığı`,
                        "59": `Bir sorunun veya sorunun kaynağını belirleyin.`,
                        "60": `Sorunun neden var olduğu ve nasıl düzeltileceği hakkında varsayımlarda bulunun.`,
                        "61": `Konuyla ilgili bilgi veya veri toplamak için araştırma yapın.`,
                        "62": `Verileri ve bulguları düzenleyin ve sıralayın.`,
                        "63": `Çözümler yaratın ve uygulayın.`,
                        "64": `Hangi çözümlerin işe yarayıp yaramadığını analiz edin.`,
                        "65": `Çözümü iyileştirmenin yollarını belirleyin.`,
                        "66": `Analitik düşünme: En iyi sonuçları elde etmek için gerçekleri çeşitli kaynaklardan değerlendirmek eleştirel düşünmenin bir parçasıdır. Analitik düşünme, insanların en uygun sonucu elde etmek için veri toplama ve analiz etme lehine önyargıları reddetmelerini sağlar.`,
                        "67": `Açık fikirlilik: Bu eleştirel düşünme yeteneği, nesnel bir yargıya varmak için verileri değerlendirmenize ve işlemenize olanak tanır. Kendi önyargılarınızın gitmesine izin vermek ve tüm kanıtlara dayalı bir karara varmak eleştirel düşünme sürecinin bir parçasıdır.`,
                        "82": `Problem çözme: Eleştirel düşünme, problem çözmenin önemli bir yönüdür çünkü mevcut tüm gerçeklere dayanarak en uygun sonuca ulaşmayı vurgular. Eleştirel düşünme, uygun şekilde yapıldığında, bir iş zorluğundan günlük yaşamdaki sorunlara kadar her türlü zorluğun üstesinden gelmenize yardımcı olabilir.`,
                        "68": `Öz düzenleme: Eleştirel düşünme, uygun şekilde yapıldığında, bir iş zorluğundan günlük yaşamdaki sorunlara kadar her türlü zorluğun üstesinden gelmenize yardımcı olabilir. Etkili bir eleştirel düşünür olmak için sahip olduğunuz bilgilere ve lehinize verdiğiniz yargılara meydan okumalısınız — ancak o zaman en iyi sonuca varabilirsiniz.`,
                        "69": `Gözlem: Eleştirel düşünürler, açık olmayan şeyleri aramak için gözlem becerilerini kullanırlar. Eleştirel bir düşünür olmak için farklı bakış açılarını kabul edebilmeli ve gözlem becerilerini kullanarak olası sorunları tanıyabilmelisiniz.`,
                        "70": `Yorumlama: Eleştirel düşünürler, tüm verilerin eşit yaratılmadığını anlar. Bilginin edinilmesinin yanı sıra sizin durumunuzla değerli ve alakalı olup olmadığını değerlendirmek çok önemlidir. Bu şekilde, topladığınız bilgilerden en yararlı sonuçları elde edebileceksiniz.`,
                        "71": `Değerlendirme: Zor bir konuyu yanıtlamaya çalışırken nadiren belirgin bir çözüm vardır. Eleştirel düşünmenin sizi önyargılarınızı bir kenara bırakmaya teşvik etmesine rağmen, sahip olduğunuz bilgilere dayanarak güvenle bir sonuç çıkarabilmelisiniz.`,
                        "72": `İletişim: Seçiminiz yapıldıktan sonra diğer paydaşları da bilgilendirmelisiniz. Verileri sunmak ve sonucunuzu desteklemek, özellikle birkaç alternatif cevap varsa, etkili profesyonel iletişimin önemli bir parçasıdır.`,
                        "73": `Her senaryoda eleştirel düşünme, en büyük potansiyel sonuçları elde etmeyi amaçlar. Bunu yapmak için mümkün olduğu kadar çok farklı kaynaktan veri toplamak ve değerlendirmek gerekir.`,
                        "74": `Eleştirel düşünme, benzersiz güçlü yönlerinizin, eksikliklerinizin ve tercihlerinizin yanı sıra bunların verebileceğiniz yargılar üzerindeki potansiyel etkilerinin kapsamlı ve genellikle nahoş bir şekilde incelenmesini gerektirir.`,
                        "75": `Eleştirel düşünme, öngörünün mümkün olduğu ölçüde geliştirilmesini ve kullanılmasını gerektirir.`,
                        "76": `Eleştirel düşünmeye dayalı kararların uygulanması, olası sonuçların değerlendirilmesini ve olası olumsuz sonuçların etkisinden kaçınma veya en azından azaltma yöntemlerini gerektirir.`,
                        "77": `Eleştirel düşünme, alınan kararların sonuçlarını analiz etmeyi ve mümkün olduğunda değişikliği yürürlüğe koymayı gerektirir`,
                        "78": `Eleştirel Düşünme | Becerilere İhtiyacınız Var. (N.D.). İhtiyacın olan Beceriler. Erişim tarihi: 13 Haziran 2022 https://www.skillsyouneed.com/learn/critical-thinking.html `,
                        "79": `Doyle, A. (2022, 15 Mart). Eleştirel Düşüncenin Tanımı, Becerileri ve Örnekleri. ThoughtCo. Erişim tarihi: 13 Haziran 2022 https://www.thoughtco.com/critical-thinking-definition-with-examples-2063745 `,
                        "80": `Scriven, M. ve Paul, R. (N.D.). Eleştirel Düşünmeyi Tanımlamak. Eleştirel Düşüncenin Temeli. Erişim tarihi: 13 Haziran 2022 https://www.criticalthinking.org/pages/defining-critical-thinking/766 `,
                        "81": `En İyi 10 Eleştirel Düşünme Becerisi (Ve Bunların Nasıl Geliştirileceği). (2022, 25 Mayıs). Öyle mi?. Erişim tarihi: 13 Haziran 2022 https://www.indeed.com/career-advice/career-development/critical-thinking-skills `,
                    },
                    "games": {
                        "title": "Eleştirel Düşünme Oyunları",
                        "description": `Eleştirel düşünme becerilerinizi, "Gerçek veya Fikir" adlı eğlenceli ve etkileşimli bir sınavla test edin.” internetteki çeşitli makalelerden hangi kategoride birden fazla küçük bölümün durduğuna hızlı bir şekilde karar vermeniz gereken yer.`,
                        "description2": `İkinci bölüm, sizi kutunun dışında düşündüren, görsel ve yazılı yaratıcılığınızı test eden ve bir takımda çalışma becerilerinizi geliştirmenize yardımcı olan 10 eleştirel düşünme oyunu oynamanıza meydan okuyor. Tek tek, bir çift, küçük bir grup veya büyük bir grup halinde tadını çıkarın.`,
                        "download": "PDF'ye bakın",
                        "startMessage": "Gerçek mi Fikir mi?",
},
                    "debates": {
                        "title": "Resmi Olmayan Tartışmalar",
                        "description1": "Bu bölüm, bir takımda çalışırken eleştirel düşünme becerilerini geliştirmek ve en iyi argümanları ve çözümleri bulmak için birbirlerini desteklemek isteyenler için çevrimiçi tartışmalara erişim sağlayacaktır.",
                        "description2": "Tartışma konuları ve ilginç ve zorlu, çeşitli yaş ve geçmişlere sahip bir genç için uygun.",
                        "item1": {
                            "title": "ÜLKEYİ 15 YILDAN FAZLA TERK EDENLERİN OY KULLANMA HAKLARI KISITLANMALIDIR",
                            "description": "Maçın önergesi vardı: 15 yılı aşkın süredir ülkeden AYRILANLARIN OY kullanma hakları KISITLANMIŞ olmalıydı Maç, Dünya Okulları Tartışma formatının kurallarına göre oynandı ve konuşmalar kısaltıldı. Oyuncular \"Constantin Brancoveanu\" Horezu Lisesi öğrencileridir."
                        },
                        "item2": {
                            "title": "OKULDA BÜYÜK KRİZDEN KURTULMA KURSLARI VERİLMELİ",
                            "description": "Etkinlik, bir öğrenci ekibi ile bir öğretmen ekibi arasındaki eşleşmedir. Maçın önergesi var: OKULDA BÜYÜK krizden kurtulma kursları açılmalı Maç, dünya okullarının kurallarına göre oynanır kısaltılmış konuşmalarla tartışma. Sonunda sadece iki karar verilir, nihai karar maçı izleyen her kişinin kararıdır."
                        },
                        "item3": {
                            "title": "KRİZ DURUMLARINDA ÇEVRİMİÇİ HABERLER SANSÜRLENMELİDİR",
                            "description": "Video, bir tartışma etkinliğinin çevrimiçi olarak nasıl hazırlanıp gerçekleştirilebileceğini gösteriyor. Etkinliğin düzenlenmesi aşamasında takımlar oluşturulur, oyunun kuralları hatırlatılır ve etkinliğe uygun bir atmosfer yaratılır. Konuşmacılar iki odaya ayrılır ve takım stratejisinin hazırlanması, konuşmacıların takımdaki rolleri için zaman verilir. Oynanan maç, KRİZ durumlarında çevrimiçi HABERLERİN sansürlenmesi önergesine sahiptir.  Maçtan sonra izleyici kararları teklif edildi, ancak her izleyici, bir takım veya diğeri tarafından nasıl ikna edildiklerine bağlı olarak hangi takımın kazanacağına karar verebilirdi."
                        },
                        "item4": {
                            "title": "DİJİTAL ARAÇLARIN SINIFLARDAKİ DEZAVANTAJLARDAN DAHA FAZLA AVANTAJI VARDIR",
                            "description": `Bu oturumun uygulanması sırasında, bu faaliyetin ima ettiği tüm adımlara saygı gösterildi. Katılımcılara oyunun kuralları tekrar sunuldu, ardından oynayacakları hareketi çözmek için bir beyin fırtınası seçildi.Takımlar organize edildi, böylece her takımın her ülkeden bir üyesi vardı. Belgeleri ve konuşmaları hazırlamak için gerekli süre teklif edildi. Tartıştıkları hareket şuydu: " Dijital araçların sınıflardaki dezavantajlardan daha fazla avantajı var.`,
                        },
                    },
                    "methodologies": {
                        "title": "Yaygın Metodoloji",
                        "description": "Bu bölümde eleştirel düşünme becerilerini geliştirmeyi başaran 8 yaygın olmayan yöntem bulacaksınız.  8 Yöntemin tümü, arkadaşlarınızla veya birlikte çalıştığınız hedef grupla kullanabilmeniz için ayrıntılı olarak sunulmuştur.",
                        "Escape Room": "Kaçış Odası",
                        "Alternative World Map": "Alternatif Dünya Haritası",
                        "Debate": "Tartışma",
                        "Anyone Can Volunteer": "Herkes Gönüllü Olabilir",
                        "Who Believes in Superstition?": "Batıl İnançlara Kim inanır?",
                        "Nasreddin Hodja": "Nasreddin Hoca",
                        "Collect Create and Play": "Oluştur ve Oyna Topla",
                        "Survival in the Desert": "Çölde Hayatta Kalma",
                        "Booklet": "Kitapçık",
                        "Methodological context": "Metodolojik bağlam",
                        "Administrative informations": "İdari bilgiler",
                        "Description of the methodology": "Metodolojinin tanımı",
                        "Evaluation and debriefing": "Değerlendirme ve bilgilendirme",
                        "Feedback": "Geri Bildirim",
                        "Annexes": "Ekler",
                        "Preparation of the group" : "Grubun hazırlanması",
                        "Implementing activities": "Uygulama faaliyetleri",
                    },
                    "hoca": {
                        "1": `Rol Yapma yöntemini kullanarak, öğrencilerin, özellikle de Z kuşağının, tarihsel ve mizahi hikayeler, fıkralar (Nasreddin Hoca masalları) ve masallar (La Fontaine Masalları) oynayarak eleştirel düşünmeyi resmi olmayan bir şekilde öğrenmelerini hedefliyoruz. Roller çağdışı bir şekilde oynanabilir, katılımcıların yaratıcılığına bağlıdır, başka bir deyişle ortam ve kostümler 21. yüzyıla uyarlanabilir.Rol yapma yöntemini kullanmaya çalışarak empati gösterecekler, ahlaki dersler alacaklar ve esnek, yaratıcı bir şekilde düşünecekler. ve özgün bir şekilde.Bu yöntemle düşünmeye yansıyacak, görüş ve duygularını ifade edecek, iletişim ve dil becerilerini geliştireceklerdir.Bazıları rolleri oynayacak olsa da, diğerleri oyunları izliyor olacak ve içerikleri aktif bir şekilde düşünecekler çünkü oyuncular dikkatlerini çekmek için aynı anda onlara bazı sorular soracaklar.Ayrıca, öğretmen oyundaki insanları kendiliğinden değiştirebilir veya değiştirebilir, böylece halk hikayeye bağlanır ve ayrıca daha çok dahil olurlar. Bu şekilde Forum Tiyatrosu yöntemini kullanacağız. Bu nedenle her katılımcı oyuna dahil olur `,
                        "2": `Süre: toplam 45 dakika: Role hazırlanmak için 15 dakika; performans için 20 dakika; tartışmak için 10 dakika (küçük gruplar halinde 5 dakika ve tüm grubun bazı sözlerini almak için 5 dakika)`,
                        "3": `Oyuncu sayısı: gruba bağlıdır; yöntem min ile uygulanabilir. 10 maks.gruplara ayrılacak 30 katılımcı.`,
                        "4": `Yerel gereksinimler: konum gereksinimi yok, tartışmanın duyulabilmesi için çok fazla gürültünün olmadığı bir alanda, rahat, resmi olmayan bir ortamda olmak yeterli.`,
                        "5": `Gerekli Araçlar: Rollerini yazmak için kağıt ve kaleme ihtiyaçları var `,
                        "6": `İnsan kaynakları: Bazı notlar da alacak bir anlatıcı. `,
                        "7": `Nasıl oynanır:`,
                        "8": `Takımımı Ye, Ye. Sana saygı duyuyorlar, bana değil!!!`,
                        "9": `Nasreddin Hoca'nın yaşadığı mahallede bir düğün var. Düğünün ev sahibi Hoca'yı da davet ediyor. Nasreddin Hoca bu davetten çok memnun. Kendi kendine şöyle diyor: "Tanrıya şükür, onlar da beni unutmadılar." Düğün evine gidiyor. Ama her zaman giydiği kıyafetleri giyiyor. Hoca'nın kıyafetleri temiz ama eski, sıradan ve sıradan. Düğün salonuna girerken kimse ona dikkat etmiyor. Böylece Nasreddin Hoca eve döner, en yeni ve en havalı takımını giyer ve düğüne geri döner., Sonra Nasreddin Hoca herkesin dikkatini ve ilgisini çeker. Masadaki en iyi yeri ona veriyorlar ve Kralmış gibi ona hizmet ediyorlar ama Nasreddin Hoca elbisesini çıkarıp çorbaya, salataya ve ana yemeğe koyuyor ve kendi kendine `,
                        "10": `Sevgili takımımı ye, ye, sana saygı duyuyorlar, bana değil!!!”`,
                        "11": `İşte hikayenin performansı için bazı küçük ipuçları`,
                        "12": `Tüm öğrencilerden hikayenin ana fikri hakkındaki duygularını ve kendi fikirlerini yazarak kendi yansımalarını yapmalarını isteyebiliriz.`,
                        "13": `Rol yapma yöntemini etkin bir şekilde kullanmak için tüm grubu daha küçük gruplara ayıracağız.`,
                        "14": `Örneğin, 30 katılımcıdan oluşan bir grubumuz var. Öncelikle öğretmenin dilediği gibi 2 veya daha fazla gruba ayıracağız, bazıları hikayeye göre rollere sahip olacak ve biri sahnede sesiyle zaman zaman anlatıcı olarak gelecek olan hikayeci olacak.`,
                        "15": `Gruplar eşit gruplara ayrılacak, biri anlatıcı diğeri katılımcılar oyuncu olacak (bunlar rol oyuncularıdır). Rehber veya eğitmen, tüm gruplar için kısa ve farklı bir Nasreddin Hoca Masalı veya La Fontaine masalı seçecek ve çıktısını alacak veya öğrencilere e-posta yoluyla gönderecektir.`,
                        "16": `Role hazırlanmak için yaklaşık 17 dakikaları ve diğer 18 dakikaları olacak. performans için kullanılacak, kalan katılımcılar seyirci olacak, ancak rolü oynayacak olanlar oyun oynarken aktif olarak doğrudan kendilerine sorular soracak, böylece bu şekilde diğerleri de oyunda aktif olacak. Öğretmen ayrıca performansı duraklatabilir ve oyundan birini halktan biriyle değiştirebilir. Sonunda 19 dakikamız olacak. hikayelerin ahlaki dersleri hakkında konuşmak.`,
                        "20": `İlk 5 dakikada. her grup hikayenin ahlakı hakkında ayrı ayrı konuşacak, artıları ve eksileri hakkında konuşabilecek ve şu sorulara cevap verebilecek: Bu hikaye bize ne anlatacak? ; Bu Fikre nasıl ulaşıyorsunuz? ; Nasreddin Hoca ya da diğer roller yerine ne yapardınız?`,
                        "21": `Bundan sonra, son 6 dakika içinde hepimiz bir daire içinde oturabiliriz ve her katılımcı duyguları ve / veya rol yapma sırasında yaşadıkları veya öğrendikleri hakkında bazı kelimeler söyleyebilir.`,
                        "22": `Dolayısıyla, bu tür sorularla rol oyuncuları (seyircilerin) dikkatini çekecek ve böylece seyirciler içerikler hakkında aktif bir şekilde düşüneceklerdir.`,
                        "23": `Nasreddin Hoca'dan yukarıdaki hikaye, rol yapma oyunumuz için kısa bir performans olarak kullanacağımız bir örnektir.`,
                        "24": `Şimdi bunu nasıl sahneleyebileceğimize bakalım (bu arada bu bölüm sadece rolü oynarken kendi yaratıcılığınızı kullanacağınız için hikayeyi nasıl gerçekleştirebileceğinize dair bir fikir vermek içindir) :`,
                        "25": `Bahsettiğimiz gibi katılımcılar hikayeyi okuyacak ve gerçekleştirmek istedikleri rolde gönüllü olarak hareket edecekler, yani biri Nasreddin Hoca, diğeri düğüne ev sahipliği yapacak ... vb.`,
                        "26": `Zaman zaman Anlatıcı olarak gelecek olan hikaye anlatıcısı da vardır.`,
                        "27": `Anlatıcının eylemiyle başlayalım, Anlatıcı olarak siz hikayeye başlayacaksınız, bu yüzden izleyicilerin dikkatini empatik bir tonla almalısınız ...."Güneşli bir gün ve Nasreddin Hoca evinin yakınında yürüyor”... aynı zamanda Nasreddin Hoca ve düğün sunucusu sahneye performans için geliyorlar ... diyaloglarına başlıyorlar, (bu hikayenin ana fikrine göre olacak ama elbette bir şeyler ekleyeceksiniz)`,
                        "28": `onlar hareket ederken ....nasreddin Hoca'nın kıyafetlerini değiştirip en yeni ve en havalı takımını giydiği ikinci bölümü hatırlayalım ....rol oyuncusu izleyicilere kendiliğinden soracak veya söyleyecek ... "Herkese merhaba! Takım elbisemi şimdi nasıl buldun?`,
                        "29": `Oyun oynandıktan sonra öğrencilerin aklında ne var?`,
                        "31": `Dikkatinizi ne çekti?`,
                        "32": `Nasreddin Hoca olsaydınız ne yapardınız?`,
                        "33": `Oyundaki en önemli an nedir?`,
                        "34": `Nasreddin Hoca haklı mı?"`,
                        "35": `Bu tür sorularla öğrenciler kendini yansıtma geliştirecekler.`,
                        "36": `Bu, sonunda eğitmen tarafından yapılacaktır.`,
                    },
                    "superstition": {
                        "1": "Batıl inançlar günlük hayatımızın bir parçasıdır. Genellikle kökenleri kültürün kendisinden gelir. Bu oturumda, öğrenciler toplumlarındaki kültürel unsurlar için içsel vizyonlarını açma şansına sahip olacaklar. Oturum, analiz etme, sentezleme, beyin fırtınası gibi eleştirel düşünmenin kritik unsurlarını içerir. Dahası, ders Gardner'ın çoklu zeka teorisine dayanan etkinlikleri birleştirir. Tartışmalar, gençlerin bir tartışmanın her iki tarafını da görmeleri ve iletişim becerilerini geliştirmeleri için harika bir yoldur. Ayrıca sınıfta kurulumu kolaydır ve ortaya çıkan temalar sonraki sınıflarda daha ayrıntılı olarak incelenebilir. Sınıfta bir tartışma oluşturmanın farklı yolları vardır, bu nedenle sizin için hangi formatın işe yaradığını kullanmaktan çekinmeyin. ",
                        "2": "Süre: 45 dakika: Temayı tanıtmak için 5 dakika; tartışma için 25 dakika; değerlendirme ve bilgilendirme için 15 dakika",
                        "3": "Oyuncu sayısı: en fazla 20 öğrenci",
                        "4": "Yerel gereksinimler: İçeride olması gerekir.",
                        "5": "Gerekli Araçlar: Öğrenci grupları için bir kalem ve bir yaprak kağıt, flash kartlar (ek 1'e bakınız), konuyla ilgili araştırma yapmak için cep telefonları veya tabletler",
                        "6": "İnsan kaynakları: Projeyi uygulamak için bir kolaylaştırıcı gereklidir.",
                        "7": "Katılımcıları yarım daire şeklinde düzenleyin. Haritayı, katılımcılar tarafından görülemeyen odanın gizli bir alanına asın. Eğitimcilerden biri görevi anlatırken, diğeri görevi ve kalemleri katılımcılara dağıtır.",
                        "8": `Öğretmen sınıfa dört yapraklı yonca gibi bir batıl inancın sembolü olan bir nesneyle veya at nalı şeklindeki bir nesneyle (hatta at nalı gibi) gelir ve öğrencilere neyi sembolize ettiğini sorar.`,
                        "9": `Öğretmen, kara kedi gibi yaygın batıl inançlarla ilgili görüntülerin bulunduğu sınıfa bazı flash kartlar gösterir (bkz. Ek 1).`,
                        "10": `Öğrencilere görüntüleri birlikte tartışmaları ve dersin temasının ne olduğuna karar vermeleri için birkaç saniye verilir. Cevaplar değişebilir, ancak muhtemelen temanın ‘şans’ veya ‘iyi / kötü şans’ olduğu sonucuna varacaklardır.`,
                        "11": `Bu noktada öğretmen, bazıları tarafından bilinmiyorsa ‘batıl inanç’ kelimesini öğretebilir. Tanım tahtaya yazılabilir veya sözlü olarak anlatılabilir.`,
                        "12": `Dersin teması açıklandıktan sonra öğretmen öğrencilerden görüntüleri tek tek göstererek görüntüleri şanslı olanlara ve şanssız olanlara bölebileceklerini görmelerini ister. (Uluslararası bir sınıf ortamıysa, bazı görüntülerin anlamları ülkeye bağlı olabilir.)`,
                        "13": `Öğrenciler, bir öğrencinin verilen bir fikre katılabileceği veya katılmayacağı fikrine tanıtılır ve fikrini değiştirmeye ikna edilebilirler.`,
                        "14": `Öğretmen, sınıfın karşı taraflarına iki hemfikir / katılmıyorum işareti koyar.`,
                        "15": `Öğrencilerden işaretlerin ortasında durmaları istenir.`,
                        "16": `Öğretmen ifadeyi yazar / söyler "Batıl inançların arkasında mantıklı bir neden vardır.”.`,
                        "17": `Öğrenciler bu ifade hakkındaki görüşlerine göre ortada kalabilir veya anlaşamama/ katılmama işaretlerine doğru ilerleyebilirler.`,
                        "18": `Aynı görüşe sahip öğrenciler gruplar oluşturur. Bir grupta çok fazla öğrenci varsa öğretmen öğrenci sayısına karar verebilir. Bir tarafta sadece bir öğrenci varsa, bireysel olarak çalışabilir veya hepsi aynı taraftaysa öğrenciler öğretmene meydan okuyabilir.`,
                        "19": `Öğretmen konuşmayı yönlendirir ve gerektiğinde sorular sorar.`,
                        "20": `Bundan sonra öğrencilere fikirlerini savunacak argümanlar bulmaları için zaman verilir. (5 dakika) İnternette istatistik arayabilir veya kişisel deneyimlerini düşünebilirler.`,
                        "21": `Ayetlerinde tekrar karşılaşırlar ve karşı tarafta duranları ikna etmeye çalışırlar. Her öğrencinin kendi tarafını en fazla bir dakika içinde savunması gerekir.`,
                        "22": `Başkaları söylenenlere katılırsa, diğerlerine daha da yaklaşabilirler' işareti kendiliğinden.`,
                        "23": `Öğrencilerden oturumu bir kelimeyle ve etkinlikte neyi değiştireceklerini anlatmaları istenir.`,
                        "24": `Öğretmen cevaplarını toplar.`,
                        "25": `Hala ortada olan öğrencilerin ikna olmaları için diğerlerine bir soru sormalarına izin verilir. Öğretmen, karşıt fikri grup içindeki uyum, argümanlarının güvenilirliği ve tersi açısından değerlendirmeyi kabul eden öğrencilere sorar.`,
                        "26": `Öğrencilerden öğretmen tarafından Menti'de sorulan değerlendirme sorularını yanıtlamaları istenir. Sorular şu şekildedir:`,
                        "27": `Batıl inançlarla ilgili fikirlerinizi değiştirdiniz mi?”`,
                        "28": `Farklı kültürlerdeki batıl inançlar arasında neden farklılıklar olabileceğini düşünüyorsunuz?”`,
                    },
                    "volunteer": {
                        "1": "Bu faaliyet, bireysel özelliklere göre hangi gönüllülük faaliyetlerinin yapılabileceğine karar vermeyi gerektirir. Katılımcılar kendilerine sunulan karakterlere uygun gönüllülük faaliyetlerini belirlerken karar verecek, bu kararların nedenlerini ifade edecek, kararlarının olası sonuçları hakkında çıkarımlar yapacak, kararlarının dayandığı varsayımları ifade edecek ve alternatif kararları belirleyeceklerdir. Aynı zamanda dolaylı olarak öğrencilerin bireysel farklılıklara saygı duyarak herkesin toplum için bir şeyler yapabileceğini fark etmelerini ve çevre sorunlarına duyarlı olmalarını sağlamak amaçlanmaktadır.",
                        "2": "Süre: 50 dakika",
                        "3": "Oyuncu sayısı: 3 grup (Her grupta eşit sayıda öğrenci olmalı veya en azından birbirine yakın olmalıdır.)",
                        "4": "Yerel gereksinimler: Her grubun etkileşimli çalışmasına izin verildiği sürece etkinliğin nerede yapıldığı önemli değildir.",
                        "5": `Gerekli Araçlar: "Gönüllüler Listesi" (Ek-1), "Karakter Listesi” (Ek-2), "Değerlendirme Formu” (Ek-3), makas, yapıştırıcı, kurşun kalem`,
                        "6": `İnsan kaynakları: Etkinlik sırasında öğretmenlerin ve öğrencilerin sorumlulukları öğretmenlere aittir: talimatların açık bir şekilde sunulması, öğrencilere gerekli materyallerin verilmesi, zaman yönetiminde iyi olunması ve öğrenciler için: tüm karakterlerin amaç dahilinde verilen gönüllüler listesine alındığından emin olun etkinliğin tüm sorularını kararları sunarken yanıtlayın, sunum yapan grubu değerlendirmek için verilen değerlendirme formunu doldururken samimi ve tarafsız olun, işbirliği içinde çalıştığınızdan emin olun.`,
                        "7": `Grubu hazırlamak:`,
                        "8": `(5 dakika) Öğretmen öğrencilere daha önce gönüllü olup olmadıklarını sorar. Daha önce gönüllü olan öğrenciler, ne tür bir gönüllülük yaptıklarını ve oradaki deneyimlerini paylaşırlar. Grupta daha önce gönüllü olmuş öğrenci yoksa, gönüllülük faaliyetlerinin neden yürütüldüğü, topluma katkıları ve gönüllüler üzerindeki olumlu etkileri gibi konular tartışılabilir.`,
                        "9": `Faaliyetlerin uygulanması:`,
                        "10": `(İlk Bölüm: 20 dakika.; İkinci Bölüm: 21 dakika.)`,
                        "11": `İlk bölümde:`,
                        "12": `İkinci bölümde:`,
                        "13": `Etkinlikten çıkmak:`,
                        "14": `Bu etkinlikte öğrencile akranlarını grup olarak değerlendirecekler. Bu amaçla grup sunumları sırasında kendilerine verilecek Değerlendirme Formunu (Ek-3) kullanacaklardır. Her öğrenci kendi grubu dışındaki diğer grupları değerlendirecek, yani her öğrenci iki değerlendirme yapacaktır. Her öğrenci grupların sunumlarını dikkatle dinlemeli ve sunumlar bittikten sonra 1 dakika içinde değerlendirme formunu doldurmalıdır. Öğrenciler, sunum yapan kişiyi değil, sunum yapan grubu değerlendirdiklerini hatırlamalıdır. Değerlendirme süreci hakkında daha ayrıntılı bilgi bir sonraki bölümde sunulmuştur.`,
                        "15": `Öğrencilerin sunumlarının değerlendirilmesinde kullanılacak kriterler aşağıdaki gibidir`,
                        "16": `Sunumu değerlendirecek diğer grup üyeleri bu kriterlere göre hazırlanan Değerlendirme Formunu (Ek-2) kullanacaklardır. Öğretmenler sunum sırasında bu formları diğer grup üyelerinin her birine vermelidir. Değerlendirme formu, bu beş kritere dayalı beş ifade içermektedir. Öğrenciler bu beş ifade ile anlaşmalarını 2'den 6'ya kadar değerlendireceklerdir. 3, “kesinlikle katılmıyorum” ve 17, “kesinlikle katılıyorum” anlamına gelir. Ardından her öğrenci bu puanları toplayarak grubun toplam puanını hesaplayacaktır. Her öğrencinin bir grup için verebileceği en düşük puan 18, en yüksek puan ise 25'tir. Böylece faaliyetlerin sonuçlarını ve öğrenme sonucunu ölçmek mümkün olacaktır.`,
                        "19": `Değerlendirme sonunda öğretmen toplam puanlara göre bir yarışma düzenleyebilir veya bu puanları öğrencilerin akademik başarılarını ölçmek için kullanabilir.`,
                        "22": `Etkinliklerden sonra öğrencilerden geri bildirim almak önemlidir. Bu etkinlik aynı zamanda öğrencilerin dikkatini bireysel farklılıkların ve gönüllülük faaliyetlerinin değerine çekmeyi amaçladığından, bu konuda sınıf içi bir tartışma düzenlenebilir. Gönüllülük faaliyetlerine olan ilgilerinin artıp artmadığı, bireysel farklılıklara bakış açılarının değişip değişmediği veya genel olarak bu faaliyet hakkındaki görüşlerinin neler olduğu sorulabilir. Ayrıca, öğrenciler kararlarının olası sonuçları hakkında bir hikaye yazabilirler. Örneğin, ne olacağını ve karakterlerin ne tür deneyimlere sahip olacağını belirterek hikayelerini yazabilirler.`,
                        "23": `Öğrenciler 3 gruba ayrılır.`,
                        "24": `Gönüllüler Listesi (Ek-1) ve Karakter Listesi (Ek-2) her gruba verilir.`,
                        "25": `Gönüllüler Listesinde üç farklı gönüllülük alanı vardır: eğitim gönüllülüğü, afet gönüllülüğü ve çevre gönüllülüğü.`,
                        "26": `Karakter Listesinde beş farklı karakterin fotoğrafları ve bu karakterleri tanıtan kısa metinler var.`,
                        "27": `Öğrenciler her karakteri incelemeli ve onları gönüllülük faaliyetine yerleştirmelidir.`,
                        "28": `Bunu yaparken kararları aşağıdaki soruların cevaplarını içermeli ve her karakter için bu sorulara cevap verilmelidir:`,
                        "29": `Bu karakteri hangi gönüllülük faaliyeti için seçtiniz?`,
                        "30": `Bu karakter seçtiğiniz gönüllülük faaliyetine nasıl katkıda bulunabilir?`,
                        "31": `Bu karakter hakkında ne gibi varsayımlarınız var?`,
                        "32": `Bu karakter sizin belirlediğiniz gönüllülük faaliyeti dışında başka hangi gönüllülük faaliyetlerinde bulunabilir? Neden ve nasıl?`,
                        "33": `Öğrenciler Karakter Listesindeki karakterlerin isimlerini kesecek ve bu isimleri karar verdikleri gönüllülük alanına yapıştıracaklardır.`,
                        "34": `Bu yerleştirme için herhangi bir kısıtlama yoktur. Önemli olan, her karakterin gönüllü bir faaliyete yerleştirilmesidir.`,
                        "35": `Gruplar yerleştirmeyi tamamladıktan sonra kendilerini temsil edecek bir sözcü seçerler.`,
                        "36": `Her grubun sözcüsü, kendilerine verilen 5 dakika içinde kararları hakkında bir sunum yapmalıdır.`,
                        "37": `Her grubun 6 dakikalık sunumundan sonra diğer grup üyelerine değerlendirmesi için 1 dakika süre verilecektir. Sonuç olarak, her grubun toplam 7 dakikası olacaktır (sunum için 8 dakika, değerlendirme için 2 dakika).`,
                        "38": `Açık ve anlaşılır olmak.`,
                        "39": `Makul ve kabul edilebilir olmak.`,
                        "40": `Önyargıdan uzak olmak.`,
                        "41": `İkna edici varsayımlar kullanmak`,
                        "42": `Makul alternatifler kullanmak.`,
                    },
                    "escape": {
                        "0": `Bu yöntem her eğitim ortamı için geçerlidir, amacı CRAAP metodolojisi hakkında katılımcılara bilgi vermek ve uygulamaya koymaktır. CRAAP yöntemlerine ek olarak, PUSULA yöntemlerine ilişkin bilgiler de verilecektir. Etkileşimli ve mantıksal bulmacaların çözümü yoluyla eleştirel düşünmeyi teşvik eden ve bilgileri düzelten temel yetkinlik faaliyetlerinin geliştirilmesinin amacı, bu, faaliyetin mantığı kullanarak zihni de uyardığı anlamına gelir. Etkinlik, grup çalışmasına ve bilmecelerin üstesinden gelmek için katılımcılar arasındaki karşılaştırmaya dayanmaktadır. Bu metodoloji, küçük değişiklikler yoluyla, herhangi bir temaya uygulanabilir.`,
                        "1": `Grubun hazırlanması:`,
                        "2": `Faaliyetlerin uygulanması:`,
                        "3": `Kaçış odasını uygulamak için talimatları aşağıdaki sırayla izlemelisiniz:`,
                        "4": `Önce ve oyuncular oynamaya başlamadan önce aşağıdaki hikayeyi odanın dışında okuyun.`,
                        "5": `Günaydın`,
                        "6": `Siz araştırmacı gazetecisiniz ve sahte haberlere karşı yürütülen soruşturmanın bir parçasısınız. Günümüzde COVID 19 ve aşılar nedeniyle sahte haberler çoğaldı. 30 dakika önce meslektaşınız Sarah Blakeslee'den bir telefon aldınız, size dava için çok önemli bilgiler aldığını, size göstermek istediğini ve soruşturmanın gidişatını değiştireceğini söyledi.`,
                        "7": `Ofisine geldiğinizde, o orada değil!! Polisin çağrıya müdahale ettiğine ve onu tutukladığına dair somut şüpheleriniz var, ancak ofise polisten önce geldiniz, bu yüzden bu bilgiyi bulmak, yayınlamak, komployu çözmek ve Sarah Black'i kurtarmak için fazla zamanınız yok.`,
                        "8": `Bunun doğrusal bir oyun olduğunu açıklığa kavuşturmalısınız, her araç yalnızca bir kez kullanılabilir, ancak yalnızca birçok kez kullanılması gereken merkezi bir öğe (bilgisayar) vardır.`,
                        "9": `Başlangıç Noktası:`,
                        "10": `Odaya girerken masanın üzerinde sadece bir bilgisayar ve içinde “Polis geliyor! Bilgisayarıma bak!  SB.”. Orijinal oyunda bu mesaj klima kanatlarında gizlidir. Oda son derece sıcak ve klimayı açmanız gerekiyor. Bu mesaj görünmezse, oyunda herhangi bir değişiklik olmaz.`,
                        "11": `Bulmaca 1-8:`,
                        "12": `Bundan sonra oyun 8 bulmacadan oluşuyor ve çözmeye devam ediyor. Aşağıdaki bulmacalar, bölüm 6'daki bağlantıdaki EK_2 dosyasında bulunabilir `,
                        "13": `Nihai görev:`,
                        "14": `Sarah'nın bulduğu önemli bilgiyi bulmak için yayıncıyı arayın ve ona verin. Kapanış / Analiz (nasıl yapıldığını açıklayın ve herhangi bir çerçeve / bağlantı ekleyin): `,
                        "15": `Değerlendirme sürecinin nasıl gideceğini ve aşağıdaki faktörlere bağlı olarak ne tür bir değerlendirmenin kullanılacağını açıklayın`,
                        "16": `Değerlendirme kriterleri:`,
                        "17": `Kaçış odalarının katılımcılar / oyuncular üzerindeki etkisi ve etkinliği, gerçekleştirilen etkinliği değerlendirme kriteri olacaktır. Değerlendirme, grup veya sohbete rehberlik eden ve kaçış odası ve onlara iletilen bilgilerle ilgili sorular soran tek bir kolaylaştırıcı tarafından yönetilecektir.`,
                        "18": `Sonuçları ölçün:`,
                        "19": `Sonuçlar, oyuncuların kaçış odası sırasında CRAAP yöntemini nasıl uyguladıklarına ve genel olarak kaçış odasını grup ve birey olarak nasıl oynadıklarına göre ölçülecektir. Sonuçlar ayrıca yansıma sırasında CRAAP yöntemi ve doğru bilgiler hakkında birkaç soru sorularak da ölçülecektir.`,
                        "20": `Bilgilendirme yöntemi:`,
                        "21": `Yukarıda da belirtildiği gibi, kaçış odasının sonunda bir kapanış analizi yapılır, katılımcıların oyun zihniyetinde kalmaması için kaçış odası biter bitmez gerçekleştirilebilecek çıkış etkinliği de aynı olacaktır`,
                        "22": `Sürecin katılımcılar tarafından nasıl hissedildiğini bulmak için kullanılabilecek bir geri bildirim yöntemi hazırlayın ve açıklanan yöntemi iyileştirmek veya uyarlamak için bu aracı kullanın.`,
                        "23": `Gerçekleştirilen faaliyet hakkındaki izlenimlerini sorarak mümkün olduğunca fazla geri bildirim toplamak için katılımcılarla görüşün. Aşağıdaki sorular kullanılabilir`,
                        "24": `Grup Büyüklüğü: 3-6 kişi`,
                        "25": `Yaş: 16-30 yaş`,
                        "26": `Süre: 45 dakika`,
                        "27": `Konum: Kaçış odası, boş olmayan, tercihen içinde başka bir odaya açılan ek bir kapı bulunan yeterince büyük bir odaya yerleştirilmelidir.`,
                        "28": `Malzemeler: Kaçış odasını kurmak ve uygulamak için kullanılması gereken birkaç malzeme var. Ayrıntılı liste EK_1 belgesinde sunulmuştur. Belge, bölüm 6'daki bağlantıda bulunabilir.`,
                        "29": `İnsan kaynakları: Etkinlik bir değerlendirici / kolaylaştırıcı / eğitmen veya bir grup tarafından uygulanabilir. Kaçış odasının uygulanması sırasında, katılımcılara ihtiyaç duyduklarında yardım etmesi gereken tek bir irtibat kişisi olmalıdır.`,
                        "30": `Katılımcılara kaçış odasının ne olduğunu bilip bilmediklerini sorun.`,
                        "31": `Katılacakları ve oynayacakları kaçış odasının klasik bir kaçış odası değil eğitici bir kaçış odası olacağını belirterek katılımcılara kaçış odasının ne olduğunu açıklayın.`,
                        "32": `Bundan sonra onları 6 kişiye kadar farklı gruplara ayırın.`,
                        "33": `Gruplar birbiri ardına kaçış odasında yer alacaklar.`,
                        "34": `Kaçış odasının uygulanması sırasında oyuncular odanın içinde yalnızdır. Kaçış odası hikayesine uyan olası bir figür yoktur. Belki de gazeteci grubunu bekleyen Sarahs arkadaşının figürü ana kapıyı açıp içeri girmelerine izin verebilir.`,
                        "35": `https://www.europarl.europa.eu/RegData/etudes/ATAG/2017/599386/EPRS_ATA (2017)599386_EN.pdf Yukarıdaki belge, haberlerin sahte olduğunu tespit etmek için izlenmesi gereken 8 adımı göstermektedir.`,
                        "36": `5 numaralı bulmacanın çözümü, Sarah Blakeslee tarafından yayınlanan bir yöntem olan craap'tır. Aynı zamanda ofisine gitmek için oyuncularla iletişime geçen gazetecidir.`,
                        "37": `6 numaralı bulmacada katılımcılar, doğru şifreye sahip belgeyi bulabilmek için daha önce bulmacada öğrendikleri CRAPP yöntemini kullanmak zorundadırlar. Bu adımda Sarah'dan bir notun faydalı olabileceğini düşünüyoruz. i.g. "Polis gelmeden önce yeterli zamanınız yok! Bu haberlerden hangisinin gerçek olduğunu kontrol etmek için bu kutuyu açmak için kullanılan yöntemi kullanın! S.B”`,
                        "38": `Haberleri internette paylaşmadan önce kontrol edip etmediklerini katılımcılarla tartışın.`,
                        "39": `Değerlendirme kriterleri`,
                        "40": `Değerlendirmeyi kim yapıyor? (komisyon, kendini yansıtma, tam grup değerlendirmesi)`,
                        "41": `Etkinliklerin sonuçlarını ve öğrenme sonucunu nasıl ölçebilirsiniz?`,
                        "42": `Katılımcıların oyun zihniyetinde veya hala rekabet modunda kalmaması için bilgilendirme için bir yöntem hazırlayın.`,
                        "43": `Eğlenceli miydi ve aktiviteye dahil hissettiniz mi?`,
                        "44": `Yeni bir şey öğrendin mi? Eğer öyleyse, ne?`,
                        "45": `Zor Bulduğunuz işler oldu mu? Eğer öyleyse, onları nasıl daha kolay hale getirirsiniz?`,
                        "46": `Faaliyetleri yürüten grup hakkındaki izlenimleriniz nelerdir? Tüm katılımcılar her bilmecenin çözümüne katkıda bulundu mu?`,
                        "47": `Kaçış odası aracılığıyla öğrendiklerinizi günlük yaşamınızda uygulayacak mısınız?`,
                    },
                "desert": {
                        "1": `Bu oyun, 5 özel keşif eylemi türüyle etkileşimli, takım odaklı bir hayatta kalma sınavı türü olacak:`,
                        "2": `Katılımcıların karar vermeleri gereken, gruplar halinde çalışarak kararlarını desteklemek için argümanlar kullanacakları ve ardından en iyi seçimi yapıp yapmadıklarını ve bir sonraki seçim için ne yapmaları gerektiğini keşfetmek için bir sınavı ortak bir kararla doldurmaları gereken bir hikaye anlatılacak. adım.`,
                        "3": `Hikaye sırasında verecekleri bir dizi kritik kararları olacak. Her karardan sonra grup kararının arkasındaki mantıksal süreci sorgulayacak ve etkinlik bitene ve en doğru kolektif kararlara sahip grup kazanana kadar gerçek çözümü sunacaksınız.`,
                        "4": `Etkinlik, kişi başına 1 dakikalık bir rasyonla her karar arasında tartışmak için 5 dakikaları olacağından, maksimum 5 kişilik maksimum 10 takım için uygundur.`,
                        "5": `Etkinliği okullarda, eğitim oturumlarında, boş zamanlarınızda eleştirel düşünen bir sosyal oyun ve hatta e-öğrenme derslerinde bir araç olarak uygulayabilirsiniz.`,
                        "6": `Grubun hazırlanması: Grubu en az 2 en fazla 5 katılımcıdan oluşan ekipler halinde ayırın ve kahoot platformuna bağlanmalarına yardımcı olun. Ardından heyecan verici bir ortam yaratmak için arka planda macera müziği çalarken hikayeyi okumaya başlayın.`,
                        "7": `Afrika'da gönüllü bir görev için askeri jet kargo uçağıyla uçuyorsunuz, Sahra Çölü'nü geçmeniz gerekiyor ve şu anda üzerinde uçuyorsunuz, 30 dakikalık uçuştan sonra bazı teknik sorunlar var gibi görünüyor, kokpitten S.O.S. sinyalini duyuyorsunuz ve ardından uçak yere dalar, sadece birkaç küçük çürükle kazadan kaçan acil çıkıştan paraşütle atlamayı başaran tek kişi sizsiniz. Yere düştükten sonra ufuktaki dumanı yanan düzleme kadar takip edersiniz. Sizden başka kurtulan yok ve alevler hala tahrip olmuş metal parçalarını yakıyor, bunların hiçbirini ağırlık ve sıcaklık nedeniyle kullanamazsınız, ancak yükün bir kısmı yere serilir. Enkazın etrafına yere dağılmış nesneleri tespit edersiniz: bir tıbbi kit, bir silah, bir şişe alkol, bir cep aynası, 1 litre suya sahip bir şişe, çölde nasıl hayatta kalacağınızla ilgili bir kitap, yeni indiğiniz paraşütünüz, pusula, yağmurluk, kapüşonlu, el feneri, bıçak, güneş gözlüğü, harita.`,
                        "8": `Hayatta kalma stratejisi oluşturmanıza yardımcı olabilecekleri için bu nesnelerden yanınıza neler alabileceğinizi dikkatlice düşünün.`,
                        "9": `Etkinliğin geri bildirimi, odayı anlaşma ve anlaşmazlıkla bir tarafa bölerek kağıt üzerinde veya iyi ve kötü bir etkinlikle yapılabilir ve katılımcıların kendilerini konumlandırabilecekleri descriptionlar yaparsınız ve bazılarına neden o bölümde olmayı seçtiklerini sorabilirsiniz. oda, görüşü not aldığınız gibi.`,
                        "10": `Ana ifadeler şöyle olmalıdır`,
                        "11": `Varsayım`,
                        "12": `Çıkarım`,
                        "13": `Yorumlama`,
                        "14": `Kesinti`,
                        "15": `Tartışma`,
                        "16": `Maksimum oyuncu sayısı: 50`,
                        "17": `Zaman dilimi: 30 dakika, çevrimiçi ve çevrimdışı kullanıma uygundur.`,
                        "18": `Gerekli araçlar: projektör, dizüstü bilgisayar, iyi internet bağlantısı, tanıtım ve geri bildirim için basılı kağıtlar Ek1 & Ek2;`,
                        "19": `İnsan kaynakları: en az bir kolaylaştırıcı;`,
                        "20": `Hayatta kalma stratejisindeki ilk göreviniz için bulunan haritayı kontrol edersiniz ve yaklaşık olarak çölde 300 km uçtuğunuzu tahmin edersiniz ve size yardımcı olabilecek en yakın üs yaklaşık 200 km'dir. Bu durumda bir karar vermeniz gerekir: kaza yerinin yakınında mı kalıyorsunuz yoksa üsse mi gidiyorsunuz? S1: kal veya git - kal +% 40`,
                        "21": `Artık harekete hazırsınız, bu durumda atacağınız ilk adım A: Kendinizi korumak için silahı ve bıçağı alın; B: Haritayı ve pusulayı alın ve yolculuğa başlayın; C: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve bir çadır hazırlayın; D: Paraşütü alın ve çölde nasıl hayatta kalınacağı ve okumaya nasıl başlanacağı üzerine bir kitap; `,
                        "22": `Güneşin çok sıcak yandığını hissetmeye başlıyorsunuz ve hazır bir barınağınız yok, sıcaktan rahatlamak için ne yapıyorsunuz: A: Gözleri rahatlatmak için güneş gözlüklerini alın; B: Kendinizi güneşten korumak için yağmurluğu alın: C: Güneş gözlüklerini alın. suyu tazelemek için başınızın üzerine koyun; D: Kapüşonu ve başının üzerinde olduğu yeri alın; `,
                        "23": `Artık sığınağı inşa etmekten yoruldunuz ve tazelemek istiyorsunuz ama suyu çoktan bitirdiniz, biraz enerji almak için ne yapıyorsunuz: Güneşten uzakta bir şeyler yiyin; Güneşten biraz uzak durun; Alkol şişesinden iç; Çalışmaya devam et;`,
                        "24": `Hazırsınız ve S.O.S. sinyalinin alındığını biliyorsunuz, bu nedenle kurtarma birimlerinin sizi bulacağından emin olmak için yardım sinyali vermek için ne kullanıyorsunuz: A: Silah ve el feneri; B: Cep aynası ve el feneri; C: Kitap ve alkol yapmak ateş; D: Havada hareket etmek için kıyafet kullanın; `,
                        "25": `Değerlendirme kriterleri: Katılımcılar Kahoot sistemi üzerinden otomatik olarak değerlendirilecek, ancak karar alma sürecine göre kolaylaştırıcı tarafından da değerlendirilecektir.`,
                        "26": `Değerlendirmeyi kim yapıyor? Değerlendirme, otomatik Kahoot puan sistemi tarafından niceliksel olarak ve kolaylaştırıcı tarafından niteliksel olarak yapılır.`,
                        "27": `Etkinliklerin sonuçlarını ve öğrenme sonucunu nasıl ölçebilirsiniz? - Puanlarla ölçülebilir. Davranış ve karar verme süreci için puan ekleyebilir veya kaldırabilirsiniz.`,
                        "28": `Katılımcıların oyun zihniyetinde veya hala rekabet modunda kalmaması için bilgilendirme için bir etkinlik hazırlayın.  Çıkış etkinliği dans etmek ve “Ben hayatta kalanım" şarkısını söylemek veya Prenses Hayatta Kalma enerji verici - Ek 3'ü oynamak olabilir.`,
                        "29": `Bu aktiviteyi seviyorum. - Niye?`,
                        "30": `Etkinlikten bir şeyler öğrendim. - ne?`,
                        "31": `Ekip çalışması hayatta kalmama yardımcı oldu.  - Nasıl ve kim?`,
                        "32": `Eleştirel bir görüşüm varsa, konumumu savunmak için şimdi daha iyi argümanlar getirebilirim. - Bu süreçte size ne yardımcı oldu?`,
                        "33": `Bu etkinlikte eleştirel düşünmeyi kullandım. - Nerede ve nasıl?`,
                        "34": `Bu aktivitede bir şeyi değiştirirdim. - eğer birisi anlaşmaya varırsa ne sorun?`,
                        "35": `Bu resmi olmayan faaliyetlerden daha fazlasını denemek istiyorum; - Neden?`,
                    },
                    "debate": {
                        "0": `Bu etkinlik lise ve üniversite öğrencilerinin eleştirel düşünme, topluluk önünde konuşma ve iletişimsel becerilerini geliştirmeleri için uygundur. Faaliyet, hedef grubun uygun araştırma yapmasına ve haklı görüşler oluşturmasına yardımcı olacaktır.`,
                        "15": `Değerlendirme sürecinin nasıl gideceğini ve aşağıdaki faktörlere bağlı olarak ne tür bir değerlendirmenin kullanılacağını açıklayın`,
                        "16": `Değerlendirme kriterleri:`,
                        "17": `Kaçış odalarının katılımcılar / oyuncular üzerindeki etkisi ve etkinliği, gerçekleştirilen etkinliği değerlendirme kriteri olacaktır. Değerlendirme, grup veya sohbete rehberlik eden ve kaçış odası ve onlara iletilen bilgilerle ilgili sorular soran tek bir kolaylaştırıcı tarafından yönetilecektir.`,
                        "18": `Sonuçları ölçün:`,
                        "19": `Sonuçlar, oyuncuların kaçış odası sırasında CRAAP yöntemini nasıl uyguladıklarına ve genel olarak kaçış odasını grup ve birey olarak nasıl oynadıklarına göre ölçülecektir. Sonuçlar ayrıca yansıma sırasında CRAAP yöntemi ve doğru bilgiler hakkında birkaç soru sorularak da ölçülecektir.`,
                        "20": `Bilgilendirme yöntemi:`,
                        "21": `Yukarıda da belirtildiği gibi, kaçış odasının sonunda bir kapanış analizi yapılır, katılımcıların oyun zihniyetinde kalmaması için kaçış odası biter bitmez gerçekleştirilebilecek çıkış etkinliği de aynı olacaktır`,
                        "22": `Sürecin katılımcılar tarafından nasıl hissedildiğini bulmak için kullanılabilecek bir geri bildirim yöntemi hazırlayın ve açıklanan yöntemi iyileştirmek veya uyarlamak için bu aracı kullanın.`,
                        "23": `Gerçekleştirilen faaliyet hakkındaki izlenimlerini sorarak mümkün olduğunca fazla geri bildirim toplamak için katılımcılarla görüşün. Aşağıdaki sorular kullanılabilir` ,
                        "24": `50 dakika`,
                        "25": `6 oyuncu`,
                        "26": `1 kez kaleci`,
                        "27": `1 moderatör / kolaylaştırıcı (öğretmen / öğrenci)`,
                        "28": `Audienc'in 10 ila 15 üyesi`,
                        "48": `Yöntem, bir konuyu rol yapma biçiminde tartışan takımlar olmak üzere iki takımı yüz yüze yerleştirir. Takımlar belirli kurallara göre oynamalı, mantıklı bir şekilde tartışmalı, kanıt sunmalı, konuyu iyi araştırmalı, takım olarak çalışmalı, organize / etkili sunum yapmalı, tartışmaları mükemmel bir biçimlendirici yöntem haline getirmelidir. Konu moderatör / kolaylaştırıcı tarafından seçilir ve araştırmanın önceden yapılması gerekir. Moderatör / kolaylaştırıcı ayrıca iki takımı nasıl atayacağına da karar verir (olumlu ve olumsuz).`,
                        "49": `Moderatör / kolaylaştırıcı, talimatları katılımcıların anlayış düzeyine göre mümkün olduğunca açık hale getirmekle yükümlüdür.`,
                        "50": `Koltukların düzenlenmesi`,
                        "51": `Moderatör, karşı karşıya gelen takımlar arasında oturmaktadır. Halk yakından oturuyor, her iki takımı da görebiliyor. Oyunun herhangi bir noktasında aldıkları karara göre ayağa kalkar ve takımlardan birine doğru hareket ederler (ek 2).`,
                        "52": `Takım için stratejiyi seçme tavsiyesi (Ek 3)`,
                        "53": `Halk için tavsiye`,
                        "54": `Argümanlar her konuşmacı tarafından sunulduktan sonra – mümkünse tek bir cümlede not alın.`,
                        "55": `Argümanları karşı argümanlarına çizgiler veya oklarla bağlayın ve bir yorum yazın. Argümanlardan hangisi karar vermenize yardımcı oldu?`,
                        "56": `Her tartışmaya 3 üyeden oluşan 2 takım katılıyor. Birincisine OLUMLU TAKIM, ikincisine OLUMSUZ TAKIM denir.`,
                        "57": `Takımların her üyesinin, olumlu takımın ilk konuşmacısından başlayarak alternatif bir sırayla (OLUMLU TAKIM sonra OLUMSUZ TAKIM) 4 dakikalık bir konuşması vardır (Ek 1)`,
                        "58": `Zaman - zaman bekçisi, her konuşmacının zamanını dikkatlice tutmalı ve 10 saniye kaldığında bir jest yapmalıdır. Süre dolduğunda, konuşmacı cümleyi bitirebilir, sonra durması gerekir. Kalan süre başka bir takım üyesine devredilemez.`,
                        "59": `Her konuşmanın sonunda moderatör, konuşmacıya tartışmaya getirilen kanıtlara veya tartışmalara atıfta bulunarak en fazla 5 soru sorar.`,
                        "60": `Maçın başında seyirciler yarım daire şeklinde şöyle oturacaklar: olumsuz takımın önünde görüşleri konuya aykırı olanlar ve olumlu takımın önünde, görüşleri konuya yönelik olanlar. Tarafsız olanlar ortada oturacaklar. Halk, en ikna edici argümanları getirdiğini düşündüğü takıma doğru ilerleyerek yanıt verir.`,
                        "61": `Tartışma sırasında halk müdahale edemez.`,
                        "62": `Tartışma bittikten sonra halk, her iki takımın da yanıtlaması gereken doğru kararı verebilmek için açıklayıcı sorular sorabilir. Daha önce karar vermiş olanlar, kararlarını motive etmek için bir konuşma yapabilirler.`,
                        "63": `Karar bireysel olarak verilir. Maç sırasında bir kişi fikrini birden çok kez değiştirebilir.`,
                        "64": `Her konuşma şu şekilde yapılandırılmıştır: onaylama, description, kanıtlar, örnek / illüstrasyon, sonuç`,
                        "65": `Nihai karar halk tarafından verilir.`,
                        "66": `(Olumlu takımdan ilk konuşmacı hariç) Zamanın ilk yarısını diğer takımdan bir argümana karşı koymak için kullanın, ardından başka bir argüman veya bakış açısı bulun.`,
                        "67": `(İlk konuşmacı için) Teklifinizi sunun – hangi kararı vermek istediğinizi söyleyin.`,
                        "68": `Argümanlarınızın sırasına karar verin ve ekibin her üyesine bir argüman atayın.`,
                        "69": `Son konuşmacı, ekibin konuşmasını üç veya dört yönü vurgulayarak sonuçlandırmalıdır. Tartışmadan sonra halk ne hatırlamalıdır? Tartışma yapılması gereken bir kararla ilgiliyse, tartışmadan sonra oy kullandıklarında halk üyelerinden ne istediğiniz konusunda çok açık olun.`,
                        "70": `Özgürce konuşun, notlarınızdan okumayın, rakiplerle ve halkla göz teması kurun.`,
                        "71": `Rakiplerinize saygılı davranın. Bir konuşmacıya hakaret etmeyin, yalnızca argümanlarını hedefleyin.`,
                        "72": `Değerlendirme iki düzeyde yapılır:`,
                        "73": `Münazara maçının değerlendirilmesi`,
                        "74": `Bu yöntemin kullanılması sonucunda iyileştirilen yeterliliklerin değerlendirilmesi:`,
                        "75": `Katılımcıları oyun zihniyetinden çıkarmak ve sorgulamak için ORID yöntemi (nesnel, yansıtıcı, yorumlayıcı ve karar verici sorular) kullanılır. (Ek 4)`,
                        "76": `Site www.menti.com katılımcılardan etkinlik hakkında geri bildirim almak için kullanılır.`,
                    },
                    "collect": {
                        "0": `Bu beyin fırtınası ve rol yapma yöntemi, işbirlikçi veya bireysel gözlem, analiz, çıkarım, iletişim ve problem çözme için kullanılır. Bu yöntem, gruplar halinde kurulan bireyler arasında bir tür işbirliğine dayalı dinleme, yaratıcı yazma ve diyalogdur. Bir şarkıyı dinleme, duvarlara yapıştırılan karton parçalar üzerine yazılan sözleri toplama, şarkı sözleri hakkında beyin fırtınası yaparak ana fikrini düşünme, topladıkları cümlelerden ilham alarak kısa bir eskiz yazma ve sınıfta rol yapma fikrine dayanır. Sadece yenilikçi fikirleri teşvik etmekle kalmaz, aynı zamanda dersler sırasında daha enerjik ve etkileşimli hale gelmek için dinleme, yazma ve oyunculuk becerilerini geliştirir.`,
                        "1": `Öğrenciler 5 kişilik gruplara ayrılır. 4 Grup olabilir. Aktiviteye başlamadan önce öğretmen şarkıya karar verir ve sözlerden en çarpıcı cümleleri, gerekli hedef kelimeleri veya dilbilgisi kalıplarını seçer. Daha sonra derse gelmeden önce seçilen cümleleri veya kelimeleri / dilbilgisi kalıplarını renkli kartonlara yazar. Bu nedenle, bu aktiviteye sahip bu yöntem, sınıfa girmeden önce biraz hazırlık gerektirir.`,
                        "2": `Öğretmen içeri girdiğinde, kartlar arasında biraz boşluk bırakarak malzemeleri duvarlara dikkatlice yapıştırır. Grupların her üyesi yan yana sıralanacaktır. Diğer üyeler ait oldukları grubun arkasında sıraya girerler. Öğretmen şarkıyı çalmaya başlar. Bir öğrenci duvara yapışmış bir cümle, kelime veya dilbilgisi kalıbı duyduğunda, duvara koşar ve kartı çıkarır ve grubunun satırının sonuna gider. Her grup mümkün olduğu kadar çok kart toplamaya çalışır. Şarkı bittiğinde masalarına geri dönerler. Tüm şarkı sözlerini sınıfla birlikte tartıştıktan sonra öğretmen, onlara yeni topladıkları kartlardaki temaya göre bir rol yapma oyunu yazma ve gerçekleştirme yönergesi verir. Öğrencilerin sahip oldukları tüm cümleleri ve kelimeleri kullanmaları gerekir. Şarkının John Lennon'ın “Imagine” olduğunu düşünün. “Hiçbir ülke olmadığını, öldürecek ya da ölecek bir şey olmadığını hayal edin / hayalperest / yukarıda cennet olmadığını hayal edin / aşağıda cehennem yok / mülk olmadığını hayal edin / Dünya bir olarak yaşayacak / katılın / bir gün / barış / açgözlülük / Merak ediyorum / zor / din vb." oyunlarında. Böylece, kendi aralarında benzersiz bir hikaye yaratmak için cümleler veya kelimeler hakkında beyin fırtınası yapmaya başlarlar. Olay örgüsüne, karakterlere karar verirler, diyalogları yazarlar ve sonunda sınıfta rol oynarlar.`,
                        "3": `YÖNTEM ÜZERİNE ELEŞTİRMEN:`,
                        "4": `Bu toplama, yaratma ve çalma yöntemi, bir şarkıdan söz toplama fikrine dayanır, ancak elbette, sağlık için ekipman tasarlamak için bazı materyaller veya kamu refahı için teknolojik cihazlar toplamak üzere geliştirilebilir. Öğretmenlerin ipler, kutular, boyalar, yazıcılar, çarşaflar vb. Gibi bazı güvenli malzemeler bırakabileceğini düşünün. Öğrenciler nasıl üreteceklerini ve ne yapacaklarını düşünürler ve karar verirler. İstedikleri materyali toplamak için her grubun öğretmenlerin kendilerine sorduğu doğru cevabı vermesi gerekir. Sorulara doğru cevabı verenler, materyalleri toplama şansına sahip olabilirler.`,
                        "5": `Öğrenci sayısı: 20 (değişebilir)`,
                        "6": `Etkinlik süresi: 45 dakika: - 5’: dinlemek için 20': diyaloglar ve roller için beyin fırtınası ve yaratıcı yazarlık için. 20’: rol yapma için.`,
                        "7": `Malzemeler: Akıllı Tahta / Mp3 çalarlar / Müzik Çalar, Renkli kartonlar, Patafix, Kalem, çarşaf, silgi vb. Gibi Sabit Malzemeler.`,
                        "8": `Konum: Bu aktivite, duvar gerektirdiği için iç mekan aktivitesidir. Gerekli materyalleri topladıktan sonra öğrenciler yüz yüze oturmalı, bu nedenle masalar grup halinde oturtularak düzenlenmelidir.`,
                        "9": `İnsan kaynakları: 4 grupta örgütlenebilen 20 öğrenci için bir öğretmen yeterlidir.`,
                        "10": `Öğrenciler, seçilen şarkının yapısına ve içeriğine göre değişebilen birçok hedef yeterlilik alabilirler. Öğretmen konulara karar verebilir. Örneğin, dilbilgisi kalıbı olarak “koşullu” ise, şarkı “ZENGİN bir adam OLSAYDIM” olabilir ve rol oyunları zengin olmanın önemli olup olmadığı hakkında yazılabilir. Farklı şarkıların sözleriyle verilen birkaç sosyal mesaj olabilir.`,
                        "11": `Öğrenciler, kişisel olarak katıldıklarında ve verilen konuda hareket ettiklerinde fikri ve içeriği daha iyi anlarlar. Bu yöntem, öğrencilerin rol yapma oyunlarındaki diyaloglar aracılığıyla etkileşimli olarak düşünmelerine, keşfetmelerine, anlamalarına ve yaratmalarına ve arsaları için yaratıcı yazılar geliştirmelerine yardımcı olur.`,
                        "12": `Dinleme kısmı sırasında, öğrenciler kartları almak için gerçekten duvarlara koştuklarından ve bazen sınıfta kaosa neden olabileceğinden dikkat edilmelidir. Birbirlerine çarpabilir veya düşebilirler. Herhangi bir şekilde, eğlendiklerinden emin olabilirsiniz.`,
                        "13": `Öğrenciler kartları toplamaktan ve dinleme etkinliğinde aktif olmaktan keyif alırlar.`,
                        "14": `Gerçekten yaratıcıdırlar ve işbirliği içinde çalışırken kendilerini güvende hissederler.`,
                        "15": `Bazı öğrenciler utangaç hissederler ancak genellikle arkadaşlarının kendilerine göre uygun parçalar almasına izin vererek bu sorunu çözerler. Örneğin, rol yapma yerine, yazma sürecinde bazı çeviriler veya araştırmalar yapabilirler. Zamanında olabilmek için her grubun bir miktar görev dağıtımı yapması gerekir.`,
                        "16": `Her grup, rol oyunlarından sonra birbirlerinin performansını sözlü olarak değerlendirebilir.`,
                        "17": `Öğretmen, verilen görev üzerinde çalışırken her katılımcı için belirli kriterler üzerinde not almalı ve değerlendirme yapmalıdır. Hem öğrencilerin hem de öğretmenin kriterleri şunlardır: BİREYSEL katkı; GRUP içindeki UYUM; BAĞLAMI ANLAMA; HEDEF kelimelerin / CÜMLELERİN / DİLBİLGİSİ kalıplarının kullanımı; OYUNCULUK sırasında AKICILIK ve TELAFFUZ `,
                        "18": `BİLGİLENDİRME: Öğrencilere aşağıda verilen sorular bir google formu veya sınıftaki bir tartışma aracılığıyla sorulur.`,
                        "19": `Dinleme etkinliğini beğendiniz mi?`,
                        "20": `Sözlerin hangi bölümünü en anlamlı buldunuz?`,
                        "21": `Yaratıcı yazarlık göreviniz sırasında birlikte çalışmayı sevdiniz mi?`,
                        "22": `Topladığınız materyallerden çıkarımlarınızı rol oyununuza iyi yansıttığınıza inanıyor musunuz?`,
                        "23": `Aktivitenin en çok hangi bölümünü beğendiniz?`,
                        "24": `Faaliyetteki herhangi bir şeyi değiştirmek ister misiniz? Ne olurdu?`,
                        "25": `Yöntem ve etkinlik hakkındaki görüşlerini almaları için öğrencilere bir Google formu hazırlanabilir ve gönderilebilir.`,
                        "26": `Menti veya Tricider, öğrencilerin etkinlik hakkındaki görüşlerini toplamak için kullanılabilir.`,
                    },
                    "worldmap": {
                        "0": `Bu yöntem üniversite bağlamında, gelecekteki eğitimcileri ve öğretmenleri eğitmek için geçerlidir. Hedef grup öğrencilerdir, ancak herhangi bir yaş grubuna veya içeriğe uygulanabilir. Amaç tartışmayı ve eleştirel düşünmeyi teşvik etmektir. Geleneksel şeyleri yeni bir bakış açısı altında inceleyerek, hedef kitleyi dünyayı görebilecekleri ve konuyla ilgili görüşlerini ifade edebilecekleri başka bakış açıları olabileceği gerçeğini kabul etmeye teşvik ediyoruz. Açık fikirliliği ve başkalarının bakış açılarını kabul etmeyi teşvik edin.`,
                        "1": `Grubun hazırlanması:`,
                        "2": `Katılımcıları yarım daire şeklinde düzenleyin. Haritayı, katılımcılar tarafından görülemeyen odanın gizli bir alanına asın. Eğitimcilerden biri görevi anlatırken, diğeri görevi ve kalemleri katılımcılara dağıtır.`,
                        "3": `Faaliyetlerin uygulanması:`,
                        "4": `Katılımcılar birer birer bu gizli alana girip haritaya üç saniye bakmaları ve ilk izlenimlerini hemen bir post-it'e en fazla 1-2 kelimeyle yazmaları, eğitimcilerden birine teslim etmeleri için çağrılır. Herkes bunu yaptıktan sonra, eğitimciler post-its'deki cevapları yüksek sesle okur ve çıkan kalıplara göre gruplayarak tahtaya yapıştırırlar. Ardından eğitimciler haritayı gruba gösterir. Genellikle postadaki cevaplardan bazıları-haritanın özelliğini fark eden katılımcıları kabul eder. Ve burada eğitimciler şu iki düşündürücü soruyu sorarak bir tartışma başlatıyorlar: `,
                        "5": `- Sizce bu haritayı kim tasarladı ve neden?`,
                        "6": `- Geleneksel haritalar hangi varsayımlara dayanmaktadır?`,
                        "7": `Katılımcılar cevaplarını ve tartışmayı teşvik eden kelimelerin seçimini motive edebilirler. Eğitimciler, katılımcıların eleştirel düşünmelerine yardımcı olmak için tartışmayı kolaylaştırır ve yönlendirir.`,
                        "8": `Değerlendirme ve bilgilendirme:`,
                        "9": `Herkese harita hakkındaki görüşlerini ifade etme şansı verildiğinde, eğitimciler son bir soru sorarlar: "Sizce bu etkinliğin amacı nedir?" daha fazla eleştirel düşünmeyi teşvik etmek için. Eğitimcilerin yardımıyla umduğumuz sonuç şudur: geleneksel batı kuzeye yönelik haritaların dünya görüşünü çarpıtma eğiliminde olduğu ve Avrupa ve Kuzey Amerika'yı vurguladığı, diğer ülkelerin daha az önemli olduğu anlamına gelir. Dünyaya alternatif bakış açıları sunarak eleştirel düşünmeyi teşvik ediyoruz. Eğitimciler, tartışmadan çıkan herhangi bir anahtar kelimeyi veya fikri yazarlar. Bir takip etkinliği olarak, daha küçük gruplara (4-5 kişi) ayrılan katılımcılardan, seçtikleri herhangi bir aracı, poster kağıdını, kalemleri, dijital uygulamaları vb. Kullanarak tartışmadan çıkan fikirleri yeniden detaylandıracak bir konsept haritası oluşturmaları istenir.`,
                        "10": 'Değerlendirme sürecinin nasıl gideceğini ve aşağıdaki faktörlere bağlı olarak ne tür bir değerlendirmenin kullanılacağını açıklayın`',
                        "11": 'Geri bildirim için aşağıdaki anket kullanılacaktır`',
                        "12": 'Süre: 1 – 2 saat;',
                        "13": 'Oyuncu sayısı: minimum 5, maksimum 30;',
                        "14": 'Yerel gereksinimler: konum gereksinimi yok, tartışmanın duyulabilmesi için çok fazla gürültünün olmadığı bir alanda, rahat, resmi olmayan bir ortamda olmak yeterli.',
                        "15": 'Gerekli Araçlar: Baş aşağı bir dünya haritası (Ek bölüme bakın), haritayı dikey yüzeye sabitlemek için herhangi bir yapışkan malzeme, post-it notları, kalemler (kurşun kalemler).',
                        "16": 'İnsan kaynakları: 3 eğitimci. İlki, etkinliği description ve haritayı gözlemlerken katılımcıları kontrol etme, ikincisi ise katılımcılara materyalleri dağıtma ve etkinliğin nasıl gerçekleştirileceğine dair talimatlar verme görevine sahiptir. Sonra her ikisi de tartışmayı kolaylaştırır ve yönlendirir. İki eğiticinin varlığı, tartışma sırasında birbirleriyle aralarında bazı anlaşmazlıklar yaratabilir, bu nedenle daha fazla bakış açısı sağlar ve böylece katılımcılar tartışmanın siyah beyaz, doğru ya da yanlış değil, farklı bakış açıları ve görüşler hakkında olduğunu anlarlar.',
                        "17": `Değerlendirme kriterleri`,
                        "18": `Değerlendirmeyi kim yapıyor? (komisyon, kendini yansıtma, tam grup değerlendirmesi)`,
                        "19": `Etkinliklerin sonuçlarını ve öğrenme sonucunu nasıl ölçebilirsiniz?`,
                        "20": `Katılımcıların oyun zihniyetinde veya hala rekabet modunda kalmaması için bilgilendirme için bir yöntem hazırlayın.`,
                        "21": `Değerlendirme kriterleri: Kriterler, etkinlik boyunca her katılımcının tartışmanın genel yoğunluğuna, bireysel katılımına ve eleştirel düşüncesine dayanır (tartışma, görevin tamamlanması).`,
                        "22": `Değerlendirmeyi kim yapıyor: Eğitimciler`,
                        "23": `Sonuçları ve Öğrenme Çıktılarını ölçün: Tartışmaya aktif katılım, eleştirel düşünme, yaratıcılık ve takım çalışmasının uygulanmasına göre kredi verilecektir.`,
                        "24": `Bilgilendirme: Kavram haritalarının gruba sunulması ve iletişim becerilerini geliştiren tartışma.`,
                        "25": `Dünya haritasının altüst olduğunu fark ettiğinizde ilk izleniminiz neydi?`,
                        "26": `Etkinliği düşündürücü buldunuz mu?`,
                        "27": `Sizce bunun arkasındaki eğitim amacı nedir?`,
                        "28": `Herhangi bir şeyi değiştirir misiniz?`,
                        "29": `Nasıl?`,
                        "31": `Bir takımda çalışmak size yardımcı oldu mu?`,
                        "32": `Bir anlaşma bulmak kolay mıydı?`,
},
                    "fakenews": {
                        "submission": "Yapmacık Gönderiminiz için teşekkür ederiz ve mümkün olan en kısa sürede yayınlayın!",
                        "summary": 'Kısa Özet',
                        "disproof": "Bozulmaz",
                        "link": 'Bağlantı',
                        "0": `Sanatta sahteciliği işaretlememize yardım edi`,
                        "1": `parçası olun`,
                        "2": `arasında`,
                        "3": `değiştir`,
                        "4": `title`,
                        "5": `Sahte sanat (20-100 karakter)`,
                        "6": `Özet description denizaltının descriptionsı. (50-600 karakter)`,
                        "7": `Bağlantı`,
                        "8": `Buraya sahte ne URL'sini yapıştırın`,
                        "9": `Consid için argümanları temizle (50-500 karakter)`,
                        "10": `Gönder',
                        "11": 'Sahte Ne Al,
                        "12": 'Sahte ne`,
                        "13": `Bu bölüm allo an',
                        "14": 'Okuyuculara sahte haberleri işaretlememize ve değişimin bir parçası olmamıza yardımcı olmaları sunulur!`,
                        "15": `W'ye bir göz atın`,
                        "16": `Sahte nef yo`,
                        "17": `description`,
                        "18": `Bozulmaz`,
                        "19":  'Bağlantı',
                        "20": `Avrupa elektrikli bırakılabilir Avusturya Kolu nüfusu büyük bir "karartmaya" hazırlıyor`,
                        "21": `Romanya basınından bu makale ve benzerleri ann Huge po out The Arm ınterven çoktan müdahale etti: Hazırlanın"veya" Avrupa, bir adım ötede Avusturya karartmaya hazırlanıyor. Etkileri w"Antena 3'ün makalesinin yanı sıra, diğer popüler neeb'ler şunlardır: Cotidianul, Opinia Timi Tim Realitatea.net , Protv.ro , B1TV`,
                        "22": `Romanya basınına ulaşan bilgiler dört an Gerçek şu ki, Avusturya Savunma Bakanlığı, büyük bir elektrik kesintisi durumunda halkı nasıl tepki vereceğini bilmesi için eğitmek için bir kampanya başlattı. Kampanyanın başlangıcı İnstagram Savunma portföyünün sahibi Klaudia Tanner tarafından, "elektrik kesintisinin Avrupa düzeyinde enerji, altyapı ve arzın kesintiye uğraması olduğunu söyleyen bir yorumun yanında işaretlendi - bu gerçekçi bir risk ve aynı zamanda hafife alındı. Bu nedenle hedefimiz cumhuriyetimizin dayanıklılığını a” olarak geliştirmektir`,
                        "23": `Covıd 19'a karşı tedavi ilacı olarak Arbidol satan birden fazla çevrimiçi gönderi`,
                        "24": `Romenler, çok hızlı bir şekilde ortaya çıkan ve kaybolan düzinelerce reklam var`,
                        "25": `başlattıtüm Sağlık Bakanı Bulaşıcı Hastalıklar Komisyonu, protokoldeki bu konumu ve Sars-CoV-2 enfeksiyonunun tedavisinde kullanımının gerçeğini yeniden doğruladı.`,
                        "26": `Covid hastalarını tedavi eden doktorlar, hastaların olumsuz etkileri olabilecek ilaçları almasının tehlikeli olduğunu söyledi b b Tarafından tavsiye edilmem gerekiyor`,
                        "27": `Deveselu kalkanı diplomatik bir utançtır, kalkanın def (anti-ester) ile ilgisi yoktu`,
                        "28": `Gönderi, Romanya'nın NATO'nun insafına kaldığı ve bağımsızlığımızı kaybettiğimiz fikrini destekleyen bir dizi yorumu kendine çekiyor: "TARAFSIZ olmamız gerekiyordu, ancak bir Muz Cumhuriyeti olduk Romanya" veya "Amerikalı'nın NATO'nun insafına kalması uygun olmaz". scooter'larını alıp kalkınma için Rusya ile çalışmamıza izin mi verelim?"ya da ne yaparsam yapayım - "Bu smaç"veya" şu anda Rus uçağını durduracak türden bir füze kalkanı yok"`,
                        "29": `Bu propagandanın pandemiyi artırmadaki rolü, dış karşıtı söylemi yoğunlaştırdı ve bazı sosyal medya kullanıcılarını radikalleştirdi. Rusya'nın Ukrayna'ya yönelik saldırısının geri dönüşü, özellikle Dış karşıtı söylemde de artışa neden oldu.`,
                        "30": `Romanya askeri kuvvetlerini sınırlarda yoğunlaştırıyo`,
                        "31": `Rus basını, bir amatörün kendi arabasında yaptığı bir video kaydına atıfta bulunarak, Moldova Cumhuriyeti ve Ukrayna sınırlarına doğru ilerlerken bir Romen askeri teçhizatı sütunu yakalandığını yazıyor.  Çekimler - başka dezenformasyon için de kullanılıyor - bir bahane `,
                        "32": `Rus medyası görüntülerin Ukrayna medyası tarafından yayınlandığını yazsa da, 33 Nisan'da PMR_History adlı bir Transdinyester telgraf kanalında yer aldılar ve ertesi gün Ukrayna ve Rusya'daki telgraf kanalları ve medyası tarafından alındı. Kayıtta görünen sütun Romanya Ordusuna bile ait değil Aslında, Romen kuvvetlerinin sınıra doğru hareketine ilişkin anlatılara paralel olarak, Amerikalıların Moldova Cumhuriyeti sınırına doğru hareket edecekleri bir başkası ortaya çıktı.  Romanya Savunma Bakanı, video görüntülerinde ordunun teçhizat birimlerini donattığını açıkladı.`,
                        "33": `Bayan Heard, Bay Depp'i Pazartesi günü tekrarlanan fiziksel olaylarla suçladı, Ocak 2015 düğünlerinden sonra Asya'daki Orient Express treninde ona saldırdığını iddia etti, ona çarptığını ve boynundan tuttuğunu iddia etti. uyuyan kompartımanlarında.`,
                        "34": ` Sınırda kişilik bozukluğu teşhisine katılıyorum, " dedi Angelica. Ayrıca De`,
                        "35": `10.000 BL aard`,
                        "36": `Bir bilgisayar programcısı Utku cıa Bunu açıkladı; dikkat çekmek için yeni bir sahte yarattı ve bunu web sitelerine gönderdi ve maalesef web sitesinin editörleri doğru olup olmadığını kontrol etmeden yenisini yayınladı.`,
                        "37": `Başkan Trump, temelde Elon Musk satın almadan önce, aslında gidip satın almasını söyledi çünkü şirketimizin amacı gerçekten insanların aile dostu, güvenli bir ortamda olduğu bir topluluk oluşturmak," dedi Devin Nunes Fox Business Network'te bir görünümde. "Bu, Elon Musk'u bunu yapmaya teşvik etti, çünkü birinin bu teknolojiyi üstlenmesi gerekiyor "Donald Trump, Amerikan halkının sesini geri aldığından ve internetin açık olduğundan emin olabildi`,
                        "38": `Musk, Nunes'u yalanladı' Trump ile doğrudan veya dolaylı olarak hiçbir iletişimim olmadığı bir teette, yalnızca Hakikatte olacağını açıkça belirten hesap Sosyal.”`,
                        "39": `Cocuklar Duymasın dizisinin "Çaycı Hüseyin" i Alpaslan Özmol'ün ölümüyle ilgili iddialar sürekli sosyal medyada yer alıyor. Dedi ki: " Ben ölmedim."`,
                        "40": `Beni nasıl göreceğini, beni nasıl göreceğini sevindirdiğini söyleyerek ölmedin mi?"Bundan bıkmadım, hatta sahneye çıktım.`,
                        "41": `De`,
                        "42": `Kör bir genç 10.000 dolar aard aldı`,
                        "43": `Elon Musk Trump'ı Sessizce reddetti'`,
                        "44": `Sus`,
                        "45": `MİG`,
                        "46": `BURASI ARENA DEĞİL. GİLETTİ, ODESSA'NIN SİPERLERİNDEN YAŞIYOR AMA ASLINDA BURASI BARRA İSTASYONU`,
                        "47": `VATİKAN KARARTMASI: Papa, Çocuk Kaçakçılığı ve Dolandırıcılıktan 80 sayım iddianamesiyle tutuklandı`,
                        "48": `14 Sahte Zeytinyağı Şirketi Ortaya Çıktı - Bu Markaları Şimdi Satın Almayı Bırakın!`,
                        "49": `makale, İtalya'da yaşayan bazı göçmenlerin yerel halka karşı çeşitli suçlar işleyeceğine işaret ediyor;`,
                        "50": `Burada görüntülerin gerçekliği ve kaynak makalenin yazarının kasıtlı olarak göçmenlere ve göçe karşı olması amaçlanan niyeti tartışılıyor. Görünüşe göre görüntüler İtalya'da olan bir şey bile değil, 2015 yılında İtalya'da çekilen bir filmden çekilen bir sahnenin sonucu. Ayrıca videolar, sorgulanan makalenin yayınlanma tarihinden çok önce zaten internet'teydi. `,
                        "51": `aldatmaca, Camiye yönelik çatışma sırasında İtalyan gazeteci Massimo Giletti'nin Ukrayna, Odessa'daki varlığına dair şüpheleri yayıyor. Daha spesifik olarak, Giletti'nin sahneleri doğrudan Napoli bölgesindeki İtalyan metropolitan istasyonu Barra'dan çekiyormuş gibi yapıyor olabileceği sorgulanıyor.`,
                        "52": `Bu videoda, İtalyan gazeteci Massimo Giletti'nin "Non è l'arena" programı için italyan TV kanalı 7'ye canlı yayın yaparken Odessa kentindeki mevcut durumu anlatan röportajı açıkça gösteriliyor. Onunla konuşan birinin sesinin uzaklaşmayı önerdiğini açıkça duyabilirsiniz.`,
                        "53": `Makale, Papa Francis'in işlediği iddia edilen suçları tartışıyor`,
                        "54": `Bu makalede, sızan haberin aynı günü Papa Francis'in Vatikan'daki koltuğunda gerçek varlığını açıkça kanıtlayabiliriz. Bu, önceki dezenformasyon haberlerinin en büyük çürütücüsünü temsil ediyor.`,
                        "55": `makale, kalitesiz zeytinler tarafından üretilmekle suçlanan bazı İtalyan zeytinyağlarını tartışıyor`,
                        "56": `Çürütme, Bertolli'nin resmi web sitesinde yayınlandı, sahte haberlerin şüphesiz nerede ve neden yanlış olduğunu farklı noktalarda tartıştı ve gerçek bilgileri yaydı.`,
                        "57": `ABD, ekonomik kazanç için Ukrayna'daki savaşı kışkırttı`,
                        "58": `Putin iyi bir Hıristiyan, Rusofobik söylemin kurbanı ve Batı tarafından Rusya'ya dayatılan komünizmle hiçbir ilgisi yok`,
                        "59": `Sovyet rejimi Besarabya'daki kıtlığı örgütlemedi`,
                        "60": `Romanya Nüfus Sayımı ve bununla ilgili olası yanlış bilgiler`,
                        "61": `ABD, Ukrayna'daki savaşı başlattıktan sonra AB'yi kolonisine dönüştürüyor. ABD, dünya hakimiyeti için Ukrayna'da Rusya ile vekalet savaşı yürütüyor. Orta Doğu ülkeleri, Rusya'dan yapılan ithalatın yerine Almanya hidrokarbonlarını satmayı reddetti.`,
                        "62": `Her şeyden önce, ABD'nin Ukrayna'daki savaşı aslında her türlü entrikayla başlattığını söyleyen, zaten klasik olan Rus propagandası anlatısını takip ediyor. Bu Kremlin anlatısına göre ABD, Ukrayna'da Rusya'ya karşı vekalet savaşı yürütecek. Açıkçası, ABD Ukrayna'daki savaşı başlatmadı, ancak bu, Şubat ayındaki 24'in Ukrayna topraklarındaki büyük Rus işgalinin sonucudur. Aslında, neredeyse bir yıl önce, Rusya, askeri tatbikatlar bahanesiyle Ukrayna sınırlarında sürekli ve niceliksel olarak daha fazla ordu ve askeri teçhizat topladı. Rusya, 25 Şubat anından önceki son güne kadar herhangi bir işgal planını sürekli olarak reddetti. Ekonomik olarak Rusya, 2021 boyunca Ukrayna'nın işgalinden çok önce doğal gaz fiyatlarının yapay olarak yükseltilmesine katkıda bulundu ve katkıda bulundu. Rusya, 2022'nin dördüncü çeyreğinde 2020'nin aynı dönemine göre Avrupa'ya ihracatını% 26 azalttı ve bu da Avrupa'da Spot piyasada gaz fiyatlarındaki artışı vurguladı. Petrol ve gaz ihracat vergileri ve tarifelerinden elde edilen gelirler, Ocak 2023'te Rusya'nın federal bütçesinin% 45'ini oluşturuyordu. Mevcut piyasa fiyatları göz önüne alındığında, yalnızca AB'ye yapılan Rus boru hattı doğalgaz ihracatının değeri günde 400 milyon dolar. Ham petrol ve rafine ürünlerin toplam ihracat geliri şu anda günlük yaklaşık 700 milyon ABD dolarıdır. Ukrayna'daki savaşın başlamasından bu yana Avrupa'ya giren Rus petrol ve doğalgaz miktarı arttı. Nature dergisinin aktardığı Brüksel merkezli bir düşünce kuruluşu olan Bruegel'e göre, Avrupa yalnızca Mart ayında Rusya'ya petrol ve doğal gaz için yaklaşık 22 milyar avro (27 milyar dolar) ödedi. Dolayısıyla Rusya, yüksek petrol ve doğal gaz fiyatlarından tam olarak yararlanıyor.`,
                        "63": `Vladimir Putin iyi bir Hıristiyan, ancak imajı Russophobia yüzünden acı çekiyor. Bu yanlış anlatılar, Tomis Başpiskoposu Theodosius'un ifadelerinden başlar ve Sputnik tarafından güçlendirilir. Aynı zamanda, Putin ve Rusya'nın Batı tarafından dayatılan komünizmle hiçbir ilgisi olmadığı fikri de destekleniyor.`,
                        "64": `Çok sayıda otoriter lider ve diktatör, popülerliklerini artırmak ve meşruiyet kazanmak amacıyla zaman içinde dindar taraflarını alenen vurgulamaya ve kendilerini dini otoritelerle aynı hizaya getirmeye çalıştılar. Bu anlamda bir örnek, laik bir siyasi hareketin temsilcisi olmasına ve iktidarda olduğu ilk yıllarda Irak toplumunu laikleştirmek için önlemler almasına rağmen, Kuveyt'in işgalinden sonra uluslararası toplumun paryası olduktan sonra optiklerini değiştiren Saddam Hüseyin'in örneğidir. Saddam, Müslüman dünyasının desteğini kazanmak için dini liderlere daha fazla özgürlük verdi, Irak bayrağına "Allah El-Ekber" yazılmasını emretti (kendi el yazısının kullanıldığı söyleniyordu), kanıyla Kur'an yazılsın ve daha fazla cami yapılsın. Diktatörün sergilediği bu dindarlık, işlediği toplu cinayetlerden artık suçlu olmadığı ya da bayrağa Allah el-ekber'i yazdıktan sonra bu suçları işlemekten vazgeçtiği anlamına gelmez - tam tersine, en büyüklerinden bazıları o anı takip etti.`,
                        "65": `Kıtlık Sovyet rejimi tarafından örgütlenmedi ve Moskova kıtlık sırasında Moldova'ya yardım etti. Açlık ve sürgünler Bolşevik terör değil, yalan söyleyen Sovyet karşıtı propagandadır.1947'deki kıtlık, yerel yönetimlerin eylemsizliğinin bir sonucudur, SSCB'nin merkezi liderliği tarafından örgütlenmemiştir.Moldova Cumhuriyeti'nden Batı yanlısı tarihçilerin araştırması önyargılıdır ve gerçek tarihini yeniden yazmayı amaçlamaktadır.`,
                        "66": `Komünist rejim, ssr'nin işgalinin en başından itibaren terörün yeni düzeni aşılamanın en hızlı ve en etkili yöntemi olduğunu gösterdi. Sovyet iktidarının halka karşı gerçekleştirdiği tutuklamalar, sürgünler, suikastların yanı sıra tarihçilere göre organize kıtlık ssr'de 150 ila 350 bin kişiyi öldürdü, Cumhuriyet nüfusunun üçte biri açlık ve hastalıktan etkilendi. Aynı zamanda, 1946-1947 kıtlığı köylüleri kolektivizasyonu kabul etmeye zorladı. Köylülerin Sovyet rejimi tarafından vergilendirilmesi, Besarabya'nın yeniden işgalinden hemen sonra 1944 gibi erken bir tarihte başladı. Genelkurmay yıldan yıla vergi topladı, Sovyet liderliği her aileden ürünlerde (yumurta, yün, peynir, Kızıl Ordu için tahıl) vergi talep etti, aynı zamanda bir miktar para istedi. Devlet ayrıca, halkın yetiştirmediği, onları satın almak zorunda kaldığı diğer ürünleri de talep etti. Parti organları, tarım ürünleri toplama planını yerine getirmeye odaklandı ve insanların gıda rezervlerini talep etti. Bu vergileri ödemeyen ailelere mallarına el konuldu ve aile reisleri Askeri Mahkemece yargılanarak zorla çalıştırılmaya mahkum edildi. Planı gerçekleştirmek için parti aktivistlerine, son rezervlerinin köylülerinin köprülerini kelimenin tam anlamıyla süpüren silahlı askerler eşlik etti. Bu vergiler kırsal kesimde hayatı daha da zorlaştırdı. Sovyet liderliği, nüfusu aç bırakma politikasını kamufle etmek için iklim koşullarını kullandı. Tarihçi Anatolţăranu, 1946'da nüfusun ihtiyaçlarının yarısı olan 365 bin ton tahıl toplandığı ve Besarabya'daki talep planının 255 bin ton olduğu göz önüne alındığında kıtlığın yakın olduğunu iddia ediyor. `,
                        "67": `Nüfus sayımı sonuçlarının isimsiz olacağının belirtildiği Romanya Ulusal İstatistik Enstitüsü'nün (RNIS) bir temsilcisinin descriptionsına dayanarak gazeteci Josefina Pascal, dsö'nün (Dünya Sağlık Örgütü) doğum, sağlık ve ölümle ilgili bilgilere erişim hakkı olarak nüfus sayımının sonucu ve bunları kötü niyetle kullanacak.`,
                        "68": `Bir Avrupa birliği üyesine ait olan RNIS, sonuçları otomatik olarak Eurostat'a iletir ve bu da sonuçları who'ya iletir. DSÖ ayrıca, üye devletlerinin resmi yardımı ile derlenen küresel sağlık ve refahla ilgili geniş bir veri tabanına sahiptir. Dsö'nün Romanya'da genel sağlıkla ilgili istatistikleri kötü niyetle kullanması, amacı küresel sağlığın iyileştirilmesi olan bu örgütün varlığına aykırıdır. Son zamanlarda, onu “Büyük Sıfırlama” ile ilişkilendiren vaxxer karşıtı komploların tercih edilen hedefi kim oldu. Gazeteci, hiçbir kanıtı olmayan basit bir ifadeye dayanarak karalayıcı varsayımlar yapıyor.`,
                    },
                    "activities": {
                        "title": `Etkinlikler`,
                        "0": `Hedefler`,
                        "1": `Gerçekleştirilen faaliyetlerin düzeyini analiz etmek ve her bir ortakla geri bildirim paylaşmak.`,
                        "2": `Gantt diyagramına dayalı gelecekteki faaliyetlerin planlanması.`,
                        "3": `Proje raporu için gerekli belgelerin kontrol edilmesi.`,
                        "4": `Toplantı içeriği:`,
                        "5": `Her bir ortak kuruluşun LTT Ranca'da geliştirilen yöntemleri uygulaması gereken proje faaliyetlerinin dikkatli analizine odaklandık. Pilotluk yöntemleri projenin önemli bir aşamasıdır.`,
                        "6": `Toplantının bir diğer önemli yönü de belgelerin, her bir faaliyetin gerçekleştirildiğine dair kanıtların doğrulanmasıydı.`,
                        "7": `Son olarak, özellikle bu toplantı 1 numaralı entelektüel çıktının gerçekleştirilmesine ve 2 numaralı entelektüel çıktıya geçişe işaret ettiğinden, aşağıdaki faaliyetlerin sunumu çok önemliydi. Çevrimiçi platformun gerçekleştirilmesi için önkoşullar tartışıldı ve platformun gerçekleştirilmesinde ulaşılması gereken bazı hedefler belirlendi.`,
                        "8": `Hedefler`,
                        "9": `Romanya, Türkiye ve İtalya'dan 20 gençlik çalışanı ve öğretmeni dahil ederek, projedeki her bir ortak tarafından geliştirilen metodolojiyi (Eleştirel düşünme ve doğru bilgi için Resmi olmayan metodoloji) denemek için bir çerçeve oluşturun.`,
                        "10": `Önceden oluşturulmuş yöntemleri geliştirmek ve yaygın eğitim yöntemlerinin klasik öğrenme sistemine işbirliği ve uyum araçlarını çeşitlendirmek amacıyla eğitim sırasında yaygın yöntemlerden oluşan bir kuluçka makinesi geliştirin.`,
                        "11": `Önümüzdeki 2 yıl boyunca 4 ortak arasında bir işbirliği stratejisi hazırlayarak kamu eğitim kurumları ve gençlik STK'ları arasındaki sektörler arası işbirliğini teşvik edin.`,
                        "12": `Eğitim içeriği:`,
                        "13": `Faaliyetleri, Gençleri Eğitin stratejik projesinin uygulanmasında yer alan kişiler için eleştirel düşünme ve doğru bilgi alanındaki yeterliliklerin geliştirilmesine odakladık.`,
                        "14": `Eğitimin başlangıcında, her bir ortağın görevi Fikri Çıktı 1 içinde aşağıdaki gibi hazırlamış olması gerekiyordu`,
                        "15": `İtalya:`,
                        "16": `Gençler için doğru bilgiye ve eleştirel düşünmeye odaklanan, resmi olmayan birkaç yönteme sahip bir rehber`,
                        "17": `CRAAP Metodolojisi Üzerine Eğitimsel Kaçış Odası`,
                        "18": `Alternatif dünya haritası`,
                        "19": `Türkiye:`,
                        "20": `Öğretmenler ve eğitmenler için, özel olarak tasarlanmış yaygın olmayan yöntemlerden bir seçki içeren ve bunları sınıflarda nasıl ve ne zaman uygulamaya koyacağına dair descriptionlar içeren metodoloji rehberi.`,
                        "21": `Herkes Gönüllü Olabilir`,
                        "22": `Topla, yarat ve oyna`,
                        "23": `Cinsiyet Klişesi Yok`,
                        "24": `Batıl inançlara kim inanır`,
                        "25": `Rol yapma ve CT ikisi Bir Masalda`,
                        "26": `Romanya (Horezu Lisesi):`,
                        "27": `Eleştirel düşünme süreci için uygulamalı bir araç olarak bir tartışma metodolojisi: Mobil tartışma`,
                        "28": `Romanya (ATDD):`,
                        "29": `Gençlik alanında eleştirel düşüncenin gelişimini desteklemek için etkileşimli oyun: Çölde Hayatta Kalma`,
                        "30": `Her ulusal gruptan katılımcılar, eğitim sırasında pilot modda denenen İO1 kapsamında geliştirilen görevleri konsorsiyumla paylaştılar. Eğitim için çalışma dili ingilizceydi.`,
                        "31": `Hedefler`,
                        "32": `Gerçekleştirilen faaliyetlerin düzeyini analiz etmek ve her bir ortakla geri bildirim paylaşmak.`,
                        "33": `Gantt diyagramına dayalı çok oyunculu etkinlikleri planlama.`,
                        "34": `Proje raporu için gerekli belgelerin kontrol edilmesi.`,
                        "35": `Toplantı içeriği`,
                        "36": `Her bir ortak kuruluşun projenin çevrimiçi platformu için görevi yerine getirmesi gereken proje faaliyetlerinin dikkatli analizine odaklandık.`,
                        "37": `oplantının bir diğer önemli yönü de belgelerin, her bir faaliyetin gerçekleştirildiğine dair kanıtların doğrulanmasıydı.`,
                        "38": `Son olarak Romanya, italya ve Türkiye'de düzenlenecek çok oyunculu etkinliklerin planlanmasını tartıştık. Her ortak için tarihler ve sorumluluklar belirlenmiştir.`,
                        "39": `Hedefler:`,
                        "40": `Gerçekleştirilen faaliyetlerin düzeyini analiz etmek ve her bir ortakla geri bildirim paylaşmak.`,
                        "41": `Gantt diyagramına dayalı gelecekteki faaliyetlerin planlanması.`,
                        "42": `Proje raporu için gerekli belgelerin kontrol edilmesi.`,
                        "43": `Toplantı içeriği:`,
                        "44": `Proje uygulama durumu arttı`,
                        "45": `Gantt Grafiği ve Kilometre Taşları`,
                        "46": `Hedefler ve beklenen sonuçlar`,
                        "47": `Soru-Cevap`,
                        "48": `Proje Yönetim Planı`,
                        "49": `Proje Yönetimi ekibi - konsorsiyum koordinasyon tartışması`,
                        "50": `Proje Yönetim araçları - Metodolojileri ve Prosedürleri, adminproject.eu `,
                        "51": `Teknik Raporlama`,
                        "52": `QBM- Kalite Güvence Komitesi dahil Kalite Güvence ve İzleme Planı`,
                        "53": `Finansal yönetim`,
                        "54": `Bütçe yürütme ve belge raporlaması`,
                        "55": `Finansal yönetim`,
                        "56": `Teknik yönetim`,
                        "57": `IO1: Eleştirel düşünme ve doğru bilgi için resmi olmayan metodoloji`,
                        "58": `IO2: Eleştirel Düşünme için Dijital E-öğrenme Platformu`,
                        "59": `Görev dağılımı`,
                        "60": `İletişim ve yaygınlaştırma Planı`,
                        "61": `İletişim planı durumu ve raporlama`,
                        "62": `Göstergeler`,
                        "63": `Sonraki adımlar`,
                        "64": `Genel Soru-Cevap ve Geri Bildirim`,
                        "65": `Kültürel Ziyaret`,
                        "66": `Hatay Arkeoloji Müzesi (Dünyanın en büyük ikinci arkeoloji müzesi)`,
                        "67": `St.Pierre Kilisesi (en eski Anadolu ve Yakın Doğu yerleşimleri. UNESCO Dünya Mirası kültürel kategorisinde geçici listede yer almaktadır) `,
                        "68": `Habibi Neccar Camii`,
                        "69": `Hatay Katolik Kilisesi`,
                        "70": `Vespasianus Titus Tüneli (Seleucia Pieria şehri için inşa edilmiş eski bir su tüneli. UNESCO Dünya Mirası kültürel kategorisinde geçici listede yer almaktadır) `,
                        "71": `1-2 Ekim 2022 tarihleri arasında Sinaia beldesinde, Avrupa Birliği tarafından Erasmus+ aracılığıyla finanse edilen Gençleri Eğit - KA2 - Örgün eğitime Gayri Resmi köprüler projesi kapsamında ikinci hareketlilik gerçekleşti. Bu ikinci proje toplantısına "Constantin Brancoveanu" Lisesi'nden 5 öğretim elemanı, Türkiye'den 5 öğretim elemanı ve her biri Romanya (ATDD) ve İtalya'dan (Sınır Ötesi) iki gençlik örgütünden 5 üye katıldı. Etkinlik sırasında, örgün veya yaygın eğitim faaliyetleri sırasında çevrimiçi olarak uygulanabilecek yaygın dijital eğitim araçları sunuldu ve pilot uygulama yapıldı. Yöntemler, bağımsız eleştirel düşünmeyi teşvik etme rolüne sahiptir ve proje kapsamında önerilen her dijital eğitim aracı aracılığıyla, her ortak ülke, eleştirel düşünmeyi geliştirme yöntemlerine ve ayrıca yeni doğru bilgi yöntemlerine ve çeşitli faaliyetlerle yanlış bilgilendirmeden kaçınmaya önemli bir katkı sağlamıştır. Katılımcıların ön plana çıkardığı üç kültür akşamı, orta ve uzun vadede etkili iletişim için kültür, medeniyet unsurları ve önemli gelenekleri sundu. Brașov ve Bran Kalesi'ne kültürel bir ziyaret de düzenlendi.`,
                        "72": `Daha önce oluşturulmuş 8 yaygın olmayan yöntemin tümü (Kaçış Odası, Alternatif Dünya Haritası, Tartışma, Herkesin gönüllü olabileceği, Batıl inançlara inanan, Nasreddin Hoca, Topla, Yarat ve Oyna, Çölde Hayatta Kalma) 4'ü tarafından 5 ay boyunca uygulamaya konuldu ve test edildi. projenin pilot aşamasında yerel ve ulusal topluluklarında yer alan ortaklar. Bu aşama çok önemliydi çünkü katılımcılar bu yöntemleri kullanan etkinliklere katıldıktan sonra, kolaylaştırıcılara kendi bakış açılarından kendileri hakkında geri bildirim verdiler. Her kuruluş geri bildirimi topladı ve bunlara göre yöntemlerde değişiklikler yaptı.`,
                        "73": `Test Yöntemleri`,
                        "click": "fotoğraflar için tıklayın",
},
                    "footer": {
                        "1": "Avrupa Komisyonu'nun bu içeriğin üretimine verdiği destek, yalnızca yazarların görüşlerini yansıtan içeriğin onaylanması anlamına gelmez ve Komisyon, içerdiği bilgilerin herhangi bir kullanımından sorumlu tutulamaz.",
                    },
                    "routes": {
                        "Home": "Ana Sayfa",
                        "Non-Formal Methodology": "Yaygın Metodoloji",
                        "Critical Thinking and Disinformation Kit": "Eleştirel Düşünme ve Dezenformasyon Kiti",
                        "Activities": "Etkinlikler",
                        "Critical Thinking Games": "Eleştirel Düşünme Oyunları",
                        "Non-Formal Debates": "Resmi Olmayan Tartışmalar",
                        "Fake News Alert": "Sahte Haber Uyarısı",
                    }

                },
            }

export default tr;
