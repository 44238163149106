import { Box, Grid, Paper, Typography } from '@mui/material';
import { PartnerLogo } from './PartnerLogo';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

interface PartnerMeta {
  imageName: string,
  href?: string
}

const partners: PartnerMeta[] = [{
  imageName: 'educate_youth',
  href: 'https://www.educateyouth.org',
}, {
  imageName: 'atdd',
  href: 'http://www.atdd.ro'
}, {
  imageName: 'belen',
  href: 'http://www.belen.gov.tr'
}, {
  imageName: 'beyond_borders',
  href: 'https://www.facebook.com/beyondbordersitaly/'
}, {
  imageName: 'horezu',
  href: 'http://www.liceulhorezu.uv.ro',
}];

export const PartnerLogos: FC = memo(function PartnerLogosComponent() {
  const { t } = useTranslation()

  return (
    <Box sx={{ p: { xs: 0, md: 10 }, pb: 5 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          className='animate__animated animate__fadeIn fontFamilyMeans'
          variant='h3'
          sx={{ display: 'block', width: '100%', textAlign: 'center', mb: 10, color: 'primary.main', textShadow: '0px 0px 5px #f7bc4a' }}
        >
          {t('home.partners')}
        </Typography>
      </Box>
      <Paper elevation={5} sx={{ p: [0, 10] }}>
        <Grid container spacing={5}>
          {partners.map((partnerMeta: PartnerMeta, index: number) => (
            <PartnerLogo key={partnerMeta.imageName} index={index} {...partnerMeta} />
          ))}
        </Grid>
      </Paper>
    </Box>
  );
})