import { Article } from '../../../../Article/Article';
import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function BeyondBordersArticles() {
  const { t } = useTranslation()

  return (
    <Stack spacing={10}>
      <Article
        articleImage='ARTICLE_1_PHOTO'
        directory='fake_news/beyond_borders'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.45'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title:  t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.49')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.50')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.snopes.com/fact-check/did-migrants-destroy-a-car-in-italy/' target='_blank'>
              https://www.snopes.com/fact-check/did-migrants-destroy-a-car-in-italy/
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_2_PHOTO'
        directory='fake_news/beyond_borders'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.46'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title:  t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.51')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.52')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.la7.it/nonelarena/video/giletti-in-diretta-da-odessa-discute-con-qualcuno-dietro-le-quinte-noi-stiamo-qui-ok-04-04-2022-432507' target='_blank'>
              https://www.la7.it/nonelarena/video/giletti-in-diretta-da-odessa-discute-con-qualcuno-dietro-le-quinte-noi-stiamo-qui-ok-04-04-2022-432507
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_3_PHOTO'
        directory='fake_news/beyond_borders'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.47'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title:  t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.53')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.54')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.vaticannews.va/en/pope-francis/angelus/2021-01/pope-angelus-10-january-2020.html' target='_blank'>
              https://www.vaticannews.va/en/pope-francis/angelus/2021-01/pope-angelus-10-january-2020.html
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_4_PHOTO'
        directory='fake_news/beyond_borders'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.48'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title:  t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.55')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.56')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://organicandnutritious.com/2021/07/26/14-fake-olive-oil-companies-revealed-stop-buying-these-brands-now/' target='_blank'>
              https://organicandnutritious.com/2021/07/26/14-fake-olive-oil-companies-revealed-stop-buying-these-brands-now/
            </Link>
          ),
        }]}
      />
    </Stack>
  )
}