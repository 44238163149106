import { useEffect, useState } from 'react';
import { getStorageUrl } from '../services/storage';

export function useComputedResource(
  resourceName?: string | string[],
  pathTo: string = '',
  extension: string = 'webp',
  deps: boolean[] = [],
  asIs: boolean = false
): string[] {
  const [computedResourceUrl, setComputedResourceUrl] = useState([''])

  useEffect(() => {
    if (asIs || !resourceName || deps.some((dep) => !dep)) {
      return
    }

    (async () => {
      if (resourceName instanceof Array) {
        const images: string[] = await Promise.all(resourceName.map((name: string) =>
          getStorageUrl(`${pathTo}/${name}`)
        ))

        setComputedResourceUrl(images)

        return
      }

      setComputedResourceUrl([
        await getStorageUrl(`${pathTo}/${resourceName}.${extension}`)
      ])
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asIs, resourceName, extension, pathTo, ...deps])

  if (asIs) {
    return [resourceName as string];
  }

  return computedResourceUrl
}