import {
  Box,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  TextField, Typography
} from '@mui/material';
import { Alert, LoadingButton } from '@mui/lab';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { setDocument } from '../../../utils/api/setDocument';
import { FakeNews, FakeNewsRequest } from '../../../types';
import { Send } from '@mui/icons-material';
import ReCAPTCHA from 'react-google-recaptcha';
import { AnimateOnVisibility } from '../../../utils/Animations/AnimateOnVisibility';
import { Hero } from '../../Hero/Hero';
import { useTranslation } from 'react-i18next';

const DEFAULT_FORM: FakeNews = {
  title: '',
  description: '',
  link: '',
  disproof: ''
}

export function FakeNewsForm() {
  const { t } = useTranslation()
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FakeNews>(DEFAULT_FORM)

  useEffect(() => {
    setCanSubmit(!Object.keys(formData).some((key: string) => (
      !formData[key as keyof FakeNews]
    )) && isSubmitVisible);
  }, [formData, isSubmitVisible])

  const handleRecaptchaChange = useCallback((value: string | null) => {
    if (value) {
      setIsSubmitVisible(true);
    }
  }, [])

  const handleRecaptchaExpired = useCallback(() => {
    setIsSubmitVisible(false);
    setCanSubmit(false);
  }, [])

  const handleChangeField = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading || !recaptchaRef.current!.getValue()) {
      return;
    }
    setLoading(true);

    (async () => {
      try {
        await setDocument<FakeNewsRequest>('news', { ...formData, visibility: false })
        setFormData(DEFAULT_FORM);
        setIsSnackBarOpen(true)
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        setIsSubmitVisible(false);
      }
    })();
  }

  const handleBlur = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget
    setFormData({ ...formData, [name]: value.trim() })
  }

  return (
    <Box>
      <Snackbar
        open={isSnackBarOpen}
        TransitionComponent={Grow}
        autoHideDuration={4000}
        onClose={(event: React.SyntheticEvent | Event, reason?: string) => reason !== 'click' && setIsSnackBarOpen(false)}
        message={t('fakenews.submission')}
      >
        <Alert onClose={() => setIsSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {t('fakenews.submission')}
        </Alert>
      </Snackbar>

      <AnimateOnVisibility minTopValue={200} animation={['fadeIn']}>
        <Hero hasFilter={true} imageName='home3' extension='jpeg'>
          <Box sx={{ display: 'flex' }}>
            <Typography
              className='fontFamilyMeans'
              variant='h4'
              component='h4'
              sx={{ p: 4, textAlign: 'center', flex: '6' }}
            >
              {t('fakenews.0')}&nbsp;
              <Box component='span' className='colorEduOrange' sx={{ textTransform: 'capitalize' }}>
                {t('fakenews.1')}
              </Box>
              &nbsp;
              {t('fakenews.2')}
              &nbsp;
              <Box component='span' className='colorEduOrange' sx={{ textTransform: 'capitalize' }}>
                {t('fakenews.3')}
              </Box>!
            </Typography>
          </Box>
        </Hero>
      </AnimateOnVisibility>

      <Paper elevation={2} sx={{ px: [2, 4], py: [1, 6] }}>
        <Box
          autoComplete="off"
          component='form'
          id='fake-news-form'
          sx={{ display: 'box', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
          onSubmit={handleSubmit}
        >
          <Stack spacing={2} sx={{ m: [0, 4] }}>
            <FormControl variant='outlined' required>
              <InputLabel htmlFor="title-input">
                {t('fakenews.4')}
              </InputLabel>
              <OutlinedInput
                value={formData.title}
                disabled={loading}
                name='title'
                inputProps={{ maxLength: 100, minLength: 20 }}
                label='title'
                id="title-input"
                aria-describedby="helper-title-input"
                onChange={handleChangeField}
                onBlur={handleBlur}
              />
              <FormHelperText id="helper-title-input">
                {t('fakenews.5')}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                value={formData.description}
                disabled={loading}
                name='description'
                inputProps={{ maxLength: 600, minLength: 50 }}
                required
                maxRows={5}
                label='Description'
                multiline
                id="description-input"
                aria-describedby="helper-description-input"
                onChange={handleChangeField}
                onBlur={handleBlur}
              />
              <FormHelperText id="helper-description-input">
                {t('fakenews.6')}
              </FormHelperText>
            </FormControl>
            <FormControl variant='outlined' required>
              <InputLabel htmlFor="link-input">
                {t('fakenews.7')}
              </InputLabel>
              <OutlinedInput
                value={formData.link}
                disabled={loading}
                name='link'
                type='url'
                inputProps={{ maxLength: 200 }}
                label='link'
                id="link-input"
                aria-describedby="helper-link-input"
                onChange={handleChangeField}
                onBlur={handleBlur}
              />
              <FormHelperText id="helper-link-input">
                {t('fakenews.8')}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                inputProps={{
                  minLength: 50,
                  maxLength: 1500,
                }}
                type='url'
                value={formData.disproof}
                disabled={loading}
                name='disproof'
                required
                maxRows={5}
                label='Disproof'
                multiline
                id="description-input"
                aria-describedby="helper-description-input"
                onChange={handleChangeField}
                onBlur={handleBlur}
              />
              <FormHelperText id="helper-description-input">
                {t('fakenews.9')}
              </FormHelperText>
            </FormControl>
          </Stack>
          <ButtonGroup sx={{ display: 'flex', justifyContent: 'center', flexDirection: ['column', 'row'] }}>
            {isSubmitVisible && (
              <LoadingButton
                disabled={!canSubmit}
                loadingPosition="end"
                loading={loading}
                variant="contained"
                type="submit"
                form="fake-news-form"
                endIcon={<Send/>}
                sx={{ mt: [4, 0], mx: [6, 0] }}
              >
                {t('fakenews.10')}
              </LoadingButton>
            )}
            <Box
              sx={{
                my: [4, 0],
                mx: ['auto', isSubmitVisible ? 4 : 0]
              }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                theme='light'
                sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY!}
                onExpired={handleRecaptchaExpired}
                onChange={handleRecaptchaChange}
              />
            </Box>
          </ButtonGroup>
        </Box>
      </Paper>
    </Box>
  )
}