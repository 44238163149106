const ro = {
    "translation": {
        "home": {
            "1": "Cine suntem?",
            "2": "Este o inițiativă internațională finanțată de Uniunea Europeană prin programul Erasmus+, Acțiunea Cheie 2 – Parteneriate Strategice în domeniul Tineretului, care include un consorțiu transnațional format din organizații din România, Italia și Turcia",
            "3": "Scopul nostru.",
            "4": "Proiectul se concentrează pe problemele similare întâmpinate în fiecare țară și pe soluția comună, care este dezvoltarea competențelor de gândire critica a generației tinere..",
            "5": "Obiectivele noastre.",
            "6": "Pentru a genera competențe de gândire critică și informare corectă în rândul tinerilor, proiectul a fost inițiat cu o abordare bidimensionala:",
            "7": "Obiectivele EDUcate YOUth are:",
            "8": "Perspectiva educației non-formale",
            "9": "O abordare structurală, căci schimbarea de abordare privind gândirea critică trebuie să fie susținută în mod systemic, printr-o o colaborare trans-sectorială (sectoarele Tineret și educație).",
            "10": `Acest proces de "backtracking" pentru soluționarea problemelor în sectoarele educaționale gemene (formale și non-formale) aduce o abordare strategica problemei, formând un puternic parteneriat transsectorial între ONG urile pentru tineret, instituțiile de învățământ și administrația locala în regiunile din Oltenia-Romania, Bari - Italia și Hatay - Turcia.`,
            "11": "Dezvoltarea și consolidarea competențelor cheie referitoare la gândirea critica si informații corecte",
            "12": "Generarea unui model pilot al unei cooperări transsectoriale între organizațiile pentru tineret și instituțiile de învățământ, multiplicabile la scara larga într-un sistem main-stream, în zonele de gândire critica si informații corecte",
            "13": "Sporirea expertizei organizaționale pentru cei 4 parteneri din proiect prin dezvoltarea metodologiilor non-formale care creează sinergie între cele 2 sectoare",
            "14": "EDUcate YOUth se întinde pe 24 de luni și este divizata în doua perioade principale de timp componente.",
            "15": "Primul an se concentrează pe căutarea locala a nevoilor specifice, interpretarea contextului, stratificarea, dezvoltarea cooperării transsectoriale, și pe metodele de educatie non-formala.",
            "16": "Cel de al doilea an se focusează pe îmbunătățirea  metodelor folosite la scară largă, prin dezvoltarea platformei de e-learning cu acces gratuit la metode de gândire critica pentru toată lumea. Noile metode educaționale generate au fost create și perfecționate cu ajutorul lucrătorilor de tineret, al elevilor și cu feedback-ul profesorilor din toate cele trei țări partenere.",
            "partners": "Partenerii noștri"
        },
        "disinformation": {
            "title": "KIT gândire critică / Informare corectă",
                "description1": "Această secțiune este despre ce înseamnă gândirea critică, dezinformarea și manipularea.",
                "description2": "Care sunt cele mai importante abilități critice și cum să ne îmbunătățim abilitățile de gândire critică?",
                "description3": "Înțelegeți cum funcționează dezinformarea și lipsa informației corecte?",
                "description4": "Care sunt amenințările dezinformării în societatea modernă?",
                "description5": "Ce este metoda CRAPP (în română CRAAS - Circulație, Relevanță, Autoritate, Acuratețe și Scop)?",
                "description6": "Unele site-uri web prezintă articole de opinie ca știri și informații false care se răspândesc în mod deliberat pentru a influența societatea.",
                "description7": "Lăsați deoparte toate prejudecățile și începeți să vă gândiți critic la orice conținut, problemă și surse.",
                "1": "În viața noastră de zi cu zi se vehiculează frecvent informații false.  Noi oamenii nu suntem impecabili. Toată lumea face greșeli. Cu toții avem pierderi de memorie uneori. Detaliile sunt auzite greșit sau uitate. Denaturăm informatii pe care le aflăm prin intermediul rețelelor de socializare sau le auzim la televizor. Din punct de vedere tehnic, distribuirea de informații false este ceea ce faci dacă difuzezi informații false, dar nu știi că acestea sunt false..",
                "2": "Termenul de dezinformare, care combină informația cu cuvântul greșit, care înseamnă „greșit” sau „eronat”, a fost folosit pentru prima dată la sfârșitul anilor 1500. Alți termini frecvenți care folosesc prefixul greșit pentru a desemna „greșit” includ greșeala, greșeala de ortografie și  neînțelegerea.",
                "3": "Desigur, dezinformarea este legată de verbul a dezinforma, care a apărut inițial în scris între anii 1350 si 1400 și înseamnă, “a oferi informații inexacte sau înșelătoare. În concluzie a dezinforma presupune ca aceste informatii false sunt răspândite.",
                "4": "Manipularea presupune ca informații incorecte să fie distribuite în mod intenționat și adesea discret (cum ar fi plantarea unui zvon) în așa fel încât opinia publică este influențată și rezultă în denaturarea adevărului.",
                "5": "De asemenea, se poate referi la „informații deliberat false sau părtinitoare, povești sau fapte fabricate, propagandă”, într-un sens mai larg.",
                "6": "Manipularea este o tehnică comună de spionaj, deoarece este atât de puternică, distructivă și polarizantă. Așa cum au făcut Uniunea Sovietică și Statele Unite în timpul Războiului Rece, de exemplu. Statele au frecvent interesul să trimită infomații false adversarilor lor. Expresia „Campanie de dezinformare” este frecvent utilizată pentru a descrie situația în care o națiune sau un grup coordonează o strategie complexă de răspândire a dezinformării.",
                "7": "Dezinformarea poate veni din propagandă, informația fiind transmisă astfel încât cineva să fie arătat într-o lumină bună sau rea. Dictaturile moderne, precum cea din Coreea de Nord, folosesc dezinvormarea propagandist pentru a insufla poporului lor un sentiment de dreptate și bunătate față de propriul guvern și un sentiment de ură pentru adversarii guvernului. O operațiune de dezinformare pe Internet a vehiculat informații false despre COVID-19. A atribuit sursa epidemiei Statelor Unite, fapt care a fost susținut de Guvernul Chinez, conform Departamentului de Stat al Statelor Unite.",
                "8": "Cuvântul cheie în a stabili distincția dintre manipulare și dezinformare este cuvântul \„scop\”. Ambii termini se aplică diferitelor tipuri de informații inexacte sau incorecte, dar numai manipularea este informație eronată transmisă astfel în mod intenționat. Chiar dacă ar părea evident, dezinformarea și manipularea sunt adesea folosite în mod interschimbabil, deoarece sunt similare și conectate în unele situații.",
                "9": "Folosirea cuvântului manipulare este adecvată atunci când informațiile incorecte sunt diseminate cu intenția de a vătăma, în special dacă se aplică unui Guvern, organizații sau unei persoane publice. Se poate spune manipulare ori de câte ori cineva răspândește informații false în scopul unor interese proprii.",
                "10": "În era digitală, dezinformarea și manipularea sunt omniprezente și includ teorii ale conspirației, propaganda, falsuri profunde, știri false, farse, fraude, manipularea imaginilor în Photoshop  și escrocherii.",
                "11": "Niciodată dezinformarea cât și distribuirea acesteia nu au fost atât de simplu și accesibil de realizat. Exista acum nenumărate programe sociale software automate care răspândesc un articol cu informații false mai mult de 50 de ori pe zi în medie. În orice caz, fac mai mult decât atât,  cu ajutorul traficului de date,  pot sa asigure/sa obțină bani din publicitate.  Recent am început sa vedem efectele pe care această tehnologie le poate avea asupra culturilor și sistemelor noastre democratice.",
                "12": "Modul în care sunt produse dezinformarea și manipularea este direct legat de sursa acesteia. Cine este autorul (autorii)? Care este motivația pentru crearea dezinformării?.",
                "13": "Cine sunt autorii? Ei pot fi:",
                "14": "Independent de conținutul articolului, cineva care vrea să profite",
                "15": "Satiriști care doresc fie sa demonstreze ceva, fie să te distreze, sau ambele",
                "16": "Jurnaliștii săraci sau fără experiență pot produced lucrări necorespunzătoare care încalcă standardele sau etica jurnalistică profesională din cauza tensiunii în mediul de televiziune și a proliferării site-urilor web de știri;",
                "17": "Partizanii care caută să influențeze atitudinile politice și factorii de decizie;",
                "18": "Ușurința tehnologică de a copia, lipi și partaja conținut online a contribuit la proliferarea acestor tipuri de articole. În unele cazuri, articolele sunt concepute pentru a provoca un răspuns emotional și sunt plasate pe anumite site-uri (\„plantate\”) pentru a atrage citoriii să le distribuie pe scară largă. În alte cazuri articolele conținând \„știri false\” pot fi generate și diseminate de \„boți\” – algoritmi de computer care sunt proiectați să actioneze ca niște oameni care împărtășesc informații, și pot face acest lucru rapid, în mod automat.",
                "19": "Există numeroase strategii de combatere a acestui flagel. Educarea cetățenilor cibernetici este întotdeauna cea mai bună cale de acțiune, deși nu este cea mai rapidă sau simplă. Abilitatea de a dezvolta o societate informată și atentă în mediul online implică timp, muncă, răbdare și resurse, la fel ca orice investiție pe termen lung.",
                "20": "O altă bună practică este schimbarea politicilor companiilor de tehnologie care rulează platforme. Deja, se fac modificări la Twitter și Facebook pentru a opri vizualizarea conținutului în sistemele lor cu o mentalitate \„dacă nu auzi de rău, nu vezi rele\”. Pentru ciclul electoral din 2022, Google a interzis și publicitatea politică. Deoarece aceste platforme servesc ca principale canale de distribuție pentru informații false și dezinformare, utilizatorii de internet s-ar putea alătura organizațiilor cu scopul de dezvălui practicile proaste ale tehnologiei.",
                "21": "Autoritățile de reglementare din sectorul tehnologiei pot crea soluții administrative. Dezinformarea trebuie definită și pedepsită, iar legislația are rolul de a opri această crimă contemporană. Dar pentru a pune totul laolaltă, ca în cazul tuturor regulilor, este nevoie de un efort metodic în crearea abilităților necesare pentru punerea lor în practică.",
                "22": "Testul CRAAP este o metodă de evaluare proiectată de Meriam Library California State University, Chico. CRAAP este acronimul pentru Currency, Relevance, Authority, Accuracy and Purpose, adică circulația, relevanța, autoritatea, acuratețea si scopul (CRAAS). Este o listă de întrebări care ajută în evaluarea informațiilor găsite. Conferă mai multă încredere în selectarea surselor care să răspundă așteptărilor și nevoilor.",
                "23": "Campaniile de dezinformare la scară largă reprezintă o provocare majoră pentru Europa și necesită un răspuns coordonat din partea UE, instituțiile UE, platformele online, mass-media de știri și cetățenii UE. Comisia a dezvoltat o serie de inițiative pentru a combate dezinformarea, astfel:",
                "24": "Gândirea critică este autodirijată, raţionamentul autodisciplinat care vizează gradul maxim de calitate rămânând obiectiv. Oamenii care gândesc critic se străduiesc  să trăiască într-un mod sensibil, rezonabil și manierat. În plus, gândirea critică îi ajută pe oameni sa realizeze că oricât de buni gânditori ar fi, por îmbunătății întotdeauna abilitățile lor de raționament și  vor cădea pradă erorilor logice, a iraționalității umane, a prejudecăților, legaturilor, distorsiunilor, convențiilor societale acceptate naiv și tabuuri, interese proprii și interese vastw uneori. În plus, gândirea critică necesită utilizarea abilităților de raționament. Este vorba despre a fi un participant, mai degrabă decât un spectator atunci când vine vorba de invatat. În loc să se bazeze pe intuiție sau instinct, gânditorii critici vor descoperi, evalua și rezolv.",
                "25": "Înțelegerea conexiunilor dintre idei.",
                "26": "Evaluați semnificația și aplicabilitatea argumentelor și conceptelor..",
                "27": "Recunoașteți, construiți și evaluați argumentele.",
                "28": "Recunoașteți inconsecvențele și greșelile logice.",
                "29": "Tratați problemele cu consecvență și sistematic.",
                "30": "Luați în considerare motivele proprii argumente ,greșeli ,sistematic ,ipoteze, puncte de vedere și valori.",
                "31": "Pentru a gândi critic, trebuie să fim capabili să lăsăm deoparte orice noțiuni sau judecăți preconcepute și să studiem doar datele care ni se oferă. În plus, trebuie să fim obiectivi în evaluarea ideilor.",
                "32": "Aproape nicio informație la care avem acces, fie în exterior, fie în interior, nu vine cu nicio asigurare că va dura sau va fi utilă.  Instrucțiunile detaliate pas cu pas pot acționa ca un cadru pentru înțelegerea noastră fundamentală a gândirii critice. Cu toate acestea, nu garantează și nu poate garanta certitudinea, utilitatea sau longevitatea.",
                "33": "Gândirea critică, ca și alte talente soft, nu este ceva ce poți dobândi la o activitate scolara. Mai degrabă, această abilitate este alcătuită dintr-o combinație de abilități interpersonale și intelectuale. Învățarea de a accepta deschiderea minții și aplicarea gândirii analitice pentru procesul dumneavoastră de rezolvare a problemelor este mai important decât dezvoltarea gândirii critice.",
                "title1": 'Informare greșită',
                "title2": 'Dezinformare',
                "title3": 'Cum să folosiți dezinformarea și manipularea',
                "title4": 'Care sunt amenințările dezinformării în societatea modernă?',
                "title5": 'De unde vine?',
                "title6": 'Cum răspundem la dezinformare?',
                "title7": 'Metoda CRAAP (CRAAS)',
                "title8": 'Inițiativa Comisiei Europene',
                "title9": 'Gândire critică',
                "title10": 'Obiectivele gândirii critice',
                "title11": 'Gândirea critică poate fi împărțită în două categorii',
                "title12": 'O persoană cu competențe de gândire critică este capabil să:',
                "title13": 'Să ne îmbunătățim competențele de gândire critică',
                "title14": 'Există anumite metode care pot fi luate pentru a dezvolta abilitățile de gândire critică prin aprofundarea problemei pentru a dezvolta abilitățile de gândire critică prin aprofundarea problemei.',
                "title15": 'Posibile obstacole ce pot apărea?',
                "title16": 'Cele mai importante competențe de gândire critică',
                "title17": 'Să recapitulăm',
                "title18": 'Referințe',
                "34": "Currency (circulația):",
                "35": "Relevance (relevanța):",
                "36": "Authority (autoritatea):",
                "37": "Accuracy (acuratețea) :",
                "38": "Purpose (scopul):",
                "39": 'Analizați dacă data publicării informației este potrivită pentru proiectul dumneavoastră. Care sunt drepturile de autor,publicația și data apariției acesteia? De ce este sau nu importanta data pentru mesajul sau conținutul sursei?Informația  este actualizată conform subiectului?',
                "40": 'Stabiliți aplicabilitatea informațiilor în cazul situației dumneavoastră. Pentru care public sau la ce nivel corespunde informația (public larg, experți/savant etc.)? Explicați de ce ati implica sau nu informațiile din această sursă în proiectul dumneavoastră..',
                "41": 'Stabiliți dacă autorul, creatorul și editorul informațiilor este bine informat. Cine este autorul, creatorul sau editorul sursei, sau ce organizație este responsabilă pentru sursă? Sunt disponibile informatii de contact? Cum știți dacă autorul este un expert pe subiect? (de exemplu examinați acreditările, experiența și/sau afilierea organizatională ale autorului)? De unde provine finanțarea pentru cercetare, daca este relevant',
                "42": 'Determinați fiabilitatea, veridicitatea și corectitudinea conținutului. Ce indicii vedeți în privința calității cercetării? Ce fel de limbaj, imagini și ton este folosit (de exemplu emotional, obiectiv, professional etc.)? Dovezile susțin premisele/afirmațiile și concluziile? Sunt faptele și afirmațiile documentate sau citate în text, ca note sau într-o bibliografie?',
                "43": `Determinați motivul apariției informațiilor. De ce a fost scrisă această sursă (de exemplu pentru a informa, a preda, a distra, a convinge)? Cum ar putea afilierea autorului să afecteze punctual de vedere, înclinația sau potențiala părtinire a sursei? Cum ar putea publicul vizat să afecteze punctul de vedere asupra informațiilor? Ce concluzii sunt prezentate? Sunt informațiile complete? Se exclude ceva major? Cum se compară această resursă de informare cu altele pe același subiect?',Comunicarea privind “abordarea dezinformării online: o abordare europeană“ este o colecție de instrumente pentru a combate dezinformarea și pentru a asigura protecția valorilor UE;`,
                 "44": `Comunicarea Comisiei “Combaterea dezinformării online – O abordare Europeană” reprezintă o colecție de instrumente pentru combaterea dezinformării și protejarea valorilor europene;`,
                "45": `Planul de acțiune privind dezinfomarea urmărește să consolideze capacitatea și cooperarea UE în lupta împotriva dezinformării;`,
                "46": `Planul de acțiune pentru o democrație europeană elaborează orientări privind obligațiile și responsabilitatea platformelor online în lupta împotriva dezinformării;`,
                "47": `Codul de practici privind dezinformarea din 2018 a fost pentru prima dată la nivel mondial când industria a acceptat în mod voluntar standard de autoreglementare pentru combaterea dezinformării. Acesta a urmărit atingerea obiectivelor stabilite în Comunicarea Comisiei prezentată în aprilie 2018.`,
                "48": `Programul de monitorizare a dezinformării COVID-19, realizat de semnatarii Codului de practici, a acționat ca o măsură de transparență pentru a asigura responsabilitatea platformelor online în abordarea dezinformării.`,
                "49": `EDMO este un observator independent care reunește verificatori de fapte și cercetători academici cu experiență în domeniul dezinformării online, platformelor de rețele sociale, media, conduse de jurnaliști și practicieni de alfabetizare media`,
                "50": `Codul de practici consolidat privind dezinformarea, semnat la 16 iunie 2022, aduce în joc un set larg de angajamente voluntare pentru a combate dezinformarea.`,
                "51": `Să cultive integritarea intelectuală`,
                "52": `Umilință intelectuală`,
                "53": `Civism intelectual`,
                "54": `Empatie intelectuală`,
                "55": `Sentimentul dreptății în gândire`,
                "56": `Autosuficiența în logică`,
                "57":  `Un set de talente pentru crearea și prelucrarea informațiilor și a credințelor`,
                "58": `Obiceiul de a folosi astfel de abilități pentru a influența conduita, care se bazează pe angajament`,
                "59": `Determinați sursa unei probleme.`,
                "60": `Faceți presupuneri despre motivul pentru care există problema și cum să o remediați.`,
                "61": `Efectuați cercetări pentru a culege cunoștințe sau date despre subiect.`,
                "62": `Organizați și sortați datele și constatările.`,
                "63": `Creați și implementați soluții.`,
                "64": `Analizați ce soluții au funcționat sau nu.`,
                "65": `Identificați modalități de îmbunătățire a soluției.`,
                "66": `Gândirea analitică: Evaluarea faptelor dintr-o varietate de surse pentru a obține cele mai bune concluzii este o parte a gândirii critice. Gândirea analitică le permite oamenilor să respingă prejudecățile în favoarea strângerii și analizării datelor pentru a obține concluzia optimă.`,
                "67": `Gândire deschisă: Acest talent de gândire critică vă permite să evaluați și să procesați datele pentru a ajunge la o judecată obiectivă. Permiterea propriilor prejudecăți să meargă și  unei decizii bazate pe toate dovezile este o parte a procesului gândirii critice. .`,
                "82": `Rezolvarea problemelor: Gândirea critică este un aspect important al rezolvării problemelor, deoarece subliniază atingerea concluziei optime pe baza tuturor faptelor disponibile. Gândirea critică, atunci când este făcută corespunzător, vă poate ajuta să faceți față oricărei dificultăți, de la o provocare de afaceri pană la probleme de zi cu zi.`,
                "68": `Auto-reglementarea: Gândirea critică, atunci când este făcută corespunzător, vă poate ajuta să faceți față oricărei dificultăți, de la o provocare de afaceri până la probleme din viața de zi cu zi. Trebuie să contestați informațiile pe care le dețineți și judecățile pe care le favorizați pentru a fi un gânditor critic eficient – numai atunci puteți ajunge la cea mai buna concluzie.`,
                "69": `Observarea: Gânditorii critici folosesc abilitățile de observare pentru a căuta lucruri care nu sunt evidente. Trebuie sa fiți capabil să acceptați diverse puncte de vedere pentru a fi un gânditor critic și trebuie sa fiți capabil să recunoașteți posibilele probleme folosind abilitățile de obervare.`,
                "70": `Interpretarea: Gânditorii critici înțeleg că nu toate datele sunt create în mod egal. Este esențial să evaluezi dacă informațiile sunt valoroase și relevante pentru situația ta, pe lângă achiziționarea lor. În acest fel, veți putea trage cele mai utile concluzii din informațiile pe care le-ați adunat.`,
                "71": `Evaluarea: Rareori există o soluție aparentă atunci când încercați să răspundeți la un subiect dificil. În ciuda faptului că gândirea critică te încurajează să îți lași prejudecățile deoparte, trebuie să poți trage cu încredere o concluzie bazată pe informațiile pe care le deții.`,
                "72": `Comunicarea: De asemenea, trebuie să informați și alte părți interesate despre alegerea dumneavoastră după ce a fost făcută. Prezentarea datelor și susținerea concluziei dumneavoastră este o parte importantă a comunicării profesionale eficiente, mai ales dacă există mai multe răspunsuri alternative.`,
                "73": `În fiecare scenariu, gândirea critică urmărește să obțină cele mai mari rezultate potențiale. Pentru a face acest lucru, este necesară colectarea și evaluarea datelor din cât mai multe surse diverse posibile.`,
                "74": `Gândirea critică necesită o examinare amănunțită și adesea neplăcută a punctelor forte, deficiențelor și preferințelor tale unice, precum și influența potențial a acestora asupra judecăților pe care le poți face.`,
                "75": `Gândirea critică necesită dezvoltarea și utilizarea previziunii în măsura în care este fezabilă.`,
                "76": `Implementarea deciziilor bazate pe gândire critică necesită o evaluare a rezultatelor prospective, precum și a metodelor de evitare sau cel puțin de reducere a efectului unor posibile consecințe nefavorabile.`,
                "77": `Gândirea critică presupune analizarea rezultatelor deciziilor luate și, atunci când este fezabil, implementarea schimbărilor`,
                "78": `Critical Thinking | SkillsYouNeed. (n.d.). Skills You Need. Retrieved June 13, 2022, from https://www.skillsyouneed.com/learn/critical-thinking.html`,
                "79": `Doyle, A. (2022, March 15). Critical Thinking Definition, Skills, and Examples. ThoughtCo. Retrieved June 13, 2022, from https://www.thoughtco.com/critical-thinking-definition-with-examples-2063745`,
                "80": `Scriven, M., & Paul, R. (n.d.). Defining Critical Thinking. Foundation for Critical Thinking. Retrieved June 13, 2022, from https://www.criticalthinking.org/pages/defining-critical-thinking/766`,
                "81": `10 Top Critical Thinking Skills (And How to Improve Them). (2022, May 25). Indeed. Retrieved June 13, 2022, from https://www.indeed.com/career-advice/career-development/critical-thinking-skills`,
        },
        "games": {
            "title": "Jocuri de gândire critică",
                "description": "Testează-ți abilitățile de gândire critică cu ajutorul un chestionar amuzant și interactiv numit „Realitate sau opinie?” unde trebuie să te decizi  cât mai repede în ce categorie se încadrează diferite secțiuni din diverse articole de pe internet.",
                "description2": "A doua parte te provoacă să joci 10 jocuri de gândire critică care te fac să gândești într-un mod neconvențional, să îți testeze creativitatea vizuală și scrisă și să te ajute să îți dezvolți abilitățile de muncă în echipă. Bucură-te de acestea individual, în perechi,  în grupuri mici sau mari. .",
                "download": "Vezi documentul PDF",
                "startMessage": "Realitate sau opinie?",
        },
        "debates": {
            "title": "Dezbateri nonformale",
                "description1": "Această secțiune va ușura acordarea accesului dezbaterilor online pentru cei care sunt interesați în a-și îmbunătăți abilitățile, gândirea critică în timp ce lucrează într-o echipa și se sprijină unul pe altul cu scopul găs.",
                "description2": "Temele dezbaterii sunt interesante și provocatoare potrivite pentru tineri cu vârste și background diferit.",
                "item1": {
                "title": "CEI CARE AU PLECAT DIN ȚARĂ DE MAI MULT DE 15 ANI AR TREBUI SĂ AIBĂ RESTRICȚIONAT DREPTUL DE A VOTA",
                "description": " Meciul a avut moțiunea ”CEI CARE AU PLECAT DIN ȚARĂ DE MAI MULT DE 15 ANI AR TREBUI SĂ AIBĂ RESTRICȚIONAT DREPTUL DE A VOTA”. Meciul s-a jucat conform formatului WS, cu speech-uri mai scurte. Jucătorii sunt elevi ai Liceului ,,Constantin Brâncoveanu” din orașul Horezu."
            },
            "item2": {
                "title": "CURSURILE DE SUPRAVIEȚUIRE ÎN VREME DE CRIZĂ MAJORĂ AR TREBU",
                "description": "Activitatea presupune un meci între o echipă de elevi și o echipa de profesori. Moțiunea meciului este,, CURSURILE DE SUPRAVIEȚUIRE ÎN VREME DE CRIZĂ MAJORĂ AR TREBUI INTRODUSE ÎN ȘCOALĂ”. Meciul se joacă conform formatului WS, cu speech-uri mai scurte.. La final, doar două decizii sunt date, decizia finală fiind a fiecărei persoane care urmărește meciul."
            },
            "item3": {
                "title": " ȘTIRILE ONLINE AR TREBUI CENZURATE ÎN SITUAȚII DE CRIZĂ",
                "description": "Filmulețul arată cum poate fi pregătită și desfășurată online o activitate de dezbatere. În faza de organizare a activității, se formează echipele, se reamintesc regulile jocului și se creează o atmosfera potrivită pentru activitate. Vorbitorii sunt împărțiți în două camere și se acordă timp pentru pregătirea strategiei echipelor, rolurile vorbitorilor în echipă. Meciul care se joacă are moțiunea  ,, ȘTIRILE ONLINE AR TREBUI CENZURATE ÎN SITUAȚII DE CRIZĂ”. După meci, deciziile publicului au fost diferite, dar fiecare privitor a putut decide ce echipa a câștigat, în funcție de cum au fost convinși de o echipa sau de cealaltă."
            },
            "item4": {
                "title": "FOLOSIREA INSTRUMENTELOR DIGITALE PREZINTĂ MAI MULTE AVANTAJE DEC T DEZAVANTAJE ÎN TIMPUL ORELOR DE CURS",
                "description": "Pe parcursul acestei sesiuni au fost respectați toți pașii pe care-i implică acest tip de activitate. Regulile jocului le-au fost prezentate din nou participanților, apoi s-a realizat un brainstorming pentru a se alege moțiunea după care vor juca. Echipele au fost organizate astfel încât în fiecare să conțină câte  un membru din cele trei țări participante. Li s-a dat timpul necesar pentru pregătirea documentării și a speech-urilor. Moțiunea pe care se joacă este  „FOLOSIREA INSTRUMENTELOR DIGITALE PREZINTĂ MAI MULTE AVANTAJE DECÂT DEZAVANTAJE ÎN TIMPUL ORELOR DE CURS”."
            }
        },
        "methodologies": {
            "title": "Metodologie non-formală",
            "description": "În această secțiune veți găsi 8 metode non-formale realizate în cadrul proiectului, care reușesc să dezvolte abilitățile de gândire critică ale tinerilor. Toate cele 8 metode sunt prezentate în detaliu astfel încât să le poți folosi în activități cu prietenii sau cu grupurile țintă cu care lucrezi. Metodele au fost testate și pilotate în cadrul proiectului EDUcate YOUth, cu un grup țintă de peste 300 tineri.",
            "Escape Room": "Escape Room",
            "Alternative World Map": "Harta alternativă a lumii",
            "Debate": "Dezbateri",
            "Anyone Can Volunteer": "Oricine poate fi voluntar",
            "Who Believes in Superstition?": "Cine crede în superstiții?",
            "Nasreddin Hodja": "Nasreddin Hodja",
            "Collect Create and Play": "Colectează, creează și joacă",
            "Survival in the Desert": "Supraviețuire în deșert",
            "Booklet": "Booklet",
            "Methodological context": "Context metodologic",
            "Administrative informations": "Informații administrative",
            "Description of the methodology": "Descrierea metodologiei",
            "Evaluation and debriefing": "Evaluare și debriefing",
            "Feedback": "Feedback",
            "Annexes": "Anexe",
            "Preparation of the group" : "Pregătirea grupului",
            "Implementing activities": "Implementarea activităților",
        },
        "hoca": {
            "1": "Folosind această metodă de role-play, dorim ca studenții, în special genrația Z, să-și dezvolte gândirea critică prin activități non-formale, parcurgând povești istorice și comice, anecdote (Nasreddin Hodja tales și basme (La Fontaine Tales). Rolurile pot fi interpretate într-un mod anacronic, depinzând de creativitatea jucătorilor, cu alte cuvinte, decorul și costumele pot fi adaptate secolului 21. Prin folosirea acestei metode, jucătorii vor arăta empatie, își vor însuși valori morale și vor gândi flexibil, creativ și original. Prin intermediul acestei metode își vor evalua modul de a gândi, își vor exprima puncte de vedere și sentimente ți își vor dezvolta abilitățile de comunicare și de limbaj. În timp ce unii interpretează rolurile, alții privesc și analizează conținutul prezentat într-un mod activ, deoarece ”actorii” le pot pune întrebări, pentru a le atrage atenția. De asemenea, profesorul poate schimba spontan actorii, astfel încât publicul să rămână conectat la poveste. În acest sens, vom folosi metoda teatrului forum, fiecare participant putându-se implica.",
                "2": `Timp: total 45 min: 15 min pentru pregătirea rolurilor, 20 de minute pentru joc; 10 min pentru discuții0 (5 min în grupuri reduse și 5 minute pentru impresii ale întregului grup)`,
                "3": 'Număr de jucători: depinde de grup; metoda poate fi implementată cu minimum 10 jucători și maxim 30, care vor fi împărțiți în grupuri.',
                "4": 'Cerințe legate de spațiu: nu este necesară o locație specială, este suficient un spațiu cu puțin zgomot, pentru ca discuțiile să se poată desfășura, un spațiu non-formal, comportabil.',
                "5": 'Instrumente necesare: Pentru a-și scrie rolurile, jucătorii au nevoie de hârtie și pixuri',
                "6": 'Resurese umane: Un povestitor care, de asemena, va lua notițe. ',
                "7": `Cum se joacă:`,
                "8": `Mănâncă, costumul meu, mănâncă! Ei te respectă pe tine, nu pe mine!`,
                "9": `În cartierul unde locuiește Nasreddin Hodja se desfășoară o nuntă. Gazda nunții îl invită pe Hodja, de asemenea. Nasreddin Hodja este foarte fericit că a fost invitat. El își spune: "Slavă Domnului, nu m-au uitat." Se îndreaptă spre nuntă dar poartă hainele pe care le poartă în fiecare zi. Hainele lui Hodja sunt curate dar vechi, banale. Când intră în sala nunții, nimeni nu îi dă atenție. Așa că Nasreddin Hodja se întoarce acasă și se îmbracă cu cel mai nou și frumos costum al lui și se întoarce la nuntă, așa că Nasreddin Hodja atrage atenția tuturor. Îi oferă cel mai bun loc la masă și îl tratează ca și când ar fi rege. Nasreddin Hodja își dă jos costumul și il pune în supă, salată și în felul principal, spunându-și`,
                "10": `“Mănâncă, costumul meu, mănâncă! Ei te respectă pe tine, nu pe mine!!!”`,
                "11": `Iată câteva sfaturi pentru punerea în scenă a poveștii`,
                "12": `Îi putem ruga pe studenți să reflecteze puțin, scriind propriile sentimente și idei generate de ideea poveștii.`,
                "13": `Pentru a utiliza metoda jocului de rol eficeint, trebuie să împătțim participanții în grupuri mai mici.`,
                "14": `De exemplu, dacă avem un grup de 30 de participanți, prima dată îi împărțim în grupuri de doi sau mai mulți, în funție de cum dorește profesorul, unii vor interpreta rolurile iat unul va fi povestitorul.`,
                "15": `Grupurile vor avea număr egal de membri, un narator și celilalți vor fi actorii. Facilitatorul va alege fie povestea lui Nasreddin Hodja, fie povestea La Fontaine și va printa sau va trimite prin e-mail scenariile, studenților.`,
                "16": `Vor avea la dispoziție aproximativ 17 minute pentru a-și pregăti rolurile, iar celelalte 18 minute vor fi folosite pentru jocul, în sine, restul de participanți vor fi spectatori dar aceștia vor primi întrebări din partea actorilor, în timpul scenei, pentru ca toată lumea să fie implicată. Profesorul poate, de asemenea, să întrerupă jocul pentru a schimba pe cineva din piesă, cu cineva din public. La final, vor exista 19 minute pentru a discute lecțiile morale ce se desprind din poveste.`,
                "20": `În primele 5 minute, fiecare grup va discuta separat despre morala poveștii, se poate discuta în format pro și contra și se poate răspunde la întrebări ca: Ce ne va transmite această poveste? Cum ai ajuns la această idee? Ce ai face dacă ai fi în locul lui Nasreddin Hodja?`,
                "21": `După aceasta, în ultimele 6 minute, ne putem așeza cu toții ăntr-un cerc și fiecare participant poate spune câteva cuvinte despre ceea ce a simțit și/sau ce au învățat jucând acest joc.`,
                "22": `Așadar, cu aceste întrebări, actorii vor capta atenția spectatorilor iar aceștia vor fi implicați în activitate într-un mod activ.`,
                "23": `Povestea lui Nasreddin Hodja, de mai sus, este un exemplu de joc de rol pe care îl putem folosi.`,
                "24": `Acum, haideți să vedem cum putem pune în scena această idee (această parte doar oferă sugestii despre cum se poate juca, dar creativitatea ta poate modela jocul) :`,
                "25": `După cum am menționat, participanții vor citi poveste și își vor alege rolurile, unul fiind Nasreddin Hodja, celălalt fiind gazda nunții …etc.`,
                "26": `Trebuie, de asemenea să existe și un narator.`,
                "27": `Să începem cu rolul naratorului, acesta va începe povestea, folosind un ton empatic, pentru a atrage atenția: ”Este o zi însorită și Nasreddin Hodja se plimbă aproape de casa sa”…în acest moment Nasreddin Hodja și gazda nunții intră pe scenă …își încep dialogul, (acesta va respecta povestea dar, bineînțeles, puteți adăuga conținut)`,
                "28": `în timp ce aceștia își joacă rolul….să ne amintim a doua parte când Nasreddin Hodja își schimbă hainele și își pune cel mai nou și frumos costum  ….actorul va rosti spontan către spectatori: “Salutări, tuturor! Vă place costumul meu?`,
                "29": `La ce se gândesc studenții după ce s-a jucat povestea?`,
                "31": `Ce ți-a atras atenția?`,
                "32": `Tu ce ai fi făcut dacă ai fi fost Nasreddin Hodja?`,
                "33": `Care este cel mai important moment din poveste?`,
                "34": `Ghidarea elevilor să gândească folsind brainstormingul, plecând de la întrebări ca "Are Nasreddin Hodja dreptate?"`,
                "35": `Cu aceste întrebări, elevii vor exersa auto-reflecția.`,
                "36": `Această parte va fi făcută de către facilitator, la sfârșit.`,
        },
        "superstition": {
            "1": "Superstițiile fac parte din viața noastră de zi cu zi. Ele au rădăcini culturale adânci. Prin această sesiune, studenții vor avea șansa să își descopere propriile viziuni asupra elementelor culturale din societate. Sesiunea include elemente importante specifice gândirii critice, cum ar fi analiza, sintetizarea, furtuna de idei. Mai mult, lecția combină activități bazate pe teoria inteligenței multiple, a lui Gardner. Dezbaterile sunt un mod ideal pentru tineri de a descoperi ambele laturi ale unui argument și de a-și dezvolta abilitățile de comunincare. Sunt, de asemena, ușor de aplicate în clasă, iar ideile care apar în discuții, pot fi detaliate în lecții ulterioare. Sunt diferite moduri de a organiza o dezbatere în clasă așa că poți să foloseșto orice format vrei. ",
                "2": "Timp: 45 de minute: 5 minute pentru introducerea temei; 25 de minute pentru dezbatere; 15 minute pentru evaluare și debriefing",
                "3": "Număr de participanți: maximum 20 de studenți",
                "4": "Cerințe legate de spațiu:  Trebuie să se desfășoare înăuntru.",
                "5": "Instrumente necesare: Un pix și o coală de hârtie pentru grupurile de studenți, flashcarduri (vezi anexa 1), telefoane mobile sau tablete pentru realizarea cercetării pe subiect",
                "6": "Resurse umane: Un facilitator este necesar pentru realizarea activității.",
                "7": "Aranjați participanții în semicerc. Atârnați harta într-o zonă ascunsă a încăperii  să nu fie văzută de participanți. În timp ce unul dintre educatori descrie sarcina, celălalt împarte bilețele  și creioane participanților,",
                "8": `Profesorul vine la clasă cu un obiect care reprezintă simbolul unei superstiții, cum ar fi un trifoi cu patru foi sau o potcoavă și îi întreabă pe studenți ce simbolizează acel obiect.`,
                "9": `Profesorul arată câteva flshcarduri (vezi apendix 1) clasei, în care câteva imagini reflectă superstiții comune, cum ar fi pisica neagră.`,
                "10": `Studenții sunt lăsați câteva secunde să discute imaginile împreună și apoi stabilesc care este tema lecției. Răspunsurile pot varia, dar probabil vor concluziona că se va discuta despre noroc sau despre ghinion.`,
                "11": `În acest moment, profesorul poate explica termenul ‘superstiție’ dacă există persoane care nu îl cunosc. Definiția poate fi scrisă pe tablă sau rostită oral.`,
                "12": `Odată ce tema lecției a fost explicată, profesorul roagă studenții să împartă imaginile în cele care simbolizează noroc și cele care simbolizează ghinion, arătându-le pe rând. (Dacă clasa este formată din mai multe naționalități, răspunsurile pot depinde de fiecare țară .)`,
                "13": `Strudenților li se prezintă faptul că cineva poate fi e acord sau poate contesta o idee, dar pot fi conviși să își schimbe opinia.`,
                "14": `Profesorul așează doua indicatoare, acord/dezacord, în părți opuse ale clasei.`,
                "15": `Studenții sunt rugați să stea între cele două semne.`,
                "16": `Profesorul spune/scrie afirmația “Există o motivație logică în spatele fiecărei superstiții.”.`,
                "17": `Studenții pot rămâne în mijloc sau se pot deplasa către semnele acord/dezacord, în funcție de opiniile lor, în legătură cu această afirmație.`,
                "18": `Studenții cu aceleași opinii formează un grup. Profesorul poate decide numărul de membri ai unui grup, daca sunt prea mulți. Dacă există doar un singur student, într-o tabără, el poate lucra singur sau studenții îl pot provoca pe profesor.`,
                "19": `Profesorul dirijează conversația și pune întrebări, atunci când sunbt necesare.`,
                "20": `După aceasta, studenții sunt lăsați să își găsească argumente pentru a-și apăra ideile. (5 minute) Ei pot căuta statistici pe internet sau pot folosi experința personală.`,
                "21": `Se reîntâlnesc la semnele lor și încearcă să îi convingă pe ceilalți, din colțul opus. Fiecare student își poate apăra partea în nu mai mult de un minut.`,
                "22": `Dacă unii sunt de acord cu ceea ce spun ceilalți, se pot apropia spontan de semnul celorlalți.`,
                "23": `Studenții sunt rugați să descrie activitatea folosind un cuvânt și ce ar schimba.`,
                "24": `Profesorul adună răspunsurile lor.`,
                "25": `Studenții care se află încă în mijloc pot pune întrebări, pentru a fi convinși. Profesorul roagă studenții să evalueze ideea opusă, în armonie, în grup,, în ceea ce privește credibilitatea argumentelor.`,
                "26": `Studenții sunt rugați să răaspundă unor întrebări, folosind Menti, pentru a realiza evaluarea activitații. Întrebările sunt:`,
                "27": `“Ți-ai schimbat opinia despre superstiții?”`,
                "28": `“De ce crezi că sunt diferențe în ceea ce privește superstițiile, în diferite culturi?”`,
        },
        "volunteer": {
            "1": "Această activitate presupune luarea deciziei asupra căror activități de voluntariat pot fi făcute conform trăsăturilor individuale. Participanții vor lua o decizie în timp ce hotărăsc activitățile de voluntariat corespunzătoare personajelor care le-au fost prezentate, vor justifica aceste alegeri, vor face inferențe în legătură cu posibilele rezultate ale deciziilor lor, vor exprima presupunerile pe care se bazează deciziile lor și vor identifica decizii alternative. În același timp, se urmărește, în mod implicit, obiectivul de a face elevii să realizeze că fiecare poate face ceva pentru societate prin respectarea diferențelor individuale și poate fi sensibil la problemele legate de mediul înconjurător.",
                "2": "Timp: 50 de minute",
                "3": "Numărul jucătorilor: 3 grupe (Ar trebui să fie un număr egal de elevi în fiecare grupă, sau cel puțin aproape ca număr.)",
                "4": "Cerințe legate de spațiu: Nu contează unde se desfășoară activitatea atâta timp cât fiecărei grupe i se permite să lucreze interactiv.",
                "5": "Instrumente Necesare: “Lista cu Voluntari” (Anexa-1), “Lista cu personaje” (Anexa-2), “Formularul de Evaluare” (Anexa-3), foarfeci, lipici, pixuri",
                "6": "Resurse umane: responsabilitățile profesorilor și ale elevilor din timpul activității sunt explicate în cele ce urmează: Profesorii ar trebui: să transmită explicit instrucțiunile, să ofere elevilor materialele necesare, să fie eficient la gestionarea timpului și elevii ar trebui: să se asigure că toate personajele sunt prezente în lista de voluntari dată, să răspundă la toate întrebările în timp ce prezintă deciziile, să fie sinceri și imparțiali în timp ce completează formularul de evaluare oferit pentru evaluarea grupului care prezintă., să se asigure că lucrează în colaborare.",
                "7": `Pregătirea grupului:`,
                "8": `(5 minute) Profesorul îi întreabă pe elevi dacă s-au oferit voluntari vreodată. Elevii care au făcut voluntariat înainte împărtășesc ce fel de voluntariat au făcut și experiențele lor acolo. Dacă nu există elevi în grup care să fi făcut voluntariat înainte, pot fi discutate subiecte precum motivul pentru care se desfășoară activități de voluntariat, rolul acestor activități în societate și impactul lor pozitiv asupra voluntarilor.`,
                "9": `Activități de implementare:`,
                "10": `(Prima parte - 20 de minute; A doua parte – 20 de minute.)`,
                "11": `În prima parte:`,
                "12": `În a doua parte:`,
                "13": `Ieșirea din activitate:`,
                "14": `În această activitate, elevii își vor evalua colegii ca grup. În acest scop, vor folosi Formularul de Evaluare (Anexa-3) care le va fi înmânat în cadrul prezentărilor de grup. Fiecare elev va evalua alte grupe în afară de grupul său, cu alte cuvinte, fiecare elev va face două evaluări. Fiecare elev ar trebui să asculte cu atenție prezentările grupurilor și să completeze formularul de evaluare în termen de 1 minut după încheierea prezentărilor. Elevii ar trebui să-și amintească că evaluează grupul, nu prezentatorul. Informații mai detaliate despre procesul de evaluare sunt prezentate în secțiunea următoare.`,
                "15": `Criteriile care vor fi utilizate în evaluarea prezentării elevilor sunt următoarele:`,
                "16": `Ceilalți membri ai grupului care vor evalua prezentarea vor folosi Formularul de Evaluare (Anexa-3) întocmit conform acestor criterii. Profesorii ar trebui să dea aceste formulare fiecăruia dintre membrii grupului în timpul prezentării. Formularul de evaluare include cinci afirmații bazate pe aceste cinci criterii. Elevii își vor evalua acordul cu aceste cinci afirmații de la 2 la 6. 3 înseamnă “dezacord total” și 17 înseamnă “acord total”. Apoi, fiecare elev va calcula punctajul total al grupului prin adunarea acestor puncte. Cel mai mic punctaj pe care îl poate acorda fiecare elev pentru o grupă este 18, iar cel mai mare punctaj este 25. Astfel, se vor putea cuantifica rezultatele activităților și rezultatul învățării.`,
                "19": `La finalul evaluării, profesorul poate organiza un concurs pe baza punctajelor totale sau poate folosi aceste scoruri pentru a măsura succesul academic al elevilor.`,
                "22": `Este important să obțineți feedback de la elevi după activități. Întrucât această activitate urmărește să atragă atenția elevilor asupra valorii diferențelor individuale și a activităților de voluntariat, se poate organiza o discuție în clasă pe aceste teme. Se poate întreba dacă interesul lor pentru activitățile de voluntariat a crescut, perspectivele lor asupra diferențelor individuale s-au schimbat sau care sunt părerile lor cu privire la această activitate în general. Și, de asemenea, elevii pot scrie o poveste despre rezultatele probabile ale deciziilor lor. De exemplu, își pot scrie povestea menționând ce se va întâmpla și ce fel de experiențe vor avea personajele.`,
                "23": `Elevii sunt împărțiți în 3 grupe.`,
                "24": `Lista de voluntari (Anexa-1) și Lista de personaje (Anexa-2) sunt date fiecărui grup.`,
                "25": `Există trei domenii diferite de voluntariat în Lista voluntarilor: voluntariat educațional, voluntariat în caz de dezastre și voluntariat pentru mediu.`,
                "26": `În Lista de personaje există fotografii cu cinci personaje diferite și texte scurte care prezintă aceste personaje.`,
                "27": `Elevii ar trebui să examineze fiecare personaj și să-l plaseze într-o activitate de voluntariat.`,
                "28": `În timp ce fac acest lucru, deciziile lor ar trebui să conțină răspunsuri la următoarele întrebări și la aceste întrebări ar trebui să se răspundă pentru fiecare personaj:`,
                "29": `Pentru ce activitate de voluntariat ai ales acest personaj??`,
                "30": `Cum poate contribui acest personaj la activitatea de voluntariat pe care ai ales-o??`,
                "31": `Ce presupuneri ai despre acest personaj?`,
                "32": `În ce alte activități de voluntariat se poate angaja acest personaj în afară de activitatea de voluntariat pe care ați stabilit-o? De ce și cum?`,
                "33": `Elevii vor tăia numele personajelor din Lista de personaje și vor lipi aceste nume în zona de voluntariat pentru care au decis.`,
                "34": `Nu există restricții pentru această plasare. Important este ca fiecare personaj să fie plasat într-o activitate de voluntariat.`,
                "35": `După ce grupurile termină plasamentul, își aleg un purtător de cuvânt care să le reprezinte.`,
                "36": `Purtătorul de cuvânt al fiecărui grup trebuie să facă o prezentare despre decizia luată în cele 5 minute care le-au fost acordate.`,
                "37": `După prezentarea de 6 minute a fiecărui grup, se va acorda 1 minut celorlalți membri ai grupului pentru evaluare. Ca urmare, fiecare grupă va avea un total de 7 minute (8 minute pentru prezentare, 2 minute pentru evaluare).`,
                "38": `Să fie clar și înțeles.`,
                "39": `Să fie rezonabil și acceptabil.`,
                "40": `Să evite prejudecățile.`,
                "41": `Să folosească ipoteze convingătoare`,
                "42": `Să folosească alternative rezonabile.`,
            },
                    "escape": {
                        "0": `Această metodă se poate aplica oricărui mediu educațional, scopul fiind oferirea informației despre metodologia CRAAP participanților și punerea ei în practică. În plus față de metodele CRAAP, vor fi oferite și informații legate de metodele COMPASS. Având ca obiectiv dezvoltarea activităților ce vizează competențe cheie care stimulează gândirea critică și corectează informația prin intermediul rezolvării unor puzzle-uri interactive și logice,activitatea stimulează mintea de asemenea , folosind logica. Activitatea se bazează pe muncă de echipă și comparații între participanți pentru rezolvarea ghicitorilor. Această metodologie, cu mici modificări, se poate aplica pe orice temă.`,
                        "1": `Prepararea grupului:`,
                        "2": `Implementarea activităților:`,
                        "3": `Ca să implementezi Escape room trebuie să urmezi instrucțiunile în următoarea ordine:`,
                        "4": `Citește următoare poveste întâi în afara camerei și înainte ca jucătorii să înceapă să joace.`,
                        "5": `Bună dimineața,`,
                        "6": `Sunteți jurnaliști de investigație și luați parte la o investigație împotriva știrilor false. În ziua de astăzi, din cauza Covid 19 și vaccinurilor, știrile false s-au înmulțit. Acum 30 de minute ai primit un telefon de la colega ta Sarah Blakeslee care ți-a spus că a obținut informații foarte importante legate de caz pe care vrea să ți le arate și care vor schimba cursul investigației.`,
                        "7": `Când apari la biroul ei, ea nu este acolo!! Ai suspiciuni concrete că poliția a intervenit pe fir și a arestat-o, oricum tu ai ajuns la birou înaintea poliției, așa că nu ai mult timp la dispoziție să cauți informația, să o publici, să dezvălui conspirația și să o salvezi pe Sarah Black.`,
                        "8": `Așadar, trebuie să specifici că este un joc linear, fiecare unealtă poate fi folosită o singură dată, dar este doar un element central care poate fi folosit de mai multe ori(calculatorul).`,
                        "9": `Punctul de începere:`,
                        "10": `Când intri în cameră, găsești un calculator pe masă și o foaie de hârtie cu mesajul: Vine poliția! Verifică-mi calculatorul!SB. În jocul original, acesst mesaj este ascuns în lamele aerului condiționat. Camera este foarte călduroasă și trebuie să pornești aerul. Dacă acest mesaj nu apare, jocul nu suferă modificări.`,
                        "11": `Puzzle 1-8:`,
                        "12": `După aceea, jocul constă în 8 puzzle-uri și continuă cu rezolvarea. Următoarele puzzle-uri se pot găsi în Anexa 2, în linkul din capitolul 6`,
                        "13": `Misiunea supremă:`,
                        "14": `Să găsești informația cheie lipsă pe care a găsit-o Sarah, apoi să suni editorul și să i-o dai. Analiză( se explică cum se procedează și se include orice link):`,
                        "15": `Explicarea felului în care va decurge  procesul de evaluare și ce tip de evaluare va fi folosit , în funcție de următorii factori:`,
                        "16": `Criterii de evaluare:`,
                        "17": `Impactul și eficiența pe care Escape Room îl vor avea asupra participanților/jucătorilor vor fi criteriile de evaluare a activității desfășurate. Evaluarea se va face de către grup sa de către un singur facilitator care va ghida conversația și va pune întrebări legate de Escape room și informațiile livrate acestora.`,
                        "18": `Cuantificarea rezultatelor:`,
                        "19": `Rezultatele vor fi cuantificate în funcție de felul în care participanții au aplicat metoda CRAAP în timpul Escape room-ului și, în general, de felul în care au jucat Escape room ca un grup sau singuri. Rezultatele vor fi, de asemenea, cuantificate prin întrebări în timpul reflecției despre metoda CRAAP și despre informația corectă.`,
                        "20": `Metoda de debriefing:`,
                        "21": `După cum s-a menționat anterior, la sfârșitul Escape room-ului se face o analiză profundă, la fel se va face și pentru activitatea de debrefing care se poate realiza îndată ce jocul s-a încheiat, astfel încât participanții să nu rămână setați în joc.`,
                        "22": `Se va pregăti o metodă de feedback care poate fi folosită pentru a afla cum a fost resimțită activitatea  de către participanți și care se va folosi pentru îmbunătățirea și adaptarea metodei descrise.`,
                        "23": `Se discută cu participanții pentru a strânge cât mai mult feedback posibil în legătură cu impresiile lor despre activitatea desfășurată. Se pot folosi următoarele întrebări:`,
                        "24": 'Mărimea grupului: 3-6 persoane',
                        "25": 'Vârstă: 16-30 de ani',
                        "26": 'Timp: 45 de minute',
                        "27": 'Locație: Escape room se poate implementa într-o cameră suficient de mare, care nu este goală, de preferat cu o ușă adițională care dă spre o altă cameră.',
                        "28": 'Materiale: Sunt necesare câteva materiale care să fie folosite înainte pentru aranjarea si implementarea metodei. Lista detaliată este prezentată în Anexa 1. Documentul poate fi găsit în link-ul din capitolul 6.',
                        "29": 'Resurse umane: Activitatea se poate implementa de către un evaluator/facilitator/trainer sau de un grup al acestora. In timpul implementării, trebuie să fie doar o persoană de contact care va ajuta participanții când au nevoie.',
                        "30": 'Se întreabă participanții dacă știu ce este Escape room.',
                        "31": 'Se explică participanților ce este  Escape room, menționandu-le că cea la care vor participa nu este una clasică, ci una educațională.',
                        "32": 'Ulterior, se împart participanții în diferite grupuri formate din maxim 6 persoane.',
                        "33": 'Grupurile vor participa la Escape room pe rând.',
                        "34": 'În timpul implementării , jucătorii sunt singuri în cameră.  Nimeni nu se potrivește poveștii evadării. Poate figura prietenului lui Sarah care așteaptă  grupul de jurnaliști s-ar potrivi să deschidă ușa principală și să îi lase înăuntru..',
                        "35": 'https://www.europarl.europa.eu/RegData/etudes/ATAG/2017/599386/EPRS_ATA(2017)599386_EN.pdf  Acest document arată cei 8 pași pe care să îi urmezi ca să vezi dacă o știre este falsă.',
                        "36": 'Soluția pentru puzzle-ul 5 este CRAAP, o metodă postată de Sarah Blackslee.Ea este jurnalista care a contactat jucătorii jucătorii pentru a merge în biroul ei.',
                        "37": `În puzzle-ul 6 participanții trebuie să folosească metoda CRAAP învățată în puzzle-ul anterior ca să găsească documentul cu parola corectă. Credem că poate un bilețel de la Sarah ar fi util în acest pas.Spre exemplu Nu ai suficient timp pentru că vine poliția!Folosește metoda folosită pentru a deschide această cutie pentru a verifica care dintre știri este adevărată! S.B”`,
                        "38": 'Discută cu participanții dacă ei obișnuiesc să verifice știrile înainte de a le distribui pe internet..',
                        "39": 'Criterii de evaluare',
                        "40": 'Cine face evaluarea?(comisie, autoreflecție, evaluare de grup)',
                        "41": 'Cum pot cuantifica rezultatele evaluării și învățării?',
                        "42": 'Pregătirea unei metode de debriefing astfel încât participanții să nu rămână setați în joc sau modul competiției.',
                        "43": 'A fost distractiv și te-ai simțit implicat în activitate?',
                        "44": 'Ai învățat ceva nou? Dacă da, ce anume?',
                        "45": 'Ai găsit vreo activitate mai degrabă dificilă? Dacă da, cum ai face-o mai ușoară?',
                        "46": 'Ce părere ai de felul în care grupul a desfășurat activitatea? Au contribuit toți jucătorii la soluționarea fiecărei ghicitori?',
                        "47": '+Vei aplica ce ai învățat la Escape room în viața de zi cu zi?',
                    },
                    "desert": {
                        "1": `Acest joc va fi unul de tip quiz, interactiv, bazat pe lucrul în echipă, cu 5 tipuri specifice de activități de explorare:`,
                        "2": `Va fi spusă o poeveste, conform căreia participanții vor trebui să ia decizii, lucrând în echipă ei vor folosi argumente pentru a-și susține deciziile și apoi vor trebui să completeze un chestionar cu o decizie de comun acord, pentru a descoperi dacă au ales cea mai bună varintă și ce au de făcut la următorul pas.`,
                        "3": `Ei vor trebui sa ia o serie de decizii critice, pe parcursul poveștii. După fiecare decizie, va trebui interogat procesul logic din spatele deciziei grupului și să fie prezentată decizia corectă, până când activitatea se termină iar grupul cu cele mai multe decizii colective corecte, câștigă.`,
                        "4": `Activitatea este adecvată pentru maximum 10 echipe cu câte 5 membri, având la dispoziție 5 minute să discute între fiecare decizie, câte un minut pentru fiecare membru.`,
                        "5": `Activitatea se poate implementa în școli, în sesiuni de training, îm timpul liber, ca un joc social de gândire critică și chiar ca un instrument în orele e-learning.`,
                        "6": `Pregătirea grupului: Împărțiți grupul în echipe de minimum 2, maximum 5 membri și ajutați-i să se conecteze pe platforma kahoot. Apoi citți povestea, în timp ce se aude muzică de aventură, pentru a crea un mediu adecvat.`,
                        "7": `Zbori într-un avion cargo militar, ca voluntar într-o misiune din Africa, trebuie să traversezi Deșertul Sahara, pe deasupra căruia zbori acum, după 30 de minute de zbor, se pare că apar niște probleme tehnice, auzi semnalul S.O.S. din cabina piloților iar apoi avionul plonjează spre pământ, tu ești singura persoană care reușește să sară cu parașuta pe ieșirea de urgență, cu doar câteva răni ușoare. Odată ajuns pe pământ, urmezi fumul de la orizont, până la avionul în flăcări. Nu există supraviețuitori iar focul încă arde metalul distrus, nu poți folosi nimic de acolo din cauza greutății și a temperaturii, dar o parte a încărcăturii este întinsă pe jos. Poți observa obiecte împrăștiate în jur, cum ar fi: o trusă medicală, o armă, o sticlă cu alcool, o oglindă de buzunar, o sticlă cu un litru de apă, o carte despre cum să supraviețuiești în deșert, parașuta cu care tocmai ai aterizat, o busolă, o pelerină de ploaie, o glugă, o lanternă, un cuțit, ochelari de soare, o hartă.`,
                        "8": `Gândește-te cu atenție ce poți lua dintre aceste obiecte deoarece te poate ajuta să construiești o strategie de supravietuire.`,
                        "9": `Feedbackul activității poate fi făcut pe hârtie sau printr-o activitate de bine sau rău, prin împărțirea camerei în acord/dezacord, iar participanții se poziționează conform opiniei lor, iar tu poți să îi întrebi care este motivul pentru au ales acea tabără, în timp ce tu iei notițe cu opiniile lor.`,
                        "10": `Principalele declarații ar putea fi:`,
                        "11": `Presupunere`,
                        "12": `deducerD`,
                        "13": `Interpretare`,
                        "14": `Deducție`,
                        "15": `Argumentare`,
                        "16": `Număr maxim de jucatori: 50`,
                        "17": `Timp: 30 de minute, adecvat pentru online & offline.`,
                        "18": `Instrumente necesare: videoproiector, laptop, o conexiune bună la internet, coli printate cu introducerea și feedbackul Anexa 1 & Anexa 2;`,
                        "19": `Resurse umane: minimum un facilitator;`,
                        "20": `În ceea ce privește prima sarcină a strategiei de supraviețuire, verifici harta găsită și aproximezi că ai zburat aproape 300 de kilometri deasupra deșertului  și că cea mai apropiată bază care te ăpoate ajuta se află la 2000 de km. În acest caz, trebuie să iei o decizie: rămâi aproape de locul prăbușirii sau mergi către bază. Întrebare 1: rămâi sau pleci - rămâi +40%`,
                        "21": `Ești pregătit acum de acțiune, în acest caz, primul pas pe care îl faci este A: Iei arma și cuțitul pentru a te proteja; B: Iei harta și busola pentru a începe călătoria; C: Iei parașuta și pregătești un cort; D:Iei cartea despre supraviețuirea în deșert și începi să citești;`,
                        "22": `Începi să simți soarele arzător și nu ai un adăpost pregătit, ce faci pentru a te feri de căldură: A: Iei ochelarii de soare pentru a-ți relaxa ochii; B: Iei pelerina de ploaie pentru a te feri de soare: C: Iei apa și ți-o torni în cap pentru a te răcori; D: iei gluga și ți-o pui pe cap;`,
                        "23": `Acum ești obosit după construirea adăpostului și vrei să te răcorești dar deja ai terminat apa, ce faci pentru a obține energie: Mănânci ceva, ferit de soare; Te odihnești, ferit de soare; Bei din sticla cu alcool; Continu să muncești;`,
                        "24": `Ești pregătit și știi că semnalul S.O.S. a fost recepționat așa că, pentru a fi sigur că echipele de salvare te vopr găsi, ce folosești pentru semnal: A: Arma și lanterna; B: Oglinda de buzunar și langterna; C: Cartea și alcoolul pentru a face focul; D: Folosești haine pentru a genra mișcare în aer;`,
                        "25": `Criterii de evaluare: participanții vor fi evaluați automat prin intermediul kahoot dar vor fi evaluați și de către facilitator, în funcție de procesul luării deciziilor.`,
                        "26": `Cine face evaluarea? Evaluarea este realizată cantitativ de către kahoot, prin puncte și calitativ, de către facilitator.`,
                        "27": `Cum pot fi cuantificate rezultatele activității și rezultatul învățării? - Cuantificare prin puncte. Poți adăuga sau elimina puncte în funcție de atitudine și de procesul de luare a deciziilor.`,
                        "28": `Pregătește o activitate de debriefing penytru ieșirea din joc și penytru ca participanții să nu rămână în modul competitiv. Activitatea de ieșire poate fi cântatul și dansul pe melodia “I'm a survivor” sau un joc de energizare - Anexa 3.`,
            "29": `Îmi place această activitate. - De ce?`,
                "30": `Am învățat ceva din această activitate. - Ce?`,
                "31": `Munca în echipă m-a ajutat să supraviețuiesc.  - Cum și cine?`,
                "32": `Pot aduce argumente mai bune, acum, pentru a-mi apăra poziția, dacă am o opinie critică. - Ce te-a ajutat în acest proces?`,
                "33": `Am folosit gândirea critică, în această activitate. - Unde și cum?`,
                "34": `Aș schimba ceva în această activitate. - Dacă cineva răspunde cu ”da”, intreabă ce?`,
                "35": `Aș vrea să încerc mai multe activități non-formale; - De ce?`,
        },
        "debate": {
            "0": `Această activitate se recomandă elevilor de liceu și studenților cu scopul de a-și dezvolta gândirea critică, pentru dezvoltarea abilităților de susținere a unui discurs public și comunicare.  Activitatea îi va ajuta pe tinerii din grupul țintă să realizeze cercetări de calitate pe baza cărora șă-și formeze opinii argumentate.`,
                "15": `Explicați cum va decurge procesul de evaluare și ce tip de evaluare va fi utilizat, în funcție de următorii factori:`,
                "16": `Criterii de evaluare:`,
                "17": `Impactul și eficiența pe care le va avea escape room asupra participanților/jucătorilor va fi criteriul de evaluare a activității desfășurate. Evaluarea va fi condusă de grup sau de un singur facilitator care ghidează conversația și pune întrebări cu privire la camera de evadare și informațiile livrate acestora.`,
                "18": `Cuantificarea rezultatelor:`,
                "19": `Rezultatele vor fi cuantificate în funcție de modul în care jucătorii au aplicat metoda CRAAP în timpul escape room și, în general, de modul în care au jucat escape room ca grup și individual. Rezultatele vor fi de asemenea cuantificate punându-le în timpul reflecției mai multe întrebări despre metoda CRAAP și despre informarea corectă.`,
                "20": `Metodă de debriefing:`,
                "21": `După cum s-a menționat anterior, la sfârșitul Escape room-ului se face o analiză profundă, la fel se va face și pentru activitatea de debrefing care se poate realiza îndată ce jocul s-a încheiat, astfel încât participanții să nu rămână setați în joc`,
                "22": `Se va pregăti o metodă de feedback care poate fi folosită pentru a afla cum a fost resimțită activitatea  de către participanți și care se va folosi pentru îmbunătățirea și adaptarea metodei descrise.`,
                "23": `Se discută cu participanții pentru a strânge cât mai mult feedback posibil în legătură cu impresiile lor despre activitatea desfășurată. Se pot folosi următoarele întrebări:`,
                "24": '50 de minute',
                "25": '6 jucători',
                "26": '1-time keeper',
                "27": '1 moderator/ facilitator (profesor/student)',
                "28": 'între 10 și 15 spectatori',
                "48": `Metoda presupune așezarea a două echipe față în față pentru a discuta pe o temă dată sub forma unor roluri. Echipele vor juca după reguli bine stabilite, argumentând logic, oferind dovezi, realizând studii pe tema dată, lucrează în echipă, oferă un discurs cu mare impact, transformând dezbaterea într-o metodă formativă excelentă.Tema de dezbătut (moțiunea) este aleasă de moderator și cercetarea se realizează înainte de meci. Moderatorul sau facilitatorul decide, de asemenea, modul în care echipelor le este desemnat rolul de echipă afirmatoare sau negatoare.`,
                "49": `Moderatorul/facilitatorul are obligația de a oferi indicații clare și pe înțelesul participanților cu privire la regulile jocului.`,
                "50": `Aranjarea locurilor`,
                "51": `Moderatorul se așează între cele două echipe cu fața la ambele echipe. Spectatorii se așează aproape, astfel încât să vadă ambele echipe. Ei stau în picioare și se mișcă între cele două echipe ăn funcție de decizia pe care o dau în acel moment al meciului. (anexa 2).`,
                "52": `Sfaturi pentru alegerea strategiei de echipă (anexa 3)`,
                "53": `Sfaturi pentru public`,
                "54": `Luați notițe după argumentele prezentate de fiecare parte – într-o singură propoziție, dacă e posibil.`,
                "55": `Legați argumentele cu contra-argumentele lor prin linii sau săgeți și scrieți câte un comentariu. Ce argumente v-au convins?`,
                "56": `La dezbatere participă 2 echipe cu câte 3 membri fiecare. Prima echipă se numește ECHIPA AFIRMATIVĂ, iar a doua ECHIPA NEGATOARE.`,
                "57": `Fiecare membru al echipelor susține un discurs de câte 2 minute, luând cuvântul în mod alternativ, câte un vorbitor din fiecare echipă, începând cu primul vorbitor al echipei afirmative (anexa 1)`,
                "58": `Persoana care cronometrează discursurile indică prin semne când vorbitorul mai are 10 secunde din timp. Când timpul expiră vorbitorul își poate sfârși propoziția, apoi trebuie să se oprească. Dacă unui vorbitor îi mai rămâne timp din cele două minute alocate, acesta nu poate fi acordat altui vorbitor.`,
                "59": `La finalul fiecărui discurs moderatorul adresează fiecărui vorbitor maximum două întrebări cu privire la dovezile aduse în discuție ori cu privire la argumente..`,
                "60": `La începutul meciului spectatorii se vor așeza în semicerc astfel: în fața echipei afirmatoare cei care sunt de acord cu moțiunea și în fața echipei negatoare cei care nu sunt de acord cu moțiunea. Cei care sunt neutri se vor așeza la mijloc. Spectatorii se vor mișca pe parcursul meciului în funcție de cât de convingător li se va părea fiecare discurs.`,
                "61": `Publicul nu poate interveni în timpul meciului.`,
                "62": `După terminarea meciului publicul poate cere clarificări pentru a putea lua cea mai bună decizie. La fiecare întrebare trebuie să răspundă ambele echipe. După luarea deciziei spectatorii pot susține discursuri prin care își motivează decizia.`,
                "63": `Decizia se ia în mod individual. O persoană poate să își schimbe decizia de mai multe ori pe parcursul meciului.`,
                "64": `Fiecare discurs va avea următoarea structură: afirmație, explicație, dovezi, exemple/ilustrații, concluzie`,
                "65": `Decizia finală este dată de public.`,
                "66": `(Cu excepția primului vorbitor din echipa afirmativă.) Utilizează prima jumătate a timpului pentru a contracara un argument al celeilalte părți, apoi prezintă un nou argument sau punct de vedere.`,
                "67": `(Pentru primul vorbitor.) Prezintă propunerea ta – spune ce decizie vrei să fie luată.`,
                "68": `Decideți în ce ordine doriți să formulați argumentele și alocați fiecare argument câte unui membru al echipei.`,
                "69": `The last speaker should conclude the team’s speech, emphasizing three or four aspects. What should the public remember after the debate? If the debate concerns a decision which must be made, be very explicit on what you are asking from the members of the public when they vote, after the debate.`,
                "70": `Vorbiți liber. Nu citiți prezentarea de pe notițe, ci stabiliți contact vizual cu oponenții și cu audiența.`,
                "71": `Tratați oponenții cu respect. Nu insultați niciodată un vorbitor, vizați doar argumentele sale.`,
                "72": `Evaluarea are loc pe două nivele:`,
                "73": `Evaluarea meciului de dezbatere (cine a câștigat meciul?)`,
                "74": `Evaluarea  modului în care s-au îmbunătățit abilitățile ca rezultat al folosirii acestei metode:`,
                "75": `Pentru debrifare și scoaterea participanților din strarea mentală de joc, se poate folosi metoda ORID (care folosește întrebări obiective, reflexive, interpretative și decizionale) (anexa 4)`,
                "76": `Site-ul www.menti.com poate fi folosit pentru a obține feedback de la participanți.`,
        },
        "collect": {
            "0": `Această metodă de brainstorming și joc de rol este folosită pentru observarea, analiza, inferența, comunicarea și rezolvarea de probleme în colaborare sau individuală. Această metodă este un fel de ascultare cooperativă, scriere creativă și dialog între indivizi care sunt organizați în grupuri. Se bazează pe ideea de a asculta o melodie, de a colecta versurile scrise pe bucăți de carton care sunt lipite pe pereți, de a contempla la ideea sa principală prin brainstorming despre versuri, de a scrie o scurtă schiță inspirându-se din propozițiile pe care le-au colectat și le-au jucat în clasă. Nu numai că stimulează ideile inovatoare, ci și îmbunătățește abilitățile de ascultare, scriere și interpretare pentru a deveni mai energic și mai interactiv în timpul lecțiilor. `,
                "1": `Elevii sunt împărțiți în grupuri de câte 5 persoane. Pot fi 4 grupuri. Înainte de a începe activitatea, profesorul decide cântecul și alege cele mai concludente propoziții, cuvintele țintă sau structurile gramaticale necesare din versuri. Apoi el/ea scrie fie propozițiile alese, fie cuvintele/structurile gramaticale pe cartonașe colorate înainte de a veni la clasă. Deci, această metodă cu această activitate necesită puțină pregătire înainte de a intra în clasă.`,
                "2": `Când intră profesorul, el/ea atașează materialele cu grijă pe pereți, lăsând puțin spațiu între cartonașe. Fiecare membru al grupelor se va alinia unul lângă altul. Ceilalți membri se aliniază în spatele grupului din care fac parte. Profesorul pornește melodia. Ori de câte ori un elev aude o propoziție, un cuvânt sau o structură gramaticală care este lipită pe perete, el/ea aleargă la perete și ia cartonașul și merge la capătul rândului grupului său. Fiecare grup încearcă să adune cât mai multe cartonașe poate. Când cântecul se termină, se întorc la băncile lor. După ce a discutat toate versurile împreună cu clasa, profesorul le dă instrucțiunea de a scrie și a interpreta un joc de rol conform temei de pe cartonașele pe care tocmai le-au adunat. Elevii trebuie să folosească toate propozițiile și cuvintele pe care le au. Melodia este „Imagine” de John Lennon. Trebuie să existe niște propoziții sau cuvinte de genul „imagine there’s no countries/nothing to kill or die/dreamer/imagine there is no heaven above/ no hell below/imagine no possessions/the World will live as one/join/someday/peace/greed/I wonder/hard/religion etc.” în piesele lor. Așadar, ei încep să facă brainstorming despre propoziții sau cuvinte pentru a crea o poveste unică între ei. Ei decid intriga, personajele, scriu dialogurile și, în cele din urmă, o interpretează în clasă.`,
                "3": `ANALIZA METODEI:`,
                "4": `Această metodă de a colecta, crea și interpreta se bazează pe ideea de a colecta versuri dintr-o melodie, dar poate fi, desigur, dezvoltată în colectarea unor materiale pentru a proiecta echipamente pentru sănătate sau dispozitive tehnologice pentru bunăstarea publică. Profesorii pot lăsa niște materiale sigure, cum ar fi frânghii, cutii, vopsele, imprimante, foi etc. Elevii se gândesc și decid cum să producă și ce să facă. Pentru a colecta materialul pe care îl dorește, fiecare grupă ar trebui să dea răspunsul corect pe care profesorii îl cer. Cei care dau răspunsul corect la întrebări, pot avea șansa de a colecta materialele.`,
                "5": `Număr de elevi: 20 (poate varia)`,
                "6": `Durata activității: 45 minute:- 5’: pentru ascultare 20’: pentru brainstorming și scriere creativă pentru dialoguri și roluri. 20’: pentru joc de rol.`,
                "7": `Materiale: Smartboard/Mp3 playere/Music Player, cartonașe colorate, patafix, materiale de papetărie precum pixuri, foi, radiere etc.`,
                "8": `Locație: Această activitate este o activitate de interior, deoarece necesită un perete. După colectarea materialelor necesare, elevii ar trebui să stea față în față, de aceea băncile trebuie aranjate astfel încât să le permită să stea în grup.`,
                "9": `Resurse umane: Un profesor este suficient pentru 20 de elevi care pot fi organizați în 4 grupe.`,
                "10": `Elevii pot primi multe competențe țintă care pot varia în funcție de structura și conținutul cântecului ales. Profesorul poate decide temele. De exemplu, dacă este vorba de „condiționale” ca tipar gramatical, cântecul poate fi „If I were a rich man”, iar jocurile de rol pot fi scrise pe baza temei: este important să fii bogat sau nu. Pot exista mai multe mesaje sociale transmise prin versurile diferitelor melodii.`,
                "11": `Elevii înțeleg mai bine ideea și conținutul atunci când participă și acționează personal la subiectul dat. Această metodă îi ajută pe elevi să gândească, să exploreze, să înțeleagă și să creeze interactiv prin dialogurile din jocurile lor de rol și să dezvolte scrieri creative pentru intrigile lor.`,
                "12": `În timpul părții de audiție trebuie acordată atenție, deoarece elevii se grăbesc cu adevărat la pereți pentru a apuca cartonașele și uneori pot provoca haos în sala de clasă. Se pot întâlni sau cădea. În orice fel, este sigur că se distrează.`,
                "13": `Elevilor le place să colecteze cartonașele și să fie activi în activitatea de audiție.`,
                "14": `Sunt cu adevărat creativi și se simt încrezători în timp ce colaborează.`,
                "15": `Unii elevi se simt timizi, dar, în general, rezolvă această problemă lăsându-și prietenii să ia roluri potrivite în funcție de ei înșiși. De exemplu, în loc de jocuri de rol, ei pot face unele traduceri sau cercetări în timpul procesului de scriere. Pentru a se încadra în timp, fiecare grup trebuie să facă o anumită distribuire a sarcinilor.`,
                "16": `Fiecare grup își poate evalua reciproc performanța oral după jocurile de rol.`,
                "17": `Fiecare grup își poate evalua reciproc performanța oral după jocurile de rol: CONTRIBUȚIE INDIVIDUALĂ; ARMONIA ÎN GRUP; ÎNȚELEGEREA CONTEXTULUI; UTILIZAREA CUVINTELOR/PROPOZIȚIILOR/STRUCTURILOR GRAMATICALE ȚINTĂ; FLUENȚĂ ȘI PRONUNȚIE ÎN TIMPUL INTERPRETĂRII`,
                "18": `DEBRIEFING: Elevilor li se adresează întrebările de mai jos printr-un formular Google sau printr-o discuție în clasă..`,
                "19": `Ți-a plăcut activitatea de ascultare?`,
                "20": `Care parte din versuri vi s-a părut cea mai semnificativă?`,
                "21": `Ți-a plăcut să lucrezi în colaborare în timpul sarcinii de scriere creativă?`,
                "22": `Crezi că ai reflectat bine inferența din materialele pe care le-ai adunat în jocul tău de rol?`,
                "23": `Care parte a activității ți-a plăcut cel mai mult?`,
                "24": `Ați dori să schimbați ceva în activitate? Ce ar fi?`,
                "25": `Un formular Google poate fi pregătit și trimis elevilor pentru a-și exprima părerile cu privire la metodă și activitate`,
                "26": `Menti sau Tricider pot fi folosite pentru a colecta punctele de vedere ale elevilor cu privire la activitate.`,
        },
        "worldmap": {
            "0": `Această metodă se aplică contextului universitar, pregătirii viitorilor educatori și profesori. Grupul țintă este format din studenți, dar se poate aplica oricărui nivel de vârstă sau context. Scopul este acela de a stimula discuția și gândirea critică. Prin observarea lucrurilor convenționale dintr-o nouă perspectivă, stimulîăm grupul țintă să recunoască faptulș că pot exista alte perspective de a vedea lumea  și să își exprime părerea pe această temă. Promovați deschiderea și acceptarea părerii celorlalți.`,
                "1": `Pregătirea grupului:`,
                "2": `Aranjați participanții în semicerc. Atârnați harta într-o zonă ascunsă a încăperii  să nu fie văzută de participanți. În timp ce unul dintre educatori descrie sarcina, celălalt împarte bilețele  și creioane participanților.`,
                "3": `Implementarea activităților:`,
                "4": `Participanții sunt chemați unul câte unul să meargă în zona ascunsă să se uite la hartă timp de trei secunde și să își noteze primele impresii imediat pe un bilțel și să îl dea educatorilor. De îndată ce toși au făcut asta, educatorii citesc tare răspunsurile de pe bilețele și le lipesc pe tablă grupându-le conform unui tipar. Apoi educatorii arată harta grupului. De obicei unele  răspunsuri de pe bilețele conțin particularitatea hărții. Și aici educatorii încep o discuție prin aceste două întrebări de provocare a gândirii:`,
                "5": `– Cine credeți că a inventat harta asta și de ce?`,
                "6": `–Pe ce se bazează hărțile tradiționale?`,
                "7": `Participanții își pot motiva răspunsurile și alegerea cuvintelor care stimulează discuția. Educatorii încurajează și conduc discuția pentru a ajuta participanții să gândească critic`,
                "8": `Evaluare și debriefing:`,
                "9": `Când toți primesc șansa de a-și exprima opinia în legătură cu harta, educatorii pun întrebarea finală “Care credeți că este scopul acestei activități?” pentru a stimula gândirea critică ulterioară. Cu ajutorul educatorilor, sperăm la următoarele: că hărțile convenționale vestice orientate spre nord ttind să distorsioneze viziunea asupra lumii și să accentueze faptul că Europa și Nord America implică că alte țări sunt mai puțin importante. Prin oferirea de perspective alternative asupra lumii, stimulăm gândirea critică. Educatorii notează orice cuvânt cheie sau idee care reise din dicuție. Ca și urmare a activității, participanții sunt împărțiți în grupuri de mici(5-4 oameni) sunt rugați să creeze o hartă conceptuală care va reelabora ideile care reise din discuție folosind orice instrument la alegere, hârtie, creioane, aplicații digitale, etc`,
                "10": `Explicați cum se va face procesul e evaluare și ce fel de evaluare va fi folosită în funcție de următorii factori:`,
                "11": `Pentru feedback, folosiți următorul chestionar:`,
                "12": 'Timp: 1 – 2 ore;',
                "13": 'Număr de participanți: minimum 5, maximum 30;',
                "14": 'Cerințe de locație: nu există ,  este de ajuns să fie un spațiu fără prea mult zgomot ca discuția să fie auzită, un mediu confortabil non-formal.',
                "15": 'Instrumente necesare: O hartă a lumii cu susul în jos ( vezi secțiunea Anexe), orice materiale care se pot lipi pentru a putea fixa harta pe o suprafață verticală, bilețele post-it, pixuri(creioane).',
                "16": 'Resurese Umane: 3 educatori. Primul are ca scop explicarea activității și verificarea participanților în timp ce observă harta, al doilea distribuie materialele participanților și dă instrucțiunile desfășurării activității. Apoi ambii conduc discuția  Prezența a doi traineri ar putea crea niște divergențe între eiîn timpul dicuției, așa că asta oferă mai multe perspective participanților, să înțeleagă faptul că discuția nu este despre al și negru, correct sau greșit, ci despre diferite perspective sau opinii.',
                "17": `Criterii de evaluare`,
                "18": `Cine face  evaluarea: Edcatorii (comisie, reflecție individual evaluare de grup,)`,
                "19": `Cum puteți cuantifica rezultatele activității și ale învățării?`,
                "20": `Pregătiți o metodă astfel încât particpanții să nu rămână ăn continuare ăn activitate sau pe modul competitiv.`,
                "21": `Criterii de evaluare: Criteriile sunt bazate pe intensitatea totală a dikscuției, implicarea individuală și gândirea critică a fiecărui participant în timpul activității (discuție, completarea sarcinii).`,
                "22": `Cine face  evaluarea: Educatorii;`,
                "23": `Cunatificați rezultatele și efectele învățării: Aprecierea va fi făcută conform participării active la discuție, aplicării gândirii critice, creativității și lucrului în echipă.`,
                "24": `Informare: Prezentarea hărților grupului și dicuția care încurajează abilitățile de comunicare.`,
                "25": `Care a fost prima ta impresie când ți-ai dat seama că harta lumii este cu susul în jos?`,
                "26": `DAi considerat că activitatea ti-a provocat gândirea?`,
                "27": `Care crezi că este scopul educational din spatele ei?`,
                "28": `Ai modifica ceva?`,
                "29": `Ce anume?`,
                "31": `A fost util pentru tine să lucrezi în echipă?`,
                "32": `A fost ușor să cădeți de acord?`,
        },
        "fakenews": {
            "submission": "Mulțumim pentru contribuția ta! Vom evalua propunerea ta și o vom publica imediat ce este posibil!",
                "summary": 'Rezumat',
                "disproof": 'contestare',
                "link": 'Link',
                "0": `Ajută-ne să semnalăm știrile false`,
                "1": `și să fim parte`,
                "2": `a`,
                "3": `schimbării`,
                "4": `Titlu`,
                "5": `Numele articolului cu știri false. (20-100 caractere)`,
                "6": `Scurtă descriere a subiectului. (50-600 caractere)`,
                "7": `Link`,
                "8": `Copiază aici linkul cu articolul ce conține știri false.`,
                "9": `Motivele pentru care crezi că articolul conține știri false (50-500 caractere)`,
                "10": `Submit`,
                "11": `Alertă de fakenews`,
                "12": `Știri false sunt peste tot și oricine poate contribui pentru a face internetul un loc mai bun, cu informații de calitate și de încredere.`,
                "13": `Această secțiune permite oricărei persoane care a citit sau a observat un articol cu știri false, să îl semnaleze și să facă dovada dezinformării, publică`,
                "14": `Cititorilor le sunt prezentate ideile principale ale articolului și dovezile care îl contestă. Ajută-ne să semnalăm știrile false și fii parte a schimbării!`,
                "15": `Aruncă o privire la știrile false semnalate de alții!`,
                "16": `Nu s-au găsit știri false`,
                "17": `Descriere`,
                "18": `Contestare`,
                "19": `Link`,
                "20": 'Europa ar putea fi lăsată fără electricitate, între o zi și o săptămână. Armata australiană pregătește populația pentru o pană de curent de proporții.',
                "21": `Acest articol, ca multe altele din presa românească, anunțau o pană de curent de proporții, în Europa, folosind formulări de titluri șocante, ca ”Pană de curent în Europa! Pană masivă de curent. Armata a intervenit deja: pregătește-te!” sau "Europa, la un pas de a rămâne în întuneric total, pentru mai multe zile. Austria se pregătește pentru o pană de curent. Efectele vor fi devastatoare." Pe lângă exemplele de articole de pe Antena 3, alte siteuri populare de știri au promovat aceste știri false. Acestea sunt: Cotidianul, Opinia Timișoarei, Observator, Capital, Realitatea.net, Protv.ro,  B1TV`,
                "22": `Informația care a ajuns în presa românească a plecat de la o presupunere legată de o activitate de avertizare a populației, a armateri austrice, care, pentru presa românească, s-a transformat într-o certitudine. Realitatea este că Ministerul austriac al apărării a demarat o campanie prin care să pregătească populația pentru a ști cum să reacționeze în cazul unei pene majore de curent. Începutul campaniei a fost prezentat pe Instagram de către Klaudia Tanner, Ministrul Apărării, alături de un comentariu care spune ”o pană de curent înseamnă o întrerupere a energiei și probleme ale infrastructurii, la nivel european - este un risc realist și, în același timp, unul subestimat.De aceeea, scopul nostru este de a îmbunătăți rezistența republicii noastre, ca un întreg!”`,
                "23": 'mai multe anunțuri online prin care se vinde Arbidol, medicament folosit în tratarea de Covid-19',
                "24": 'Românii cărora le era teamă de Covid au început să achiziționeze Arbidol, un medicament din Rusia, neautorizat în Uniunea Europeană. Există zeci de anunțuri care apar și dispar foarte rapid, pe platformele de anunțuri online, în care Arbisolul se vinde cu sume cuprinse între 200 și 250 de lei.',
                "25": 'Initial, tratamentul a fost folosit în teste, unde au descopeeit că nu este eficient. Comisia de boli infecțioase a Ministerului Sănătățiia reconfirmat acest rezultat și a întărit recomandarea ca acest medicament să nu fie folosit în tratamentul infecției cu Sars-CoV-2.',
                "26": 'Doctorii implicați în tratarea pacienșilor Covid au spus că este periculos pentru pacienți să își administreze medicamente acasă, ce pot avea efecte adverse, cumpărate online. Tratamentele trebuie să fie reomandate de un medic.',
                "27": 'Scutul de la Deveselu reprezintă o minciună diplomatică, scutul nu este folosit în apărare. (discurs aniti-verstic și anti-NATO)',
                "28": `Articolul a atras o serie de comentarii care susțin ideea că România se află la mila NATO și că țara noastră și-a pierdut independența: "se presupunea că noi suntem neutri, dar, în schimb, am devenit Republica Bananieră România" sau "Nu ar fi mai bine americanii să își ia instrumentele și să ne lase să colaborăm cu Rusia, pentru a ne dezvolta?" sau că nu conteaza cât de puternic este scutul, nu va fi capabil sa respingă arsenalul rusesc - "Acesta este gunoiul americanilor, luat pe bani grei, acceptat de incompetenții nostri." sau "În acest moment, nu exista niciun scut anti-rachetă care sa oprească un atat hypersonic al Rusiei."`,
                "29": `Rolul acestei propagande este de a slăbi parteneriatul cu Alința Nord-Atlantică și statutul de membru UE. Pandemia a intensificat discursurile anti-vestice și a radicalizat unii utilizatrori de social media. Atacul Ucrainei de către Rusia a adus, de asemenea, o intensificare a discursurilor anti-vestice și au vizat, în special, ieșirea României din UE.`,


                "30": 'România își concentrează forțele armate la granițele cu Republica Moldova și Ucraina',
                "31": `O coloană cu vehicule ce transportă echipament militar a fost surprinsă în timp ce se deplasa către granițele cu Republica Moldova și Ucraina, scrie presa rusă, făcând referire la un videoclip filamat de un amator, din mașina sa. Filmul - folosit, de asemenea, și în alte campanii de dezinformare - este un pretext pentru a susține teoriile conform cărora România intenționează să ocupe Republica Moldova și să atace regiunea separatistă Transnistria.`,
                "32": `Deși presa din Rusia susține că imaginile au fost publicate de media ucriniană,acestea au apărut pe un canal Telegram transnistrian, denumit PMR_History, pe 33 Aprilie, de unde au fost preluate de alte canale telegram, de media din Ucraina și din Rusia, în următoarea zi. Coloana de vehicule care apare în imagini nu aparține armatei române - este formată din vehicule ale Armatei SUA, staționate în România. De fapt, în paralel cu narațiunile despre mutarea trupelor române la granițe, a mai apărut una, potrivit căreia americanii ar fi cei care își mută trupele la granița cu Republica Moldova. Ministerul Român de Apărare a anuțat că echipamentul miltar care apare în imagini se îndreptă, de fapt, către poligonul de antrenament de la Babadag.`,
                "33": `Dna. Heard l-a acuzat pe dl. Depp de repetate abuzuri psihice. Luni, ea l-a acuzat că a tacat-o, în timp ce se aflau la bordul Orient Express, în Asia, după nunta lor din Februarie 2015, susținând că a strâns-o de gât, în compartimentul lor din tren.`,
                "34": `"Ceea ce am văzut în timpul acestui proces reprezintă simptomele unei dereglări de personalitate, către narcisism. Sunt de acord cu diagnoosticul de tulburare de personalitate instabilă emoțional", a spus Angelica.Deasemenea, Johnny Depp a câștigat procesul.`,
                "35": `Unui tânăr orb, dl. Faysaloğlu, i s-a oferit un premiu de 10,000 dolari, de către Apple, pentru că a identificat un defect.`,
                "36": `Un programator, Utku Şen, susține că această știre este falsă și că este editată de el. Acesta a explicat că a creat această știre falsă și a distribuit-o catre alte siteuri și, din păcate, editorii websiteurilor au publicat-o fără să verifice dacă este reală sau nu.`,
                "37": `“Președintele Trump, practic, înainte ca Elon Musk să cumpere, i-a sugerat să facă acestă mișcare, deoarece  scopul companiei este acela de a construi o comunitate unde oamenii să găsească un loc sigur, prietenos”, a spus Devin Nunes, în apariția sa de la Fox Business Network. “De aceea l-am încurajat pe Elon MUsk să cumpere, pentru că cineva trebuie să se lupte cu giganții din domeniul tech”, a spus Nunes. “Donald Trump a vrut să se asigure că poporul american își va recâcștiga vocea și că internetul este deschis.”`,
                "38": `Musk a negat informațiile intr-un tweet, urmat de publicarea unui articol, în care se spune ”Această informație este un fals. Nu am comunicat niciodată, direct sau indirect, cu Trump, care a declarat public că va folosi doar Truth Social.”`,
                "39": `Afirmațiile  privind moartea lui Alpaslan Özmol, actorul care-l interpretează pe "Çaycı Hüseyin", din serialul Cocuklar Duymasın, sunt prezente constant în social media. Alpaslan Özmol, despre cae s-a spus că a murit, a spu: ”Nu sunt mort.”`,
                "40": `Recunoscând că se fac și glume bune în știrile despre moasrtea sa, Özmol spune: "Umorul nostru a ajuns la apogeu. Când mă văd, oamenii vin și mă întreabă: NU ești mort? Nu sunt sătul de asta. Ei, de fapt, vor să mă vadă la televizor.”`,
                "41": 'Johnny Depp m-a lovit în luna de miere, a spus Amber Heard',
                "42": 'Un adolescent orb a primit un premiu de 10,000 dolari pentru că a identificat un defect Apple.',
                "43": 'Elon Musk neagă că Trump l-a ”încurajat discret” să cumpere Twitter',
                "44": 'Çaycı Huseyin a murit.',
                "45": 'IATĂ: MIGRANȚI ÎN ITALIA',
                "46": 'NU ESTE ARENA. GILETTI LIVE DIN TRANȘEELE DIN ODESSA, DAR, ÎN REALITATE, ESTE ÎN STAȚIA BARRA',
                "47": 'VATICAN FĂRĂ ELECTRICITATE: Papa este arestat pentru 80 de capete de acuzare, printre care trafic de copii, fraudă',
                "48": '14 companii false de ulei de măsline dezvăluite – Încetează a mai cumpăra aceste branduri de ulei de măsline IMEDIAT!',
                "49": `articolul susține că unii migranți care locuiesc în Italia, ar comite crime împotriva localnicilor;`,
                "50": `aici se ia în discuție autenticitatea imaginilor cât și intenția autorului articolului sursă, care adoptă o poziție evidentă împotriva migranților. Aparent, imagnile nici măcar nu sunt filmate în Italia, ci reprezintă o scenă dintr-un film, filmat în Italia, în 2015. De asemenea, imaginile existau dej, pe internet, cu mult înaintea publicării articolului.`,
                "51": `Farsa diseminează îndoieli în legătură cu prezenta jurnalistului italian Massimo Giletti în Odessa, Ucraina, în timpul conflictului de la Moschee. Mai mult, se presupune chiar că Giletti a filmat scenele în stația Barra, în zona Napoli.`,
                "52": `In acest video, se vede clar jurnalistul italian Massimo Giletti, care descrie situația din orașul Odessa, live pe Canalul 7 italian, pentru emisunea "Nu este arena". Se poate chiar auzi vocea unui om care îl avertizează să se mute.`,
                "53": `Acest articol vorbește despre presupusle fărădelegi comise de Papa Francis`,
                "54": `În acest articol se poate atesta clar prezența papei Francis în Vatican, în aceeași zi în care știrea a apărut. Aceasta contrazice total dezinformarea anterioară.`,
                "55": `articolul vorbește despre câteva companii producătoare de ulei de măsline acuzate că produc ulei din măsline de proastă calitate`,
                "56": `Dezmințirea apare pe siteul oficial Bertolli, discutând din mai multe puncte de vedere, unde și de ce știrea falsă a fost fără îndoială greșită.`,
                "57": 'SUA a provocat războiul din Ucraina pentru câștiguri economice',
                "58": 'Putin este un bun creștin, o victimă a discursurilor rusofobe și nu are nicio legătură cu comunismul, impus în Rusia de către Vest',
                "59": 'Regimul sovietic nu a provocat foametea din Basarabia',
                "60": 'Recensământul din România și posibilile dezinformări legate de acesta',
                "61": `SUA transformă UE în colonia sa, după începerea războilui din Ucraina. SUA duce un război la distanță cu Rusia, în Ucraina, pentru dominația mondială. Țările din Orientul Mijlociu au refuzat să vândă Germaniei hidrocarburi, pentru a înlocui importurile din Rusia.`,
                "62": `În primul rând, știrea urmărește deza clasica narațiune a propagandei rusești, care spune că SUA a inițiat, de fapt, războiul din Ucraina, prin intermediul a mai multor intrigi. Potrivit acestei narațiuni a Kremlinului, SUA ar duce un război la distanță, în Ucraina, contra Rusiei. Evident, nu SUA a început războiul în Ucraina, ci acesta este rezultatul invaziei masive a rușilor, din 24 februarie. De fapt, aproape cu un an inainte, Rusia a masat constant personal si echipamente militare, la granița cu Ucraina, sub pretextul exercițiilor militare. Rusia a negat orice plan de invazie, până în ziua de dinaintea invaziei. Din punct de vedere economic, Rusia a creat și a contibuit la creșterea artificială a prețului gazelor naturale, cu mult înaintea invaziei din Ucraina, învcepând cu 2021. Rusia și-a redus exporturile către Europa cu 26%, în al patrulea trimestru din 2022, comparativ cu aceeași perioadă din 2020, ceea ce a accentuat creșterea prețului gazelor, 
pe piața Spot, în Europa. Veniturile din taxele pe exportul de petrol și gaze și din tarifele lor, reprezintă 45% din bugetul fedderal al Rusiei, în ianuarie 2023. Luând în calcul prețurile curente din piață, valoarea exporturilor rusești de gaze doar către UE însumează 400 de milioane de dolari pe zi. Totalul exporturilor de petrol și a produselor rafinate reprezintă aproape 700 de milioane de dolari pe zi. Cantitatea de gaze și petrol rusești a crescut, de fapt, de la începutul războiului din Ucraina. Europa i-a plătit Rusiei aproape 22 de miliarde de euro, pentru petrol și gaze, doar în martie, potrivit Bruegel, citat de revista Nature. Așa că Rusia profită din plin de prețurile mari ale gazului și ale petrolului.`,
                "63": `Vladimir Putin este un bun creștin, dar imaginea sa suferă din cauza rusofobiei. Aceste narațiuni false pornesc din afirmațiile Aehiepiscopului de Tomis Teodosie, amplificate de Sputnik. În același timp, știrea promovează și că Putin și Rusia nu au nicio legătură cu comunismul, care a fost impus de către Vest.`,
                "64": `Numeroși lideri totalitari și dictatori au încercat de-a lungul timpului să își sporească imaginea lor pioasă și să se alieze cu autoritatea religioasă, din dorința de a-și spori popularitatea și de a câștiga legitimitate. Un exemplu în acest sens este cel al lui Saddam Hussein care, deși a fost un exponent al încercării de secularizare a socetății irakiene, încă din primii ani în care s-a aflat la putere, și-a schimbat optica după ce a devenit un paria pe plan internațional, în urma invaziei din Kuweit. Din dorința de a obține susținerea lumii musulmane, Saddam a oferit mai multa libertate liderilor religioși, a ordonat ca  "Allah al-Akbar" să fie imprimat pe steagul irakian (se spune chiar că a fost folosit scrisul său de mână), Coranul a fost scris folosindu-se sângele său și a ordonal sa fie construite moschee. Această smerenie afișată de dictator nnu înseamnă că nu mai este vinovat de crimele în masă comise, nici macar că a încetat să mai comită aceste crime, după ce a scris Allah al-akbar pe steag - dimpotrivă, unele dintre cele mai odioase crime au urmat acelui moment.`,
                "65": `Foametea nu a fost provocată de regimul sovietic iar Moscova a ajutat Moldova în timpul foametei. Înfometarea șă deportările nu sunt terori bolșevice, ci doar propagandă anti-sovietică. Foametea din 1947 este o consecință a lipsei de acțiune din partea autorităților locale, nu a fost provocată de puterea centrală din URSS. Cercetările istoricilor pro-vestici din Moldova sunt viciate și au ca scop rescrierea adevăratei istorii.`,
                "66": `Regimul comunist a arătat, încă de la începutul ocupației teritoriilor sovietice, că teroarea este cea mai rapidă și eficientă metodă de instaurare a noii puteri. În plus față de arestările, deportările, asasinatele comise de către puterea sovietică împotriva populației, potrivit istoricilor, foametea a ucis între 150 și 300 de mii de oameni în zonele sovietice, o treime din populația Republicii Moldova fiind afectată de foamete și boli. În același timp, foametea din 1946-1947 a forțat țăranii să accepte colectivizarea. Taxarea țăranilor de către regimul sovietic a început chiar din 1944, imediat după reocuparea Basarabiei, afirmă Mariana Țăranu, doctor în istorie. Autoritățile au crescut taxele de la an la an, sovieticii cerând de la fiecare familie o taxă în produse: ouă, lână, brânză, grâu, pentru Armata Roșie - dar și în bani. Statul, de asemenea, cerea și alte produse pe care populația nu le producea, fiind astfel forțați să le cumpere. Organismele partidului se concentrau pe îndeplinirea planului în ceea ce privește produsele agricole și rechizitarea rezervei de alimente a populației. Familiilor care nu plăteau aceste taxe li se confiscau proprietățile iar capul familiei era judecat de către un Tribunal militar și condamnat la munca forțată.  Pentru îndeplinirea planului, activiștii erau însoțiți de soldați înarmați care, efectiv, smulgeau ultimele alimemnte de la țărani. Aceste taxe făceau viața la țară mult mai dificilă. Puerea sovietică a folosit condițiile climatice pentru a camufla politica înfometării populației. Având în vedere că, în 1946, 365 de mii de tone de grâu au fost recoltate, jumătate din nevoia populației, iar planu de rechiziție pentru Basrabia însemna 255 de mii de tone, foametea era iminentă, susține istoricul Anatol Țăranu`,
                "67": `Bazându-se pe o declarație a Institutului Național de Statistică din România, unde se menționează că rezultatele recensământului vor fi secretizate, jurnalista Josefina Pascal afirmă că Organizația Modială de Sănătate va avea acces la informații despre natalitate, sănătate și mortalitate, informații preluate din recensământ, și le va folosi în mod malițios.`,
                "68": `Aparținând unei țări membre a Uniunii europene, Institutul Național de Statistică, înaintează automat rezultatele unui recensământ către Eurostat, care, la rândul său, le trimite către Organizația Mondială a Sănătății. OMS administrează o bază de date mondială despre sănătatea și bunăstarea populației, realizată cu informații pe care le primește de la statele membre. Ideea că OMS ar folosi în mod malițios informațiile cu privire la sănătatea populației din România contravine principiilor organizației, al cărei scop este îmbunătățirea sănătății globale. În ultima vreme, OMS a fost ținta conspiraționiștilor anti-vaxxeri, care leagă organizația de teoriile ”marii resetări”. Jurnalistul face o presupunere defăimătoare, bazată pe o afirmație fărăr dovezi`,
        },
        "activities": {
            "title": `Activități`,
            "description": `În această secțiune veți găsi informații despre activitățile și rezultatele proiectului EDUcate YOUth, precum și un timeline al întâlnirilor din timpul proiectului.`,
            "march": `Martie`,
            "september": `Septembrie`,
            "october": `Octombrie`,
            "november": `Noiembrie`,
            "february": `Februarie`,
            "0": `Obiective`,
            "1": `Analiza nivelului de realizare a fiecărei activități și partajarea feedbackului cu fiecare partener.`,
            "2": `Planificarea următoarelor activități, bazate pe diagrama Gantt.`,
            "3": `Verificarea documentelor necesare pentru raportarea proiectului.`,
            "4": `Conținutul întâlnirilor:`,
            "5": `Ne-am concentrat pe analiza atentă a activităților proiectului, potrivit cărora fiecare partenerar fi trebuit să implementeze metodele dezvoltate la întâlnirea LTT Rânca. Pilotarea metodelor este o etapă importantă din proiect .`,
            "6": `Un alt aspect important al întâlnirii a fost verificarea documentelor, a dovezilor care atestă realizarea fiecărei activități.`,
            "7": `În final, prezentarea următoarelor activități a fost foarte importantă, mai ales că întâlnirea marchează realizarea rezultatului intelectual numărul 1 și tranziția către rezultatul intelectual numărul 2. Au fost discutate premisele realizării platformei online și câteva obiective au foswt stabilite, care trebuie atinse în realizarea platformei.`,
            "8": `Obiective`,
            "9": `Crearea cadrului pentru experimentarea metodologiei dezvoltate de către fiecare partener al proiectului (Metodologie cu activități nonformale pentru dezvoltarea gândirii critice și informare corectă), prin implicarea a 20 de profesori și youth workers din România, Turcia și Italia.`,
            "10": `Dezvoltarea unui incubator de metode non-formale, în timpul trainingului, cu scopul de a îmbunătăți metodele create anterior și de a dezvolta cooperarea și adaptarea metodelor non-formale din educație, la cele din educația clasică.`,
            "11": `Încurajarea cooperării inter-sectoriale dintre instituții publice de educație și ONG-uri din domeniul tineretului, prin realizarea unei scheme de strategie a colaborării dintre cei patru parteneri, pentru următorii 2 ani.`,
            "12": `Conținutul trainingului:`,
            "13": `Am concentrat activitățile spre îmbunătățirea  competențelor în ceea ce privește gândirea critică și informarea corectă a persoanelor implicate în derularea proiectului strategic EDUcate YOUth.`,
            "14": `Până la începerea trainingului, fiecare partener trebuia să aibă pregătite sarcinile specifice rezultatului intelctual numărul 1, după cum urmează:`,
            "15": `Italia:`,
            "16": `Un ghid cu metode non-formale pentru tineri, concentrate pe informarea corectă și pe gândirea critică`,
            "17": `Escape rOOM, joc bazat pe metoda CRAAP`,
            "18": `Harta alternativă a lumii`,
            "19": `Turcia:`,
            "20": `Ghid metodologic pentru profesori și traineri, conținând o selecție de metode non-formale special realizate, cu indicații despre cum și când pot fi aplicate la clasă.`,
            "21": `Oricine poate fi voluntar`,
            "22": `Colectează, crează sijoacă`,
            "23": `Fără stereotipii legate de gen`,
            "24": `Cine crede în superstiții`,
            "25": `Joc de rol & povestea 2 în 1`,
            "26": `România (Liceul Horezu):`,
            "27": `O metodologie de dezbateri, folosită ca un instrument pentru procesul gândirii critice: dezbaterea mobilă`,
            "28": `România (ATDD):`,
            "29": `Joc interactiv pentru dezvoltarea gândirii critice în rândul tinerilor: Supraviețuirea în deșeert`,
            "30": `Participanții din fiecare țară au împărtășit în consorțiu metodele dezvoltate în cadrul rezultatului intelctual numărul 1, metode care au fost experimentate într-un modul pilot, în timpul trainingului. Limbs folosită în timpul activităților a fost engleza.`,
            "31": `Obiective`,
            "32": `Analiza nivelului de realizare a fiecărei activități și partajarea feedbackului cu fiecare partener.`,
            "33": `Planificarea evenimentelor de multiplicare a rezultatelor, pe baza diagrtamei Gantt.`,
            "34": `Verificarea documentelor necesare pentru raportarea proiectului.`,
            "35": `Conținutul întâlnirilor`,
            "36": `Ne-am concentrat pe analiza atentă a activităților proiectului, potrivit cărora fiecare partenerar fi trebuit să implementeze activitățile pentru realizarea platformei on-line.`,
            "37": `Un alt aspect important al întâlnirii a fost verificarea documentelor, a dovezilor care atestă realizarea fiecărei activități.`,
            "38": `Și ultimul, dar nu cel din urmă, am discutat despre planificarea evenimentelor de multiplicare a rezultatelor, care urmează a se desfășura în România, Italia și Turcia. Datele de desfășurare și responsabilii au fost stabilite.`,
            "39": `Obiective:`,
            "40": `Analiza nivelului de realizare a fiecărei activități și partajarea feedbackului cu fiecare partener.`,
            "41": `Planificarea următoarelor activități, bazate pe diagrama Gantt.`,
            "42": `Verificarea documentelor necesare pentru raportarea proiectului.`,
            "43": `Conținutul întâlnirilor:`,
            "44": `Statusul implementării proiectului`,
            "45": `Diagrama Gantt și puncte de reper`,
            "46": `Ținte și rezultate așteptate`,
            "47": `Q&A`,
            "48": `Planul de management al proiectului`,
            "49": `Echipa de management al proiectului`,
            "50": `Instrumente de management al proiectului - Metodologii și proceduri, adminproject.eu`,
            "51": `Raportări tehnice`,
            "52": `Asigurarea calității  & Planul de monitorizare, incluzând QBM- Comisia de asigurare a calității`,
            "53": `Managementul financiar`,
            "54": `Execuții bugetare și documente de raportare`,
            "55": `Management financiar`,
            "56": `Management tehnic`,
            "57": `IO1: Metodologie non-formală pentru dezvoltatea gândirii critice și a informării corecte`,
            "58": `IO2: Platformă digitală de e-learning, pentru dezvoltarea gândirii critice`,
            "59": `Distribuirea sarcinilor`,
            "60": `Planul de comunicare și de diseminare`,
            "61": `Statusul planului de comunicare și raportare`,
            "62": `Indicatori`,
            "63": `Pașii următori`,
            "64": `Q&A general și Feedback`,
            "65": `Vizite culturale`,
            "66": `Muzeul Arheologic Hatay (Al doilea, cel mai amre muzeu arheologic din lume)`,
            "67": `Biserica St.Pierre (cele mai vechi așezări din apropierea Anatoliei de est. Se află pe lista de inventar, la categoria culturală, din patrimoniul mondial UNESCO)`,
            "68": `Moscheea Habibi Neccar`,
            "69": `Biserica catolică Hatay`,
            "70": `Tunelul Vespasianus Titus (un tunel acvatic antic, construit pentru orașul Seleucia Pieria. Se află pe lista de inventar, la categoria culturală, din patrimoniul mondial UNESCO)`,
            "71": `Între 3-10 Octombrie 2022, în localitatea Sinaia, România, a avut loc a doua mobilitate (LTT2) din cadrul proiectului EDUcate YOUth - Nonformal bridges to formal education, finanțat de către Uniunea Europeană, prin programul Erasmus+. `,
            "74": "La această întâlnire au luat parte 20 participanți, câte 5 din partea fiecărui partener: profesori din partea Liceului \"Constantin Brâncoveanu\" din Horezu, profesori și lucrători de tineret din Turcia, lucrători de tineret aparținând ONG-urilor din domeniu tineretului din România (ATDD) și Italia (Beyond Borders). ",
            "75": "În cadrul mobilității au fost analizate instrumentele digitale aferente rezultatului intelectual 2 care pot aplicate online, au fost prezentate și pilotate activitățile educaționale non-formale. Activitățile au avut rolul de a stimula gândirea critică independentă și, prin fiecare instrument educațional, digital, fiecare țară parteneră a adus o contribuție importantă la dezvoltarea metodelor de antrenare a gândirii critice, cât și în ceea ce privește informarea corectă și evitarea dezinformării. Participanții au adus în prim plan elemente de cultură, civilizație și tradiții importante, pentru dezvoltarea unei comunicări eficiente pe termen mediu și lung. O vizită culturală la Brașov a fost de asemenea organizată.",
            "72": `Toate cele 8 metode non-formale create anterior (Escape Room, Harta alternativă a lumii, Dezbateri, Oricine poate fi voluntar, Cine crede în superstiții, Nasreddin Hodja, Colectează, creează si joacă, Supraviețuire ăn deșert) au fost puse în practică și testare de către toți cei 4 parteneri, în comunitățile lor locale și naționale, în faza de pilotare a proiectului. Aceasta etapă a fost foarte importantă deoarece, după ce participanții au luat parte la activitățile care folosesc aceste metode, ei au oferit facilitatorilor feedback, din punctul lor de vedere. Fiecare organizațăie a colectat feedback-ul și a realizat ajustări, conform acestuia.`,
            "73": `Testarea metodelor`,
            "click": "click pentru imagini",
        },
        "footer": {
            "1": "Finanțat de Uniunea Europeană. Punctele de vedere și opiniile exprimate în acest material aparțin  exclusiv autorului (autorilor) și nu reflectă neapărat punctele de vedere și opiniile Uniunii Europene. Uniunea Europeană nu poate fi considerată răspunzătoare pentru acestea.",
        },
        "routes": {
            "Home": "Acasă",
            "Non-Formal Methodology": "Metodologie Non-Formală",
            "Critical Thinking and Disinformation Kit": "KIT gândire critică / Informare corectă",
            "Activities": "Activități",
            "Critical Thinking Games": "Jocuri gândire critică",
            "Non-Formal Debates": "Dezbateri Non-Formale",
            "Fake News Alert": "Alertă FAKE NEWS",
    }
    },
}

export default ro