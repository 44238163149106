import React, { Children, PropsWithChildren, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grow,
  IconButton,
  LinearProgress,
  MobileStepper,
  Paper,
  Snackbar,
  Typography
} from '@mui/material';
import { Answer } from './QuizStep';
import { ArrowRight, Check, Close } from '@mui/icons-material';
import { useRenderOnVisible } from '../../hooks/useRenderOnVisible';

type WizardProps = PropsWithChildren<{
  initialStep?: number;
  startMessage?: string;
  shouldWait?: boolean;
}>

export function Quiz({
  children,
  startMessage,
  initialStep,
  shouldWait = true,
}: WizardProps) {
  const [steps, setSteps] = useState(Children.toArray(children).sort(() => (Math.random() > .5) ? 1 : -1))

  const totalSteps = useRef(steps.length)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<number | undefined>(initialStep)
  const [score, setScore] = useState(0)
  const [isFinished, setIsFinished] = useState(false)
  const [hasPressed, setHasPressed] = useState(false)
  const { visibility, VisibilityComponent } = useRenderOnVisible()

  const roundedScore = Math.round(score / totalSteps.current * 100)

  const retakeQuiz = () => {
    setScore(0)
    setIsFinished(false)
    setHasPressed(false)
    setCurrentStep(0)
    setSteps(steps.sort(() => (Math.random() > .5) ? 1 : -1))
  }

  const handleClickAnswer = (isAnswer: boolean) => {
    if (hasPressed) {
      return
    }

    if (isAnswer) {
      setScore((prevScore) => prevScore + 1)
    }

    setHasPressed(true)
  }

  const handleGoToNext = () => {
    if (currentStep! + 1 === totalSteps.current) {
      setIsFinished(true)
      return
    }

    setHasPressed(false)
    setCurrentStep((prevCurrentStep) => prevCurrentStep! + 1)
  }

  return (
    <>
      <Snackbar
        open={isSnackBarOpen}
        TransitionComponent={Grow}
        autoHideDuration={4000}
        onClose={(event: React.SyntheticEvent | Event, reason?: string) => reason !== 'click' && setIsSnackBarOpen(false)}
      >
        <Alert onClose={() => setIsSnackBarOpen(false)} severity="error" sx={{ width: '100%' }}>
          Oops! Change to desktop version to see this content.
        </Alert>
      </Snackbar>
      <Box sx={{ textAlign: 'center', display: ['block', 'none'] }}>
        <Button
          variant='outlined'
          sx={{ fontSize: 36, transition: 'all .2 ease-in-out', ':hover': { transform: 'scale(1.1)' } }}
          onClick={() => setIsSnackBarOpen(true)}
        >
          Start!
        </Button>
      </Box>
      <Paper
        elevation={2}
        sx={{
          minHeight: 750,
          display: ['none', 'flex'],
          mb: 15,
          justifyContent: 'center'
        }}
      >
        <Box sx={{ m: [10, 5], justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          {(currentStep === undefined || isFinished) ? (
            isFinished
              ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                  <Box>
                    {score === totalSteps.current ? (
                      <Typography variant='h4'>Congratulations!</Typography>
                    ) : (
                      <>
                        <Typography variant='h4'>Re-take quiz and improve your skills!</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={roundedScore} />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">{`${roundedScore}%`}</Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                  <Button
                    variant='outlined'
                    sx={{ width: 'fit-content', fontSize: 36, mt: 5, transition: 'all .2 ease-in-out', ':hover': { transform: 'scale(1.1)' } }}
                    onClick={retakeQuiz}
                  >
                    Re-take Quiz!
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                  {VisibilityComponent}
                  {startMessage && visibility && <Typography className='animate__animated animate__zoomInDown animate__slow' variant='h3'>{startMessage}</Typography>}
                  <Box className='animate__animated animate__fadeIn animate__delay-3s'>
                    <Button
                      className='animate__animated animate__pulse animate__infinite'
                      variant='outlined'
                      sx={{ fontSize: 36, mt: startMessage ? 6 : 0, transition: 'all .2 ease-in-out', ':hover': { transform: 'scale(1.1)' } }}
                      onClick={() => setCurrentStep(0)}
                    >
                      Start!
                    </Button>
                  </Box>
                </Box>
              )
            ) : (
              <Box>
                <Box sx={{ width: '100%' }}>
                  <LinearProgress color='primary' variant="determinate" value={roundedScore} />
                </Box>
                {steps[currentStep]}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    mt: 5,
                  }}
                >
                  {shouldWait && hasPressed && (
                    <Box className="animate__animated animate__fadeIn" sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', order: 999 }}>
                      <Button
                        disabled={!hasPressed}
                        variant='outlined'
                        startIcon={<ArrowRight/>}
                        onClick={handleGoToNext}
                      >
                        Next
                      </Button>
                    </Box>
                  )}
                  {(steps[currentStep] as React.ReactElement).props.answers.map(({ isAnswer, name, order }: Answer, index: number) => (
                    <Button
                      key={index}
                      color={hasPressed ? (isAnswer ? 'success' : 'error') : 'primary'}
                      variant='outlined'
                      startIcon={hasPressed ? (isAnswer ? <Check /> : <Close />) : undefined}
                      sx={{ order, fontSize: 36, minWidth: 200, transition: 'all .2s ease-in-out', transform: hasPressed ? (isAnswer ? 'scale(1.3)' : 'scale(1)') : 'scale(1)', ':hover': { transform: hasPressed ? (isAnswer ? 'scale(1.3)' : 'scale(1)') : 'scale(1.1)' } }}
                      onClick={() => handleClickAnswer(isAnswer)}
                    >
                      {name}
                    </Button>
                  ))}
                </Box>
                <MobileStepper
                  backButton={<IconButton/>}
                  nextButton={<IconButton/>}
                  steps={totalSteps.current}
                  activeStep={currentStep}
                  sx={{ position: 'relative', mt: 5 }}
                />
              </Box>
            )}
        </Box>
      </Paper>
    </>
  )
}