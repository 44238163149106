import React, { FC, memo } from 'react';
import { EduList } from '../../../List/List';
import { Bold, InlineBold, Italic } from '../../../Typography/Inline';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const EscapeRoom: FC = memo(function EscapeRoom() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='escape_room'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: t(`methodologies.Escape Room`),
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          <>
            {t('escape.0')}
          </>
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('escape.24'),
              t('escape.25'),
              t('escape.26'),
              t('escape.27'),
              t('escape.28'),
              t('escape.29'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <InlineBold>
              {t('escape.1')}
            </InlineBold>
            <br/>
            <EduList
              items={[
                t('escape.30'),
                t('escape.31'),
                t('escape.32'),
                t('escape.33'),
                t('escape.34'),
              ]}
            />
            <br/>
            <InlineBold>
              {t('escape.2')}
            </InlineBold>
            <br/>
            {t('escape.3')}
            <br/>
            {t('escape.4')}
            <br/><br/>
            <Italic>
              {t('escape.5')}
              <br/>
              {t('escape.6')}
              <br/>
              {t('escape.7')}
            </Italic>
            <br/><br/>
            <InlineBold>
              {t('escape.8')}
            </InlineBold>
            <br/><br/>

            <Bold>
              {t('escape.9')}
            </Bold>
            {t('escape.10')}
            <br/><br/>

            <Bold>
              {t('escape.11')}
            </Bold>
            {t('escape.12')}
            <br/><br/>

            <Bold>
              {t('escape.13')}
            </Bold>
            {t('escape.14')}
            <EduList
              items={[
                t('escape.35'),
                t('escape.36'),
                t('escape.37'),
                t('escape.38'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <Italic>
              {t('escape.15')}
            </Italic>
            <EduList
              items={[
                t('escape.39'),
                t('escape.40'),
                t('escape.41'),
                t('escape.42'),
              ]}
            />
            <Bold>
              {t('escape.16')}
            </Bold>
            {t('escape.17')}
            <br/><br/>
            <Bold>
              {t('escape.18')}
            </Bold>
            {t('escape.19')}
            <br/><br/>
            <Bold>
              {t('escape.20')}
            </Bold>
            {t('escape.21')}
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            <Italic>
              {t('escape.22')}
            </Italic>
            <br/><br/>
            {t('escape.23')}
            <EduList
              items={[
                t('escape.43'),
                t('escape.44'),
                t('escape.45'),
                t('escape.46'),
                t('escape.47'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})