import { initializeApp, FirebaseApp } from 'firebase/app';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getFirestore, Firestore } from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider, AppCheck } from 'firebase/app-check';
import { getAnalytics, Analytics } from "firebase/analytics";
import reportWebVitals from '../reportWebVitals';


let storage: FirebaseStorage;
let app: FirebaseApp;
let appCheck: AppCheck;
let database: Firestore;
let analytics: Analytics

function init() {
  const {
    REACT_APP_API_KEY: apiKey,
    REACT_APP_AUTH_DOMAIN: authDomain,
    REACT_APP_PROJECT_ID: projectId,
    REACT_APP_STORAGE_BUCKET: storageBucket,
    REACT_APP_MESSAGING_SENDER_ID: messagingSenderId,
    REACT_APP_APP_ID: appId,
    REACT_APP_MEASUREMENT_ID: measurementId,
    REACT_APP_RECAPTCHA_V3_SITE_KEY: siteV3Key,
  } = process.env

  const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  };

  app = initializeApp(firebaseConfig);

  analytics = getAnalytics(app);

  // Initialize Firebase App Check
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(siteV3Key!),
    // isTokenAutoRefreshEnabled: true,
  })

  app = appCheck.app;

  // Get a reference to the storage service, which is used to create references in your storage bucket
  storage = getStorage(app);

  // Get a Firestore instance
  database = getFirestore(app)

  reportWebVitals(analytics);
}

export {
  init,
  app,
  storage,
  database,
  analytics,
}