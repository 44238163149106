import React, { useEffect, useRef, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Description } from '../../Description/Description';
import { Hero } from '../../Hero/Hero';
import { Box, Button, CircularProgress, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { getListAll, getStorageUrl } from '../../../services/storage';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function Activities() {
  const { t } = useTranslation()
  const galleryRef = useRef<ReactImageGallery>(null)
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string | null>(null);
  const [images, setImages] = useState<Record<string, ReactImageGalleryItem[]>>({})
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const startInFullscreen = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    (async (selectedImages: string | null) => {
      if (!selectedImages) {
        return
      }

      if (images[selectedImages]) {
        setLoading(false)
        return
      }

      const { items } = await getListAll(selectedImages);
      const range = Array(items.length / 2).fill(0);

      const imagesUrl: string[] = await Promise.all(range.map((_, index: number) =>
        getStorageUrl(`${selectedImages}/${index + 1}.jpeg`)
      ))

      const thumbNailsUrl: string[] = await Promise.all(range.map((_, index: number) =>
        getStorageUrl(`${selectedImages}/${index+1}_Small.jpeg`)
      ))

      const newImages: ReactImageGalleryItem[] = thumbNailsUrl.map((_, index: number) => ({
        original: imagesUrl[index],
        loading: 'lazy',
        thumbnail: thumbNailsUrl[index],
      }))

      setImages((prevImages) => ({ ...prevImages, [selectedImages]: newImages }))
      setLoading(false)
    })(selectedImages)
  }, [selectedImages, images])

  const handleClose = () => {
    setOpen(false);
    setSelectedImages(null)
  }

  const handleOpenMedia = (folder: string) => {
    setLoading(true)
    setOpen(true)
    console.log(startInFullscreen)
    if (startInFullscreen) {
      galleryRef.current?.fullScreen()
    }
    setSelectedImages(folder);
  }

  return (
    <>
      <Hero imageName='media'>
        {t('activities.title')}
      </Hero>
      <Description>
        {t('activities.description')}
      </Description>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {loading ? (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ) : (
            <ReactImageGallery
              ref={galleryRef}
              lazyLoad
              items={selectedImages ? images[selectedImages] : []}
              useTranslate3D
              showThumbnails
              showIndex
              showNav
              showFullscreenButton={false}
              showPlayButton={false}
              slideDuration={650}
            />
          )}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </Modal>
      <Box sx={{ my: 10 }}>
        <VerticalTimeline
          lineColor="rgba(246, 246, 244, 1)"
        >
          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`19-20 ${t('activities.march')} 2021`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='italy' src={require('../../../assets/images/flags/italy.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('tpm_italia')}
            onTimelineElementClick={() => handleOpenMedia('tpm_italia')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                TPM Italy
              </Box>
              <h4 className="vertical-timeline-element-subtitle">
                Corato, Italy
              </h4>
              <p>
                {t('activities.0')}
              </p>
              <ul>
                <li>
                  {t('activities.1')}
                </li>
                <li>
                  {t('activities.2')}
                </li>
                <li>
                  {t('activities.3')}
                </li>
              </ul>
              <p>
                {t('activities.4')}
              </p>
              <ul>
                <li>
                  {t('activities.5')}
                </li>
                <li>
                  {t('activities.6')}
                </li>
                <li>
                  {t('activities.7')}
                </li>
              </ul>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' sx={{ margin: 'auto' }}>
                  {t('activities.click')}
                </Button>
              </Box>
            </Box>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`12-19 ${t('activities.september')} 2021`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='romania' src={require('../../../assets/images/flags/romania.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('ltt_ranca')}
            onTimelineElementClick={() => handleOpenMedia('ltt_ranca')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                LTT Ranca
              </Box>
              <h4 className="vertical-timeline-element-subtitle">
                Romania
              </h4>
              <p>
                {t('activities.8')}
              </p>
              <ul>
                <li>
                  {t('activities.9')}
                </li>
                <li>
                  {t('activities.10')}
                </li>
                <li>
                  {t('activities.11')}
                </li>
              </ul>
              <p>
                {t('activities.12')}
              </p>
              <ul>
                <li>
                  {t('activities.13')}
                </li>
                <li>
                  {t('activities.14')}
                  <p>
                    {t('activities.15')}
                  </p>
                  {t('activities.16')}
                  <ul>
                    <li>
                      {t('activities.17')}
                    </li>
                    <li>
                      {t('activities.18')}
                    </li>
                  </ul>
                  <p>
                    {t('activities.19')}
                  </p>
                  {t('activities.20')}
                  <ul>
                    <li>
                      {t('activities.21')}
                    </li>
                    <li>
                      {t('activities.22')}
                    </li>
                    <li>
                      {t('activities.23')}
                    </li>
                    <li>
                      {t('activities.24')}
                    </li>
                    <li>
                      {t('activities.25')}
                    </li>
                  </ul>
                  <p>
                    {t('activities.26')}
                  </p>
                  {t('activities.27')}
                  <p>
                    {t('activities.28')}
                  </p>
                  {t('activities.29')}
                </li>
                <br/>
                <li>
                  {t('activities.30')}
                </li>
              </ul>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' sx={{ margin: 'auto' }}>
                  {t('activities.click')}
                </Button>
              </Box>
            </Box>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`20-23 ${t('activities.november')} 2021`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='turkey' src={require('../../../assets/images/flags/turkey.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('tpm_turcia')}
            onTimelineElementClick={() => handleOpenMedia('tpm_turcia')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                TPM Türkiye
              </Box>
              <h4 className="vertical-timeline-element-subtitle">
                Belen, Hatay, Türkiye
              </h4>
              <p>
                {t('activities.39')}
              </p>
              <ul>
                <li>
                  {t('activities.40')}
                </li>
                <li>
                  {t('activities.41')}
                </li>
                <li>
                  {t('activities.42')}
                </li>
              </ul>
              <p>
                {t('activities.43')}
              </p>
              <ol>
                <li>
                  {t('activities.44')}
                </li>
                <ul>
                  <li>
                    {t('activities.45')}
                  </li>
                  <li>
                    {t('activities.46')}
                  </li>
                  <li>
                    {t('activities.47')}
                  </li>
                </ul>
                <li>
                  {t('activities.48')}
                </li>
                <ul>
                  <li>
                    {t('activities.49')}
                  </li>
                  <li>
                    {t('activities.50')}
                  </li>
                  <li>
                    {t('activities.51')}
                  </li>
                  <li>
                    {t('activities.52')}
                  </li>
                </ul>

                <li>
                  {t('activities.53')}
                </li>
                <ul>
                  <li>
                    {t('activities.54')}
                  </li>
                  <li>
                    {t('activities.55')}
                  </li>
                </ul>

                <li>
                  {t('activities.56')}
                </li>
                <ul>
                  <li>
                    {t('activities.57')}
                  </li>
                  <li>
                    {t('activities.58')}
                  </li>
                  <li>
                    {t('activities.59')}
                  </li>
                </ul>

                <li>
                  {t('activities.60')}
                </li>
                <ul>
                  <li>
                    {t('activities.61')}
                  </li>
                  <li>
                    {t('activities.62')}
                  </li>
                  <li>
                    {t('activities.63')}
                  </li>
                  <li>
                    {t('activities.64')}
                  </li>
                </ul>

                <li>
                  {t('activities.65')}
                </li>
                <ul>
                  <li>
                    {t('activities.66')}
                  </li>
                  <li>
                    {t('activities.67')}
                  </li>
                  <li>
                    {t('activities.68')}
                  </li>
                  <li>
                    {t('activities.69')}
                  </li>
                  <li>
                    {t('activities.70')}
                  </li>
                </ul>
              </ol>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' sx={{ margin: 'auto' }}>
                  {t('activities.click')}
                </Button>
              </Box>
            </Box>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`${t('activities.october')} 2021 - ${t('activities.february')} 2022`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='romania' src={require('../../../assets/images/flags/romania.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('method_testing')}
            onTimelineElementClick={() => handleOpenMedia('method_testing')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                {t('activities.73')}
              </Box>
              {t('activities.72')}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant='contained' sx={{ margin: 'auto' }}>
                  {t('activities.click')}
                </Button>
              </Box>
            </Box>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`1-2 ${t('activities.october')} 2022`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='romania' src={require('../../../assets/images/flags/romania.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('ltt_sinaia')}
            onTimelineElementClick={() => handleOpenMedia('ltt_sinaia')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                TPM Sinaia
              </Box>
              <h4 className="vertical-timeline-element-subtitle">
                Sinaia, Romania
              </h4>
              <p>
                {t('activities.31')}
              </p>
              <ul>
                <li>
                  {t('activities.32')}
                </li>
                <li>
                  {t('activities.33')}
                </li>
                <li>
                  {t('activities.34')}
                </li>
              </ul>
              <p>
                {t('activities.35')}
              </p>
              <ul>
                <li>
                  {t('activities.36')}
                </li>
                <li>
                  {t('activities.37')}
                </li>
                <li>
                  {t('activities.38')}
                </li>
              </ul>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' sx={{ margin: 'auto' }}>
                  {t('activities.click')}
                </Button>
              </Box>
            </Box>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="scale"
            contentStyle={{ background: '#f6f6f4', color: 'black', cursor: 'pointer' }}
            contentArrowStyle={{ borderRight: '20px solid rgba(246, 246, 244, 1)' }}
            date={`3-10 ${t('activities.october')} 2022`}
            iconStyle={{ background: 'rgba(0, 0, 0, .1)', color: 'black', cursor: 'pointer' }}
            icon={<Box component='img' alt='italy' src={require('../../../assets/images/flags/romania.png')} sx={{ height: [40, 60] }} />}
            iconOnClick={() => handleOpenMedia('tpm_sinaia')}
            onTimelineElementClick={() => handleOpenMedia('tpm_sinaia')}
          >
            <Box sx={{ my: 2 }}>
              <Box component="h4" className="vertical-timeline-element-title">
                LTT2 (Learning, Teaching, Training 2) - Sinaia
              </Box>
              <h4 className="vertical-timeline-element-subtitle">
                Sinaia, Romania
              </h4>
              <Box component='div' sx={{ mt: 2 }}>
                {t('activities.71')}
                <br/>
                <br/>
                {t('activities.74')}
                <br/>
                <br/>
                {t('activities.75')}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button variant='contained' sx={{ margin: 'auto' }}>
                    {t('activities.click')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Box>
    </>
  )
}