import React, { FC, memo } from 'react';
import { Bold } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Superstition: FC = memo(function SuperstitionComponent() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='superstition'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: 'Who believes in superstition?',
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          t('superstition.1')
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t("superstition.2"),
              t("superstition.3"),
              t("superstition.4"),
              t("superstition.5"),
              t("superstition.6"),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <Bold>{t('methodologies.Preparation of the group')}:</Bold>
            <EduList
              items={[
                t('superstition.8'),
                t('superstition.9'),
                t('superstition.10'),
                t('superstition.11'),
                t('superstition.12'),
              ]}
            />
            <Bold>{t('methodologies.Implementing activities')}:</Bold>
            <EduList
              items={[
                t('superstition.13'),
                t('superstition.14'),
                t('superstition.15'),
                t('superstition.16'),
                t('superstition.17'),
                t('superstition.18'),
                t('superstition.19'),
                t('superstition.20'),
                t('superstition.21'),
                t('superstition.22'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            {t('superstition.25')}
            <br/>
            {t('superstition.26')}
            <br/>
            {t('superstition.27')}
            <br/>
            {t('superstition.28')}
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            <EduList
              items={[
                t('superstition.23'),
                t('superstition.24'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
