import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export function Italic({ children, ...props }: PropsWithChildren<TypographyProps>) {
  return (
    <InlineTypography fontStyle='italic' {...props}>
      {children}
    </InlineTypography>
  )
}

export function InlineBold({ children, ...props }: PropsWithChildren<TypographyProps>) {
  return (
    <InlineTypography fontWeight='bold' {...props}>
      {children}
    </InlineTypography>
  )
}

export function Bold({ children, ...props }: PropsWithChildren<TypographyProps>) {
  return (
    <Typography fontWeight='bold' {...props}>
      {children}
    </Typography>
  )
}

export function InlineTypography({ children, ...typographyProps }: PropsWithChildren<TypographyProps>) {
  return (
    <Typography sx={{ display: 'inline-flex' }} {...typographyProps}>
      {children}
    </Typography>
  );
}