import React, { FC, memo } from 'react';
import { Bold, Italic } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Methodology } from '../Methodologies.types';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AlternativeWorldMap: FC<Methodology> = memo(function AlternativeWorldMapComponent({
  title,
}) {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='map'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title,
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          <>
            {t('worldmap.0')}
          </>
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('worldmap.12'),
              t('worldmap.13'),
              t('worldmap.14'),
              t('worldmap.15'),
              t('worldmap.16'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <Bold>
              {t('worldmap.1')}
            </Bold>
            {t('worldmap.2')}
            <br/>
            <br/>
            <Bold>
              {t('worldmap.3')}
            </Bold>
            {t('worldmap.4')}
            <br/><br/>
            {t('worldmap.5')}
            <br/>
            {t('worldmap.6')}
            <br/><br/>
            {t('worldmap.7')}
            <br/><br/>
            <Bold>
              {t('worldmap.8')}
            </Bold>
            {t('worldmap.9')}
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <Italic>
              {t('worldmap.10')}
            </Italic>
            <EduList
              items={[
                t('worldmap.17'),
                t('worldmap.18'),
                t('worldmap.19'),
                t('worldmap.20'),
                t('worldmap.21'),
                t('worldmap.22'),
                t('worldmap.23'),
                t('worldmap.24'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            <Italic>
              {t('worldmap.11')}
            </Italic>
            <EduList
              items={[
                t('worldmap.25'),
                t('worldmap.26'),
                t('worldmap.27'),
                t('worldmap.28'),
                t('worldmap.29'),
                t('worldmap.31'),
                t('worldmap.32'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
