import {
  Box, Button,
  Container,
  Link,
  Tab,
  Tabs
} from '@mui/material';
import { Hero } from '../../Hero/Hero';
import { Description } from '../../Description/Description';
import { MethodologiesItems } from './Methodologies.constants';
import { Location, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from '@mui/lab';
import { TabPanelModel } from '../../../models/TabPanelModel';
import { useComputedResource } from '../../../hooks/useComputedResource';
import { School } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function Methodologies() {
  const { t } = useTranslation()

  const location: Location = useLocation();

  const [ bookletUrl ] = useComputedResource('booklet', 'pdfs', 'pdf')

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, [])

  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  useEffect(() => {
  }, [location]);

  return (
    <>
      <Hero imageName="methodologies" extension='jpeg'>{t('methodologies.title')}</Hero>
      <Description>
        {t("methodologies.description")}
      </Description>
      <Container id='content' sx={{ px: [0, 2] }}>
        <TabContext value={tabIndex.toString()}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabIndex}
            textColor="primary"
            onChange={handleChange}
          >
            {MethodologiesItems.map((item: TabPanelModel, index: number) => (
              <Tab key={index} value={index} label={t(`methodologies.${item.title}`)} />
            ))}
          </Tabs>
          <SwipeableViews
            springConfig={{ duration: '0.5s', easeFunction: 'ease-in-out', delay: '0s' }}
            index={tabIndex}
            onChangeIndex={handleChangeIndex}
          >
            {MethodologiesItems.map(({ component: Component, title }: TabPanelModel, index: number) => (
              <TabPanel key={index} value={index.toString()} tabIndex={index} sx={{ p: '2px' }}>
                <Component title={t(`methodologies.${title}`)} />
              </TabPanel>
            ))}
          </SwipeableViews>
        </TabContext>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: [0, 5] }}>
          <Link href={bookletUrl} target='_blank' rel='noopener noreferrer' underline='none'>
            <Button variant='contained' endIcon={<School/>}>
              Booklet
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
}