import { ref, getDownloadURL, StorageReference, listAll, ListResult } from 'firebase/storage';
import { storage } from './firebase';

export function getRef(path: string, otherStorage?: StorageReference): StorageReference {
  return ref(otherStorage || storage, path);
}

export async function getStorageUrl(path: string): Promise<string> {
  return await getDownloadURL(ref(storage, path));
}

export async function getListAll(path: string): Promise<ListResult> {
  return await listAll(getRef(path));
}

