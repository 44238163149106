import { EscapeRoom } from './MethodologiesSubPages/EscapeRoom';
import { TabPanelModel } from '../../../models/TabPanelModel';
import { AlternativeWorldMap } from './MethodologiesSubPages/AlternativeWorldMap';
import { DebateMethodology } from './MethodologiesSubPages/DebateMethodology';
import { Volunteer } from './MethodologiesSubPages/Volunteer';
import { Hoca } from './MethodologiesSubPages/Hoca';
import { Collect } from './MethodologiesSubPages/Collect';
import { Desert } from './MethodologiesSubPages/Desert';
import { Superstition } from './MethodologiesSubPages/Superstition';

export const MethodologiesItems: TabPanelModel[] = [
  {
    title: 'Escape Room',
    component: EscapeRoom,
    imageName: 'escape_room',
  },
  {
    title: 'Alternative World Map',
    component: AlternativeWorldMap,
    imageName: 'map',
  },
  {
    title: 'Debate',
    component: DebateMethodology,
    imageName: 'debate',
  },
  {
    title: 'Anyone Can Volunteer',
    component: Volunteer,
    imageName: 'volunteer',
  },
  {
    title: 'Who Believes in Superstition?',
    component: Superstition,
    imageName: 'superstition',
  },
  {
    title: 'Nasreddin Hodja',
    component: Hoca,
    imageName: 'hoca',
  },
  {
    title: 'Collect Create and Play',
    component: Collect,
    imageName: 'we_like_to_create',
  },
  {
    title: 'Survival in the Desert',
    component: Desert,
    imageName: 'desert',
  }
];