import { Styles } from '../../types';

const styles: Styles = {
  Main: () => ({
    pt: 11,
    pb: ['18vh', '232px'],
    position: 'relative',
  }),
}

export default styles