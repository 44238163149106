import { QuizStepProps } from '../../Quiz/QuizStep';

export const FAKE_NEWS_QUIZ_ITEMS: QuizStepProps[] = [ {
  imageName: '1',
  answers: [ true, false ],
}, {
  imageName: '2',
  answers: [ true, false ]
}, {
  imageName: '3',
  answers: [ false, true ]
}, {
  imageName: '4',
  answers: [ false, true ]
}, {
  imageName: '5',
  answers: [ true, false ]
}, {
  imageName: '6',
  answers: [ false, true ]
}, {
  imageName: '7',
  answers: [ false, true ]
}, {
  imageName: '8',
  answers: [ true, false ]
}, {
  imageName: '9',
  answers: [ false, true ]
}, {
  imageName: '10',
  answers: [ false, true ]
}, {
  imageName: '11',
  answers: [ false, true ]
}, {
  imageName: '12',
  answers: [ true, false ]
}, {
  imageName: '13',
  answers: [ true, false ]
}, {
  imageName: '14',
  answers: [ false, true ]
}, {
  imageName: '15',
  answers: [ true, false ]
}, {
  imageName: '16',
  answers: [ true, false ]
} ].map(({imageName, answers}) => ({
  imageName,
  base: 'games',
  answers: answers ? answers.map((isAnswer, index) => ({  isAnswer, name: !index ? 'fact' : 'opinion', order: index * 2 })) : undefined
}));