import { Styles } from '../../types';

const styles: Styles = {
  HeroOverlay: (imageUrl, hasFilter, size) => ({
    mb: 2,
    maxHeight: { md: size === 'small' ? 450 : '80vh', xs: '80vh' },
    position: 'relative',
    height: {
      md: '750px',
      xs: '400px'
    },
    minHeight: 500,
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, transparent 90%, rgba(255, 255, 255, 1)), url(${imageUrl})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '::before': {
      background: hasFilter && `linear-gradient(to bottom, rgba(0,0,0,1) 60%, rgba(255, 255, 255, 1))`,
      position: 'absolute',
      content: '""',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      opacity: '0.7',
    },
  }),
  HeroContainer: (size) => ({
    maxHeight: { md: size === 'small' ? 450 : '80vh', xs: '80vh' },
    position: 'relative',
    height: {
      md: '750px',
      xs: '400px'
    },
    width: '100%',
    '::before': {
      background: `linear-gradient(to bottom, rgba(0,0,0,1) 50%, rgba(255, 255, 255, 1))`,
      position: 'absolute',
      content: '""',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      opacity: '0.3',
    },
  }),
  Title: () => ({
    fontSize: {
      md: 'inherit',
      xs: '40px'
    },
    animationDelay: '.3s',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    color: 'white',
    p: 2,
    lineHeight: 1.2,
    textShadow: '0px 0px 5px black',
  })
}

export default styles