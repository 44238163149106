import React from 'react';
import { Hero } from '../../Hero/Hero';
import { Description } from '../../Description/Description';
import { Box, Button, Container, Link } from '@mui/material';
import { useComputedResource } from '../../../hooks/useComputedResource';
import { useRenderOnVisible } from '../../../hooks/useRenderOnVisible';
import { FAKE_NEWS_QUIZ_ITEMS } from './Games.constants';
import { QuizStep } from '../../Quiz/QuizStep';
import { Quiz } from '../../Quiz/Quiz';
import { useTranslation } from 'react-i18next';

export function Games() {
  const { t } = useTranslation()
  const { visibility, VisibilityComponent } = useRenderOnVisible()
  const [ pdfUrl ] = useComputedResource('game', 'pdfs', 'pdf', [visibility])

  return (
    <>
      <Hero imageName='games'>{t('games.title')}</Hero>
      <Description>
        {t('games.description')}
      </Description>
      <Container fixed>
        <Quiz startMessage={t('games.startMessage')!}>
          {FAKE_NEWS_QUIZ_ITEMS.map((props, index) => (
            <QuizStep key={index} {...props} />
          ))}
        </Quiz>
      </Container>
      <Description>
        {t('games.description2')}
      </Description>
      <Container>
        {VisibilityComponent}
        <Box sx={{ display: ['flex', 'none'], justifyContent: 'center' }}>
          <Button variant='outlined' component={Link} href={pdfUrl} target='_blank' rel='noopener noreferrer' sx={{ fontSize: 36 }}>
            {t('games.download')}
          </Button>
        </Box>
        <Box sx={{ display: ['none', 'flex'] }}>
          {pdfUrl && visibility && (
            <Box
              component='object'
              data={pdfUrl}
              type='application/pdf'
              width='100%'
              height='700px'
            />
          )}
        </Box>
      </Container>
    </>
  )
}