import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import { FacebookTwoTone, Language, YouTube } from '@mui/icons-material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

export const Footer: FC = memo(function FooterComponent() {
  const { t } = useTranslation()

  return (
    <Box
      component='footer'
      sx={{
        position: 'absolute',
        width: '100%',
        height: '15vh',
        bottom: 0,
        display: 'flex',
        backgroundImage: `linear-gradient(to bottom, white, #f6f6f4 5%)`,
      }}
    >
      <Divider/>
      <Box sx={{ ml: [0, 3], my: 1, overflow: 'hidden', display: 'flex', flexDirection: ['column', 'row'], alignItems: ['none', 'center'], width: ['100%', '75%'] }}>
        <Box component='img' sx={{ width: [150, 190], ml: [2, 0] }} alt="eu_fund" src={require('../../assets/images/logo/eu_fund.png')}/>
        <Typography variant='subtitle1' sx={{ fontSize: [10, 14], mx: [2, 2] }}>
          {t("footer.1")}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center', py: 3, width: '25%', display: ['none', 'block'] }}>
        <Box sx={{ pr: 10 }}>
          <Link href='https://www.facebook.com/ATDD.ro/' target='_blank'>
            <IconButton>
              <FacebookTwoTone/>
            </IconButton>
          </Link>
          <Link href='https://www.youtube.com/channel/UCc3LK5-i9Mr-006evz19fvA' target='_blank'>
            <IconButton>
              <YouTube/>
            </IconButton>
          </Link>
          <Link href='https://www.atdd.ro' target='_blank'>
            <IconButton>
              <Language/>
            </IconButton>
          </Link>
        </Box>
        <Typography variant="body2" sx={{ mb: 3, pr: 10, fontWeight: 'bold' }}>A.T.D.D &copy; <span>{new Date().getFullYear()}</span></Typography>
      </Box>
    </Box>
  );
})