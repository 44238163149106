import React, { useEffect, useMemo } from 'react';
import { Header } from '../Header/Header';
import { Route, Routes, useLocation, Location, useMatch } from 'react-router-dom';
import { NotFound } from '../Pages/404/NotFound';
import { Box, useScrollTrigger } from '@mui/material';
import { Footer } from '../Footer/Footer';
import { getRoutes, RouteName } from '../../routes/routes';
import { ScrollToTop } from '../ScrollToTop/ScrollToTop';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import styles from './App.styles';
import { ROUTES_ORDER } from '../../routes/routes.constants';

function App() {
  const location: Location = useLocation()
  const match = useMatch({ path: ':lang/*', end: false })

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  });

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location])

  const routes = useMemo(() =>
    getRoutes(match?.params?.lang)
  , [match?.params?.lang])

  return (
    <>
      <Header/>
      <Box component='main' sx={styles.Main()}>
        <Routes>
          {ROUTES_ORDER.map((routeName: RouteName) => {
            const { element: Element, path, name } = routes[routeName]
            return <Route key={name} path={path} element={<Element />} />
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
      <LanguageSwitcher />
      <Footer/>
      {trigger && <ScrollToTop />}
    </>
  );
}

export default App;
