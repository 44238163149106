import { Analytics, logEvent } from 'firebase/analytics';

const reportWebVitals = (analytics: Analytics) => {
  const handler = (metric: any) => {
    console.log(metric)
    // Pass on to Google Analytics
    logEvent(analytics, 'web-vitals', {
      name: metric.name,
      delta: metric.delta,
      value: Math.round(metric.value), // values must be integers
      id: metric.id, // id unique to current page load
    });
  }

  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(handler);
    getFID(handler);
    getFCP(handler);
    getLCP(handler);
    getTTFB(handler);
  });
};

export default reportWebVitals;
