import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  styled,
  Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { FC, memo, useCallback, useState } from 'react';
import { FakeNewsDoc } from '../../../../types';
import { useTranslation } from 'react-i18next';

type FakeNewsCardProps = FakeNewsDoc & {
  isExpanded: boolean;
}

const ellipsisTitleStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const StyledAccordionSummary = styled(({ children, ...restOfProps }: AccordionSummaryProps) => (
  <AccordionSummary {...restOfProps}>
    {children}
  </AccordionSummary>
))((theme) => ({
  '& .MuiAccordionSummary-content': {
    maxWidth: '80vw',
  },
}))

export const FakeNewsCard: FC<FakeNewsCardProps> = memo(function FakeNewsCardComponent({
  description,
  disproof,
  link,
  title,
  isExpanded,
}: FakeNewsCardProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(isExpanded);

  const handleChange = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [])

  return (
    <Accordion expanded={open} onChange={handleChange}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          backgroundColor: open ? 'primary.light' : 'secondary.main',
          content: {
            maxWidth: '100%'
          }
        }}
      >
        <Typography
          variant='h6'
          sx={{ fontWeight: open ? 'bold' : 'normal', ...(!open ? ellipsisTitleStyle : {}) }}
        >
          {title}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Typography gutterBottom variant='subtitle1' sx={{ fontWeight: 'bold', ml: '3px' }}>
          {t('fakenews.17')}
        </Typography>
        <Typography sx={{ overflowWrap: 'break-word' }}>
          {description}
        </Typography>
      </AccordionDetails>
      <AccordionDetails>
        <Typography gutterBottom variant='subtitle1' sx={{ fontWeight: 'bold', ml: '3px' }}>
          {t('fakenews.18')}
        </Typography>
        <Typography variant='body1'>
          {disproof}
        </Typography>
      </AccordionDetails>
      <AccordionDetails>
        <Typography gutterBottom variant='subtitle1' sx={{ fontWeight: 'bold', ml: '3px' }}>
          {t('fakenews.19')}
        </Typography>
        <Typography
          variant='body1'
          component='a'
          href={link}
          target='_blank'
          rel='noreferrer'
        >
          {link}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
})