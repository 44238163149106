import { memo } from 'react';
import { Box, Grid, Link } from '@mui/material';
import { useComputedResource } from '../../hooks/useComputedResource';
import { useRenderOnVisible } from '../../hooks/useRenderOnVisible';

interface PartnerLogoProps {
  imageName: string,
  index: number,
  href?: string
}

export const PartnerLogo = memo(function PartnerLogoComponent({
  index,
  href,
  imageName,
}: PartnerLogoProps) {
  const { visibility, VisibilityComponent } = useRenderOnVisible()
  const [image]: string[] = useComputedResource(imageName, 'partner_logos', 'jpeg');

  return (
    <Grid item xs={8} md={6} lg={4} key={imageName} sx={{ mx: 'auto' }}>
      {VisibilityComponent}
      {visibility && (
        <Box
          sx={{
          display: 'flex',
          justifyContent: 'center',
          transition: 'all 0.5s',
          ':hover': {
            transform: 'scale(1.1)',
          },
        }}>
          <Link href={href} target='_blank'>
            <img style={{ height: 250 }} src={image} alt={imageName}/>
          </Link>
        </Box>
      )}
    </Grid>
  )
})