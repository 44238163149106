import { SubsectionProps } from '../Subsection/Subsection';
import { SxProps } from '@mui/material';

export const sharedProps: Partial<SubsectionProps> = {
  hasDivider: false,
  titleStyle: {
    '&:first-letter': {fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase'},
    mt: 4,
    mb: 1,
    textAlign: [ 'center', 'start' ]
  },
  bodyStyle: {mt: 0}
};

export const titleStyle: SxProps = {
  '&:first-letter': {fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase'},
  mt: [ 12, 6 ],
  textAlign: 'center'
};