import { AppBar, Box, Divider, IconButton, Modal, Toolbar, useScrollTrigger } from '@mui/material';
import { Link } from 'react-router-dom';
import { NavButton } from '../NavButton/NavButton';
import { getPath, RouteName, ROUTES } from '../../routes/routes';
import { ROUTES_ORDER } from '../../routes/routes.constants';
import { Fragment, useState } from 'react';
import { ScrollIndicator } from '../ScrollIndicator/ScrollIndicator';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { MenuRounded as MenuIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export function Header() {
  const { t, i18n: { language } } = useTranslation()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  const scrollDirection = useScrollDirection();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 85,
  });

  const shouldBeSeen = trigger && scrollDirection === 'down' && !isHeaderHovered;

  return (
    <Box sx={{ position: 'relative' }}>
      <Modal
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '75vw',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            {ROUTES_ORDER.map((routeName: RouteName, index: number) => (
              <Fragment key={routeName}>
                <NavButton
                  onClick={() => setIsMenuOpen(false)}
                  variant='text'
                  path={getPath(routeName, language)}
                  sx={{ display: 'flex' }}
                >
                  {ROUTES[routeName].name}
                </NavButton>
                {index !== ROUTES_ORDER.length - 1 && (
                  <Divider variant='fullWidth' flexItem sx={{ display: 'flex' }} />
                )}
              </Fragment>
            ))}
          </Box>
        </Box>
      </Modal>

      <AppBar
        elevation={0}
        onMouseEnter={(event) => setIsHeaderHovered(true)}
        onMouseLeave={(event) => setIsHeaderHovered(false)}
        sx={{
          pb: shouldBeSeen ? 7 : 0,
          background: shouldBeSeen ? 'transparent' : '#f6f6f4',
          transition: 'transform 0.3s, padding .3s, background .2s',
          transform: shouldBeSeen ? 'translateY(-64px)' : '',
        }}
      >
        <Box sx={{ display: 'flex', transition: 'all 0.1s', height: trigger ? 60 : 90 }}>
          <Toolbar variant='dense' sx={{ width: '100%' }}>
            <IconButton onClick={() => setIsMenuOpen(true)} sx={{ display: ['block', 'none'] }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{ mr: 'auto', ml: { xs: 'auto', md: 0 } }}>
              <Link to={getPath(RouteName.Home, language)}>
                <Box sx={{ display: 'flex', alignItems: ['end', 'center'] }}>
                  <Box component='img' sx={{ height: 55 }} alt="edu_logo" src={require('../../assets/images/logo/avatar_color.webp')}/>
                  <Box className='animate__animated animate__fadeIn animate__slow' component='img' sx={{ width: 170, ml: 1 }} alt="eu_fund" src={require('../../assets/images/logo/eu_fund.png')}/>
                </Box>
              </Link>
            </Box>
            {ROUTES_ORDER.map((routeName: RouteName, index: number) => !!index && (
              <Fragment key={routeName}>
                <NavButton
                  path={getPath(routeName, language)}
                  sx={{ display: ['none', 'flex'] }}
                >
                  {t(`routes.${ROUTES[routeName].name}`)}
                </NavButton>
              </Fragment>
            ))}
          </Toolbar>
        </Box>
        <ScrollIndicator sx={{ height: scrollDirection === 'down' ? (isHeaderHovered ? 3 : 6) : 3, transition: 'all .3s' }} />
      </AppBar>
    </Box>
  )
}