import { collection, query, onSnapshot, Query, QueryConstraint, Unsubscribe } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { database } from '../services/firebase';
import { FirebaseDoc } from '../types';

interface UseGetCollection<T extends FirebaseDoc> {
  data: T[];
  loading: boolean;
}

export function useGetCollection<T extends FirebaseDoc>(collectionName: string, shouldFetch = true, ...queryConstraints: QueryConstraint[]): UseGetCollection<T> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<(T & { id: string })[]>([]);

  useEffect(() => {
    if (!shouldFetch) {
      return
    }

    (async () => {
      const q: Query = query(collection(database, collectionName), ...queryConstraints);
      const unsubscribe: Unsubscribe = onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as (T & { id: string });
        });
        setData(data);
        setLoading(false);
        unsubscribe();
      }, (error) => {
        setLoading(false);
        console.error(error.code, error)
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, shouldFetch]);

  return {
    data,
    loading,
  };
}
