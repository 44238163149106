import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { DEFAULT_LANG, languages } from './language.constants';

export const LanguageSwitcher = memo(function LanguageSwitcherComponent() {
  const match = useMatch({ path: ':lang/*' })
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const urlLanguage: string | undefined = match?.params?.lang

  const [isOpen, setIsOpen] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(
    urlLanguage && Object.keys(languages).includes(urlLanguage) ? urlLanguage : DEFAULT_LANG
  )

  useEffect(() => {
    if (!match) {
      navigate(`/${DEFAULT_LANG}/`)
    }

    i18n.changeLanguage(currentLanguage)
  }, [])

  const handleChangeLanguage = useCallback((lang: string) => {
    setCurrentLanguage(lang)
    i18n.changeLanguage(lang)
    if (match?.params) {
      navigate(`/${lang}/${match.pathname.split('/')[2]}`)
    }
  }, [i18n, match?.params, match?.pathname, navigate])

  return (
    <Box
      component='button'
      sx={{
        all: 'unset',
        cursor: 'pointer',
        border: 'none',
        width: '80px',
        position:' fixed',
        bottom:'20px',
        left:'20px',
        zIndex: 1000,
        opacity: isOpen ? 1 : .5,
        ':hover': {
          opacity: 1,
        }
      }}
      onClick={() => setIsOpen((prevOpen) => !prevOpen)}
      onBlur={() => setIsOpen(false)}
    >
      {isOpen && (
        Object.keys(languages).filter((lang: string) => lang !== currentLanguage).map((otherLang: string) => (
          <Box
            key={otherLang}
            component='img'
            alt={currentLanguage}
            src={require(`../../assets/images/flags/${languages[otherLang]}.png`)}
            sx={{
              height: [40, 60],
              cursor: 'pointer',
              ':hover': {
                opacity: 1,
                transform: isOpen ? 'scale(1.1)' : ''
              }
            }}
            onClick={() => handleChangeLanguage(otherLang)}
          />
        ))
      )}
      <Box
        component='img'
        alt={currentLanguage}
        src={require(`../../assets/images/flags/${languages[currentLanguage]}.png`)}
         sx={{
           height: [40, 60],
           cursor: 'pointer',
           ':hover': {
             opacity: 1,
             transform: isOpen ? 'scale(1.1)' : ''
           }
         }}
      />
    </Box>
  )
})