import { Home } from '../components/Pages/Home/Home';
import { Methodologies } from '../components/Pages/Methodologies/Methodologies';
import { FC } from 'react';
import { Games } from '../components/Pages/Games/Games';
import { DisinformationKit } from '../components/Pages/DisinformationKit/DisinformationKit';
import { Activities } from '../components/Pages/Activities/Activities';
import { FakeNewsPage } from '../components/Pages/FakeNews/FakeNewsPage';
import { Debate } from '../components/Pages/Debate/Debate';
import { DEFAULT_LANG } from '../components/LanguageSwitcher/language.constants';

interface Route {
  name: string
  path: string
  element: FC
}

export enum RouteName {
  Home = 'Home',
  Methodologies = 'Non-Formal Methodology',
  DisinformationKit = 'Critical Thinking and Disinformation Kit',
  Activities = 'Activities',
  Games = 'Critical Thinking Games',
  Debate = 'Non-Formal Debates',
  FakeNews = 'Fake News Alert',
}

export const ROUTES: Record<RouteName, Route> = {
  [RouteName.Home]: {
    name: RouteName.Home,
    path: '/',
    element: Home
  },
  [RouteName.Methodologies]: {
    name: RouteName.Methodologies,
    path: '/methodologies',
    element: Methodologies,
  },
  [RouteName.Games]: {
    name: RouteName.Games,
    path: '/quiz',
    element: Games,
  },
  [RouteName.DisinformationKit]: {
    name: RouteName.DisinformationKit,
    path: '/disinformation-kit',
    element: DisinformationKit,
  },
  [RouteName.Activities]: {
    name: RouteName.Activities,
    path: '/activities',
    element: Activities,
  },
  [RouteName.FakeNews]: {
    name: RouteName.FakeNews,
    path: '/fake-news',
    element: FakeNewsPage,
  },
  [RouteName.Debate]: {
    name: RouteName.Debate,
    path: '/debate',
    element: Debate,
  }
}

export const getRoutes = (lang: string = DEFAULT_LANG): Record<RouteName, Route> => {
  return Object.keys(ROUTES).reduce((acc: Record<string, Route>, routeName: string) => ({
    ...acc,
    [routeName]: {
      ...ROUTES[routeName as RouteName],
      path: `/${lang}${ROUTES[routeName as RouteName].path}`
    }
  }), {}) as Record<RouteName, Route>
}

export const getPath = (routeName: RouteName, lang: string = DEFAULT_LANG): string => {
  return `/${lang}${ROUTES[routeName].path}`
}