import React, { FC, memo } from 'react';
import { Bold } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Desert: FC = memo(function DesertComponent() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='desert'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: 'Survival in the Desert',
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          <>
            {t('desert.1')}
            <EduList
              items={[
                t('desert.11'),
                t('desert.12'),
                t('desert.13'),
                t('desert.14'),
                t('desert.15'),
              ]}
            />
            {t('desert.2')}
            <br/>
            {t('desert.3')}
            <br/>
            {t('desert.4')}
            <br/>
            {t('desert.5')}
          </>
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('desert.16'),
              t('desert.17'),
              t('desert.18'),
              t('desert.19'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <Bold>{t('methodologies.Preparation of the group')}:</Bold>
            {t('desert.6')}
            <Bold>{t('methodologies.Implementing activities')}:</Bold>
            {t('desert.7')}
            <br/>
            {t('desert.8')}
            <EduList
              items={[
                t('desert.20'),
                t('desert.21'),
                t('desert.22'),
                t('desert.23'),
                t('desert.24'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <EduList
              items={[
                t('desert.25'),
                t('desert.26'),
                t('desert.27'),
                t('desert.28'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            {t('desert.9')}
            <br/>
            {t('desert.10')}
            <EduList
              items={[
                t('desert.29'),
                t('desert.30'),
                t('desert.31'),
                t('desert.32'),
                t('desert.33'),
                t('desert.34'),
                t('desert.35'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
