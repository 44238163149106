import VisibilitySensor from 'react-visibility-sensor';
import { PropsWithChildren, useMemo, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import * as React from 'react';

type AnimateOnVisibilityProps = PropsWithChildren<{
  animation: string[];
  onChange?: (isVisible: boolean) => void;
  active?: boolean;
  partialVisibility?: boolean;
  minTopValue?: number;
  intervalCheck?: boolean;
  intervalDelay?: number;
  scrollCheck?: boolean;
  scrollDelay?: number;
  scrollThrottle?: number;
  resizeCheck?: boolean;
  resizeDelay?: number;
  resizeThrottle?: number;
  containment?: any;
  delayedCall?: boolean;
  boxSxProps?: SxProps;
}>

export function AnimateOnVisibility({ children, animation, minTopValue = 200, boxSxProps, ...restOfProps }: AnimateOnVisibilityProps) {
  const [isAnimated, setIsAnimated] = useState(false);

  const animations: string = useMemo(
    () => animation.map((animationName: string) => `animate__${animationName}`).join(' '),
    [animation]
  );

  return (
    <VisibilitySensor
      partialVisibility
      minTopValue={minTopValue}
      onChange={(isVisible: boolean) => { isVisible && setIsAnimated(true) }}
      {...restOfProps}
    >
      <Box
        className={isAnimated ? `animate__animated ${animations}` : ''}
        sx={{ opacity: isAnimated ? 1 : 0, ...boxSxProps }}
      >
        {children}
      </Box>
    </VisibilitySensor>
  )
}