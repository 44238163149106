import { Box, Divider, SxProps, Typography } from '@mui/material';
import React from 'react';
import { useComputedResource } from '../../hooks/useComputedResource';
import { useRenderOnVisible } from '../../hooks/useRenderOnVisible';

export interface SubsectionProps {
  title: string;
  context?: string;
  imageUrl?: string;
  position?: 'left' | 'right' | 'down';
  hasDivider?: boolean;
  children?: React.ReactNode;
  titleStyle?: SxProps;
  bodyStyle?: SxProps;
  dividerStyle?: SxProps;
  extension?: 'webp' | 'png' | 'jpeg';
}

export function Subsection({
  title,
  context = '',
  children,
  imageUrl,
  hasDivider = true,
  position,
  extension = 'jpeg',
  titleStyle = {
    mb: 5,
    color: 'primary.main',
    position: 'relative',
    width: 'fit-content',
    '&::after': {
      content: '""',
      display: 'block',
      opacity: 1,
      position: 'absolute',
      height: '2px',
      width: '100%',
      bottom: '-15px',
      backgroundColor: 'primary.main',
    },
  },
  bodyStyle = { fontWeight: 400, mb: 4, order: 1 },
  dividerStyle = { my: 5, mx: 10 },
}: SubsectionProps) {
  const { visibility, VisibilityComponent } = useRenderOnVisible(-500);
  const images: string[] = useComputedResource(imageUrl, context, extension);

  return (
    <Box>
      {VisibilityComponent}
      <Typography
        className='fontFamilyMeans'
        variant="h5"
        sx={titleStyle}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: position === 'down' ? 'column' : 'row' }}>
        <Typography
          component="div"
          variant="body1"
          sx={bodyStyle}
        >
          {children}
        </Typography>
        {images[0] && visibility && (
          <Box
            sx={{
              [position === 'left' ? 'mr' : 'ml']: 5,
              order: position === 'left' ? 0 : 2,
              maxWidth: position === 'down' ? undefined : '400px',
              display: {
                xs: 'none',
                md: 'block'
              }
          }}
          >
            <Box
              component='img'
              src={images[0]}
              alt={images[0]}
              sx={{
                height: 400,
                minWidth: position === 'down' ? 'fit-content' : 400,
                maxWidth: position === 'down' ? 'fit-content' : 400,
              }}
            />
          </Box>
        )}
      </Box>
      {hasDivider && <Divider variant="middle" sx={dividerStyle} />}
    </Box>
  )
}