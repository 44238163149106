import { Article } from '../../../../Article/Article';
import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { sharedProps, titleStyle } from '../../../../Article/Article.constants';
import { useTranslation } from 'react-i18next';

export function ATDDArticles() {
  const { t } = useTranslation()

  return (
    <Stack spacing={3}>
      <Article
        articleImage='ARTICLE_1_PHOTO'
        directory='fake_news/atdd'
        sharedProps={sharedProps}
        subsections={[{
          title: t('fakenews.20'),
          titleStyle,
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.21')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.22')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.instagram.com/p/CUmLO4pMQvb/?utm_source=ig_embed&ig_rid=cb30b49c-3c67-4f64-bbd1-89c415d0f923' target='_blank'>
              https://www.antena3.ro/actualitate/europa-fara-curent-electric-armata-austria-populatia-blackout-618360.html
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_2_PHOTO'
        directory='fake_news/atdd'
        sharedProps={sharedProps}
        subsections={[{
          title: t('fakenews.23'),
          titleStyle,
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            t('fakenews.24')
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            t('fakenews.25'),
            t('fakenews.26')
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://romania.europalibera.org/a/arbidol-tratament-covid/31539718.html' target='_blank'>
              https://romania.europalibera.org/a/arbidol-tratament-covid/31539718.html
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_3_PHOTO'
        directory='fake_news/atdd'
        sharedProps={sharedProps}
        subsections={[{
          title: t('fakenews.27'),
          titleStyle,
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.28')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.29')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://buletin.de/bucuresti/cum-a-aratat-propaganda-antioccidentala-pe-retelele-romanesti-in-2021/' target='_blank'>
              https://buletin.de/bucuresti/cum-a-aratat-propaganda-antioccidentala-pe-retelele-romanesti-in-2021/
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_4_PHOTO'
        directory='fake_news/atdd'
        sharedProps={sharedProps}
        subsections={[{
          title: t('fakenews.30'),
          titleStyle,
          dividerStyle: { mt: 4, mx: 20 },
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.31')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.32')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.gazeta.ru/politics/news/2022/04/28/17649620.shtml' target='_blank'>
              https://www.gazeta.ru/politics/news/2022/04/28/17649620.shtml
            </Link>
          ),
        }]}
      />
    </Stack>
  )
}