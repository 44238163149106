import { Box, Container, Typography } from '@mui/material';
import { Children, FC, memo, PropsWithChildren } from 'react';
import { AnimateOnVisibility } from '../../utils/Animations/AnimateOnVisibility';

export const Description: FC<PropsWithChildren> = memo(function DescriptionComponent({
  children
}: PropsWithChildren) {
  return (
    <Box sx={{
      my: 15,
      backgroundColor: '#f6f6f4'
    }}>
      <Container component='section'>
        <Box sx={{ py: 10 }}>
          {Children.toArray(children).map((child, index) => (
            <AnimateOnVisibility partialVisibility animation={['fadeInUp']} key={index}>
              <Typography key={index} variant='h6' sx={{ '&:first-letter': { fontSize: 25, fontWeight: 'bold', textTransform: 'uppercase' } }}>
                {child}
              </Typography>
            </AnimateOnVisibility>
          ))}
        </Box>
      </Container>
    </Box>
  )
})