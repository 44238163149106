import { Hero } from '../../Hero/Hero';
import { Description } from '../../Description/Description';
import { FakeNewsForm } from './FakeNewsForm';
import { Container, Stack } from '@mui/material';
import { FakeNewsFeed } from './FakeNewsFeed/FakeNewsFeed';
import React from 'react';
import { FakeNewsArticles } from './FakeNewsArticles/FakeNewsArticles';
import { useTranslation } from 'react-i18next';

export function FakeNewsPage() {
  const { t } = useTranslation()

  return (
    <>
      <Hero imageName='fake_news'>
        {t('fakenews.11')}
      </Hero>
      <Description>
        {t('fakenews.12')}
        <br/>
        {t('fakenews.13')}
        <br/>
        {t('fakenews.14')}
      </Description>
      <Container sx={{ p: [0, 2] }}>
        <Stack spacing={15}>
          <FakeNewsArticles />
          <FakeNewsFeed />
          <FakeNewsForm />
        </Stack>
      </Container>
    </>
  )
}