import { Hero, HeroFileExtensions } from '../Hero/Hero';
import { Subsection, SubsectionProps } from '../Subsection/Subsection';
import { Paper, Stack } from '@mui/material';
import React from 'react';
import { useRenderOnVisible } from '../../hooks/useRenderOnVisible';

interface FakeNewsArticleProps {
  articleImage?: string;
  directory?: string;
  extension?: HeroFileExtensions;
  subsections: SubsectionProps[];
  sharedProps?: Partial<SubsectionProps>;
}

export function Article({
  articleImage,
  directory,
  subsections,
  extension = 'jpeg',
  sharedProps = {},
}: FakeNewsArticleProps) {
  const { visibility, VisibilityComponent } = useRenderOnVisible();

  return (
    <Paper elevation={2} sx={{ px: [2, 5], py: 3 }}>
      <Stack spacing={1}>
        {VisibilityComponent}
        {visibility && articleImage && <Hero imageName={articleImage} extension={extension} directory={directory} hasFilter={false} size='small' />}
        {subsections.map(({ children, ...restOfProps }) => (
          <Subsection key={restOfProps.title} {...sharedProps} {...restOfProps}>
            {children}
          </Subsection>
        ))}
      </Stack>
    </Paper>
  )
}