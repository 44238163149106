import { Article } from '../../../../Article/Article';
import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function BelenArticles() {
  const { t } = useTranslation()

  return (
    <Stack spacing={10}>
      <Article
        articleImage='ARTICLE_1_PHOTO'
        directory='fake_news/belen'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.41'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.33')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.34')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.bbc.com/news/world-us-canada-61467766' target='_blank'>
              https://www.bbc.com/news/world-us-canada-61467766
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_2_PHOTO'
        directory='fake_news/belen'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.42'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.35')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.36')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://sabahhaberi.com/applein-acigini-bulan-gorme-engelli-genc-10000-dolar-odul-kazandi-h303949.html' target='_blank'>
              https://sabahhaberi.com/applein-acigini-bulan-gorme-engelli-genc-10000-dolar-odul-kazandi-h303949.html
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_3_PHOTO'
        directory='fake_news/belen'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.43'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.37')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.38')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://nypost.com/2022/05/05/donald-trump-quietly-encouraged-elon-musk-to-buy-twitter/?utm_source=NYPTwitter&utm_campaign=SocialFlow&utm_medium=SocialFlow' target='_blank'>
              https://nypost.com/2022/05/05/donald-trump-quietly-encouraged-elon-musk-to-buy-twitter/?utm_source=NYPTwitter&utm_campaign=SocialFlow&utm_medium=SocialFlow
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_4_PHOTO'
        directory='fake_news/belen'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.44'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.39')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.40')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.sozcu.com.tr/hayatim/magazin-haberleri/cayci-huseyin-yine-oldu/' target='_blank'>
              https://www.sozcu.com.tr/hayatim/magazin-haberleri/cayci-huseyin-yine-oldu/
            </Link>
          ),
        }]}
      />
    </Stack>
  )
}