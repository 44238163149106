import React, { FC, memo } from 'react';
import { Bold, Italic } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Hoca: FC = memo(function HocaComponent() {
  const { t } = useTranslation()

  return (
    <Article
      articleImage='hoca'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title: 'Nasreddin Hodja',
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          t('hoca.1')
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <>
          <EduList
            items={[
              t('hoca.2'),
              t('hoca.3'),
              t('hoca.4'),
              t('hoca.5'),
              t('hoca.6'),
            ]}
          />

            {t('hoca.7')}
          <EduList
            items={[
              t('hoca.13'),
              t('hoca.14'),
              t('hoca.15'),
              t('hoca.16'),
              t('hoca.20'),
              t('hoca.21'),
              t('hoca.22'),
            ]}
          />
        </>
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <Bold>
              {t('hoca.8')}
            </Bold>
            <br/>
            <Italic>
              {t('hoca.9')}
            </Italic>
            <br/>
            <Bold>
              {t('hoca.10')}
            </Bold>

            <Bold>
              {t('hoca.11')}
            </Bold>
            <EduList
              items={[
                t('hoca.23'),
                t('hoca.24'),
                t('hoca.25'),
                t('hoca.26'),
                t('hoca.27'),
                t('hoca.28'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            <EduList
              items={[
                t('hoca.29'),
                t('hoca.31'),
                t('hoca.32'),
                t('hoca.33'),
                t('hoca.34'),
                t('hoca.35'),
                t('hoca.36'),
              ]}
            />
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            {t('hoca.12')}
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
