import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { ATDDArticles } from './teams/ATDDArticles';
import { BeyondBordersArticles } from './teams/BeyondBordersArticles';
import { HorezuArticles } from './teams/HorezuArticles';
import { BelenArticles } from './teams/BelenArticles';
import SwipeableViews from 'react-swipeable-views';
import { TabContext, TabPanel } from '@mui/lab';

export const FakeNewsArticles = memo(() => {
  const [tabIndex, setTabIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, [])

  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <Box>
      <TabContext value={tabIndex.toString()}>
        <Tabs
          value={tabIndex}
          textColor="primary"
          scrollButtons="auto"
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          onChange={handleChange}
        >
          <Tab value={0} label="A.T.D.D (Romania)" />
          <Tab value={1} label="Beyond Borders (Italy)" />
          <Tab value={2} label="Horezu Highschool (Romania)" />
          <Tab value={3} label="T.C. Belen Kaymakamligi (Türkiye)" />
        </Tabs>
        <SwipeableViews
          springConfig={{ duration: '0.5s', easeFunction: 'ease-in-out', delay: '0s' }}
          index={tabIndex}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value='0' tabIndex={0} sx={{ p: '2px' }}>
            <ATDDArticles />
          </TabPanel>
          <TabPanel value='1' tabIndex={1} sx={{ p: '2px' }}>
            <BeyondBordersArticles />
          </TabPanel>
          <TabPanel value='2' tabIndex={2} sx={{ p: '2px' }}>
            <HorezuArticles />
          </TabPanel>
          <TabPanel value='3' tabIndex={3} sx={{ p: '2px' }}>
            <BelenArticles />
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </Box>
  )
})
