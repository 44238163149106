import React from 'react'
import { Box, Paper, Skeleton } from '@mui/material';
import { useComputedResource } from '../../hooks/useComputedResource';

export interface Answer {
  name: string,
  isAnswer: boolean,
  order?: number,
}

export interface QuizStepProps {
  title?: string
  imageName?: string
  base?: string
  answers?: Answer[]
}

export function QuizStep({
  imageName,
  base,
}: QuizStepProps) {
  const [show, setShow] = React.useState(false);
  const image = useComputedResource(imageName, base, 'jpeg');

  return (
    <Paper elevation={10} sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
      {image && (
        <>
          {!show && <Skeleton variant='rectangular' animation='wave' width={900} height={500} />}
          <Box component='img' onLoad={() => setShow(true)} src={image[0]} alt={imageName} loading='lazy' sx={{ visibility: !show ? 'hidden' : '', maxHeight: 500, maxWidth: 900 }} />
        </>
      )}
    </Paper>
  )
}