import { Article } from '../../../../Article/Article';
import React from 'react';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function HorezuArticles() {
  const { t } = useTranslation()

  return (
    <Stack spacing={10}>
      <Article
        articleImage='ARTICLE_1_PHOTO'
        directory='fake_news/horezu'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.57'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.61')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.62')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.national.ro/politica/planul-sua-de-inrobire-a-europei-sau-cum-platim-noi-pentru-bunastarea-americii-765208.html' target='_blank'>
              https://www.national.ro/politica/planul-sua-de-inrobire-a-europei-sau-cum-platim-noi-pentru-bunastarea-americii-765208.html
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_2_PHOTO'
        directory='fake_news/horezu'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.58'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.63')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.64')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.radiodobrogea.ro/18947-cuvantul-ierarhului-dialog-duhovnicesc-cu-ascultatorii-70/' target='_blank'>
              https://www.radiodobrogea.ro/18947-cuvantul-ierarhului-dialog-duhovnicesc-cu-ascultatorii-70/
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_3_PHOTO'
        directory='fake_news/horezu'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.59'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.65')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.66')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://www.rubaltic.ru/article/kultura-i-istoriya/20220724-sssr-pomog-moldavanam-vyzhit-pravda-o-poslevoennom-golode-v-moldove/' target='_blank'>
              https://www.rubaltic.ru/article/kultura-i-istoriya/20220724-sssr-pomog-moldavanam-vyzhit-pravda-o-poslevoennom-golode-v-moldove/
            </Link>
          ),
        }]}
      />

      <Article
        articleImage='ARTICLE_4_PHOTO'
        directory='fake_news/horezu'
        sharedProps={{
          hasDivider: false,
          titleStyle: { '&:first-letter': { fontSize: 30, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, mb: 1},
          bodyStyle: { mt: 0 },
        }}
        subsections={[{
          title: t('fakenews.60'),
          titleStyle: { '&:first-letter': { fontSize: 50, fontWeight: 'bold', textTransform: 'uppercase' }, mt: 4, textAlign: 'center' },
          dividerStyle: { mt: 4, mx: 20 },
          hasDivider: true,
        }, {
          title: t('fakenews.summary'),
          children: (
            <>
              {t('fakenews.67')}
            </>
          )
        }, {
          title: t('fakenews.disproof'),
          children: (
            <>
              {t('fakenews.68')}
            </>
          ),
        }, {
          title: t('fakenews.link'),
          children: (
            <Link to='https://radiogoldfm.ro/recensamantul-populatiei-si-posibilele-dezinformari/' target='_blank'>
              https://radiogoldfm.ro/recensamantul-populatiei-si-posibilele-dezinformari/
            </Link>
          ),
        }]}
      />
    </Stack>
  )
}