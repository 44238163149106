import React, { FC, memo } from 'react';
import { InlineBold } from '../../../Typography/Inline';
import { EduList } from '../../../List/List';
import { Article } from '../../../Article/Article';
import { sharedProps, titleStyle } from '../../../Article/Article.constants';
import { Methodology } from '../Methodologies.types';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Volunteer: FC<Methodology> = memo(function VolunteerComponent({
  title
}) {

  const { t } = useTranslation()

  return (
    <Article
      articleImage='volunteer'
      directory='methodologies'
      sharedProps={sharedProps}
      subsections={[{
        title,
        titleStyle,
        dividerStyle: { mt: 4, mx: 20 },
        hasDivider: true,
      }, {
        title: t(`methodologies.Methodological context`),
        children: (
          t('volunteer.1')
        )
      }, {
        title: t(`methodologies.Administrative informations`),
        children: (
          <EduList
            items={[
              t('volunteer.2'),
              t('volunteer.3'),
              t('volunteer.4'),
              t('volunteer.5'),
              t('volunteer.6'),
            ]}
          />
        )
      }, {
        title: t(`methodologies.Description of the methodology`),
        children: (
          <>
            <InlineBold>
              {t('volunteer.7')}
            </InlineBold>
            <br/>
            {t('volunteer.8')}
            <br/>
            <InlineBold>
              {t('volunteer.9')}
            </InlineBold>
            <br/>
            {t('volunteer.10')}
            <br/>
            {t('volunteer.11')}
            <EduList
              items={[
                t('volunteer.23'),
                t('volunteer.24'),
                t('volunteer.25'),
                t('volunteer.26'),
                t('volunteer.27'),
                t('volunteer.28'),
                t('volunteer.29'),
                t('volunteer.30'),
                t('volunteer.31'),
                t('volunteer.32'),
                t('volunteer.33'),
                t('volunteer.34'),
              ]}
            />

            {t('volunteer.12')}
            <br/>
            <EduList
              items={[
                t('volunteer.35'),
                t('volunteer.36'),
                t('volunteer.37'),
              ]}
            />
            <br/>
            <InlineBold>
              {t('volunteer.13')}
            </InlineBold>
            <br/>

            {t('volunteer.14')}
          </>
        )
      }, {
        title: t(`methodologies.Evaluation and debriefing`),
        children: (
          <>
            {t('volunteer.15')}
            <EduList
              items={[
                t('volunteer.38'),
                t('volunteer.39'),
                t('volunteer.40'),
                t('volunteer.41'),
                t('volunteer.42'),
              ]}
            />
            {t('volunteer.16')}
            <br/>
            {t('volunteer.19')}
          </>
        )
      }, {
        title: t(`methodologies.Feedback`),
        children: (
          <>
            {t('volunteer.22')}
          </>
        )
      }, {
        title: t(`methodologies.Annexes`),
        children: (
          <Link href='https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST' target='_blank'>
            https://drive.google.com/drive/folders/113io4SYPUu_9ebkhotRlfsIY-rS_xBST
          </Link>
        ),
      }]}
    />
  )
})
