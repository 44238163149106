import React, { useState } from 'react';
import { Box } from '@mui/material';
import VisibilitySensor from 'react-visibility-sensor';

export function useRenderOnVisible(minTopValue?: number) {
  const [visibility, setVisibility] = useState(false);

  return {
    visibility,
    VisibilityComponent: (
      <VisibilitySensor
        minTopValue={minTopValue}
        partialVisibility
        onChange={(isVisible: boolean) => { isVisible && setVisibility(true) }}
      >
        <Box sx={{ display: visibility ? 'none' : 'block', visibility: 'hidden' }}>hidden sensor trigger</Box>
      </VisibilitySensor>
    ),
  };
}