const it = {
    "translation": {
        "home": {
            "1": "Chi siamo?",
            "2": "è un'iniziativa internazionale finanziata dall'Unione Europea attraverso il programma Erasmus+, che comprende un consorzio transnazionale formato da Romania, Italia e Turchia. ",
            "3": "Il nostro obiettivo principale",
            "4": "Il progetto si concentra sui problemi simili identificati in ciascun paese e su una soluzione comune che consiste nello sviluppo delle competenze di pensiero critico della giovane generazione.",
            "5": "I nostri obiettivi.",
            "6": "Al fine di sviluppare le competenze di pensiero critico e di acquisizione di informazioni corrette per i giovani, il progetto ha adottato un approccio tematico bidimensionale:",
            "7": "Gli obiettivi di EDUcate YOUth sono:",
            "8": "Una prospettiva sull’educazione non-formale",
            "9": "Un approccio strutturale, poiché il cambiamento di mentalità deve essere sostenuto a livello sistemico attraverso una collaborazione transettoriale (settore giovani e settore dell'istruzione).",
            "10": "Questo processo di ritorno indietro per risolvere i problemi comuni nei due settori educativi (formale e non formale) ha fornito un approccio strategico alla questione, formando una forte partnership transettoriale tra le organizzazioni giovanili, le istituzioni educative e l'amministrazione locale nelle regioni di Oltenia - Romania, Bari - Italia e Hatay - Turchia.",
            "11": "Sviluppo e consolidamento delle competenze chiave riguardanti il pensiero critico e l'informazione corretta",
            "12": "Generare un modello pilota di cooperazione cross-settoriale tra le organizzazioni giovanili e le istituzioni educative, moltiplicabile su larga scala in un sistema mainstream, nelle aree del pensiero critico e dell'informazione corretta",
            "13": "Aumentare l'esperienza organizzativa per i quattro partner del progetto, sviluppando metodologie non formali che creino sinergie tra i due settori",
            "14": "“EDUcate YOUth” si estende su 24 mesi ed è diviso in due componenti temporali principali.",
            "15": "Il primo anno è incentrato sulla ricerca locale dei bisogni specifici, interpretazione del contesto e stratificazione, sviluppo della cooperazione transettoriale e dei metodi educativi non formali.",
            "16": "Il secondo anno è incentrato sul perfezionamento dei metodi da utilizzare su larga scala, sviluppando la piattaforma di e-learning con accesso gratuito ai metodi di pensiero critico per tutti. I nuovi metodi educativi sono stati sperimentati e perfezionati con l'aiuto dei commenti dei lavoratori giovanili, degli studenti e degli insegnanti in tutti e tre i paesi partner del progetto.",
            "partners": "I nostri partner",
        },
        "disinformation": {
            "title": "Pensiero critico e kit sulla disinformazione",
            "description1": "Questa sezione si occupa del significato di pensiero critico, disinformazione e misinformazione.",
            "description2": "Quali sono le abilità critiche più importanti e come migliorare le nostre capacità di pensiero critico?",
            "description3": "Come si usano la disinformazione e la misinformazione?",
            "description4": "Quali sono le minacce della disinformazione nella società moderna?",
            "description5": "Che cos'è il metodo CRAAP (Currency o Periodo, Rilevanza, Autorevolezza, Accuratezza e Purpose o Finalità)?",
            "description6": "Alcuni siti web presentano opinioni sotto forma di notizie e diffondono deliberatamente informazioni false per influenzare la società.",
            "description7": "Mettete da parte ogni giudizio e iniziate a pensare in modo critico a qualsiasi contenuto, problema e fonte d'informazione.",
            "1": "Nella nostra vita quotidiana circolano spesso informazioni false. Notizia flash: noi esseri umani non siamo impeccabili. Tutti sono capaci di commettere errori. Tutti abbiamo perdite di memoria. I dettagli vengono ascoltati male o dimenticati. Travisiamo informazioni mal riportando ai nostri amici le informazioni che abbiamo appreso tramite i social media o sentito in televisione. Tecnicamente parlando, si parla di distribuzione di misinformazione quando si diffondono informazioni false senza essere consapevoli della loro falsità.",
            "2": "Il termine misinformazione, che combina la parola “informazione” con il prefisso mis-, che significa \"sbagliato\" o \"errato\", è stato usato per la prima volta alla fine del 1500. Altri termini frequenti che utilizzano il prefisso mis- per indicare \"sbagliato\" sono mistake, misspelling e misunderstunding, corrispettivi inglesi dell'italiano errore, errore di ortografia e malinteso.",
            "3": "Naturalmente, la misinformazione è legata al verbo misinformare, apparso originariamente per iscritto tra il 1350 e il 1400, che significa \"offrire informazioni inesatte o fuorvianti\". Come per la misinformazione, anche il verbo misinformare indica che si stanno diffondendo informazioni false.",
            "4": "La disinformazione è definita come un'informazione scorretta che viene distribuita in modo intenzionale e spesso discreto (ad esempio attraverso la diffusione di voci) al fine di influenzare l'opinione pubblica o offuscare la verità.",
            "5": "Inoltre, può riferirsi a \"informazioni deliberatamente false o distorte, storie o fatti inventati, propaganda\" in senso più ampio.",
            "6": "La disinformazione è una tecnica di spionaggio comune perché è così potente, distruttiva e polarizzante. Allo stesso modo, ad esempio, dell'Unione Sovietica e degli Stati Uniti durante la Guerra Fredda, gli Stati hanno spesso interesse nell’ inviare informazioni false ai loro avversari. Spesso vengono impiegate campagne di disinformazione per descrivere quando una nazione o un gruppo coordina una complessa strategia di diffusione della disinformazione.",
            "7": "La disinformazione può derivare dalla propaganda, che è un'informazione veicolata per mettere qualcuno o qualcosa in buona o cattiva luce. Le dittature moderne, come quella della Corea del Nord, usano la disinformazione propagandistica per instillare nella popolazione un senso di rettitudine e bontà nei confronti del proprio governo e di malvagità nei confronti degli avversari. Secondo il Dipartimento di Stato degli USA, il governo cinese avrebbe presumibilmente fatto circolare informazioni false sulla pandemia di COVID-19 all’interno di un'operazione di disinformazione su Internet, attribuendone la causa agli stessi Stati Uniti.",
            "8": "Tenete a mente la parola \"scopo\" quando distinguete tra misinformazione e disinformazione. Entrambi i termini si applicano a diversi tipi di informazioni imprecise o errate, ma solo la misinformazione è imprecisa di proposito. Anche se può sembrare ovvio, la misinformazione e la disinformazione - o scorretta informazione - sono spesso usate in modo intercambiabile, poiché sono simili e collegate in alcune situazioni.",
            "9": "Usate il termine disinformazione se avete la certezza che vengano diffuse informazioni fuorvianti o errate con l'intento di danneggiare, in particolare un governo, un'organizzazione o una persona pubblica. Usate il termine misinformazione ogni volta che è possibile se non sapete con certezza il motivo per il quale qualcuno sta diffondendo informazioni false.",
            "10": "Nell'era digitale, la misinformazione e la disinformazione o l'informazione scorretta sono pervasive e comprendono teorie cospirazioniste, propaganda, deepfake, fake news, bufale, frodi, manipolazioni con Photoshop e truffe.",
            "11": "Mai prima d'ora la produzione e la distribuzione di misinformazione sono state così semplici e accessibili da automatizzare. Oggi disponiamo di bot automatizzati che sono in grado di diffondere rapidamente sui social media un articolo con informazioni false più di cinquanta volte al giorno, in media. Tuttavia, non si limitano a questo: guidando il traffico di informazioni, sono anche in grado di provvedere in tempi rapidi al denaro per le pubblicità. Solo di recente abbiamo iniziato a vedere gli effetti che questa tecnologia avrebbe sulle nostre culture e democrazie.",
            "12": "Il modo in cui vengono prodotte la misinformazione e la disinformazione è direttamente collegato all'autore o agli autori e alle diverse ragioni per cui vengono create.",
            "13": "Chi sono gli autori? Possono essere:",
            "14": "Indipendentemente dal contenuto dell'articolo, qualcuno che vuole trarre profitto;",
            "15": "comici di satira che vogliono esprimere un concetto o divertire, o entrambi;",
            "16": "Giornalisti scadenti o inesperti possono produrre lavori di qualità inferiore che violano gli standard o l'etica giornalistica professionale a causa della tensione fra il continuo ciclo di notizie H24 e della proliferazione dei siti web di notizie;",
            "17": "I politicanti che cercano di influenzare gli atteggiamenti politici e gli organi decisionali;",
            "18": "La facilità tecnologica di copiare, incollare, cliccare e condividere contenuti online ha contribuito alla proliferazione di questo tipo di articoli. In alcuni casi, gli articoli nascono con l’intento di provocare una reazione emotiva e vengono inseriti in determinati siti (\"seeded\") per invogliare i lettori a condividerli ampiamente. In altri casi, gli articoli di \"fake news\" possono essere generati e diffusi da \"bot\" - algoritmi informatici progettati per agire come persone che condividono informazioni, ma in grado di farlo in modo rapido e automatico.",
            "19": "Esistono numerose strategie per combattere questo flagello. L'educazione dei cyber-cittadini è sempre la migliore linea d'azione, anche se non è la più rapida o la più semplice. La capacità di sviluppare una società online che sia informata e in grado di riflettere richiede tempo, lavoro, pazienza e risorse, proprio come qualsiasi investimento a lungo termine.",
            "20": "Un'altra buona pratica consiste nel sollecitare il cambiamento delle politiche delle aziende tecnologiche che gestiscono le piattaforme. Sono già in corso cambiamenti presso Twitter e Facebook per smettere di considerare i contenuti nei loro sistemi sulla base del famoso detto \“occhio che non vede, cuore che non duole”\. Per il ciclo elettorale del 2022, anche Google ha vietato la pubblicità a sfondo politico. Poiché queste piattaforme sono i principali canali di distribuzione delle false informazioni e della disinformazione, gli utenti di Internet potrebbero unirsi a organizzazioni con l'obiettivo di smascherare le cattive pratiche tecnologiche.",
            "21": "I regolatori del settore tecnologico possono creare soluzioni amministrative. La disinformazione deve essere definita e punita, e si è in fase di approvazione di leggi per fermare questo crimine moderno. Ma per mettere insieme tutto questo, come per tutte le regole, è necessario uno sforzo metodico e di abilità",
            "22": "Il test CRAAP è uno dei metodi di valutazione. È stato ideato dalla Meriam Library California State University, a Chico. CRAAP è un acronimo che sta per Currency o Periodo, Rilevanza, Autorevolezza, Accuratezza e Purpose o Finalità). Fornisce un elenco di domande che aiutano a valutare le informazioni trovate. In questo modo sarete più sicuri di selezionare le fonti per soddisfare le aspettative del vostro compito.",
            "23": "Le campagne di disinformazione su larga scala rappresentano una sfida importante per l'Europa e richiedono una risposta coordinata da parte dei Paesi dell'UE, delle istituzioni europee, delle piattaforme online, dei media e dei cittadini dell'UE. La Commissione ha sviluppato una serie di iniziative per contrastare la disinformazione:",
            "24": "Il pensiero critico è un ragionamento autodiretto e autodisciplinato che mira al massimo grado di qualità pur rimanendo obiettivo. Le persone che pensano in modo critico si sforzano di vivere in modo sensato, ragionevole e comprensivo. Inoltre, il pensiero critico aiuta le persone a rendersi conto che, per quanto brave siano a pensare, possono sempre migliorare le loro capacità di ragionamento e che, a volte, sono vittime di errori logici, irrazionalità umana, pregiudizi, preconcetti, distorsioni, convenzioni e tabù sociali ingenuamente accettati, interessi personali e interessi acquisiti. Inoltre, il pensiero critico richiede l'uso di capacità di ragionamento. Si tratta di essere un partecipante attivo piuttosto che uno spettatore, quando si tratta di imparare. Invece di affidarsi all'intuito o all'istinto, i pensatori critici scoprono, valutano e risolvono i problemi in modo metodico.",
            "25": "Comprendere le connessioni tra le idee.",
            "26": "Valutare il significato e l'applicabilità di argomenti e concetti.",
            "27": "Riconoscere, costruire e valutare argomenti.",
            "28": "Riconoscere incongruenze ed errori logici.",
            "29": "Affrontare i problemi in modo coerente e sistematico.",
            "30": "Considerare le ragioni delle proprie ipotesi, opinioni e valori.",
            "31": "Per pensare in modo critico, dobbiamo essere in grado di mettere da parte qualsiasi nozione o giudizio a priori e limitarci a studiare i dati che ci vengono forniti. Inoltre, dobbiamo essere obiettivi nella valutazione delle idee.",
            "32": "Quasi nessuna informazione a cui abbiamo accesso, sia all'esterno che all'interno, ha la certezza di durare nel tempo o di essere utile. Istruzioni dettagliate passo dopo passo possono fungere da quadro di riferimento per far crescere la nostra fondamentale capacità di pensiero critico, ma non garantiscono e non possono garantire certezza, utilità o longevità.",
            "33": "Il pensiero critico, come altre soft skills, non è qualcosa che si può acquisire in un corso. Piuttosto, questa capacità è costituita da una combinazione di abilità interpersonali e intellettuali. Imparare ad accettare l'apertura mentale e applicare il pensiero analitico al processo di risoluzione dei problemi è più importante che sviluppare il pensiero critico.",
            "title1": 'Misinformazione',
            "title2": 'Disinformazione',
            "title3": 'Come si usano la disinformazione e la misinformazione',
            "title4": 'Quali sono le minacce della disinformazione nella società moderna?',
            "title5": 'Da dove deriva?',
            "title6": 'Come rispondere alla disinformazione?',
            "title7": 'METODO CRAAP',
            "title8": 'Iniziative della Commissione Europea',
            "title9": 'Pensiero Critico',
            "title10": 'Il Pensiero Critico si sforza di',
            "title11": 'Il pensiero critico può essere suddiviso in due categorie',
            "title12": 'Una persona con capacità di pensiero critico è in grado di:',
            "title13": 'Migliorare le nostre capacità di pensiero critico',
            "title14": 'Ci sono alcuni metodi che possono essere adottati per sviluppare le capacità di pensiero critico approfondendo la questione.',
            "title15": 'Possibili ostacoli da superare',
            "title16": 'Le più importanti abilità di pensiero critico',
            "title17": 'Per riassumere',
            "title18": 'Riferimenti bibliografici',
            "34": "Currency o Periodo:",
            "35": "Rilevanza:",
            "36": "Autorevolezza:",
            "37": "Accuratezza:",
            "38": "Purpose o Finalità:",
            "39": `Stabilite se la data di pubblicazione delle informazioni è adeguata al vostro progetto. Qual è la data di copyright, di pubblicazione o di posting? Perché la data è o non è importante per il messaggio o il contenuto della fonte? Le informazioni sono obsolete rispetto all'argomento?`,
            "40": `Determinate l'applicabilità delle informazioni al vostro progetto. Per quale pubblico o livello è stata scritta l'informazione (pubblico generale, esperti/studiosi, ecc.)? Spiegate perché citereste o meno le informazioni di questa fonte nel vostro progetto.`,
            "41": `Stabilite se l'autore, il creatore o l'editore della fonte siano i più competenti. Chi è l'autore, il creatore o l'editore della fonte o quale organizzazione è responsabile della fonte? Sono disponibili informazioni di contatto? Come si fa a sapere se l'autore è un esperto dell'argomento (ad esempio, esaminando le credenziali, l'esperienza e/o l'affiliazione organizzativa dell'autore)? Da dove provengono i fondi per la ricerca o la programmazione, se pertinente?`,
            "42": `Determinare l'affidabilità, la veridicità e la correttezza del contenuto. Quali indizi indicano che le informazioni sono o non sono ben studiate o forniscono prove sufficienti? Che tipo di linguaggio, immagini e/o tono viene utilizzato (ad esempio, emotivo, oggettivo, professionale, ecc.)? Le prove supportano le premesse/le affermazioni e le conclusioni? I fatti e le affermazioni sono documentati o citati nel testo, nelle note o nella bibliografia?`,
            "43": `Determinare il motivo per cui l'informazione esiste. Perché è stata scritta questa fonte (per esempio, per informare, insegnare, intrattenere, persuadere)? In che modo l'affiliazione dell'autore può influenzare il punto di vista, la posizione o la potenziale parzialità della fonte? In che modo il pubblico a cui è destinata la fonte può influenzare il punto di vista, la posizione o la potenziale parzialità dell'informazione? Quali conclusioni vengono presentate e le informazioni sono complete? È stato escluso qualcosa di importante? Come si colloca questa risorsa rispetto ad altre sullo stesso argomento?`,
            "44": `la Comunicazione riguardo allo strumento dal titolo "Affrontare la disinformazione online: un approccio europeo" è una raccolta di strumenti per affrontare la diffusione della disinformazione e garantire la tutela dei valori dell'UE;`,
            "45": `il piano d'azione sulla disinformazione mira a rafforzare la capacità e la cooperazione dell'UE nella lotta contro la disinformazione;`,
            "46": `il Piano d'azione per la democrazia europea sviluppa linee guida per gli obblighi e la responsabilità delle piattaforme online nella lotta alla disinformazione;`,
            "47": `Con il Codice di condotta sulla disinformazione del 2018 è stata la prima volta al mondo che l'industria ha concordato, su base volontaria, gli standard di autoregolamentazione per combattere la disinformazione. Esso mirava a raggiungere gli obiettivi fissati dalla comunicazione della Commissione presentata nell'aprile 2018`,
            "48": `Il programma di monitoraggio della disinformazione COVID-19, realizzato dai firmatari del Codice di condotta, ha agito come misura di trasparenza per garantire la responsabilità delle piattaforme online nella lotta alla disinformazione.`,
            "49": `EDMO è un osservatorio indipendente che riunisce fact-checkers e ricercatori accademici esperti nel campo della disinformazione online, delle piattaforme di social media, dei media guidati dai giornalisti e dei professionisti dell'alfabetizzazione mediatica`,
            "50": `Il Codice di condotta rafforzato sulla disinformazione, firmato il 16 giugno 2022, riunisce un'ampia gamma di attori che si impegnano a rispettare un'ampia serie di impegni volontari per contrastare la disinformazione.`,
            "51": `coltivare con zelo le qualità intellettuali dell'integrità intellettuale`,
            "52": `umiltà intellettuale`,
            "53": `civiltà intellettuale`,
            "54": `empatia intellettuale`,
            "55": `senso di giustizia nella mente`,
            "56": `sicurezza di sé nella logica`,
            "57":  `un insieme di talenti per creare ed elaborare informazioni e convinzioni`,
            "58": `l'abitudine di impiegare tali capacità per influenzare la condotta, che si fonda sull'impegno intellettuale`,
            "59": `Determinare l'origine di un problema o di una questione`,
            "60": `Formulare ipotesi sul perché del problema e su come risolverlo.`,
            "61": `Condurre ricerche per raccogliere conoscenze o dati sull'argomento.`,
            "62": `Organizzare e ordinare i dati e i risultati.`,
            "63": `Creare e implementare soluzioni.`,
            "64": `Analizzare quali soluzioni hanno funzionato o non hanno funzionato.`,
            "65": `Identificare i modi per migliorare la soluzione.`,
            "66": `Pensiero analitico: Valutare i fatti da una varietà di fonti per ottenere le conclusioni migliori fa parte del pensiero critico. Il pensiero analitico consente di rifiutare i pregiudizi a favore della raccolta e dell'analisi dei dati per ottenere la conclusione ottimale.`,
            "67": `Apertura mentale: Questa dote del pensiero critico consente di valutare ed elaborare i dati per giungere a un giudizio obiettivo. Lasciare da parte i propri pregiudizi e prendere una decisione basata su tutte le prove fa parte del processo di pensiero critico.`,
            "82": `Problem solving: Il pensiero critico è un aspetto importante del problem solving perché sottolinea il raggiungimento della conclusione ottimale sulla base di tutti i fatti disponibili. Il pensiero critico, se applicato in modo appropriato, può aiutarvi ad affrontare qualsiasi difficoltà, dalle sfide lavorative ai problemi della vita quotidiana.`,
            "68": `Autoregolazione: Il pensiero critico, se articolato in modo appropriato, può aiutare ad affrontare qualsiasi difficoltà, dalle sfide commerciali ai problemi della vita quotidiana. Per essere un pensatore critico efficace, è necessario mettere in discussione le informazioni di cui si dispone e i giudizi che si prediligono: solo così si può giungere alla conclusione migliore.`,
            "69": `Osservazione: I pensatori critici usano la capacità di osservazione per cercare cose che non sono ovvie. Per essere un pensatore critico bisogna essere in grado di accettare diversi punti di vista e di riconoscere i possibili problemi utilizzando la capacità di osservazione.`,
            "70": `Interpretazione: I pensatori critici sanno che non tutti i dati sono uguali. È fondamentale valutare se le informazioni sono preziose e rilevanti per la vostra situazione, oltre ad acquisirle. In questo modo, sarete in grado di trarre le conclusioni più utili dalle informazioni raccolte.`,
            "71": `Valutazione: Raramente esiste una soluzione apparente quando si cerca di rispondere a un argomento difficile. Nonostante il pensiero critico vi spinga a mettere da parte i vostri pregiudizi, dovete essere in grado di trarre conclusioni sicure sulla base delle informazioni in vostro possesso.`,
            "72": `Comunicazione: È necessario informare le altre parti interessate della propria scelta dopo che è stata fatta. Presentare i dati e sostenere le proprie conclusioni è una parte importante di una comunicazione professionale efficace, soprattutto se ci sono diverse risposte alternative.`,
            "73": `In ogni scenario, il pensiero critico mira a ottenere i massimi risultati potenziali. A tal fine, è necessario raccogliere e valutare dati provenienti da fonti il più possibile diverse.`,
            "74": `Il pensiero critico richiede un esame approfondito, e spesso sgradevole, dei propri punti di forza, delle proprie carenze e delle proprie preferenze, nonché della loro potenziale influenza sui giudizi che si possono esprimere.`,
            "75": `Il pensiero critico richiede lo sviluppo e l'uso della lungimiranza nella misura in cui è possibile.`,
            "76": `L'attuazione di decisioni basate sul pensiero critico richiede una valutazione dei risultati futuri e dei metodi per evitare o almeno ridurre l'effetto di eventuali conseguenze sfavorevoli.`,
            "77": `Il pensiero critico implica l'analisi dei risultati delle decisioni prese e, quando è possibile, l'attuazione di un cambiamento`,
            "78": `Critical Thinking | SkillsYouNeed. (n.d.). Skills You Need. Retrieved June 13, 2022, da https://www.skillsyouneed.com/learn/critical-thinking.html`,
            "79": `Doyle, A. (2022, March 15). Critical Thinking Definition, Skills, and Examples. ThoughtCo. Retrieved June 13, 2022, da https://www.thoughtco.com/critical-thinking-definition-with-examples-2063745`,
            "80": `Scriven, M., & Paul, R. (n.d.). Defining Critical Thinking. Foundation for Critical Thinking. Retrieved June 13, 2022, da https://www.criticalthinking.org/pages/defining-critical-thinking/766`,
            "81": `10 Top Critical Thinking Skills (And How to Improve Them). (2022, May 25). Indeed. Retrieved June 13, 2022, da https://www.indeed.com/career-advice/career-development/critical-thinking-skills`,
        },
        "games": {
            "title": "Giochi sul pensiero critico",
            "description": `Mettete alla prova le vostre capacità di pensiero critico con l'aiuto di un quiz divertente e interattivo chiamato "Fatto o opinione?", in cui dovrete decidere rapidamente in quale categoria si collocano diverse piccole sezioni prese da vari articoli su Internet.`,
            "description2": "La seconda parte vi sfida a giocare a 10 giochi sul pensiero critico che vi insegneranno a pensare fuori dagli schemi, metteranno alla prova la vostra creatività attraverso input visivi e testuali e vi aiuteranno a migliorare le vostre capacità di lavorare in gruppo. Divertitevi a giocare da soli, in coppia, in gruppi più piccoli o più numerosi.",
            "download": "Scarica il PDF",
            "startMessage": "Fatto o opinione?",
        },
        "debates": {
            "title": "Dibattiti non-formali",
            "description1": "Questa sezione fornisce l'accesso a dibattiti online per coloro che sono interessati a migliorare le proprie capacità di pensiero critico, lavorando in gruppo e sostenendosi a vicenda per trovare gli argomenti e le soluzioni migliori.",
            "description2": "Gli argomenti dei dibattiti sono interessanti e stimolanti, adatti a giovani di varie età e provenienze.",
            "item1": {
                "title": "CHI HA LASCIATO IL PAESE PER PIÙ DI 15 ANNI DOVREBBE AVERE DIRITTO DI VOTO LIMITATO",
                "description": "La discussione aveva come oggetto la mozione: CHI HA LASCIATO IL PAESE DA PIÙ DI 15 ANNI DEVE AVERE DIRITTI LIMITATI AL VOTO Il dibattito si è svolto secondo le regole del formato Dibattito Scolastico stabilite a livello mondiale, con interventi più brevi. I partecipanti sono studenti del Liceo Horezu \"Constantin Brâncoveanu."
            },
            "item2": {
                "title": "I CORSI DI SOPRAVVIVENZA ALLE GRANDI CRISI DOVREBBERO ESSERE INTRODOTTI A SCUOLA",
                "description": "L'attività consiste in una sfida tra una squadra di studenti e una di insegnanti. La sfida ha come tema: I CORSI DI SOPRAVVIVENZA ALLE CRISI MAGGIORI DEVONO ESSERE INTRODOTTI NELLE SCUOLE La sfida si svolge secondo le regole del dibattito scolastico stabilite a livello mondiale, con interventi ridotti. Alla fine vengono proposte solo due conclusioni, mentre la valutazione finale spetta a ogni persona che assiste al dibattito."
            },
            "item3": {
                "title": "LE NOTIZIE ONLINE DOVREBBERO ESSERE CENSURATE IN SITUAZIONI DI CRISI",
                "description": `Il video mostra come si può preparare e svolgere un'attività di dibattito online. Nella fase di organizzazione dell'attività, si formano le squadre, si ricordano le regole del gioco e si crea un'atmosfera adatta all'attività. Gli speaker vengono divisi in due stanze e viene assegnato il tempo per la preparazione della strategia delle squadre ed i ruoli degli speaker nella squadra. Il dibattito che si sta disputando ha come tema "LE NOTIZIE ONLINE DEVONO ESSERE CENSURATE IN SITUAZIONI DI CRISI". Al termine del dibattito il pubblico ha proposto le decisioni vincenti, ma ogni spettatore ha potuto decidere quale squadra ha vinto in base a quanto è stato convinto da una squadra o dall'altra.`,
            },
            "item4": {
                "title": "GLI STRUMENTI DIGITALI HANNO PIÙ VANTAGGI CHE SVANTAGGI NELLE CLASSI",
                "description": "Durante la realizzazione di questa sessione sono state rispettate tutte le fasi che questa attività comporta. Ai partecipanti sono state presentate nuovamente le regole del gioco, poi è stato scelto un brainstorming per definire la mozione che avrebbero giocato. Le squadre sono state organizzate in modo che ogni squadra avesse un membro per ogni Paese. È stato offerto loro il tempo necessario per preparare la documentazione e i loro interventi. La mozione discussa era: \"Gli strumenti digitali hanno più vantaggi che svantaggi nelle classi”."
            }
        },
        "methodologies": {
            "title": "Metodologia non formale",
            "description": "In questa sezione troverai 8 metodi non formali che riescono a sviluppare le abilità di pensiero critico. Tutti e 8 i metodi sono presentati in dettaglio in modo che tu possa utilizzarli con i tuoi amici o con il gruppo di destinatari con cui lavori.",
            "Escape Room": "Escape Room",
            "Alternative World Map": "Mappa del mondo alternativa",
            "Debate": "Dibattito",
            "Anyone Can Volunteer": "Chiunque può fare il volontario",
            "Who Believes in Superstition?": "Chi crede nelle superstizioni?",
            "Nasreddin Hodja": "Nasreddin Hodja",
            "Collect Create and Play": "Raccogliere, creare e giocare",
            "Survival in the Desert": "Sopravvivenza nel deserto",
            "Booklet": "Opuscolo",
            "Methodological context": "Contesto metodologico",
            "Administrative informations": "Informazioni amministrative",
            "Description of the methodology": "Descrizione della metodologia",
            "Evaluation and debriefing": "Valutazione e debriefing",
            "Feedback": "Feedback",
            "Annexes": "Allegati",
            "Preparation of the group" : "Preparazione del gruppo",
            "Implementing activities": "Implementazione delle attività",
        },
        "hoca": {
            "1": "Questo metodo è applicabile al contesto universitario, per la formazione di futuri educatori e insegnanti. Il gruppo target sono gli studenti, ma può essere applicato a qualsiasi gruppo di età o contesto. Lo scopo è di stimolare la discussione e il pensiero critico. Guardando le cose convenzionali da una nuova prospettiva, stimoliamo il gruppo target a riconoscere il fatto che ci possono essere altre prospettive da cui vedere il mondo e a esprimere le loro opinioni sulla questione. Promuovere l'apertura mentale e l'accettazione dei punti di vista degli altri.",
            "2": 'Tempo: 2-3 ore;',
            "3": 'Numero di giocatori: minimo 6, massimo 31;',
            "4": 'Luogo ideale: non ci sono requisiti di luogo, è sufficiente essere in uno spazio con poco rumore affinché la discussione possa essere ascoltata, in un ambiente confortevole e informale.',
            "5": 'Strumenti necessari: una mappa del mondo capovolta (vedi sezione Allegati), qualsiasi materiale adesivo per fissare la mappa sulla superficie verticale, foglietti adesivi, penne (matite).',
            "6": `Risorse umane: 3 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali ai partecipanti e di dare le istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcuni disaccordi tra loro durante la discussione, in modo da offrire più prospettive e far capire ai partecipanti che la discussione non riguarda il bianco o nero, giusto o sbagliato, ma riguarda diverse prospettive ed opinioni.`,
            "7": `Criteri di valutazione`,
            "8": `Chi effettua la valutazione? (commissione, auto-riflessione, valutazione di gruppo)`,
            "9": `Come si possono quantificare i risultati dell'attività e l'esito dell'apprendimento?`,
            "10": `Preparare un metodo per il de-briefing in modo che i partecipanti non rimangano nella mentalità di gioco o ancora in modalità di competizione.`,
            "11": `Criteri di valutazione: I criteri si basano sull'intensità complessiva della discussione, il coinvolgimento individuale e il pensiero critico di ogni partecipante durante l'attività (discussione, completamento del compito).`,
            "12": `Chi effettua la valutazione: Educatori;`,
            "13": `Quantificare i risultati e l'esito dell'apprendimento: Il punteggio sarà assegnato in base alla partecipazione attiva alla discussione, all'applicazione del pensiero critico, alla creatività e al lavoro di squadra.`,
            "14": `Debriefing: Presentazione delle mappe concettuali al gruppo e discussione che favorisce le competenze comunicative.`,
            "15": `Qual è stata la tua prima impressione quando hai capito che la mappa del mondo era capovolta?`,
            "16": `Hai trovato l'attività stimolante dal punto di vista del pensiero critico?`,
            "17": `Qual è secondo te lo scopo educativo dietro a questa attività?`,
            "18": `Vorresti modificare qualcosa?`,
            "19": `In che modo?`,
            "20": `Ti è stato utile lavorare in team?`,
            "21": `È stato facile trovare un accordo?`,
            "22": `Preparazione del gruppo:`,
            "23": `Disporre i partecipanti in semicerchio. Appendere la mappa in una zona nascosta della stanza, non visibile ai partecipanti. Mentre uno degli educatori descrive il compito, l'altro distribuisce i post-it e le matite ai partecipanti.`,
            "24": `Svolgimento dell'attività:`,
            "25": `I partecipanti vengono chiamati uno per volta ad andare in questa zona nascosta per guardare la mappa per tre secondi e scrivere subito la loro prima impressione su un post-it in massimo 2-1 parole, consegnandolo a uno degli educatori. Una volta che tutti l'hanno fatto, gli educatori leggono ad alta voce le risposte sui post-it e le attaccano sulla lavagna raggruppandole secondo i modelli che emergono. Poi gli educatori mostrano la mappa al gruppo. Di solito alcune delle risposte sui post-it riconoscono quei partecipanti che hanno notato la particolarità della mappa. E qui gli educatori iniziano una discussione ponendo queste due domande stimolanti:`,
            "26": `– Chi pensi abbia ideato questa mappa e perché?`,
            "27": `– Su quali assunzioni si basano le mappe tradizionali?`,
            "28": `I partecipanti possono motivare le loro risposte e la scelta delle parole, il che stimola la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare in modo critico.`,
            "29": `Valutazione e conclusioni:`,
            "30": `Quando tutti hanno avuto la possibilità di esprimere le proprie opinioni sulla mappa, gli educatori pongono una domanda finale "Qual è secondo te lo scopo di questa attività?" per stimolare ulteriore pensiero critico. Con l'aiuto degli educatori, la conclusione che ci auguriamo sia quella che le mappe convenzionali dell'Occidente, orientate a nord, tendono a distorcere la visione del mondo e mettono in risalto l'Europa e il Nord America, implicando che gli altri paesi sono meno importanti. Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori annotano ogni parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti divisi in gruppi più piccoli (5-4 persone) vengono invitati a creare una mappa concettuale che elabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, come carta da poster, matite, app digitali, ecc.`,
            "31": `Spiega come avverrà il processo di valutazione e quale tipo di valutazione sarà utilizzato, a seconda dei seguenti fattori:`,
            "32": `Per il feedback sarà utilizzato il seguente questionario:`,
        },
        "superstition": {
            "1": "Questo metodo è applicabile al contesto universitario, per la formazione di futuri educatori e insegnanti. Il gruppo target sono gli studenti, ma può essere applicato a qualsiasi fascia d'età o contesto. Lo scopo è quello di stimolare la discussione e il pensiero critico. Vedendo cose convenzionali da una nuova prospettiva, si stimola il gruppo target a riconoscere il fatto che ci possono essere altre prospettive dal quale vedere il mondo ed esprimere le proprie opinioni sulla questione. Promuovere la mentalità aperta e l'accettazione dei punti di vista degli altri.",
            "2": "Durata: 1 - 2 ore;",
            "3": "Numero di giocatori: minimo 5, massimo 30;",
            "4": "Requisiti locali: nessun requisito di posizione, è sufficiente essere in uno spazio con un rumore non troppo elevato per far sentire la discussione, un ambiente informale e confortevole.",
            "5": "Strumenti necessari: Una mappa del mondo capovolta (vedere sezione Allegati), qualsiasi materiale adesivo per fissare la mappa sulla superficie verticale, note adesive, penne (matite).",
            "6": "Risorse umane: 2 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali ai partecipanti e di dare le istruzioni su come svolgere l'attività. Quindi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcune divergenze tra di loro durante la discussione, in modo da fornire più prospettive e affinché i partecipanti comprendano che la discussione non riguarda il bianco o il nero, il giusto o il sbagliato, ma riguarda diverse prospettive e opinioni.",
            "7": "Disponi i partecipanti in un semicerchio. Appendere la mappa in un'area nascosta della stanza, non visibile ai partecipanti. Mentre uno dei formatori descrive il compito, l'altro distribuisce i foglietti adesivi e le penne ai partecipanti.",
            "8": "I partecipanti vengono chiamati uno per volta per entrare in quest'area nascosta, guardare la mappa per tre secondi e scrivere immediatamente la loro prima impressione su un foglio adesivo in max 1-2 parole, consegnarlo a uno dei formatori. Una volta che tutti lo hanno fatto, i formatori leggono ad alta voce le risposte sui foglietti e li attaccano sulla bacheca raggruppandoli secondo i pattern che emergono. Poi i formatori mostrano la mappa al gruppo. Solitamente alcune delle risposte sui post-it riconoscono i partecipanti che hanno notato la particolarità della mappa. Qui gli educatori avviano una discussione ponendo queste due domande che stimolano la riflessione:",
            "9": "Chi pensi abbia ideato questa mappa e perché?",
            "10": "Su quali presupposti si basano le mappe tradizionali?",
            "11": "I partecipanti possono motivare le loro risposte e la scelta delle parole che stimolano la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare criticamente.",
            "12": `Quando tutti hanno avuto la possibilità di esprimere le loro opinioni sulla mappa, gli educatori pongono una domanda finale: "Qual è secondo te lo scopo di questa attività?", per stimolare ulteriormente il pensiero critico. Con l'aiuto degli educatori, ci aspettiamo che la conclusione sia che le mappe convenzionali orientate a nord tendono a distorcere la visione del mondo ed enfatizzare l'Europa e il Nord America, implicando che altri paesi siano meno importanti. Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori annotano qualsiasi parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti divisi in gruppi più piccoli (4-5 persone) sono invitati a creare una mappa concettuale che rielabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, come carta da poster, matite, app digitali, ecc.`,
            "13": "Spieghiamo ora come avverrà il processo di valutazione e che tipo di valutazione verrà utilizzata, in base ai seguenti fattori",
            "14": "Criteri di valutazione",
            "15": "Chi effettuerà la valutazione? (commissione, auto-riflessione, valutazione di gruppo)",
            "16": "Come si possono quantificare i risultati delle attività e dell'apprendimento?",
            "17": "Preparare un metodo di debriefing affinché i partecipanti non rimangano nella mentalità del gioco o ancora in modalità di competizione.",
            "18": "I criteri di valutazione si basano sull'intensità complessiva della discussione, sul coinvolgimento individuale e sul pensiero critico di ciascun partecipante durante l'attività (discussione, completamento del compito).",
            "19": "La valutazione sarà effettuata dagli educatori;",
            "20": "I punteggi saranno assegnati in base alla partecipazione attiva alla discussione, all'applicazione del pensiero critico, alla creatività e al lavoro di squadra.",
            "21": "Per il debriefing, si prevede una presentazione delle mappe concettuali al gruppo e una discussione che favorisca le competenze comunicative.",
            "22": "Per il feedback, verrà utilizzato il seguente questionario",
            "23": "Qual è stata la tua prima impressione quando hai capito che la mappa del mondo era capovolta?",
            "24": "Hai trovato l'attività stimolante?",
            "25": "Quale pensi sia lo scopo educativo dietro di essa?",
            "26": "Modificheresti qualcosa?",
            "27": "Come?",
            "28": "È stato utile per te lavorare in un team?",
            "29": "È stato facile trovare un accordo?",
        },
        "volunteer": {
            "1": "Questo metodo è applicabile in contesti universitari, ad esempio per formare futuri educatori e insegnanti. Il gruppo target sono gli studenti, ma può essere applicato a qualsiasi fascia d'età o contesto. Lo scopo è stimolare la discussione e il pensiero critico. Vedendo le cose convenzionali sotto una nuova prospettiva, si stimola il gruppo target a riconoscere che ci possono essere altre prospettive da cui vedere il mondo ed esprimere le proprie opinioni sulla questione. Promuovere la mentalità aperta e l'accettazione dei punti di vista degli altri.",
            "2": "Tempo: 1-2 ore;",
            "3": "Numero di giocatori: minimo 5, massimo 30;",
            "4": "Requisiti locali: non ci sono requisiti di posizione, è sufficiente essere in uno spazio con poco rumore affinché la discussione possa essere ascoltata, un ambiente informale e confortevole.",
            "5": "Strumenti necessari: una mappa del mondo capovolta (vedi sezione Allegato), qualsiasi materiale adesivo per fissare la mappa sulla superficie verticale, post-it, penne (matite).",
            "6": "Risorse umane: 2 educatori. Il primo ha il compito di spiegare l'attività e controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali per i partecipanti e di dare le istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcune divergenze tra loro durante la discussione, in modo da fornire più prospettive e far capire ai partecipanti che la discussione non riguarda il bianco o il nero, il giusto o il sbagliato, ma riguarda diverse prospettive e opinioni.",
            "7": "Preparazione del gruppo:",
            "8": "Disporre i partecipanti in semicerchio. Appendere la mappa in una zona nascosta della stanza, non visibile ai partecipanti. Mentre uno degli educatori descrive il compito, l'altro consegna i post-it e le penne ai partecipanti.",
            "9": "Implementare le attività:",
            "10": "I partecipanti vengono chiamati uno per uno a entrare in questa zona nascosta per guardare la mappa per tre secondi e scrivere subito la loro prima impressione su un post-it in massimo 1-2 parole, consegnarlo a uno degli educatori. Una volta che tutti hanno finito, gli educatori leggono ad alta voce le risposte sui post-it e li attaccano sulla lavagna raggruppandoli secondo i modelli che emergono.  Quindi gli educatori mostrano la mappa al gruppo. Di solito alcune risposte sui post-it riconoscono quei partecipanti che hanno notato la peculiarità della mappa. E qui gli educatori avviano una discussione ponendo queste due domande che stimolano la riflessione:",
            "11": "Chi pensi abbia ideato questa mappa e perché?",
            "12": "Su quali presupposti si basano le mappe tradizionali?",
            "13": "I partecipanti possono motivare le loro risposte e la scelta delle parole che stimolano la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare criticamente.",
            "14": "Valutazione e debriefing:",
            "15": "Quando tutti hanno avuto la possibilità di esprimere le proprie opinioni sulla mappa, gli educatori pongono una domanda finale: “Qual è secondo te lo scopo di questa attività?”, al fine di stimolare ulteriore pensiero critico. Con l'aiuto degli educatori, la conclusione che speriamo di ottenere è questa: le mappe convenzionali a orientamento nord tendono a distorcere la visione del mondo ed enfatizzare l'Europa e il Nord America, implicando che altri paesi sono meno importanti. Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori scrivono ogni parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti divisi in gruppi più piccoli (4-5 persone) sono invitati a creare una mappa concettuale che rielabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, come carta da poster, matite, app digitali, ecc.",
            "16": "Spiega come procederà il processo di valutazione e che tipo di valutazione verrà utilizzata, a seconda dei seguenti fattori:",
            "17": "Criteri di valutazione",
            "18": "Chi effettua la valutazione? (commissione, autovalutazione, valutazione di gruppo)",
            "19": "Come è possibile quantificare i risultati delle attività e l'apprendimento?",
            "20": "Prepara un metodo di debriefing in modo che i partecipanti non rimangano nella mentalità di gioco o ancora in modalità di competizione.",
            "21": "Criteri di valutazione: I criteri si basano sull'intensità complessiva della discussione, il coinvolgimento individuale e il pensiero critico di ciascun partecipante durante l'attività (discussione, completamento del compito).",
            "22": "Chi effettua la valutazione: Educatori;",
            "23": "Quantificazione dei risultati e dell'apprendimento: Verrà attribuito un punteggio in base alla partecipazione attiva alla discussione, all'applicazione del pensiero critico, alla creatività e al lavoro di squadra.",
            "24": "Debriefing: Presentazione delle mappe concettuali al gruppo e discussione che favorisce le competenze comunicative.",
            "25": "Qual è stata la tua prima impressione quando ti sei reso conto che la mappa del mondo era capovolta?",
            "26": "Hai trovato l'attività stimolante?",
            "27": "Quale pensi sia lo scopo educativo dietro di essa?",
            "28": "Vorresti modificare qualcosa?",
            "29": "Come?",
            "30": "Ti è stato utile lavorare in gruppo?",
            "31": "È stato facile trovare un accordo?",
            "32": "Per il feedback verrà utilizzato il seguente questionario:",
        },
        "escape": {
            "0": `Questo metodo è applicabile a qualsiasi ambiente educativo, lo scopo è fornire informazioni sulla metodologia CRAAP ai partecipanti e metterla in pratica. Oltre ai metodi CRAAP, verranno fornite informazioni anche sui metodi COMPASS. L'obiettivo delle attività di sviluppo delle competenze chiave è di stimolare il pensiero critico e l'informazione corretta attraverso la risoluzione di puzzle interattivi e logici, ciò significa che l'attività stimola anche la mente usando la logica. L'attività si basa sul lavoro di gruppo e sul confronto tra i partecipanti per affrontare gli enigmi. Questa metodologia, attraverso piccole modifiche, è applicabile a qualsiasi tema.`,
            "1": `Preparazione del gruppo:`,
            "2": `Attuazione delle attività:`,
            "3": `Per attuare l'escape room devi seguire le istruzioni nell'ordine seguente:`,
            "4": `Leggi prima di tutto la seguente storia fuori dalla stanza e prima che i giocatori inizino a giocare.`,
            "5": `Buongiorno,`,
            "6": `Siete giornalisti investigativi e fate parte di un'indagine contro le fake news. Al giorno d'oggi a causa del COVID-19 e dei vaccini, le fake news si sono moltiplicate. 30 minuti fa avete ricevuto una chiamata dalla vostra collega Sarah Blakeslee, che vi ha detto di aver ottenuto informazioni molto importanti per il caso, che vuole mostrarvi e che cambieranno il corso dell'indagine.`,
            "7": `Quando siete arrivati nel suo ufficio, lei non c'è!! Avete sospetti concreti che la polizia abbia interferito con la chiamata e l'abbia arrestata, tuttavia siete arrivati all'ufficio prima della polizia, quindi non avete molto tempo per trovare quell'informazione, pubblicarla, svelare la cospirazione e salvare Sarah Black.`,
            "8": `Dovresti chiarire che è un gioco lineare, ogni strumento può essere usato solo una volta ma c'è solo un elemento centrale (il computer) che dovrebbe essere usato molte volte.`,
            "9": `Punto di partenza`,
            "10": `Entrando nella stanza trovi solo un computer sul tavolo e un pezzo di carta con un messaggio: "La polizia sta arrivando! Controlla il mio computer! SB.”.Nel gioco originale questo messaggio è nascosto sulle lame del condizionatore d'aria. La stanza è estremamente calda e devi accendere il condizionatore.Se questo messaggio non appare, il gioco non subisce cambiamenti.`,
            "11": `Puzzle 1-8:`,
            "12": `Dopo di che, il gioco consiste in 8 puzzle e continua con la risoluzione. I seguenti puzzle possono essere trovati nel file ANNEX_2 nel link nel capitolo 6`,
            "13": `Missione finale:`,
            "14": `Trovare l’informazione-chiave che Sarah aveva trovato, quindi chiamare l'editore e darglielo.Chiusura/Analisi (spiegare come è fatto e includere qualsiasi framework/link):`,
            "15": ` Spiegare come andrà il processo di valutazione e quale tipo di valutazione verrà utilizzato, a seconda dei seguenti fattori:`,
            "16": `Criteri di valutazione:`,
            "17": `L'impatto e l'efficienza che le escape room avranno sui partecipanti/giocatori saranno il criterio per valutare l'attività svolta. La valutazione sarà guidata dal gruppo o da un singolo facilitatore che guiderà la conversazione e farà domande riguardanti l'escape room e le informazioni fornite loro.`,
            "18": `Quantificare i risultati:`,
            "19": ` I risultati saranno quantificati in base a come i giocatori hanno applicato il metodo CRAAP durante l'escape room e, in generale, come hanno giocato l'escape room come gruppo e come individui. I risultati saranno anche quantificati facendo loro alcune domande sul metodo CRAAP e sulle informazioni corrette durante la riflessione.`,
            "20": `Metodo per il debriefing:`,
            "21": `Come già accennato in precedenza, alla fine dell'escape room c'è un'analisi di chiusura, la stessa sarà l'attività di uscita che può essere svolta non appena l'escape room è conclusa in modo che i partecipanti non rimangano nella mentalità del gioco.`,
            "22": `Preparare un metodo di feedback che può essere utilizzato per capire come il processo è stato percepito dai partecipanti e utilizzare questo strumento per migliorare o adattare il metodo descritto.`,
            "23": `Discutere con i partecipanti per raccogliere il maggior numero possibile di feedback chiedendo le loro impressioni sull'attività svolta:`,
            "24": 'Dimensione del gruppo: 3-6 persone’',
            "25": 'Età: 16-30 anni ',
            "26": 'Tempo: 45 minuti',
            "27": `Location: La stanza dell'escape room dovrebbe essere implementata in una stanza abbastanza grande, che non sia vuota, preferibilmente con un'ulteriore porta interna che conduce ad un'altra stanza.`,
            "28": `Materiali: Ci sono diversi materiali che saranno necessari per allestire e implementare l'escape room. L'elenco dettagliato è presentato nel documento ANNEX_1. Il documento può essere trovato nel link nel capitolo 6.`,
            "29": `Risorse umane: L'attività può essere implementata da un valutatore/facilitatore/formatore o da un gruppo formato da loro. Durante l'implementazione dell'escape room, deve esserci solo una persona di riferimento che dovrà aiutare i partecipanti quando ne hanno bisogno`,
            "30": `Chiedi ai partecipanti se sanno cos'è una escape room.`,
            "31": `Spiega ai partecipanti cos'è una escape room, specificando che l'escape room in cui prenderanno parte e giocheranno non sarà una escape room classica ma una escape room educativa.`,
            "32": `Dopo di che, dividili in diversi gruppi di massimo 6 persone.`,
            "33": `I gruppi parteciperanno all'escape room uno dietro l'altro.`,
            "34": `Durante l'implementazione dell'escape room i giocatori sono soli dentro la stanza. Non c'è alcun personaggio possibile che si adatti alla storia dell'escape room. Forse la figura dell'amico di Sarah, che sta aspettando il gruppo di giornalisti, potrebbe adattarsi per aprire la porta principale e farli entrare.`,
            "35": 'https://www.europarl.europa.eu/RegData/etudes/ATAG/2017/599386/EPRS_ATA(2017)599386_EN.pdf Il documento sopra mostra 8 passaggi da seguire per individuare quando una notizia è falsa.',
            "36": 'La soluzione per l’indovinello numero 5 è CRAAP, un metodo pubblicato da Sarah Blakeslee. È anche la giornalista che ha contattato i giocatori per andare nel suo ufficio.',
            "37": `Nell’indovinello numero 6 i partecipanti devono usare il metodo CRAAP appreso nell’indovinello precedente per trovare il documento con la password corretta. Pensiamo che forse una nota da Sarah sia utile in questo passaggio. Esempio: "Non avete abbastanza tempo prima dell'arrivo della polizia! Usate il metodo usato per aprire questa scatola per verificare quale di queste notizie è vera! S.B”`,
            "38": 'Discutere con i partecipanti se sono abituati a verificare le notizie prima di condividerle su Internet.',
            "39": 'Criteri di valutazione',
            "40": 'Chi sta effettuando la valutazione? (commissione, autovalutazione, valutazione di gruppo)',
            "41": 'Come si possono quantificare i risultati delle attività e di apprendimento?',
            "42": 'Prepara un metodo di debriefing così che i partecipanti non restino in modalità di gioco o di competizione.',
            "43": 'E’ stato divertente? Ti sei sentito coinvolto nell’attività?',
            "44": 'Hai imparato qualcosa di nuovo? Se sì, cosa?',
            "45": 'Ci sono state attività che hai trovato piuttosto difficili? In tal caso, come le renderesti più facili?',
            "46": 'Quali sono le tue impressioni sul gruppo che ha svolto le attività? Tutti i partecipanti hanno contribuito alla soluzione di ogni enigma?',
            "47": `Applicherai ciò che hai imparato attraverso l'escape room nella tua vita quotidiana?`,
        },
        "desert": {
            "0": `Questo metodo è applicabile nel contesto universitario, per la formazione di futuri educatori e insegnanti. Il gruppo target sono gli studenti, ma può essere applicato a qualsiasi gruppo di età o contesto. Lo scopo è quello di stimolare la discussione e il pensiero critico. Vedendo le cose convenzionali sotto una nuova prospettiva, stimoliamo il gruppo target a riconoscere il fatto che ci possono essere altre prospettive da cui vedere il mondo ed esprimere le loro opinioni sulla questione. Promuovere la mente aperta e l'accettazione dei punti di vista degli altri.`,
            "1": `Preparazione del gruppo:`,
            "2": `Disporre i partecipanti in semicerchio. Appendere la mappa in una zona nascosta della stanza, non visibile ai partecipanti. Mentre uno degli educatori descrive il compito, l'altro distribuisce ai partecipanti i foglietti adesivi e le matite.`,
            "3": `Svolgimento delle attività:`,
            "4": `I partecipanti vengono chiamati uno per volta ad entrare in questa zona nascosta per guardare la mappa per tre secondi e scrivere immediatamente la loro prima impressione su un foglietto adesivo in massimo 1-2 parole, consegnandolo ad uno degli educatori. Una volta che tutti lo hanno fatto, gli educatori leggono ad alta voce le risposte sui foglietti adesivi e li attaccano sulla lavagna raggruppandoli secondo i modelli che emergono. Poi gli educatori mostrano la mappa al gruppo. Di solito alcune risposte sui foglietti adesivi riconoscono quei partecipanti che hanno notato la peculiarità della mappa. E qui gli educatori avviano una discussione ponendo queste due stimolanti domande:`,
            "5": `– Chi pensi abbia elaborato questa mappa e perché?`,
            "6": `– Su quali assunzioni si basano le mappe tradizionali?`,
            "7": `I partecipanti possono motivare le loro risposte e la scelta delle parole che stimola la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare in modo critico.`,
            "8": `Valutazione e riflessione finale:`,
            "9": `Quando tutti hanno avuto la possibilità di esprimere le proprie opinioni sulla mappa, gli educatori pongono una domanda finale "Cosa pensi sia lo scopo di questa attività?" per stimolare ulteriore pensiero critico. Con l'aiuto degli educatori, ci auguriamo che la conclusione sia questa: le mappe convenzionali occidentali orientate a nord tendono a distorcere la visione del mondo e ad enfatizzare l'Europa e il Nord America, implicando che gli altri paesi siano meno importanti. Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori scrivono qualsiasi parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti, divisi in gruppi più piccoli (4-5 persone), sono invitati a creare una mappa concettuale che ri-elabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, come carta da poster, matite, app digitali, ecc.`,
            "10": `Spiega come si svolgerà il processo di valutazione e che tipo di valutazione verrà utilizzata, a seconda dei seguenti fattori:`,
            "11": `Per il feedback verrà utilizzato il seguente questionario:`,
            "12": 'Tempo: 1-2 ore;',
            "13": 'Numero di partecipanti: minimo 5, massimo 30;',
            "14": `Requisiti per la location: non ci sono requisiti, è sufficiente essere in uno spazio con un rumore non eccessivo per consentire l'udibilità della discussione, in un ambiente confortevole e non formale.`,
            "15": `Risorse umane: 2 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali ai partecipanti e di fornire le istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcuni disaccordi tra di loro durante la discussione, in modo da offrire più prospettive e in modo che i partecipanti comprendano che la discussione non riguarda il bianco o il nero, il giusto o il sbagliato, ma diverse prospettive e opinioni.`,
            "16": `Risorse umane: 2 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali ai partecipanti e di fornire le istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcuni disaccordi tra di loro durante la discussione, in modo da offrire più prospettive e in modo che i partecipanti comprendano che la discussione non riguarda il bianco o il nero, il giusto o il sbagliato, ma diverse prospettive e opinioni.`,
            "17": `Criteri di valutazione`,
            "18": `Chi effettua la valutazione? (commissione, auto-riflessione, valutazione di gruppo)`,
            "19": `Come si possono quantificare i risultati delle attività e gli obiettivi di apprendimento?`,
            "20": `Preparare un metodo di debriefing in modo che i partecipanti non rimangano nella mentalità del gioco o ancora in modalità di competizione.`,
            "21": `I criteri di valutazione si basano sull'intensità generale della discussione, sull'implicazione individuale e sul pensiero critico di ciascun partecipante durante l'attività (discussione, completamento del compito).`,
            "22": `Chi effettua la valutazione: Educatori;`,
            "23": `Quantificare i risultati e gli obiettivi di apprendimento: Il punteggio verrà assegnato in base alla partecipazione attiva alla discussione, all'applicazione del pensiero critico, alla creatività e al lavoro di squadra.`,
            "24": `Debriefing: Presentazione delle mappe concettuali al gruppo e discussione che favorisca le abilità comunicative.`,
            "25": `Qual è stata la tua prima impressione quando hai capito che la mappa del mondo era capovolta?`,
            "26": `Hai trovato l'attività stimolante dal punto di vista del pensiero?`,
            "27": `Qual è secondo te lo scopo educativo dietro questa attività?`,
            "28": `Modificheresti qualcosa?`,
            "29": `Come?`,
            "30": `Ti è stato utile lavorare in squadra?`,
            "31": `È stato facile trovare un accordo?`,
        },
        "debate": {
            "0": `Questa attività è adatta per studenti delle scuole superiori e dell'università al fine di sviluppare il loro pensiero critico, migliorare le loro abilità di parlare in pubblico e comunicative. L'attività aiuterà il gruppo target a fare una ricerca adeguata e formare opinioni giustificate.`,
            "1": `Preparazione del gruppo:`,
            "2": `Attuazione delle attività:`,
            "3": `Per attuare la sala escape devi seguire le istruzioni nell'ordine seguente:`,
            "4": `Leggi prima la seguente storia fuori dalla stanza e prima che i giocatori inizino a giocare.`,
            "5": `Buongiorno,`,
            "6": `Siete giornalisti d'inchiesta e fate parte di un'indagine contro le fake news. Al giorno d'oggi, a causa del COVID-19 e dei vaccini, le fake news si sono moltiplicate. 30 minuti fa avete ricevuto una chiamata dalla vostra collega Sarah Blakeslee, che vi ha detto di aver ottenuto informazioni molto importanti per il caso, che vuole mostrarvi e che cambieranno il corso dell'indagine.`,
            "7": `Quando arrivate nel suo ufficio, lei non c'è!! Avete sospetti concreti che la polizia abbia interferito con la chiamata e l'abbia arrestata, tuttavia siete arrivati all'ufficio prima della polizia, quindi non avete molto tempo per trovare quella informazione, pubblicarla, svelare la cospirazione e salvare Sarah Black.`,
            "8": `Dovresti chiarire che si tratta di un gioco lineare, ogni strumento può essere utilizzato solo una volta ma c'è solo un elemento centrale (il computer) che dovrebbe essere utilizzato molte volte.`,
            "9": `Punto di partenza:`,
            "10": `Quando entri nella stanza, trovi solo un computer sul tavolo e un pezzo di carta con un messaggio: "La polizia sta arrivando! Controlla il mio computer! SB". Nel gioco originale questo messaggio è nascosto sulle lame del condizionatore d'aria. La stanza è estremamente calda e devi accendere il condizionatore.Se questo messaggio non compare, il gioco non subisce alcuna modifica.`,
            "11": `Enigma 1-8:`,
            "12": `Dopo di ciò, il gioco consiste in 8 enigmi e continua con la soluzione. Gli enigmi successivi possono essere trovati nel file ANNEX_2 al link nel capitolo 6`,
            "13": `Missione finale:`,
            "14": `Trova l’informazione-chiave che Sarah ha trovato, quindi chiama l'editore e dagliela. Chiusura/Analisi (spiegare come viene fatto e includere qualsiasi struttura/link):`,
            "15": `Spiegare come andrà il processo di valutazione e che tipo di valutazione sarà usata, a seconda dei seguenti fattori:`,
            "16": `Criteri di valutazione:`,
            "17": `L'impatto e l'efficienza che le escape room avranno sui partecipanti/giocatori saranno il criterio per valutare l'attività svolta. La valutazione sarà condotta dal gruppo o da un singolo facilitatore che guida la conversazione e fa domande riguardo all'escape room e alle informazioni fornite loro.`,
            "18": `Quantificare i risultati:`,
            "19": `I risultati saranno quantificati in base a come i giocatori hanno applicato il metodo CRAAP durante l'escape room e, in generale, come hanno giocato l'escape room come gruppo e individui. I risultati saranno anche quantificati facendo loro durante la riflessione diverse domande sul metodo CRAAP e sulle informazioni corrette.`,
            "20": `Metodo per il debriefing:`,
            "21": `Come già menzionato in precedenza, alla fine dell'escape room c'è un'analisi di chiusura, la stessa sarà l'attività di uscita che può essere svolta non appena l'escape room è conclusa in modo che i partecipanti non rimangano nel mindset di gioco`,
            "22": `Preparare un metodo di feedback che possa essere utilizzato per scoprire come il processo è stato percepito dai partecipanti e utilizzare questo strumento per migliorare o adattare il metodo descritto.`,
            "23": `Discutere con i partecipanti per raccogliere il maggior numero possibile di feedback chiedendo loro le loro impressioni sull'attività svolta. Possono essere utilizzate le seguenti domande:`,
            "24": 'Tempo: 1 - 2 ore;',
            "25": 'Numero di giocatori: minimo 5, massimo 30;',
            "26": 'Requisiti locali: non ci sono requisiti di ubicazione, è sufficiente essere in uno spazio con poco rumore affinché la discussione possa essere udita, un ambiente confortevole e informale.',
            "27": 'Strumenti necessari: una mappa del mondo capovolta (vedi sezione Allegato), qualsiasi materiale adesivo per fissare la mappa sulla superficie verticale, post-it, penne (matite).',
            "28": `Risorse umane: 3 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, il secondo di distribuire i materiali ai partecipanti e di dare istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare alcuni disaccordi tra di loro durante la discussione, ma ciò offre più prospettive affinché i partecipanti comprendano che la discussione non riguarda il bianco o il nero, il giusto o lo sbagliato, ma diverse prospettive e opinioni.`,
            "29": `Chiedi ai partecipanti se sanno cos'è una escape room.`,
            "31": `Spiega ai partecipanti cos'è una escape room, specificando che la escape room in cui prenderanno parte non sarà una escape room classica, ma una escape room educativa.`,
            "32": `Dopo di che, dividi i partecipanti in diversi gruppi di fino a 6 persone.`,
            "33": `I gruppi parteciperanno alla escape room uno dopo l'altro.`,
            "34": `Durante l'implementazione della escape room, i giocatori sono soli all'interno della stanza. Non c'è una figura possibile che si adatti alla storia della escape room. Forse la figura dell'amico di Sarah che sta aspettando il gruppo di giornalisti potrebbe adattarsi per aprire la porta principale e farli entrare.`,
            "35": `https://www.europarl.europa.eu/RegData/etudes/ATAG/2017/599386/EPRS_ATA(2018)599387_EN.pdf Il documento sopra mostra 8 passi da seguire per individuare quando le notizie sono false.`,
            "36": `Nel rompicapo numero 9 i partecipanti devono utilizzare il metodo CRAAP appreso nel rompicapo precedente per trovare il documento con la password corretta. Pensiamo che una nota di Sarah sia utile in questo passaggio, ad esempio: "Non avete abbastanza tempo prima che arrivi la polizia! Utilizzate il metodo utilizzato per aprire questa scatola per verificare quale di queste notizie è reale! S.B": In puzzle number 9 the participants have to use the CRAPP method learned in the puzzle before in order to find the document with the correct password. We think that maybe a note from Sarah is useful in this step. i.g. “You don't have enough time before the police arrive! Use the method used to open this box to check which of this news is real! S.B”`,
            "38": `Discutere con i partecipanti se sono soliti verificare le notizie prima di condividerle su Internet.`,
            "39": `Criteri di valutazione`,
            "40": `Chi effettua la valutazione? (commissione, auto-riflessione, valutazione di gruppo)`,
            "41": `Come è possibile quantificare i risultati delle attività e dell'apprendimento?`,
            "42": `Preparare un metodo per la sessione di de-briefing in modo che i partecipanti non rimangano nel mindset del gioco o ancora in modalità di competizione.`,
            "43": `Ti sei divertito/a e ti sei sentito/a coinvolto/a nell'attività?`,
            "44": `Hai imparato qualcosa di nuovo? Se sì, cosa?`,
            "45": `Ci sono state attività che hai trovato piuttosto difficili? In tal caso, come le renderesti più facili?`,
            "46": `Quali sono le tue impressioni sul gruppo che ha svolto le attività? Tutti i partecipanti hanno contribuito alla soluzione di ogni enigma?`,
            "47": `Applicherai ciò che hai imparato attraverso l'escape room nella tua vita quotidiana?`,
        },
        "collect": {
            "0": `Questo metodo è applicabile al contesto universitario, alla formazione di futuri educatori e insegnanti. Il gruppo target è composto da studenti, ma può essere applicato a qualsiasi fascia d'età o contesto. Lo scopo è stimolare la discussione e il pensiero critico. Vedendo cose convenzionali da una nuova prospettiva, stimoliamo il gruppo target a riconoscere il fatto che ci possono essere altre prospettive da cui vedere il mondo ed esprimere le loro opinioni sulla questione. Promuoviamo la mentalità aperta e l'accettazione dei punti di vista degli altri.`,
            "1": `Preparazione del gruppo:`,
            "2": `Disporre i partecipanti a semicerchio. Appendere la mappa in un'area nascosta della stanza, non visibile ai partecipanti. Mentre uno degli educatori descrive il compito, l'altro distribuisce i post-it e le matite ai partecipanti.`,
            "3": `Svolgimento delle attività:`,
            "4": ` I partecipanti vengono chiamati uno per volta ad entrare in questa zona nascosta per guardare la mappa per tre secondi e scrivere subito la loro prima impressione su un post-it in massimo 1-2 parole, consegnandolo a uno degli educatori. Una volta che tutti lo hanno fatto, gli educatori leggono ad alta voce le risposte sui post-it e li attaccano alla lavagna raggruppandoli in base ai modelli che emergono. Poi gli educatori mostrano la mappa al gruppo. Di solito alcune delle risposte sui post-it evidenziano quei partecipanti che hanno notato la particolarità della mappa. E qui gli educatori iniziano una discussione ponendo queste due domande stimolanti:`,
            "5": `– Chi pensi abbia ideato questa mappa e perché?`,
            "6": `– Su quali presupposti si basano le mappe tradizionali?`,
            "7": `I partecipanti possono motivare le loro risposte e la scelta delle parole che stimolano la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare criticamente.`,
            "8": `Valutazione e discussione finale:`,
            "9": `Quando tutti hanno avuto la possibilità di esprimere le loro opinioni sulla mappa, gli educatori pongono una domanda finale "Cosa pensate sia lo scopo di questa attività?"
                                in modo da stimolare ulteriore pensiero critico. Con l'aiuto degli educatori, speriamo di arrivare alla conclusione che le mappe convenzionali dell'Occidente orientate a nord tendono a distorcere la visione del mondo e a enfatizzare l'Europa e il Nord America, implicando che altri paesi siano meno importanti. Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori annotano qualsiasi parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti, divisi in gruppi più piccoli (4-5 persone), sono invitati a creare una mappa concettuale che rielabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, come carta da poster, matite, app digitali, ecc.`,
            "10": `Spiega come andrà il processo di valutazione e che tipo di valutazione verrà utilizzata, a seconda dei seguenti fattori:`,
            "11": `Per il feedback verrà utilizzato il seguente questionario:`,
            "12": 'Tempo: 1-2 ore;',
            "13": `Numero di partecipanti: minimo 5, massimo 30;`,
            "14": `Requisiti per la lcoation: non sono necessari requisiti specifici, è sufficiente uno spazio con poca rumorosità per poter sentire la discussione, un ambiente confortevole e non formale.`,
            "15": `Strumenti necessari: una mappa del mondo capovolta (vedi sezione allegati), qualsiasi materiale adesivo per fissare la mappa su una superficie verticale, note adesive, penne (matite).`,
            "16": `Risorse umane: 3 educatori. Il primo ha il compito di spiegare l'attività e di controllare i partecipanti mentre osservano la mappa, e il secondo di distribuire i materiali ai partecipanti e di fornire le istruzioni su come svolgere l'attività. Poi entrambi facilitano e guidano la discussione. La presenza di due formatori potrebbe creare qualche disaccordo tra loro durante la discussione, quindi dà più prospettive e in modo che i partecipanti capiscano che la discussione non riguarda il bianco o il nero, il giusto o il sbagliato, ma diverse prospettive ed opinioni.`,
            "17": `Criteri di valutazione`,
            "18": `Chi sta effettuando la valutazione? (commissione, auto-riflessione, valutazione di gruppo)`,
            "19": `Come puoi quantificare i risultati delle attività e degli obiettivi di apprendimento?`,
            "20": `Prepara un metodo per la discussione post-attività in modo che i partecipanti non rimangano nel mindset del gioco o ancora in modalità competitiva.`,
            "21": `Criteri di valutazione: i criteri si basano sull'intensità complessiva della discussione, sul coinvolgimento individuale e sul pensiero critico di ciascun partecipante durante l'attività (discussione, completamento del compito).`,
            "22": `Chi fa la valutazione: gli educatori;`,
            "23": `Quantificare i risultati e gli obiettivi di apprendimento: il punteggio sarà assegnato in base alla partecipazione attiva alla discussione, all'applicazione del pensiero critico, alla creatività e al lavoro di squadra.`,
            "24": `Debriefing: presentazione delle mappe concettuali al gruppo e discussione che favoriscono le abilità comunicative.`,
            "25": `Qual è stata la tua prima impressione quando hai capito che la mappa del mondo era capovolta?`,
            "26": `Hai trovato l'attività stimolante?`,
            "27": `Qual è secondo te lo scopo educativo dietro questa attività?`,
            "28": `Vorresti modificare qualcosa?`,
            "29": `Come?`,
            "31": `Ti è stato utile lavorare in squadra?`,
            "32": `È stato facile trovare un accordo?`,
        },
        "worldmap": {
            "0": `Questo metodo è applicabile nel contesto universitario, per formare futuri educatori e insegnanti. Il gruppo di riferimento sono gli studenti, ma può essere applicato a qualsiasi fascia d'età o contesto. Lo scopo è quello di stimolare la discussione e il pensiero critico. Guardando le cose convenzionali da una prospettiva nuova, si stimola il gruppo di riferimento a riconoscere il fatto che ci possono essere altre prospettive da cui vedere il mondo ed esprimere le loro opinioni sulla questione. Promuovere la mentalità aperta e l'accettazione dei punti di vista degli altri.`,
            "1": `Preparazione del gruppo:`,
            "2": `Disporre i partecipanti in una semicerchio. Appendere la mappa in un'area nascosta della stanza, non visibile ai partecipanti. Mentre uno degli educatori descrive il compito, l'altro distribuisce i post-it e le matite ai partecipanti.`,
            "3": `Svolgimento delle attività:`,
            "4": `I partecipanti sono chiamati uno per uno ad andare in questa zona nascosta per guardare la mappa per tre secondi e scrivere subito la loro prima impressione su un post-it in max 1-2 parole, consegnarlo a uno degli educatori. Una volta che tutti lo hanno fatto, gli educatori leggono ad alta voce le risposte sui post-it e li attaccano sulla lavagna raggruppandoli in base ai modelli che emergono. Poi gli educatori mostrano la mappa al gruppo. Di solito alcune delle risposte sui post-it riconoscono quei partecipanti che hanno notato la particolarità della mappa. E qui gli educatori iniziano una discussione ponendo queste due domande stimolanti:`,
            "5": `– Chi pensi abbia ideato questa mappa e perché?`,
            "6": `– Su quali presupposti si basano le mappe tradizionali?`,
            "7": `I partecipanti possono motivare le loro risposte e la scelta delle parole che stimolano la discussione. Gli educatori facilitano e guidano la discussione per aiutare i partecipanti a pensare criticamente.`,
            "8": `Valutazione e debriefing:`,
            "9": `Quando tutti hanno avuto la possibilità di esprimere le loro opinioni sulla mappa, gli educatori pongono una domanda finale "Qual è secondo voi lo scopo di questa attività?" per stimolare ulteriore pensiero critico. Con l'aiuto degli educatori la conclusione auspicabile è questa: che le mappe convenzionali orientate a nord tendono a distorcere la visione del mondo e ad enfatizzare l'Europa e il Nord America, implicando che gli altri paesi siano meno importanti.
            Fornendo prospettive alternative sul mondo, stimoliamo il pensiero critico. Gli educatori annotano ogni parola chiave o idea che emerge dalla discussione. Come attività di follow-up, i partecipanti divisi in gruppi più piccoli (4-5 persone) vengono invitati a creare una mappa concettuale che rielabori le idee emerse dalla discussione utilizzando qualsiasi strumento a loro scelta, carta da poster, matite, app digitali, ecc.`,
            "10": `Spiega il processo di valutazione e il tipo di valutazione che sarà utilizzato, in base ai seguenti fattori:`,
            "11": ` Per il feedback verrà utilizzato il seguente questionario:`,
            "12": 'Time: 1 – 2 hours;',
            "13": 'Number of players: minimum 5, maximum 30;',
            "14": 'Local requirements: no location requirements, it’s enough to be in a space with not too much noise for the discussion to be heard, a comfortable non-formal environment.',
            "15": 'Necessary Tools: An upside-down world map (see Annex section), any sticky material to fix the map on the vertical surface, post-it notes, pens (pencils).',
            "16": 'Human Resources: 3 educators. The first has the task to explain the activity and to check on participants while they observe the map, and the second to distribute the materials for the participants and to give the instructions on how to perform the activity. Then both of them facilitate and guide the discussion. The presence of two trainers could create some disagreements between them with each other during the discussion, so it gives more perspectives and so that the participants understand that the discussion it’s not about black and white, right or wrong, but about different perspectives and opinions.',
            "17": `Criterias of evaluation`,
            "18": `Who is making the evaluation? (commission, self reflection, full group evaluation)`,
            "19": `How can you quantify the results of the activities and the learning outcome?`,
            "20": `Prepare a method for debriefing so the participants don't remain in the game mindset or still in competition mode.`,
            "21": `Criterias of evaluation: Criteria are based on the overall intensity of the discussion, individual involvement and critical thinking of each participant throughout the activity  (discussion, completion of the task).`,
            "22": `Who is making the evaluation: Educators;`,
            "23": `Quantify the results & Learning Outcomes: Credit will be given according to the active participation in the discussion, the application of critical thinking, creativity, and teamwork.`,
            "24": `Debriefing: Presentation of the concept maps to the group and discussion that foster communication skills.`,
            "25": `What was your first impression when you realised  that the world map is upside down?`,
            "26": `Did you find the activity thought provoking?`,
            "27": `What do you think is the educational purpose behind it?`,
            "28": `Would you modify anything?`,
            "29": `How?`,
            "31": `Was it helpful to you to work in a team?`,
            "32": `Was it easy to find an agreement?`,
        },
        "fakenews": {
            "submission": "Thank you for your contribution! We will review your submission and publish it as soon as possible!",
            "summary": 'Short Summary',
            "disproof": 'Disproof',
            "link": 'Link',
            "0": `Help us mark fake news articles and`,
            "1": `be part`,
            "2": `of the`,
            "3": `change`,
            "4": `Title`,
            "5": `Fake news article title. (20-100 characters)`,
            "6": `Summary description of the subject. (50-600 characters)`,
            "7": `Link`,
            "8": `Paste here the fake news URL.`,
            "9": `Clear arguments for why you consider it's fake news. (50-500 characters)`,
            "10": `Submit`,
            "11": `Fake News Alert`,
            "12": `Fake news is everywhere and everybody can contribute to making the internet a better place for finding quality and reliable information.`,
            "13": `This section allows any person who has read or noticed a fake news article to signal it and make the disinformation proof available to the public.`,
            "14": `The readers are presented with the main ideas of the articles and the disproof that supports it. Help us mark the fake news articles and be part of the change!`,
            "15": `Take a look at what other people reported as fake news!`,
            "16": `No fake news found`,
            "17": `Description`,
            "18": `Disproof`,
            "19": `Link`,
            "20": 'Europe could be left without electricity between a day and a week. The Austrian army is preparing the population for a massive "blackout"',
            "21": `This article and similar ones from the the Romanian press were announcing a huge blackout in Europe with dramatic titles such as "Blackout in Europe! Huge power outage. The army has already intervened: Get ready!” or "Europe, one step away from remaining in total darkness for days on end. Austria is preparing for a blackout. The effects would be devastating."  Besides the examples article from Antena 3, other popular news websites also promoted the fake news. Those are: Cotidianul, Opinia Timișoarei, Observator, Capital, Realitatea.net, Protv.ro,  B1TV`,
            "22": `The information that reached the Romanian press four was an assumption for the Austrian warning campaign that became a certainty for the Romanian press. The reality is that the Austrian Ministry of Defense started a campaign to train the population to know how to react in the event of a major power outage. The start of the campaign is marked on Instagram by Klaudia Tanner, the holder of the portfolio of Defence, next to a comment which says that a "power cut is an interruption of energy, infrastructure and supply at European level - it is a realistic risk and at the same time, underestimated. Therefore, our goal is to improve the resilience of our republic as a whole!”`,
            "23": 'multiple online posts selling Arbidol as a treatment medicine against Covid 19',
            "24": 'Romanians who were afraid of Covid have started to purchase Arbidol, a medicine from Russia, not authorised in the European Union. There are dozens of ads that appeared and disappeared very quickly on online sales platforms, where Arbidol was sold for between 200 and 250 lei.',
            "25": 'Initially the treatment was used in trials, but they realised it was not efficient. The Infectious Diseases Commission of the Ministry of Health, has reconfirmed this position in the protocol and the fact that its use in the treatment of Sars-CoV-2 infection was not recommended.',
            "26": 'The doctors who were treating Covid patients said that it is dangerous for patients to take drugs that can have adverse effects by themselves at home, bought online. They need to be recommended by a doctor.',
            "27": 'The Deveselu shield is a diplomatic shame, the shield has nothing to do with defense. (anti-Western and ani-NATO discourse)',
            "28": `The post attracts a series of comments that support the idea that Romania is at the mercy of NATO and that we have lost our independence: "We were supposed to be NEUTRAL, but we became Banana Republic Romania" or "It would not be appropriate for the American to take his scooters and to let us collaborate with Russia for development?" or that no matter how powerful the shield is, it won't be able to counter the arsenal Russia has - "This is junk from the Americans, for hard money, accepted by our incompetents." or "Currently, there is no anti-missile shield of any kind to stop the Russian hypersonic vanguard."`,
            "29": `The role of this propaganda is weakening the partnership with the North Atlantic Alliance and membership of the European Union. The pandemic has intensified the anti-Western discourse and radicalised some social media users. The return of the attack by Russia against Ukraine has also brought an increase in anti-Western discourse, especially that aimed at Romania's exit from the EU.`,
            "30": 'Romania concentrates its military forces on the borders with the Republic of Moldova and Ukraine',
            "31": `A column of Romanian military equipment was caught while moving towards the borders with the Republic of Moldova and Ukraine, writes the Russian press referring to a video recording made by an amateur in his own car.  The filming - also used for other disinformation - is a pretext for the resumption of theses regarding Romania's intention to occupy the Republic of Moldova and attack the separatist Transnistrian region.`,
            "32": `Although Russian media write that the images are published by Ukrainian media, they appeared on a Transnistrian telegram channel called PMR_History on April 33, and were picked up by telegram channels and media in Ukraine and Russia the next day. The column that appears in the recording does not even belong to the Romanian army - it is made up of vehicles of the US forces stationed in Romania. In fact, in parallel with the narratives regarding the movement of Romanian forces towards the border, another appeared, according to which the Americans would be the ones who would move towards the border with the Republic of Moldova.  The Romanian Ministry of Defense announced that the military equipment units in the video images were, in fact, heading towards the Babadag training ground.`,
            "33": `Ms Heard has accused Mr Depp of repeated instances of physical abuse. On Monday, she alleged that he attacked her while aboard the Orient Express train in Asia after their February 2015 wedding, claiming that he struck her and held her by the neck while in their sleeper compartment.`,
            "34": `"What I have seen throughout this trial are the symptoms of narcissistic personality disorder. I agree with the diagnosis of borderline personality disorder," said Angelica. Also Jhonny Depp won the case.`,
            "35": `A young blind guy  Mr . Faysaloğlu was given 10,000 dollars award from Apple by finding their defect.`,
            "36": `A computer programmer Utku Şen claimed that this new is fake and it is all edited by him. He explained that ; he just created fake new to throw attentions and he sent this new to websites and unfortunately the website editors published the new without checking whether it is true or not.`,
            "37": `“President Trump, basically before Elon Musk bought it, actually said to go and buy it because the goal of our company is really to build a community where people are in a family-friendly, safe environment,” Devin Nunes said in an appearance on Fox Business Network. “That’s why we encouraged Elon Musk to buy it, because someone has to take on these tech tyrants,” Nunes said. “Donald Trump wanted to make sure that the American people got their voice back and that the internet was open and that’s what we are doing.”`,
            "38": `Musk denied Nunes’ account in a tweet following publication of this article, writing, “This is false. I’ve had no communication, directly or indirectly, with Trump, who has publicly stated that he will be exclusively on Truth Social.”`,
            "39": `Allegations about the death of Alpaslan Özmol, the "Çaycı Hüseyin" of Cocuklar Duymasın (tv series), are constantly on social media. Alpaslan Özmol, who was reported to have died on social media again, said, "I am not dead."`,
            "40": `Stating that good jokes were also made in his 'Died' posts, Özmol said, "Our humor culture has reached its peak. When some see me, they come to me saying, 'Are you not dead?' I'm not tired of it, I even come up with it. They actually want to see me on TV, so I guess they did.”`,
            "41": 'Johnny Depp hit me on honeymoon, says Amber Heard',
            "42": 'A blind teenager got 10,000 dollars award by finding Apple’s defect.',
            "43": 'Elon Musk denies Trump quietly ‘encouraged’ him to buy Twitter',
            "44": 'Çaycı Huseyin Died.',
            "45": 'WATCH: MIGRANTS IN ITALY',
            "46": 'NON È L\'ARENA. GILETTI IN DIRETTA DALLE TRINCEE DI ODESSA MA IN REALTÀ È LA STAZIONE DI BARRA',
            "47": 'VATICAN BLACKOUT: Pope arrested on 80 count indictment for Child Trafficking, Fraud',
            "48": '14 Fake Olive Oil Companies Revealed – Stop Buying These Brands Now!',
            "49": `the article points out that some migrants living in Italy would commit several crimes against locals;`,
            "50": `here it is discussed the authenticity of the footage as well as the intention of the author of the source article, intended to be deliberately against migrants and migration. Apparently the footage is not even something happening in Italy but the result of a scene taken from a movie filmed in Italy in 2015. Also the videos were already on the internet way before the date of publication of the questioned article`,
            "51": `the hoax disseminates doubts about the presence of the Italian journalist Massimo Giletti in Ukraine, Odessa during the conflict towards Mosque. More specifically, it is questioned that Giletti could actually be pretending his footage filming the scenes directly from the Italian metropolitan station of Barra, in the area of Naples.`,
            "52": `In this video it is evidently shown the interview taken by the Italian journalist Massimo Giletti describing the current situation in the city of Odessa while going live to the Italian TV channel 7 for the programme "Non è l'arena". You can clearly hear someone's voice talking to him suggesting to move away.`,
            "53": `The article discusses about the alleged crimes committed by the Pope Francis`,
            "54": `In this article we can clearly attest the actual presence of the Pope Francis at his seat in Vatican city, the same day of the leaking news. This represents the major disproof of the previous disinforming news.`,
            "55": `the article discusses about some Italian olive oils accused of being produced by bad quality olives`,
            "56": `The disproof was published on the Bertolli official website, discussing in different points where and why the fake news was undoubtedly wrong and spreading the real information.`,
            "57": 'The United States provoked the war in Ukraine for economic gain',
            "58": 'Putin is a good Christian, a victim of Russophobic discourse and has nothing to do with communism, imposed on Russia by the West',
            "59": 'The Soviet regime did not organize the famine in Bessarabia',
            "60": 'The Romanian Census and possible misinformation regarding it',
            "61": `The US is turning the EU into its colony after starting the war in Ukraine. The US is waging a proxy war with Russia in Ukraine for world domination. Middle Eastern countries refused to sell Germany hydrocarbons to replace imports from Russia.`,
            "62": `First of all, it follows the already classic narrative of Russian propaganda which says that the US actually initiated the war in Ukraine through all kinds of machinations. According to this Kremlin narrative, the US would be waging a proxy war in Ukraine against Russia. Obviously, the US did not start the war in Ukraine, but this is the result of the massive Russian invasion of February 24 on the territory of Ukraine. In fact, almost a year before, Russia massed constantly and quantitatively more army and military equipment on the borders of Ukraine under the pretext of military exercises. Russia consistently denied any invasion plan until the last day before the February 25 moment. Economically, Russia created and contributed to artificially raising natural gas prices long before the invasion of Ukraine throughout 2021. Russia reduced its exports to Europe by 26% in the fourth quarter of 2022 compared to the same period in 2020, which accentuated the increase in gas prices on the Spot market in Europe. Revenues from oil and gas export taxes and tariffs accounted for 45% of Russia's federal budget in January 2023. Given current market prices, the value of Russian pipeline gas exports to the EU alone amounts to 400 million dollars a day. The total export revenue of crude oil and refined products currently stands at about US$700 million per day. The amount of Russian oil and gas entering Europe has actually increased since the start of the war in Ukraine. Europe paid Russia around 22 billion euros ($27 billion) for oil and gas in March alone, according to Bruegel, a Brussels-based think tank, cited by Nature magazine. So Russia is taking full advantage of high oil and gas prices.`,
            "63": `Vladimir Putin is a good Christian, but his image suffers because of Russophobia. These false narratives start from the statements of the Archbishop of Tomis, Theodosius, and are amplified by Sputnik. At the same time, the idea is promoted that Putin and Russia have nothing to do with communism, which was imposed by the West.`,
            "64": `Numerous authoritarian leaders and dictators have sought over time to publicly emphasize their pious side and align themselves with religious authorities in an attempt to increase their popularity and gain legitimacy. An example in this sense is that of Saddam Hussein who, although he was an exponent of a secular political movement and took measures to secularize Iraqi society in the first years in which he was in power, changed his optics after he became a pariah of the international community following the invasion of Kuwait. In his bid to win the support of the Muslim world, Saddam gave more freedom to religious leaders, ordered "Allah al-Akbar" to be emblazoned on the Iraqi flag (his own handwriting was said to have been used), let the Quran be written with his blood and more mosques be built. This piety displayed by the dictator does not mean that he is no longer guilty of the mass murders he committed, nor that he stopped committing those crimes after writing Allah al-akbar on the flag - on the contrary, some of the biggest followed that moment.`,
            "65": `The famine was not organized by the Soviet regime, and Moscow helped Moldova during the famine. Starvation and deportations are not Bolshevik terror, but lying anti-Soviet propaganda.The famine of 1947 is a consequence of the inaction of local authorities, it was not organized by the central leadership of the USSR.The research of pro-Western historians from the Republic of Moldova is biased and aims to rewrite its true history.`,
            "66": `The communist regime showed from the very beginning of the occupation of the SSR that terror is the fastest and most effective method for inoculating the new order. In addition to the arrests, deportations, assassinations carried out by the Soviet power against the population, according to historians, the organized famine killed between 150 and 350 thousand people in the SSR, one third of the population of the Republic being affected by hunger and disease. At the same time, the famine of 1946-1947 forced the peasants to accept collectivization. The taxation of the peasants by the Soviet regime began as early as 1944, immediately after the reoccupation of Bessarabia, reports Mariana Țăranu, PhD in history. The general staff raised taxes from year to year, the Soviet leadership demanded from each family a tax in products - eggs, wool, cheese, grain for the Red Army - but also a sum of money. The state also demanded other products that the people did not grow, being forced to buy them. Party organs were focused on fulfilling the plan to collect agricultural products and requisitioned people's food reserves. Families that failed to pay these taxes had their property confiscated, and the heads of the families were tried by the Military Tribunal and sentenced to forced labor. To carry out the plan, the party activists were accompanied by armed soldiers who literally swept the bridges of the peasants of their last reserves. These taxes made life in the countryside even more difficult. The Soviet leadership used the climatic conditions to camouflage the policy of starving the population. Given that in 1946 365 thousand tons of grain were harvested, half of the population's needs, and the requisition plan in Bessarabia was 255 thousand tons, famine was imminent, claims historian Anatol Țăranu`,
            "67": `Based on a statement of a representative of the Romanian National Institute of Statistics (RNIS) where it is mentioned that the results of the census will be anonymous, journalist Josefina Pascal affirms that WHO (World Health Organization) will have access to information about births, health and mortality as a result of the census and will use these maliciously.`,
            "68": `Belonging to a member of the European union, RNIS automatically forwards the results to Eurostat, who, in turn, forwards them to WHO. WHO also hase a larga database regarding global health and wellbeing that was compiled with the official help of its member states. The fact that WHO would maliciously use statistics about general health in Romania goes against the raison d’etre of this organization, whose purpose is the improvement of global health. Lately, WHO has been the preferred target of anti vaxxer conspirations that relate it to the “Great Reset”. The journalist making defamatory assumptions based on a simple statement without any proof.`,
        },
        "activities": {
            "title": `Attività`,
            "0": `Objectives`,
            "1": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "2": `Planning future activities, based on the Gantt diagram.`,
            "3": `Checking the necessary documents for the project report.`,
            "4": `Meeting content:`,
            "5": `We focused on the careful analysis of the project activities, according to which each partner body should have implemented the methods developed at LTT Ranca. Piloting methods is an important stage of the project.`,
            "6": `Another important aspect of the meeting was the verification of the documents, of the proofs of the accomplishment of each activity.`,
            "7": `Finally, the presentation of the following activities was very important, especially since this meeting marks the realization of the intellectual output no. 1 and the transition to intellectual output no. 2. The premises for the realization of the online platform were discussed and some objectives were established that must be achieved in the realization of the platform.`,
            "8": `Objectives`,
            "9": `Create the framework for experimenting with the methodology developed by each partner in the project (Non Formal methodology for critical thinking and correct information), by engaging 20 youth workers and teachers from Romania, Türkiye and Italy.`,
            "10": `Develop an incubator of non-formal methods during the training, with the purpose of improving the previously created methods, as well as diversify the means of cooperation and adaptation of non-formal education methods to the classical learning system.`,
            "11": `Encourage the cross-sectoral cooperation between public educational institutions and youth NGOs by drafting a collaboration strategy between the 4 partners for the next 2 years.`,
            "12": `Training content:`,
            "13": `We focused the activities on improving the competences in the field of critical thinking and correct information for the people engaged in the implementation of the EDUcate YOUth strategic project.`,
            "14": `By the start of the training, each partner had to have ready the task within Intellectual Output 1, as follows:`,
            "15": `Italy:`,
            "16": `A guide with several non-formal methods for youth, focused on correct information and critical thinking`,
            "17": `Educational Escape Room on CRAAP Methodology`,
            "18": `Alternative world map`,
            "19": `Türkiye:`,
            "20": `Methodology guide for the teachers and trainers, containing a selection of specially designed non-formal methods, with explanations on how  and when to put them in practice in classes.`,
            "21": `Anyone Can Volunteer`,
            "22": `Collect, create and play`,
            "23": `No Gender Stereotypes`,
            "24": `Who believes in superstitions`,
            "25": `Role play & CT Two In One Tale`,
            "26": `Romania (Horezu high-school):`,
            "27": `A debate methodology as an applied tool for the critical thinking process: Mobile debate`,
            "28": `Romania (ATDD):`,
            "29": `Interactive game to support the development of critical thinking in the field of youth: Survival in the Desert`,
            "30": `Participants from each national group shared with the consortium the developed tasks under IO1, which were experimented in a pilot mode during the training. Working language for the training was English.`,
            "31": `Objectives`,
            "32": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "33": `Planning multiplayer events, based on the Gantt diagram.`,
            "34": `Checking the necessary documents for the project report.`,
            "35": `Meeting content`,
            "36": `we focused on the careful analysis of the project activities, according to which each partner body should have implemented the task for project’s online platform.`,
            "37": `Another important aspect of the meeting was the verification of the documents, of the proofs of the accomplishment of each activity.`,
            "38": `And last but not least, we discussed the planning of multiplayer events to be held in Romania, Italy and Türkiye. Dates and responsibilities for each partner have been established.`,
            "39": `Objectives:`,
            "40": `Analyzing the level of the accomplished activities and sharing feedback with each partner.`,
            "41": `Planning future activities, based on the Gantt diagram.`,
            "42": `Checking the necessary documents for the project report.`,
            "43": `Meeting content:`,
            "44": `Project implementation status up`,
            "45": `Gantt Chart & Milestones`,
            "46": `Targets and expected results`,
            "47": `Q&A`,
            "48": `Project Management Plan`,
            "49": `Project Management team – consortium coordination discussion`,
            "50": `Project Management tools – Methodologies and Procedures, adminproject.eu`,
            "51": `Technical Reporting`,
            "52": `Quality Assurance & Monitoring Plan, including QBM- Quality Assurance Committee`,
            "53": `Financial management`,
            "54": `Budget execution and documents reporting`,
            "55": `Financial management`,
            "56": `Technical management`,
            "57": `IO1: Non-formal methodology for critical thinking and correct information`,
            "58": `IO2: Digital E-learning Platform for Critical Thinking`,
            "59": `Tasks distribution`,
            "60": `Communication and dissemination Plan`,
            "61": `Communication plan status and reporting`,
            "62": `Indicators`,
            "63": `Next steps`,
            "64": `Overall Q&A and Feedback`,
            "65": `Cultural Visit`,
            "66": `Hatay Archaeology Museum (the second largest archaeological museum in the World)`,
            "67": `St.Pierre Church (the oldest Anatolian and Near Eastern settlements. It is in the tentative list in the cultural category of UNESCO World Heritage Site)`,
            "68": `Habibi Neccar Mosque`,
            "69": `Hatay Catholic Church`,
            "70": `The Vespasianus Titus Tunnel (an ancient water tunnel built for the city of Seleucia Pieria. It is in the tentative list in the cultural category of UNESCO World Heritage Site)`,
            "71": `Between 1-2 October 2022, in the resort of Sinaia, the second mobility took place within the EDUcate YOUth - KA2 - Nonformal bridges to formal education project, financed by the European Union through Erasmus+. This second project meeting was attended by 5 teaching staff from "Constantin Brâncoveanu" High School, 5 teaching staff from Türkiye and 5 members, each from the two youth organizations from Romania (ATDD) and Italy (Beyond Borders). During the event, non-formal digital educational tools that can be applied online during formal or non-formal educational activities were presented and piloted. The methods have the role of stimulating independent critical thinking and through each digital educational tool proposed within the project, each partner country brought an important addition in the methods of developing critical thinking, as well as new methods of correct information and avoiding misinformation through various activities. The three cultural evenings brought to the fore by the participants, presented elements of culture, civilization and important traditions for effective communication in the medium and long term. A cultural visit to Brașov and Bran Castle was also organized.`,
            "72": `All the previously created 8 non-formal methods (Escape Room, Alternative World Map, Debate, Anyone can volunteer, Who believes in superstitions, Nasreddin Hodja, Collect, Create and Play, Survival in the desert) were put into practice and tested during 5 months by all the 4 partners involved in their local and national communities in the piloting phase of the project. This phase was very important because after the participants took part in the activities which used these methods, they gave the facilitators feedback about them from their point of view. Each organisation collected the feedback and made changes to the methods according to them.`,
            "73": `Methods Testing`,
            "click": "click for photos",
        },
        "footer": {
            "1": "European Commission’s support for the production of this content does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
        },
        "routes": {
            "Home": "Casa",
            "Non-Formal Methodology": "Metodologia non formale",
            "Critical Thinking and Disinformation Kit": "KIT pensiero critico / Informazioni corrette",
            "Activities": "Attività",
            "Critical Thinking Games": "Giochi di pensiero critico",
            "Non-Formal Debates": "Dibattiti non formali",
            "Fake News Alert": "FAKE NEWS alert",
        }
    },
}

export default it;