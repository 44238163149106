import { RouteName } from './routes';

export const ROUTES_ORDER: RouteName[] = [
  RouteName.Home,
  RouteName.Methodologies,
  RouteName.DisinformationKit,
  RouteName.Activities,
  RouteName.Games,
  RouteName.Debate,
  RouteName.FakeNews,
]