import { Hero } from '../../Hero/Hero';
import { Description } from '../../Description/Description';
import { Box, Container, Fade, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridItemModel } from '../../../models/GridItemModel';
import { GridItem } from '../Methodologies/MethodologiesGridItem/GridItem';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Debate() {
  const [selectedItem, setSelectedItem] = useState<GridItemModel | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { t } = useTranslation()

  const handleClickGridItem = useCallback((gridItem: GridItemModel) =>
    () => {
      if (isMobile && gridItem.href) {
        window.location.href = gridItem.href
      }

      setSelectedItem(gridItem)
      setIsModalOpen(true)
    }
  , [isMobile])

  const DEBATE_ITEMS: GridItemModel[] = [{
    title: t('debates.item1.title'),
    description: t('debates.item1.description'),
    imageName: 'https://img.youtube.com/vi/o546gMv_0B8/0.jpg',
    href: 'https://www.youtube.com/embed/o546gMv_0B8',
  }, {
    title: t('debates.item2.title'),
    description: t('debates.item2.description'),
    imageName: 'https://img.youtube.com/vi/SHhxQmwBhkE/0.jpg',
    href: 'https://www.youtube.com/embed/SHhxQmwBhkE',
  }, {
    title: t('debates.item3.title'),
    description: t('debates.item3.description'),
    imageName: 'https://img.youtube.com/vi/uwGW50Laqlc/0.jpg',
    href: 'https://www.youtube.com/embed/uwGW50Laqlc',
  }, {
    title: t('debates.item4.title'),
    description: t('debates.item4.description'),
    imageName: 'https://img.youtube.com/vi/rKt0Fg0C5Lw/0.jpg',
    href: 'https://www.youtube.com/embed/rKt0Fg0C5Lw',
  }]

  return (
    <>
      <Modal
        sx={{ display: ['none', 'block'] }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              alignItems: 'center',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '800',
              height: '600',
              backgroundColor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box>
              <iframe
                width="700"
                height="500"
                src={`${selectedItem?.href}?autoplay=1`}
                title={selectedItem?.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={{ mt: 2 }}>
              {selectedItem?.description}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Hero imageName='debate'>{t('debates.title')}</Hero>
      <Description>
        {t('debates.description1')}
        <br/>
        {t('debates.description2')}
      </Description>
      <Container>
        <Grid container rowSpacing={15}>
          {DEBATE_ITEMS.map((item: GridItemModel, index) => (
            <GridItem
              key={item.title}
              asIs={true}
              baseRoute='debate'
              onClick={handleClickGridItem(item)}
              {...item}
            />
          ))}
        </Grid>
      </Container>
    </>
  )
}