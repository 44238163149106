import { List, ListItem, ListProps } from '@mui/material';

interface EduListProps extends ListProps {
  items: any[];
  itemProps?: any;
}

export function EduList({ items, itemProps = {}, ...listProps }: EduListProps) {
  return (
    <List sx={{ listStyleType: 'disc', pl: 2 }} {...listProps}>
      {items.map((item, index) => (
        <ListItem key={index} {...itemProps} sx={{ display: 'list-item' }}>{item}</ListItem>
      ))}
    </List>
  );
}
