import React from 'react';
import { ArrowUpwardTwoTone } from '@mui/icons-material';
import { Zoom, Fab } from '@mui/material';
import styles from './ScrollToTop.styles';

function scrollToTop() {
  window.scrollTo({ behavior: 'smooth', top: 0 })
}

export function ScrollToTop() {
  return (
    <Zoom in>
      <Fab
        color="primary"
        aria-label="scroll-to-top"
        sx={styles.Fab()}
        onClick={scrollToTop}
      >
        <ArrowUpwardTwoTone />
      </Fab>
    </Zoom>
  )
}